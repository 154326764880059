<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row class="mt-1">
                  <v-col cols="12" sm="4" md="4">
                    <v-row>
                      <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="コメント"
                              class="title-input-item photo"
                            >
                              <v-textarea
                                v-model="item.inspection_comment"
                                :disabled="true"
                                no-resize
                                outlined
                                height="64.666667vh"
                              >
                              </v-textarea>
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <div>
                      <v-carousel
                        height="57.791537667698655vh"
                        v-model="image_active"
                        hide-delimiters
                        @change="changeImageActive"
                      >
                        <template v-slot:prev="{ on, attrs }" v-if="myItems.length != 0">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            :class="image_active !== 0 ? 'button-slide' : 'd-none'"
                          >mdi-chevron-left
                          </v-icon>
                        </template>
                        <template v-slot:next="{ on, attrs }" v-if="myItems.length != 0">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="button-slide"
                            :class="image_active !== (myItems.length - 1) ? 'button-slide' : 'd-none'"
                          >mdi-chevron-right
                          </v-icon>
                        </template>
                        <v-carousel-item
                          v-for="(item,index) in myItems"
                          :key="index"
                          :src="item.image_url? getUrl(item.id): ''"
                          contain
                          class="big-image"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>
                    <div class="ml-13 mr-13">
                      <MultipleImageSlider
                        :items="myItems"
                        :editable="false"
                        style="margin-top: 15px;"
                        :sourceImages="sourceImages"
                        @changeImageActive="changeImageActive" />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
  <script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import MultipleImageSlider from "./MultipleImageSlider.vue";
//  import { Store } from "@/store/Store.js";
//  import Popup from "../../../common/Popup.vue";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      isCheckBox: String,
      editable: false,
      image_active: null,
      myItems: [],
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputTextArea,
    MultipleImageSlider
    // Popup,
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    imageActive: Number,
    item: Object,
    sourceImages : Array,
  },
  async mounted() {
    this.$watch(
      () => this.imageActive,
      (data) => {
        if (data) {
          this.changeImageActive(data);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.myItems = [...data?.before_delivery_images];
      },
      {
        immediate: true,
        deep: true
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    getUrl(id) {
      return this.sourceImages.find(e => e.id == id)?.url;
    },
    changeImageActive(index) {
      if (index || index == 0) this.image_active = index;
      let items = [...this.myItems];
      items.forEach((e, index) => {
        if (index == this.image_active) {
          e.check = true;
        } else {
          delete e.check;
        }
      });
      this.myItems = [...items];
      let element = document.getElementById(`image_patrol_${this.image_active}`);
      if (element) {
        element.scrollIntoView({ block: "end" });
      }
    }
  },
};
</script>
  <style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}

.title-input-item.photo .label{
  font-weight: 400;
  font-size: 16px;
}
.button-slide.v-icon {
  color: rgba(0, 0, 0, 0.87) !important;
  background: none !important;
  font-size: 82px !important;
  top: 50%;
  transform: translate(0, -50%);
}
.button-slide.v-icon:after {
  background: none !important;
}

.v-window__next, .v-window__prev {
  background: none !important;
}
.big-image .v-image.v-responsive {
  width: 46.875vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.v-window__next, .v-window__prev {
  margin: 0 -28px !important;
}
</style>
  