<template>
  <SingleLayout>
    <v-card class="mx-auto my-12" max-width="374" outlined>
      <div class="login-header">D2T</div>
      <div></div>
      <ValidationObserver v-slot="{ invalid }">
        <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
          <!-- テキスト入力 -->
          <v-list-item>
            <v-list-item-content>
              <Label label="メールアドレス" :editable="editable">
                <InputText
                  name="login_id"
                  :values="formValue"
                  :editable="editable"
                  placeholder="メールアドレス"
                  validation_label="メールアドレス"
                  validation_rules="minmax:6,10"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- セレクト -->
          <v-list-item>
            <v-list-item-content>
              <Label label="タイプ" :editable="editable" required>
                <Select
                  name="types"
                  :values="formValue"
                  :items="formItem.types"
                  :editable="editable"
                  validation_label="タイプ"
                  validation_rules="required"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- パスワード入力 -->
          <v-list-item>
            <v-list-item-content>
              <Label label="パスワード" :editable="editable" required>
                <Password
                  name="password"
                  :values="formValue"
                  :editable="editable"
                  placeholder="パスワード"
                  validation_label="パスワード"
                  validation_rules="required|password|minmax:8,16"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <Label label="日付" :editable="editable" required>
                <InputDatepicker
                  name="date"
                  @onInput="onInput"
                  :values="formValue"
                  validation_label="日付"
                  validation_rules="required"
                  :editable="editable"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- ファイルアップロード -->
          <v-list-item>
            <v-list-item-content>
              <Label
                label="ファイルのアップロード"
                :editable="editable"
                required
              >
                <FileInput
                  name="file"
                  @onInput="onInput"
                  :values="formValue"
                  :editable="editable"
                  validation_label="ファイルのアップロード"
                  validation_rules="required"
                  placeholder="ファイルを選択"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- チェックボックス -->
          <v-list-item>
            <v-list-item-content>
              <Label label="チェック項目" :editable="editable" required>
                <Checkbox
                  name="check_items"
                  :values="formValue"
                  :items="formItem.check_items"
                  :editable="editable"
                  validation_label="チェック項目"
                  validation_rules="required"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- ラジオグループ -->
          <v-list-item>
            <v-list-item-content>
              <Label label="ラジオグループ" :editable="editable" required>
                <RadioGroup
                  name="radio_group_items"
                  :values="formValue"
                  :items="formItem.radio_group_items"
                  :editable="editable"
                  validation_label="ラジオグループ"
                  validation_rules="required"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- 最低血圧 -->
          <v-list-item>
            <v-list-item-content>
              <Label label="最低血圧" :editable="editable">
                <InputNumber
                  name="blood_pressure"
                  :values="formValues"
                  :editable="editable"
                  placeholder="79"
                  validation_label="最低血圧"
                  validation_rules="max:32"
                  @onInput="onInput"
                />
              </Label>
            </v-list-item-content>
          </v-list-item>

          <!-- 可変長フォーム（フォームの数を増やす） -->
          <v-list-item>
            <v-list-item-content>
              <SampleValiableInput
                name="items"
                :values="formValue"
                :editable="editable"
                @onInput="onInput"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                height="48px"
                color="primary"
                depressed
                :disabled="invalid"
                @click="Submit()"
                >Submit</v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-form>
      </ValidationObserver>
    </v-card>
  </SingleLayout>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select";
import Password from "@/components/forms/elements/Password";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import FileInput from "@/components/forms/elements/FileInput";
import Checkbox from "@/components/forms/elements/Checkbox";
import RadioGroup from "@/components/forms/elements/RadioGroup";

const TYPES = [
  { id: 0, name: "AAA" },
  { id: 2, name: "BBBB" },
  { id: 3, name: "CCCCC" },
];

const CHECK_ITEMS = [
  { id: 0, name: "土", value: "value1" },
  { id: 1, name: "建", value: "value2" },
  { id: 2, name: "大", value: "value3" },
  { id: 3, name: "左", value: "value4" },
  { id: 4, name: "と", value: "value5" },
  { id: 5, name: "石", value: "value6" },
  { id: 6, name: "屋", value: "value7" },
  { id: 7, name: "電", value: "value8" },
  { id: 8, name: "管", value: "value9" },
];

const RADIOGROUP_ITEMS = [
  { id: 0, name: "加入", value: 0 },
  { id: 1, name: "未加入", value: 1 },
  { id: 2, name: "適用除外", value: 2 },
];

export default {
  components: {
    SingleLayout,
    ValidationObserver,
    Label,
    InputText,
    Select,
    Password,
    InputDatepicker,
    FileInput,
    Checkbox,
    RadioGroup,
  },
  data: () => {
    return {
      formValue: {
        types: TYPES[0],
        login_id: "123",
        password: "",
        file: "",
        items: [{ sei: "", mei: "" }],
      },
      formItem: {
        types: TYPES,
        check_items: CHECK_ITEMS,
        radio_group_items: RADIOGROUP_ITEMS,
      },
      // members: [{ name: "", types: TYPES[0] }],
      editable: true,
      valid: false,
    };
  },
  methods: {
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
    },
    async Submit() {
    },
    addFormElement() {
      this.formValue.members.push({ name: "", types: TYPES[0] });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  color: green;
}
</style>
