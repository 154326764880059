<template>
   <v-card class="dialog">
     <v-card-title>
       <div class="title">{{ title }}</div>
     </v-card-title>
     <v-card-text class="body">
       <div class="text-wrap">
         <div v-if="description" class="text-description" v-html="description"></div>
         <div class="text">{{ text + (getNameCode) }}</div>
       </div>
       <v-card-actions class="justify-center flex-column">
         <v-btn center @click="getFile" class="btn-download-qr" >
           QRコードをダウンロード
         </v-btn>
         <div class="img-qr">
          <v-img v-if="qrBase64.length" class=".qr-image" :src="getBase64"></v-img>
         </div>
       </v-card-actions>
     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn
         depressed
         outlined
         class="btn-qr-close"
         @click="$emit('close')"
       >
         閉じる
       </v-btn>
     </v-card-actions>
   </v-card>
 </template>
 
 <script>
 
 import { qr } from "@/api/modules/qr";
 import { Store } from "@/store/Store.js";
 import { DOCUMENT_TYPE } from "@/constants/EXPORT";
 
 export default {
   data(){
     return {
       qrBase64 : '',
       nameCode : '',
       fileNamePdf: '',
       fileNameExcel: '',
     }
   },
   computed: {
      getBase64(){
        return this.qrBase64;
      },
      getNameCode(){
        return this.nameCode;
      }
   },
   props: {
     text: {
       type: String,
     },
     title: {
       type: String,
     },
     data: {
       type: Object,
     },
     description : {
       type: String
     },
     type : {
       type: String
     },
   },
   async created() {
     await this.getQRCode();
   },
   async mounted() {
     this.$watch(
       () => [Store.getters["File/getData"], Store.getters["File/getTypeDoc"]],
       (data) => {
         if (data && data[0] && data[1]) {
           let nameFile = 'Field_QR_code_' + this.data.field_code + '_' + this.data.name;
           const url = window.URL.createObjectURL(new Blob([data[0]]));
           const link = document.createElement("a");
           link.href = url;
           link.setAttribute("download",
             `${nameFile}.${
               data[1] == DOCUMENT_TYPE.PDF ? "pdf" : "xlsx"
             }`
           );

           document.body.appendChild(link);
           link.click();
           Store.dispatch("File/clearData");
         }
       },
       {
         deep: true,
       }
     );
   },
   methods:{
     async getQRCode() {
       let response = await qr.getFieldQrCode({field_id : this.data.field_id});
       if (response.data && response.data.status_code === 200) {
         this.qrBase64 = response.data?.contents?.entries?.qr_code;
         this.nameCode = response.data?.contents?.entries?.field_code;
       }
     },
     async getFile() {
       await this.downloadDocument({field_id: this.data.field_id, file_code: DOCUMENT_TYPE.PDF});
     },
 
     async downloadDocument(params) {
       const configParams = {
         url: `${process.env.VUE_APP_API_BASE_URL}/reports/export_field_qr_code`,
         params: params,
       };
       await Store.dispatch("File/download", configParams);
     },
   }
 }
 </script>
 
 <style lang="scss" scoped>
 .dialog {
   width: 480px;
   height: 570px;
   background: #FFFFFF;
   border-radius: 8px;
   .v-card__title{
     font-size: 16px;
     font-weight: 400;
     padding: 37px 28px 30px 28px;
     color : #274FC2;
   }
   .btn-download-qr{
     background: #274FC2 !important;
     border-radius: 4px;
     color: #FFFFFF;
     font-weight: 500;
     font-size: 14px;
     text-align: center;
     letter-spacing: 1.25px;
     text-transform: uppercase;
     padding: 10px 30px !important;
     line-height: 16px;
     margin-bottom: 20px;
   }
   .body {
     .text-wrap {
       .text, .text-description {
         color: rgba(0, 0, 0, 0.6);
         letter-spacing: 0.5px;
         font-size: 16px;
         padding-left: 20px;
         padding-right: 20px;
         font-weight: 400;
         margin-bottom: 20px;
       }
       .text-description {
         margin-bottom: 20px;
       }
     }
   }
   .btn-qr-close{
     color: #274FC2;
     padding: 10px 20px !important;
     position: absolute;
     bottom: 20px;
     right: 20px;
     letter-spacing: 1.25px;
     font-weight: 500;
   }
   .qr-image{
     border: 1px solid #274FC2;
   }
   .img-qr {
     width: 225px;
     height: 225px;
     border: 1px solid #274FC2;
     padding: 25px;
   }
 }
 </style>
 
