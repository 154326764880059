<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page="{ layoutParams }">
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab5"
          >
            <template #tableHeader="{ updateHeader }">
              <TableHeader
                ref="tableHeader"
                :pageTitle="TITLE"
                :isDelete="false"
                :isAddNew="false"
                :isClassify="false"
                :updateHeader="updateHeader"
              >
                <SearchFormWrapper>
                  <!-- ロール -->
                  <Label label="期間" class="title-input-item" style="max-width: 224px">
                    <InputMonthpicker
                      name="startUseFieldDate"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :flagNull="true"
                      validation_label="期間 (左) "
                      :validation_rules="getStartMonthRules()"
                    />
                  </Label>
                  <span class="mt-12 ml-3">
                    <v-icon>mdi-tilde</v-icon>
                  </span>
                  <Label label="期間" class="title-input-item" style="max-width: 224px">
                    <InputMonthpicker
                      name="endUseFieldDate"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :flagNull="true"
                      validation_label="期間（右）"
                      :validation_rules="getEndMonthRules()"
                    />
                  </Label>
                  <Label label="協力会社" class="title-input-item">
                    <Select
                      name="companySubId"
                      :values="searchParams"
                      :items="companyItem"
                      item_text="name"
                      :editable="true"
                      validation_label="協力会社"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="ナンバー" class="title-input-item">
                    <InputText
                      name="machinesNumberPlate"
                      :values="searchParams"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <!--  (共通) 検索ボタン -->
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-6 mt-10 btn-on-search-constractor"
                    color="primary"
                    depressed
                    @click="onSearch(invalid)"
                  >
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </TableHeader>

              <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
              <TableSortWrapper>
                <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
                <TableSort
                  :values="searchParams"
                  :sort_items="SORT_ITEMS_BY_SUB"
                  sort_item_text="name"
                  sort_item_value="id"
                  :PageSize="searchParams.pageCount"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3 sort_report"
                />
              </TableSortWrapper>
            </template>
            <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
            <template #tableBody="{ tableHeight }">
              <template>
                <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                  <v-tab 
                    v-for="tab in TAB" 
                    :key="tab.id" 
                    @click="changeTab(tab.id)" 
                    color="primary" 
                    class="tab-machine primary--text">
                    {{tab.name}}
                  </v-tab>
                </v-tabs>
              </template>
              <v-data-table
                :item-key="tabShare == 1 ? 'list_id' : 'bookingId'"
                v-model="selectedItems"
                :headers="tabShare == 0 ? TABLE_LABELS_RESULT_BY_SUB_CONTACTOR : TABLE_LABELS_RESULT_BY_SUB_CONTACTOR_SHARE"
                :items="items"
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 158 : tableHeight - 203"
                fixed-header
                hide-default-footer
                disable-sort
                class="elevation-1 v-data-table__wrapper"
                sort-by="updatedAt"
                show-select
                :noDataText="NO_DATA_MESSAGE"
                @item-selected="updateSelectedItems"
                @toggle-select-all="selectAllItems"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
              >
                <template v-slot:top>
                  <v-btn
                    color="primary"
                    depressed
                    class="ml-4 mt-2 mb-2"
                    left
                    @click="exportCsv()"
                    :disabled="disableRemoveBtn"
                  >
                    出力
                  </v-btn>
                  <v-tabs v-model="tabShare" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                    <v-tab 
                      v-for="tab in TAB_SHARE" 
                      :key="tab.id" 
                      @click="changeTabShare(tab.id)" 
                      color="primary" 
                      class="tab-machine primary--text">
                      {{tab.name}}
                    </v-tab>
                  </v-tabs>
                </template>
                <template v-slot:[`item.companyName`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ convertName(item.companyName, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.numberPlate`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ item.numberPlate }}
                  </div>
                </template>
                <template v-slot:[`item.machineSpecs`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ item.machineSpecs }}
                  </div>
                </template>
                <template v-slot:[`item.bookingDate`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{
                      convertDateBooking(
                        item.useStartDate,
                        item.useEndDate,
                        item.useStartTime,
                        item.useEndTime
                      )
                    }}
                  </div>
                </template>
                <!-- tab 占有機 -->
                <template v-slot:[`item.dateBooking`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ floatFormat(item.dateBooking) }}日
                  </div>
                </template>

                <!-- tab 共用機 -->
                <template v-slot:[`item.total_tenken`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ item.total_tenken ? item.total_tenken : '0' }}回
                  </div>
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;"
              />
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  TAB,
  TAB_SHARE,
  TITLE,
  TABLE_LABELS_RESULT_BY_SUB_CONTACTOR,
  TABLE_LABELS_RESULT_BY_SUB_CONTACTOR_SHARE,
  SORT_ITEMS_BY_SUB,
  TAB_RESULTS,
} from "@/constants/REPORT";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import { convertName, dateFormat, convertDateBooking, floatFormat, replaceDate } from "@/common/helper";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {format} from "date-fns";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Reports";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      TITLE,
      TABLE_LABELS_RESULT_BY_SUB_CONTACTOR,
      TABLE_LABELS_RESULT_BY_SUB_CONTACTOR_SHARE,
      SORT_ITEMS_BY_SUB,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TAB,
      TAB_SHARE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},
      defaultOption: { id: "", name: "" },
      companyBranches: {},

      companyItem: [],

      // 検索パラメータ
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
        startUseFieldDate: "",
        endUseFieldDate: "",
        machinesNumberPlate: "",
        companySubId: [],
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      tab: TAB_RESULTS,
      tabShare: 0,
      flagUserRentalCompany: true,
      fieldId: null,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    InputText,
    Select,
    Label,
    InputMonthpicker,
    SitePortalHeader,
    ValidationProvider,
    ValidationObserver,
  },

  async mounted() {
    let today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    this.searchParams.startUseFieldDate = yyyy + "/" + mm;
    this.searchParams.endUseFieldDate = yyyy + "/" + mm;
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;

    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items;

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[0].last_page;
        searchParams.currentPage = data[0].current_page;
        searchParams.total_item = data[0].total;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Reports/getCompanySub`],
      (data) => {
        this.companyItem = [this.defaultOption, ...data];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.name,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        companySubId: this.searchParams.companySubId,
        startUseFieldDate: this.searchParams.startUseFieldDate,
        endUseFieldDate: this.searchParams.endUseFieldDate,
        machinesNumberPlate: this.searchParams.machinesNumberPlate,
        fieldId: this.fieldId,
        is_shared: this.tabShare ? 1 : null
      };
    },
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid) {
        await this.getItems();
        this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab5.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }, invalid) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid) {
        this.getItems();
        this.selectedItems = [];
      }
    },
    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`Reports/getBookingInfoBySubContractor`, {
        params: this.apiParams,
      });
    },

    updateSelectedItems(value) {
      if (this.tabShare === 1) {
        let currentItems = value.value
          ? this.items.filter(
              (element) => element.list_id === value.item.list_id
            )
          : [];
        if (currentItems.length > 0) {
          this.selectedItemsRemoved.push(currentItems[0]);
          this.selectedItems.push(...currentItems);
        } else {
          this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
            (item) => item.list_id !== value.item.list_id
          );
          this.selectedItems = this.selectedItems.filter(
            (item) => item.list_id !== value.item.list_id
          );
        }
        
        return
      }

      let currentItems = value.value
        ? this.items.filter(
            (element) => element.bookingId === value.item.bookingId
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.bookingId !== value.item.bookingId
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.bookingId !== value.item.bookingId
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.bookingId)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.bookingId);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },

    changeTab(id) {
      Store.dispatch(`${STORE}/setSelected`, id);
    },
    resetData(){
      this.searchParams["totalPage"] = 0;
      this.items = [];
      this.searchParams["currentPage"] = 1;
      this.searchParams["pageCount"] = PAGE_COUNT;
    },
    changeTabShare (id) {
      this.resetData();
      this.tabShare = id;
      this.selectedItems = [];
      this.getItems();
    },
    convertDateBooking(start_date, end_date, start_time, end_time) {
      let startDate = replaceDate(start_date);
      let endDate = replaceDate(end_date);
      return convertDateBooking(startDate, endDate, start_time, end_time);
    },
    floatFormat(number) {
      return floatFormat(number);
    },
    async exportCsv() {
      
      const searchParams = { ...this.apiParams };
      if (this.tabShare === 1) {
        const list_id = this.selectedItems.map((items) => items.list_id);
        searchParams.list_id = list_id;
      }
      else {
        const booking_ids = this.selectedItems.map((items) => items.bookingId);
        searchParams.booking_ids = booking_ids;
      }
      const response = await Store.dispatch(
        `${STORE}/exportBookingInfoBySubcontractor`,
        searchParams
      );
      const { file, file_name } = response.data.contents;
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const url = window.URL.createObjectURL(new Blob([byteArray]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
    },
    getStartMonthRules() {
      let fieldEndDate = this.searchParams?.endUseFieldDate;
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01');
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let compareFieldStartDate = fieldEndDate ? "required|" + `compare-field-start-date:${fieldEndDate},期間（左）,期間（右）` : "required";
      return compareFieldStartDate;
    },
  
    getEndMonthRules() {
      let fieldStartDate = this.searchParams?.startUseFieldDate;
      fieldStartDate = fieldStartDate + '/01';
      let compareFieldEndDate = "";
      if (fieldStartDate) {
        compareFieldEndDate = "required|" + `compare-field-end-date:${fieldStartDate},期間（右）,期間（左）`;
      }
      return compareFieldEndDate;
    },
  },
};
</script>
<style lang="css" scoped>
@media only screen and (width: 1024px) {
  .btn-on-search-constractor {
    margin-left: 15px;
  }
  
}
  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(1) {
    padding-right: 0;
  }

  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(2) {
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    .SearchFormWrapper .labelWrapper {
      max-height: inherit !important;
      height: auto !important;
    } 
  }

.cst-row {
  border-bottom: 1px solid;
  padding-top: 12px;
  border-color: #E5E5E5;
  padding-left: 30px;
}
</style>
