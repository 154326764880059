<template>
  <v-app id="app" :class="$sp ? 'sp' : ''">
    <transition>
      <router-view />
    </transition>
    <ErrorMessage />
    <ToastMessage />
    <TokenErrorMessage />
    <GlobalHeaderDrawer />
    <NotificationDrawer />
    <Tooltip />

  </v-app>
</template>

<script>
import Vue from "vue";
import ErrorMessage from "@/components/error/ErrorMessage";
import TokenErrorMessage from "@/components/error/TokenErrorMessage";
import ToastMessage from "@/components/toast/ToastMessage";
import Tooltip from "@/components/tooltip/Tooltip";
import GlobalHeaderDrawer from "@/components/globalHeader/components/GlobalHeaderDrawer";
import NotificationDrawer from "@/components/globalHeader/components/NotificationDrawer";
import window from "@/plugins/window";
import sp from "@/plugins/sp";
import { Store } from "@/store/Store";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";
import device from "vue-device-detector";

Vue.use(device);
Vue.use(window);
Vue.use(sp);

export default {
  name: "App",
  components: {
    ErrorMessage,
    TokenErrorMessage,
    ToastMessage,
    GlobalHeaderDrawer,
    NotificationDrawer,
    Tooltip,

},
  data: () => {
    return {};
  },
  computed: {
    isMobile() {
      return this.$device.iphone || this.$device.androidPhone;
    },
  },
  async mounted() {
    //if (this.isMobile && this.$route?.path !== "/doesnotsupportmobile" && this.$route?.path !== "/password_reset" && this.$route?.path !== "/termsofservice") {
    //  this.$router.push("/doesnotsupportmobile");
    //  return;
    //}
    //通常
    if (process.env.VUE_APP_NODE_ENV !== "local") this.getToken();
  },
  methods: {
    async getToken() {
      const flag = localStorage.getItem(LOGIN_FLAG_KEY);
      const isLoginPage = this.$route.path === "/";
      if (flag && !isLoginPage) {
        const response = await Store.dispatch("Login/loginReacqire");
        if (response.hasError) {
          // 再ログイン失敗でログイン画面へ遷移
          window.location.href = "/";
          return;
        }
        await Store.dispatch("GlobalHeader/init");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/responsive.scss";
#app {
  overflow: hidden;
  &.sp {
    max-width: $sp;
    margin: auto;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
@import "@/assets/scss/overRideVuetify.scss";
</style>
