<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          会社情報
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onStopEditCF"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="handleSave"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <FormCompany
          :editable="editable"
          :detailData="item"
          :actions="actions"
          :dataSelectCompany="dataSelectCompany"
          @openListCompany="openListCompany"
          @searchListCompany="searchListCompany"
          :mainHeight="params.mainHeight"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="onStopEdit"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirm">
        <Confirm
          title="会社登録"
          text1="登録したメールアドレスにログイン情報を記載したメールが送信されます。"
          text2="よろしければ「登録」ボタンを、修正する場合は「戻る」ボタンを選択してください。"
          @close="onClickBtnCancelConfirm"
          @yes="saveData"
          warning
        />
      </Popup>
      <Popup width="900px" :dialog="isShowItemForm">
        <div class="popup-company">
          <ListCompany
            :items="dataListCompany"
            @dataCheckCompany="handCheckCompany"
            @cancel="closeItemForm"
          />
        </div>
      </Popup>
      <Popup width="480px" :dialog="isShowQR">
        <ShowQR
          @close="closeShowQrCode()"
          description="法人番号が重複しています。<br>
            スマホアプリからアカウント作成をお願いします。"
          title="所属会社QRコード"
          :data="dataRegister"
          text="所属会社コード："
          type="field"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ListCompany from "../popup/ListCompany.vue";
import { Store } from "@/store/Store.js";
import FormCompany from "./FormCompany.vue";
import ShowQR from "@/components/qr/ShowQR";
import Confirm from "../popup/ConfirmCompany.vue";
import _ from "lodash";
import { COMPANY_SCENE, MODE_ADD, MODE_EDIT } from "@/constants/COMMON";

const FORMS = {
  BasicInfo: {
    id: 1,
    title: "基本情報"
  },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      isShowQR: false,
      errors: {},
      isSubmitted: false,
      idSelect : null,
      isShowItemForm: false,
      dataCompanyTest: null,
      dataSelectCompany: null,
      dataRegister: null,
      keySearch: '',
      dataListCompany: {},
      isShowConfirm: false,
      COMPANY_SCENE,
      MODE_ADD,
      MODE_EDIT,
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormCompany,
    FormDialog,
    ListCompany,
    ShowQR,
    Confirm
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    actions: String,
  },

  computed: {
    getDataDetail() {
      return Store.getters[`Companies/getCompany`];
    },
  },

  async mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.item,
      (data) => {
        if (data) this.dataRegister = { ...data };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    formUpdate(params) {
      this.dataRegister = params;
      this.$emit("formUpdate", params);
    },

    onQR(){
      this.isShowQR = true;
    },

    onEditable() {
      this.editable = true;
    },

    onStopEdit() {
      if (this.actions == 'create' || this.isClickCloseBtn) {
        this.$emit("cancel");
      } else {
        this.item = _.cloneDeep(this.getDataDetail);
        this.editable = false;
        this.isShowConfirmDialog = false;
      }
    },

    onStopEditCF() {
      this.isShowConfirmDialog = true;
    },

    handleSave() {
      if (this.dataRegister.id) {
        this.saveData();
      } else {
        this.isShowConfirm = true;
      }
    },

    async saveData() {
      let method = this.dataRegister.id ? this.MODE_EDIT : this.MODE_ADD;
      if (this.dataRegister.company_type === COMPANY_SCENE) {
        this.dataRegister.plate_number_prefix = '';
      }
      const res = await Store.dispatch(`Companies/${method}`, this.dataRegister);
      if (!res.hasError && res.data.status_code === 200) {
        if (method === this.MODE_ADD) {
          this.$emit("cancel");
        } else {
          this.editable = false;
        }
        Store.dispatch("Toast/show", {
            status: "200",
            message: res.data.message
          },
          {
            root: true
          }
        );
      }
      this.isShowConfirm = false;
      this.$emit("reloadCompany");
    },

    updateValidate(params) {
      const { valid, errors } = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    closeShowQrCode(){
      this.isShowQR = false;
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    handCheckCompany(data) {
      if (data) {
        this.dataSelectCompany = data;
        this.closeItemForm();
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelConfirm() {
      this.isShowConfirm = false;
    },

    openListCompany(name) {
      this.keySearch = name.keyword
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },

    async searchListCompany() {
      const res = await Store.dispatch('Register/getCompany', {
        name: this.keySearch
      });
      if (res.data.count > 0) {
        this.dataListCompany = res.data.corporation
        this.isShowItemForm = true;
      } else {
        Store.dispatch("Error/show", {
            status: "422",
            message: "データがありません。"
          },
          {
            root: true
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title-popup{
  color: #274FC2;
}
.popup-company{
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
