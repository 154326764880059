import Vue from "vue";
import Vuex from "vuex";
import { machine } from "@/api/modules/machineScene";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = machine;
export const Machine = {
  namespaced: true,

  state: {
    machines: [],
    company: [],
    spaces: [],
    gates: [],
    delivery: [],
    deTailTenken: [],
    isLoading: {},
  },
  mutations: {
    SET_DATA_MACHINE(state, payload) {
      state.machines = payload;
    },
    SET_DATA_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_DATA_SPACES(state, payload) {
      state.spaces = payload;
    },
    SET_DATA_GATES(state, payload) {
      state.gates = payload;
    },
    SET_DATA_DELIVERY(state, payload) {
      state.delivery = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_DATA_TENKEN(state, payload) {
      state.deTailTenken = payload;
    },
  },

  actions: {
    async getListFieldInventory({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldInventory(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const machines = response.data.contents.entries;
        commit("SET_DATA_MACHINE", machines);
      }
      return response;
    },
    async getListFieldCompanyByRole({ commit }, payload) {
      const response = await ENTITY.getListFieldCompanyByRole(payload);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const company = response.data.contents.entries;
        commit("SET_DATA_COMPANY", company);
      }
      return response;
    },
    async getListSpaceByFieldId({ commit }, payload) {
      const response = await ENTITY.getListSpaceByFieldId(payload);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const spaces = response.data.contents.entries;
        commit("SET_DATA_SPACES", spaces);
      }
      return response;
    },
    async getListGateByFieldId({ commit }, payload) {
      const response = await ENTITY.getListGateByFieldId(payload);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const gates = response.data.contents.entries;
        commit("SET_DATA_GATES", gates);
      }
      return response;
    },
    async getListDeliveryMachineOnField({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListDeliveryMachineOnField(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const delivery = response.data.contents.entries;
        commit("SET_DATA_DELIVERY", delivery);
      }
      return response;
    },
  },
  getters: {
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
