<template>
  <FormDialog>
    <template #header>
      <!-- 
         (共通)
         ヘッダーは全て共通
        -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <template>
          <v-toolbar-title color="primary" class="primary--text">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted"
          :key="isErrorSubmit"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </template>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TAB_PRODUCTION_INFO" :key="key" @click="changeTab(form.id)">
              {{ form.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
         (共通)
         全てのフォームのバリデーションの監視を行う
        -->
      <ValidationObserver ref="observer" v-slot="observer">
        <InspectionInformationPage1 v-if="selectedTab == 2"
          :editable="editable"
          :detailData="item"
          :beforeUpdate="beforeUpdate"
          :mainHeight="params.mainHeight"
          :actions="actions"
          :active_flg="item.active_flg"
          @formUpdate="formUpdate"
        />
        <InspectionInformationPage2 v-if="selectedTab == 1"
          :editable="editable"
          :detailData="item"
          :mainHeight="params.mainHeight"
          :actions="actions"
          :active_flg="item.active_flg"
          @formUpdate="formUpdate"
        />
        <!-- 
           (共通)
           ValidationObserverのスロットプロパティを受け取って
           updateValidateメソッドを実行して、
           バリデーション結果をスクリプト側に渡してます
         -->
         
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
 
 <script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { TITLE_FORM_1,TAB_PRODUCTION_INFO } from "@/constants/MACHINE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import InspectionInformationPage1 from "./components/InspectionInformationPage1";
import InspectionInformationPage2 from "./components/InspectionInformationPage2";
const STORE = "Machines";
import { format, parseISO } from "date-fns";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE_FORM_1,
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      actions: "",
      TAB_PRODUCTION_INFO,
      selectedTab : 2,
      isNewItem: false,
      detailData: {}
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    InspectionInformationPage1,
    InspectionInformationPage2
  },
  props: {
    item: Object,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    isShowItemForm : true,
    isShowItemForm2 : false,
    beforeUpdate: {}
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = false;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`${STORE}/getOnSiteMachineInfo`], this.editable],
      (data) => {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * Catch after submitted
     */
    this.$watch(
      () => this.isEmitted,
      () => {
        if (!this.isErrorSubmit) {
          this.editable = false;
        }
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return TITLE_FORM_1;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit("submit");
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通) 
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.beforeUpdate = {...this.beforeUpdateItem.entries};
        this.editable = false;
        this.beforeUpdate.end_bookable_date = this.dateFormat(this.beforeUpdate.end_bookable_date);
        this.beforeUpdate.start_bookable_date = this.dateFormat(this.beforeUpdate.start_bookable_date);
        this.item = this.beforeUpdate;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    changeTab(id) {
      this.selectedTab = id;
    },
    dateFormat(date) {
      return format(parseISO(date), "yyyy/MM/dd");
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
 
 <style lang="sass" scoped>
.from-close-btn
  float: right
</style>
 