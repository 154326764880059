<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isClassify="true"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openClassifyDialog="openClassifyDialog"
            >
              <SearchFormWrapper>
                <!-- 文字入力 -->
                <Label label="Name">
                  <InputText
                    name="name"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- ロール -->
                <Label label="Description" width="350px">
                  <InputText
                    name="description"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn class="mr-6" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <v-data-table
              item-key="id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-1 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @item-selected="updateSelectedItems"
              @toggle-select-all="selectAllItems"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.name`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 280px;
                  "
                >
                  {{ convertName(item.name, 50) }}
                </div>
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                >
                  {{ convertName(item.description, 50) }}
                </div>
              </template>
              <template v-slot:[`item.age`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 470px;
                  "
                >
                  {{ convertName(item.age, 65) }}
                </div>
              </template>
              <template v-slot:[`item.sex`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                >
                  {{ item.sex === 1 ? 'Male' : item.sex === 2 ? 'Female' : 'None' }}
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                  "
                >
                  {{ formatDate(item.created_at) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <CrudForm
        :flag="true"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :companyBranches="companyBranches"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItemsRemoved"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog"; //削除確認ダイアログ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import CrudForm from "@/components/forms/crud/CrudForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  CRUD_TABLE_LABELS,
  CRUD_SORT_ITEMS,
  CRUD_INITIAL_ITEM,
} from "@/constants/FIELDS"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import _ from "lodash";
import {convertName, dateFormat} from "@/common/helper";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "CRUD SAMPLE";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Cruds";

//テーブルヘッダーラベル
const TABLE_LABELS = CRUD_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = CRUD_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = CRUD_INITIAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
        companyBranchId: null,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    CrudForm,
    InputText,
    Select,
    Label,
  },

  async mounted() {
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items.map((x) => ({
          ...x
        }));

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        this.selectedItems,
      ],
      
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.name,
        description: this.searchParams.description,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      let params = { id: item.id}
      const siteInfo = await Store.dispatch(
        `${STORE}/getDetail`,
        {params}
      );
      // Show Detail Form
      if (!siteInfo.hasError) {
        this.editedItem = _.cloneDeep(siteInfo.data.contents.entries);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/get`, { params: this.apiParams });
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { ids });

      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.id === value.item.id
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      }
      else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.id !== value.item.id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.id !== value.item.id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.id);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    }
  },
};
</script>
