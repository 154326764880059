import Vue from "vue";
import Vuex from "vuex";
import { machineRequests } from "@/api/modules/machineRequests";

Vue.use(Vuex);

const ENTITY = machineRequests;

export const MachineRequests = {
  namespaced: true,
  state: {
    // TODO: Implement
  },
  mutations: {
    // TODO: Implement
  },
  actions: {
    async orderMachineRequests(_, payload) {
      const response = await ENTITY.orderMachineRequests(payload);
      if (response.hasError) {
        return { ...response };
      }
      return response;
    },
  },
  getters: {
    // TODO: Implement
  },
};
