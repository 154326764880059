<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <Label label="商号又は名称" class="search-company title-input-item" :editable="userEdit" required>
                      <v-row>
                        <v-col cols="9" style="padding: 0">
                          <v-list-item-content>
                            <SearchSelectListHight
                              name="name"
                              :values="formValues"
                              :editable="true"
                              @formUpdate="openListCompany"
                              @search="searchListCompany"
                            />
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </Label>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="法人番号" class="title-input-item" :editable="userEdit">
                              <InputText
                                name="corporate_number"
                                :values="formValues"
                                placeholder=""
                                :disabled="true"
                                validation_label="法人番号"
                                validation_rules="required|max:255"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="会社名" class="title-input-item" :editable="userEdit">
                              <InputText
                                name="name"
                                :values="formValues"
                                :disabled="true"
                                placeholder=""
                                validation_label="会社名"
                                validation_rules="required|max:255"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="24" sm="12" md="12">
                            <Label label="所在地" class="title-input-item" :editable="userEdit">
                              <InputText
                                name="address"
                                :values="formValues"
                                :disabled="true"
                                validation_label="所在地"
                                validation_rules="max:120"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-row>
                        <v-col cols="24" sm="12" md="6">
                          <Label label="種別" class="title-input-item" :editable="userEdit">
                            <RadioGroup
                              name="company_type"
                              :values="formValues"
                              :items="COMPANY_TYPE"
                              :editable="false"
                              validation_label="種別"
                              validation_rules="required"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="24" sm="12" md="6">
                            <Label label="CCUS事業者ID" class="title-input-item" :editable="userEdit">
                              <InputText
                                name="ccus_id"
                                :values="formValues"
                                :editable="companyEdit"
                                validation_label="CCUS事業者ID"
                                validation_rules="ccusid:CCUS事業者ID"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                      <Label label="職長情報" class="title-input-item" :editable="userEdit">
                        <v-row>
                          <v-col>
                            <v-list-item-content>
                              <Label label="メールアドレス" :editable="userEdit" required>
                                <InputText
                                  name="email"
                                  :values="formValues?.users"
                                  :editable="true"
                                  placeholder=""
                                  validation_label="メールアドレス"
                                  validation_rules="required|max:255|email"
                                  @onInput="onInput('users', $event)"
                                  @focusout="getFieldUserInfoByEmail('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                          <v-col>
                            <v-list-item-content>
                              <Label label="電話番号" class="title-input-item" :editable="userEdit" required>
                                <InputText
                                  name="phone"
                                  :values="formValues?.users"
                                  :editable="userEdit"
                                  placeholder=""
                                  validation_label="電話番号"
                                  validation_rules="required|max:16|phone"
                                  @onInput="onInput('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-list-item-content>
                              <Label label="姓" class="title-input-item" :editable="userEdit" required>
                                <InputText
                                  name="name_sei"
                                  :values="formValues?.users"
                                  :editable="userEdit"
                                  placeholder=""
                                  validation_label="姓"
                                  validation_rules="required|max:64"
                                  @onInput="onInput('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                          <v-col>
                            <v-list-item-content>
                              <Label label="名" class="title-input-item" :editable="userEdit" required>
                                <InputText
                                  name="name_mei"
                                  :values="formValues?.users"
                                  :editable="userEdit"
                                  placeholder=""
                                  validation_label="名"
                                  validation_rules="required|max:64"
                                  @onInput="onInput('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-list-item-content>
                              <Label label="セイ" class="title-input-item" :editable="userEdit" required>
                                <InputText
                                  name="kana_sei"
                                  :values="formValues?.users"
                                  :editable="userEdit"
                                  placeholder=""
                                  validation_label="セイ"
                                  validation_rules="required|max:128|kana"
                                  @onInput="onInput('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                          <v-col>
                            <v-list-item-content>
                              <Label label="メイ" class="title-input-item" :editable="userEdit" required>
                                <InputText
                                  name="kana_mei"
                                  :values="this.formValues?.users"
                                  :editable="userEdit"
                                  placeholder=""
                                  validation_label="メイ"
                                  validation_rules="required|max:128|kana"
                                  @onInput="onInput('users', $event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                      </Label>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import {Store} from "@/store/Store.js";
import {COMPANY_RENTAL, COMPANY_SCENE} from "@/constants/COMMON";
import {COMPANY_TYPE} from "@/constants/COMPANIES";
import {MESSAGES} from "@/constants/FIELD_USERS";
import {kuroshiro} from "@/utils/convertKana";
import _ from "lodash";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      COMPANY_TYPE: COMPANY_TYPE,
      COMPANY_SCENE,
      COMPANY_RENTAL,
      MESSAGES,
      companyEdit: true,
      userEdit: true,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
  },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    companyEditable: {
      type: Boolean
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    dataSelectCompany: Object,
    formUpdate: Function,
    fieldID: Number
  },
  async mounted() {
    this.$watch(
      () => this.fieldID,
      (fieldID) => {
        this.fieldID = fieldID
      },
      {immediate: true, deep: true}
    );
    this.$watch(
      () => this.companyEditable,
      (data) => {
        this.companyEdit = data;
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.editable,
      (data) => {
        this.userEdit = data;
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => [this.dataSelectCompany],
      async (data) => {
        if (data[0]) {
          const formValues = {...this.formValues};
          formValues.corporate_number = data[0].corporateNumber ?? '';
          formValues.name = data[0].name ?? '';
          formValues.name_kana = typeof data[0].furigana === 'object' ? '' : data[0].furigana ?? '';
          formValues.address = data[0].prefectureName + data[0].cityName + data[0].streetNumber;
          await this.$emit("formUpdate", formValues);
          await this.getCompanyInfoByCorporateNumber(data[0].corporateNumber);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  watch: {},
  methods: {
    onInput(parentName, {name, value}) {
      const formValues = {...this.formValues};
      if (parentName) {
        if (!formValues[parentName]) {
          formValues[parentName] = {};
        }
        formValues[parentName][name] = value;
      } else {
        formValues[name] = value;
      }
      if (parentName == "users" && (name == 'name_sei' || name == 'name_mei')) {
        this.getNameKana(name, formValues[parentName][name]);
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getCompanyInfoByCorporateNumber(id) {
      let formValues = {...this.formValues};
      const res = await Store.dispatch('Register/getCompanyInfoByCorporateNumber', {
        corporate_number: id,
      });
      let itemRes = res.data.contents;
      if (!itemRes) {
        formValues.ccus_id = '';
        formValues.plate_number_prefix = '';
        formValues.company_code = '';
        formValues.company_type = COMPANY_SCENE;
        this.companyEdit = true;
      } else {
        const response = await Store.dispatch('Companies/checkFieldCompanyByCorporateNumber', {params : {
          corporate_number: id,
          fieldID: this.fieldID
        }})

        if (response.hasError) {
          formValues.corporate_number = '';
          formValues.address = '';
          formValues.name = '';
          formValues.company_type = 1;
          formValues.ccus_id = '';
          this.companyEdit = true;
        } else {
          formValues.company_type = itemRes.company_type ?? '';
          formValues.plate_number_prefix = itemRes.plate_number_prefix ?? '';
          if (itemRes.id) {
            formValues.id = itemRes.id;
          }
          if (itemRes.company_code) {
            formValues.company_code = itemRes.company_code ?? '';
          }
          if (itemRes.corporate_number) {
            formValues.corporate_number = itemRes.corporate_number;
          }
          if (itemRes.name) {
            formValues.name = itemRes.name;
          }
          if (itemRes.address) {
            formValues.address = itemRes.address;
          }
          if (itemRes.name_kana) {
            formValues.name_kana = itemRes.name_kana;
          }
          formValues.ccus_id = itemRes.ccus_id ?? '';
          this.companyEdit = false;
        }
      }
      this.updateCompanyEditable(this.companyEdit);
      this.$emit("formUpdate", formValues);
    },

    async getNameKana(name, value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues =  _.cloneDeep(this.formValues);
      if (name == 'name_sei') {
        formValues.users.kana_sei = result.split(" ").join("");
      }
      if (name == 'name_mei') {
        formValues.users.kana_mei = result.split(" ").join("");
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    updateCompanyEditable(value) {
      this.$emit('updateCompanyEditable', value)
    },

    openListCompany(name) {
      this.$emit('openListCompany', name)
    },

    searchListCompany() {
      this.$emit('searchListCompany')
    },

    getFieldUserInfoByEmail(parentName, {name, value}) {
      const formValues = {...this.formValues};
      if (parentName) {
        if (!formValues[parentName]) {
          formValues[parentName] = {};
        }
        formValues[parentName][name] = value;
      } else {
        formValues[name] = value;
      }
      let params = [];
      params["email"] = value;
      this.$emit("getFieldUserInfoByEmail", params);
    }
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
