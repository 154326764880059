<template>
    <div>
        <Loading v-if="isLoading" />
        <v-data-table class="data" :headers="TABLE_LABELS" :items="LIST_DATA" :items-per-page="LIST_DATA.length"
            :height="tableHeight" fixed-header hide-default-footer disable-sort sort-by="updatedAt"
            noDataText="データがありません。" @update:page="$vuetify.goTo(0)" :key=page>
            <template v-slot:[`item.company`]="{ item }">
                <div>
                    {{ item.company_renta_name }}
                </div>
            </template>
            <template v-slot:[`item.delivery_date`]="{ item }">
                <span class="mr-8">{{ formatDate(item.scheduled_delivery_date) }}</span>
                <span>
                    {{getDateTable(item)}}
                </span>
            </template>
            <template v-slot:[`item.loading_gate`]="{ item }">
                <div>
                    {{ item.gate_name }}
                </div>
            </template>
            <template v-slot:[`item.number`]="{ item }">
                <div>
                    {{ item.number_plate }}
                </div>
            </template>
            <template v-slot:[`item.model`]="{ item }">
                <div>
                    <span>{{ item.floor_height }}m </span>
                    <span>{{ item.lifting_method }} </span>
                    <span>{{ item.undercarriage }} </span>
                    <span>{{ item.working_floor }}</span>
                </div>
            </template>
            <template v-slot:[`item.shipment`]="{ item }">
                <v-btn v-if="item.inspection_before_deliveries_id != null" depressed icon class="set-color-icon"
                    @click="openItemForm(item.inspection_before_deliveries_id)">
                    <v-icon>mdi-paperclip</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <div class="mt-6">
            <Popup :dialog="popups.isShowItemForm">
                <InspectionResult
                    :item="editedItem"
                    @cancel="closeItemForm"
                />
            </Popup>
        </div>
    </div>
</template>
<script>
import {
    MACHINE_DELIVERY_TAB_TABLE_LABELS
} from "@/constants/FIELD_MACHINE"
import { CRUD_INITIAL_ITEM } from "@/constants/MACHINE"; //絞り込みフォームで使用
import moment from "moment";
import { Store } from "@/store/Store.js";
import Loading from "@/components/loading/Loading";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import InspectionResult from "../../../../components/forms/machine/InspectionResult";

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_DELIVERY_TAB_TABLE_LABELS;
const INITIAL_ITEM = CRUD_INITIAL_ITEM;

export default {
    components: {
        Loading,
        Popup,
        InspectionResult
    },
    data() {
        return {
            TABLE_LABELS,
            LIST_DATA: [],
            isLoading: false,
            editedItem: {},
            popups: {
                // 追加/編集フォーム
                isShowItemForm: false,
                // 削除確認ダイアログ表示
                isShowRemoveDialog: false,
            },
        };
    },
    props: {
        tableHeight: {
            type: Number
        },
        paramsSearch: {
            type: Object,
        },
        page: {
            type: Number
        }
    },
    watch: {
        paramsSearch: {
            handler: function (params) {
                this.getItems(params);
            },
            deep: true
        },
    },
    mounted() {
        this.$watch(
            () => Store.getters["Machine/getIsLoading"],
            (data) => {
                this.isLoading = data;
            },
            {
                immediate: true,
                deep: true,
            }
        );
    },
    created() {
        this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
        let params = {
            "field_id": this.fieldId,
            "sort_by": "ASC",
            "sort_value": 'scheduled_delivery_date',
            "page": 1,
            "page_size": 25
        };
        this.getItems(params);
    },
    methods: {
        async getItems(params) {
            const response = await Store.dispatch("Machine/getListDeliveryMachineOnField", params);
            if (!response.hasError) {
                this.LIST_DATA = response.data.contents.entries.data;
                this.$emit("pagination-data", response.data.contents.pagination);
            }
        },
        formatDate: function (date) {
            return moment(new Date(date)).format("YYYY/MM/DD");
        },
        getDateTable(data) {
            let dateTime = null;
            const during_day = 0;
            const morning_day = 1;
            const afternoon_day = 2;
            const timeDesignation = 3;
            if (data.scheduled_delivery_type === during_day) {
            dateTime = "当日中";
            } else if (data.scheduled_delivery_type === morning_day) {
            dateTime = "午前";
            } else if (data.scheduled_delivery_type === afternoon_day) {
            dateTime = "午後";
            } else if (data.scheduled_delivery_type === timeDesignation) {
            dateTime = data.scheduled_delivery_time.substring(0,2) + ":00";
            }
            return dateTime;
        },
        async openItemForm(id) {
            let params = {id: id};
            const deliveryBeforeInfo = await Store.dispatch(`Machines/getInspectionBeforeDeliveryById`, {params});
            this.popups.isShowItemForm = true
            if (!deliveryBeforeInfo.hasError && deliveryBeforeInfo.data?.status_code == 200) {
                this.editedItem = deliveryBeforeInfo.data?.contents?.entries;
                this.popups.isShowItemForm = true;
            }
        },
        closeItemForm() {
            this.popups.isShowItemForm = false;
            this.$nextTick(() => {
                //選択を初期化
                this.editedItem = { ...INITIAL_ITEM.entries };
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
    .tab-select-header {
        height: 56px !important;
    }

    .data {
        ::v-deep table {
            td {
                line-height: 24px;
            }
        }

        .set-color-icon {
            color: rgba(0, 0, 0, 0.87);
        }
    }
}
</style>
