<template>
  <div>
    <NotificationDrawer width="320px">
      <v-list>
        <v-subheader>
          <div class="NotificationDrawer__title">通知</div>
        </v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            disabled
            class="NotificationItem"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="NotificationItem__wrapper">
                <div>
                   <span
                    class="NotificationItem__label"
                    :class="statusClass(item.message_color_id)" >
                    {{ getTypeMessage(item.message_title_id).name }}
                  </span>
                </div>
                <div class="NotificationItem__date">
                    {{ item.period }}
                </div>
              </v-list-item-subtitle>
              <v-list-item-title class="NotificationItem__wrapper">
                <div>
                  <div class="NotificationItem__message">
                    <div style="white-space: pre-line;">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </NotificationDrawer>
  </div>
</template>

<script>
import NotificationDrawer from "@/components/common/NotificationDrawer";
import { Store } from "@/store/Store";
import { NOTIFICATION_COLOR , NOTIFICATION_MESSAGE_TYPE , NOTIFICATION_SYS, NOTIFICATION_URGENCY } from "@/constants/NOTIFICATION.js";

/**
 * ポータル共通 初期値
 */

export default {
  data: () => {
    return {
      items: [],
      NOTIFICATION_MESSAGE_TYPE,
      NOTIFICATION_SYS,
      NOTIFICATION_URGENCY,
      cmn_mst_safety_diary_types: [],
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["Notification/getItems"],
      (newValue) => {
          this.items = [...newValue];
          this.readNotificationByUser();
      },
      {
        deep: true,
      }
    );
  },
  components: {
    NotificationDrawer,
  },
  methods: {
    statusClass(tag_color) {
      const key = Object.keys(NOTIFICATION_COLOR).find((key) => {
        return NOTIFICATION_COLOR[key].id === tag_color;
      });
      return `status_${NOTIFICATION_COLOR[key].key}`;
    },
    getTypeMessage(messageId) {
      return this.NOTIFICATION_MESSAGE_TYPE.find((item) => {
        return item.id == messageId;
      })
    },
    async readNotificationByUser() {
      let ids = [];
      this.items.forEach(
        (item) => ids.push(item.id)
      );
      if(ids.length > 0) {
        await Store.dispatch("Notification/readNotificationByUser", { ids })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.NotificationItem {
  border-bottom: 1px solid #ddd;
}
.NotificationDrawer__title {
  font-weight: bold;
  font-size: 24px;
}
.NotificationItem__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NotificationItem__message {
  color: black;
  white-space: normal;
  padding-top: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.None__static__message{
  color: black;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.NotificationItem__date {
  font-size: 14px;
  font-weight: bold;
}
.NotificationItem__label {
  font-size: 14px;
  color: white;
  padding: 8px 22px;
  height: 32px;
   border-radius: 16px;
  line-height: 18px;
  display: block;
  &.status_red{
    background-color: $color_red;
  }
  &.status_green {
    background-color: $color_green;
  }
  &.status_orange_light {
    background-color: $color_register;
  }
}
.NotificationItem__urgency {
  font-size: 9px;
  color: white;
  padding: 0 6px;
  height: 8px;
  border-radius: 4px;
  line-height: 18px;
  &.status_positive {
    background-color: $color_approved;
  }
  &.status_negative {
    background-color: $color_rejected;
  }
  &.status_normal {
    background-color: $color_created;
  }
  &.status_blue {
    background-color: $color_warning_blue;
  }
  &.status_red {
    background-color: $color_warning_dark_red;
  }
}

.NotificationItem__buttons {
  width: 48px;
  display: flex;
  justify-content: space-between;
}
.NotificationItem__btn {
  display: flex;
  align-items: center;
  margin-left: 4px;
  pointer-events: all;
  &.none {
    display: none;
    cursor: default;
  }
}
</style>
