<template>
  <div class="report-list-wrap">
    <Loading v-if="isLoading" />
    <v-data-table
      class="report-list"
      :headers="TABLE_LABELS"
      :items="enrichedDesserts"
      :items-per-page="desserts.length"
      hide-default-footer
      fixed-header
      disable-sort
      v-model="selected"
      item-key="key_item"
      show-select
      :noDataText="NO_DATA"
      :height="tableHeight - 46"
      @click:row="openFormDetailModel"
      @update:page="$vuetify.goTo(0)"
      :key=page
    >
      <template v-slot:item.repair_status="{ item }">
        <v-btn depressed class="status" v-if="item.repair_status == null" :color="getColor(item.repair_status)" style="color: #000000">
          {{ getStatus(item.repair_status) }}
        </v-btn>
        <v-btn depressed class="status" v-else :color="getColor(item.repair_status)" dark>
          {{ getStatus(item.repair_status) }}
        </v-btn>
      </template>
      <template v-slot:item.report_kind="{ item }">
        {{ getKind(item.report_kind) }}
      </template>
      <template v-slot:item.report_date="{ item }">
        {{ formattedDate(item.report_date) }}
      </template>
      <template v-slot:item.act="{ item }">
        <div class="act-circle"
          v-if="(item.created_field_user_id != item.reporter_id)"
        >
          <img class="block-frame" v-bind:src="ICON_REPORTER" alt=""/>
        </div>
      </template>
      <template v-slot:item.reporter="{ item }">
        <p class="mb-0">
          {{ item.company_partner_name }} {{ item.user_name_sei}} {{ item.user_name_mei }}
        </p>
        <p class="mb-0">{{ item.user_phone }}</p>
      </template>
      <template v-slot:item.company_renta_name="{ item }">
        {{ statusShowCompany.includes(item.repair_status) ? item.company_renta_name : null }}
      </template>
      <template v-slot:item.number_plate="{ item }">
        {{ item.number_plate }}
      </template>
      <template v-slot:item.model="{ item }">
        <p class="mb-0">
          {{ item.floor_height }}m {{ item.lifting_method }}
          {{ item.undercarriage }}
        </p>
        <p class="mb-0">{{ item.working_floor }}</p>
      </template>
      <template v-slot:item.place_name="{ item }">
        <div style="word-break:break-all;">
          {{ item.place_name }}
        </div>
      </template>

      <template v-slot:top class="top-report">
        <v-btn
          depressed
          color="primary"
          @click="dialogConfirm = true"
          style="width: 110px; margin: 0px 30px 0px 20px"
          :disabled="isDisable"
          v-if="roleUser != LEADER_ROLE"
        >
          現場で対応
          <Popup width="480px" :dialog="dialogConfirm">
            <ExtendDialog
              v-model="dialogConfirm"
              title="現場で対応"
              text1="現場で対応します。"
              text2="この処理を元に戻すことはできません。"
              acceptBtn="確定"
              @close="dialogConfirm = false"
              @yes="updateHandle()"
            >
            </ExtendDialog>
          </Popup>
        </v-btn>
        <v-btn
          depressed
          v-if="roleUser != LEADER_ROLE"
          color="darken-1"
          @click="handelShowModalComment"
          style="width: 170px; background-color: #ff7b52; color: #ffffff; margin-right: 10px;"
          :disabled="isDisable"
        >
          レンタル会社を手配
          <Popup width="480px" :dialog="dialogSubmit">
            <v-card class="dialog">
              <ValidationObserver v-slot="{ invalid }" ref="observer">
                <v-card-title>
                  <div class="title">レンタル会社を手配</div>
                  <v-spacer></v-spacer>
                  <Popup width="480px" :dialog="isShowCancel">
                    <ConfirmCloseDialog v-model="isShowCancel" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
                                        :text2="WRN_0002.TEXT2" @close="isShowCancel = false" @yes="cancelSendData()" warning />
                  </Popup>
                  <v-icon @click="isShowCancel = true">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="body">
                  <div class="text-wrap">
                    <div class="text text-content">
                      <div v-for="item in companyRental" :key="item" :value="item">
                        <span class="rd-time">{{ item }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="textAr">
                    <v-col style="padding: 0px 8px;" class="mb-5">
                      <ValidationProvider v-slot="{ errors, valid }" name="メッセージ"
                                          rules="minmax:0,255">
                        <Label label="メッセージ" class="title-input-item">
                          <InputTextArea
                              :values="commentData"
                              name="message"
                              validation_label="メッセージ"
                              validation_rules="minmax:0,255"
                              @onInput="onChangeSearchParams"
                              :editable="true">
                          </InputTextArea>
                        </Label>
                      </ValidationProvider>
                    </v-col>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    outlined
                    class="btn"
                    color="primary"
                    @click="isShowCancel = true"
                  >
                    やめる
                  </v-btn>
                  <v-btn
                    depressed
                    class="btn"
                    color="primary"
                    :disabled="invalid || isSubmitted"
                    @click="commentMachineReport"
                  >
                    手配
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </Popup>
        </v-btn>
        <!-- MMSV2-1573-start -->
        <v-btn
          depressed
          color="primary"
          @click="dialogConfirmApprove = true"
          style="width: 60px; margin: 0px 30px 0px 20px"
          :disabled="isDisableApprove"
          v-if="roleUser != LEADER_ROLE"
        >
        承認
          <Popup width="480px" :dialog="dialogConfirmApprove">
            <ExtendDialog
              v-model="dialogConfirmApprove"
              title="承認"
              text1="修理報告を承認します。"
              text2="この処理を元に戻すことはできません。"
              acceptBtn="承認"
              @close="dialogConfirmApprove = false"
              @yes="updateAproved()"
            >
            </ExtendDialog>
          </Popup>
        </v-btn>
        <!-- MMSV2-1573-start -->
        <v-btn
          :class="roleUser != LEADER_ROLE ? 'btn-delete btn-delete-padding' : 'btn-delete btn-delete-padding ml-3'"
          color="darken-1"
          icon
          @click="dialogDel = true"
          :disabled="isDisable"
        >
          <Popup width="480px" :dialog="dialogDel">
            <ConfirmCloseDialog
                v-model="dialogDel"
                :title="WRN_0001.TITLE"
                :text1="WRN_0001.TEXT1"
                :text2="WRN_0001.TEXT2"
                :warning="true"
                textSubmit="解除"
                @close="dialogDel = false"
                @yes="deleteMachineReport" />
          </Popup>
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <FormDetailTenken :idSend="idSend" :titelModal="TITLE" :inspectorId="inspectorId"
          :idFieldMachine="idFieldMachine" :showModel="showModelTenkenDetail" :nameCompanyRental="nameCompanyRental" :typeModal="'machine'"
          :callFromTable="reportKindItem"
          :repairStatusItem="repairStatusItem"
          @close-formDetail-modal="closeFormDetailModel" @reload-data="getListMachineDefault" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { format, parseISO, isValid } from "date-fns";
import {
  MACHINE_REPORT_TAB_TABLE_LABELS,
  LIST_STATUS_REPORT_TAB,
  LIST_KIND_REPORT_TAB,
  LIST_KEY_NAME_STATUS_REPORT_TAB,
  TYPE_REPORT_KIND,
  TYPE_REPORT_KIND_API,
  LEADER_ROLE
} from "@/constants/MACHINE";
import { NO_DATA, SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { WRN_0001, WRN_0002 } from "@/constants/MESSAGE";
import Loading from "@/components/loading/Loading";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Label from "@/components/forms/elements/Label";
import FormDetailTenken from "../../../booking/components/FormDetailTenken.vue";

const TABLE_LABELS = MACHINE_REPORT_TAB_TABLE_LABELS;
const LIST_KEY_REPORT_TAB = LIST_KEY_NAME_STATUS_REPORT_TAB;
export default {
  data() {
    return {
      NO_DATA,
      WRN_0001: WRN_0001,
      WRN_0002: WRN_0002,
      TYPE_REPORT_KIND: TYPE_REPORT_KIND,
      TYPE_REPORT_KIND_API: TYPE_REPORT_KIND_API,
      LEADER_ROLE: LEADER_ROLE,
      ICON_REPORTER: "/images/icon_reporter.png",
      TABLE_LABELS,
      fieldId: null,
      selected: [],
      checkboxAll: false,
      listStatus: LIST_STATUS_REPORT_TAB,
      listKind: LIST_KIND_REPORT_TAB,
      desserts: [],
      inspectionIds: [],
      repairIds: [],
      //MMSV2-1573-start
      approveInspecIds: [],
      approveRepairIds: [],
      dialogConfirmApprove: false,
      isDisableApprove: true,
      //MMSV2-1573-end
      companyRental: [],
      isDisable: true,
      dialogDel: false,
      dialogSubmit: false,
      dialogConfirm: false,
      isShowCancel: false,
      isLoading: true,
      roleUser: null,
      statusShowCompany: [
        LIST_KEY_REPORT_TAB.rentalArranged,
        LIST_KEY_REPORT_TAB.estimated,
        LIST_KEY_REPORT_TAB.reportedForRepair,
        LIST_KEY_REPORT_TAB.approved
      ],
      showModelTenkenDetail: false,
      idSend: null,
      reportKindItem: null,
      repairStatusItem: null,
      machineId: null,
      nameCompanyRental:null,
      commentData: {
        message: null
      },
      idFieldMachine: null,
      inspectorId: null,
      TITLE: null,
      SUBMIT_DELAY_TIMEOUT,
      isSubmitted: false
    };
  },
  components: {
    Label,
    InputTextArea,
    ExtendDialog,
    Popup,
    ConfirmCloseDialog,
    Loading,
    ValidationProvider,
    ValidationObserver,
    FormDetailTenken
  },
  computed: {
    enrichedDesserts() {
      return this.desserts.map((item) => ({
        ...item,
        //MMSV2-15723-start
        isSelectable: item.repair_status === null || item.repair_status === 4,
        //MMSV2-15723-end
      }));
    },
  },
  props: {
    paramsSearch: {
      type: Object,
    },
    tableHeight: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  watch: {
    selected(listSelect) {
      this.inspectionIds = [];
      this.repairIds = [];
      //MMSV2-1573-start
      this.approveInspecIds = [];
      this.approveRepairIds = [];
      listSelect.forEach((item) => {
        if (item.report_kind == this.TYPE_REPORT_KIND.inspectionResult) {
          if(item.repair_status == 4) this.approveInspecIds.push(item.report_id);
          else this.inspectionIds.push(item.report_id);
        } else {
          if(item.repair_status == 4) this.approveRepairIds.push(item.report_id);
          else this.repairIds.push(item.report_id);
        }
        //MMSV2-1573-end
      });
      if(listSelect.length) {
        if(
          (this.approveInspecIds.length > 0 || this.approveRepairIds.length > 0)
          && (this.inspectionIds.length == 0 || this.repairIds.length == 0)
        ) {
          this.isDisableApprove = false
          this.isDisable = true
        }
        if(
          (this.inspectionIds.length > 0 || this.repairIds.length > 0)
          && (this.approveInspecIds.length == 0 || this.approveRepairIds.length == 0)
        ) {
          this.isDisableApprove = true
          this.isDisable = false
        }
        if(
          (this.inspectionIds.length > 0 || this.repairIds.length > 0)
          && (this.approveInspecIds.length > 0 || this.approveRepairIds.length > 0)
        ) {
          this.isDisableApprove = false
          this.isDisable = false
        }
      } else {
        this.isDisableApprove = true
        this.isDisable = true
      }
    },
    paramsSearch: {
      handler: function (filterParams) {
        let params = { ...filterParams };
        if (filterParams?.repair_status && filterParams.repair_status.length) {
          params.repair_status = filterParams.repair_status.map(i => (i === 0 ? null : i));
        }

        if (params.repair_status === undefined) {
          params.repair_status = [LIST_KEY_REPORT_TAB.notCompatible];
        }

        this.getListMachineReport(params);
      },
      deep: true,
    }
  },
  created() {
    this.roleUser = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.getListMachineLoadTab();
  },
  mounted() {
    this.$watch(
    () => Store.getters["Machines/getIsLoading"],
    (data) => {
      this.isLoading = data;
    },
    {
      immediate: true,
      deep: true,
    }
    );
  },
  methods: {
    formattedDate(date) {
      return date && isValid(parseISO(date)) ? format(parseISO(date), "yyyy/MM/dd") : "";
    },
    getColor(status) {
      if (status === LIST_KEY_REPORT_TAB.notCompatible) return "#E5951D";
      //MMSV2-1573-start
      else if (status === LIST_KEY_REPORT_TAB.onSite || status === LIST_KEY_REPORT_TAB.approved) return "#6CA955";
      //MMSV2-1573-end
      else if (status === LIST_KEY_REPORT_TAB.rentalArranged) return "#B00020";
      else if (status === LIST_KEY_REPORT_TAB.estimated) return "#274FC2";
      else if (status === LIST_KEY_REPORT_TAB.reportedForRepair) return "rgba(0,0,0,0.38)";
    },
    getStatus(status) {
      return this.listStatus[status];
    },
    getKind(kind) {
      return this.listKind[kind];
    },
    cancelSendData() {
      this.commentData = {message: null};
      this.isShowCancel = false;
      this.dialogSubmit = false;
      this.companyRental = [];
    },
    async getListMachineReport(params) {
      if (!params.sort_by) {
        params.sort_by = "ASC";
      }
      if (!params.sort_value) {
        params.sort_value = "report_date";
      }
      const result = await Store.dispatch(
        `Machines/getListInspectionAndRepairRequest`,
        params
      );
      if (!result.hasError) {
        let _data = result.contents.entries;
        this.desserts = _data.map((item) => {
          item.key_item = item.report_id + '_' + item.report_kind;
          return item
        });
        this.$emit("pagination-report", result.contents.pagination);
      }

      this.inspectionIds = [];
      this.repairIds = [];
      //MMSV2-1573-start
      this.approveInspecIds = [],
      this.approveRepairIds = [],
      this.isDisableApprove = true;
      //MMSV2-1573-end
      this.isDisable = true;
      this.selected = [];
      this.showModelTenkenDetail = false;
      this.idSend = null;
      this.reportKindItem = null;
      this.repairStatusItem = null;
      this.machineId = null
    },

    async commentMachineReport() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      await Promise.all([
        this.repairIds.length ? Store.dispatch('Tenken/postSenDataTenkenReport', {
          ids: this.repairIds,
          message: this.commentData.message,
          type: this.TYPE_REPORT_KIND_API.requestResult,
          fieldId: this.fieldId
        }) : {},
        this.inspectionIds.length ? Store.dispatch('Tenken/postSenDataTenkenReport', {
          ids: this.inspectionIds,
          message: this.commentData.message,
          type: this.TYPE_REPORT_KIND_API.inspectionResult,
          fieldId: this.fieldId
        }) : {},
      ]).then(async ([repairResult, inspectionResult]) => {
        if(!repairResult.hasError && !inspectionResult.hasError) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "更新しました",
          });
        }
      });
      await this.getListMachineDefault();
      this.companyRental = [];
      this.commentData = {message: null};
      this.dialogSubmit = false;
    },
    async deleteMachineReport() {
      const params = {
        ids: this.repairIds,
      };

      await Promise.all([
        this.repairIds.length ? Store.dispatch('Machines/deleteMachineRepairRequest', params) : {},
        this.inspectionIds.length ? Store.dispatch('Tenken/postDeleteTenken', this.inspectionIds) : {}
      ]).then(async ([repairResult, inspectionResult]) => {
        if(!repairResult.hasError || !inspectionResult.hasError) {
          await this.getListMachineDefault();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "削除しました",
          });
          this.dialogDel = false;
          this.isDisable = true;
          //MMSV2-1573-start
          this.isDisableApprove = true;
          //MMSV2-1573-end
          this.selected = [];
        }
      });
    },

    async updateHandle() {
      await Promise.all([
        this.repairIds.length ? Store.dispatch('Tenken/postUpdateTenkenReport', {
          ids: this.repairIds,
          type: this.TYPE_REPORT_KIND_API.requestResult,
          fieldId: this.fieldId
        }) : {},
        this.inspectionIds.length ? Store.dispatch('Tenken/postUpdateTenkenReport', {
          ids: this.inspectionIds,
          type: this.TYPE_REPORT_KIND_API.inspectionResult,
          fieldId: this.fieldId
        }) : {},
      ]).then(async ([repairResult, inspectionResult]) => {
        if(!repairResult.hasError && !inspectionResult.hasError) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "更新しました",
          });
        }
      });
      await this.getListMachineDefault();
      this.dialogConfirm = false;
    },
    //MMSV2-1573-start
    async updateAproved() {
      await Promise.all([
        this.approveRepairIds.length ? Store.dispatch('Tenken/postUpdateTenkenReport', {
          ids: this.approveRepairIds,
          approvedRequestResult: 5,
          type: this.TYPE_REPORT_KIND_API.approveRepair,
          fieldId: this.fieldId
        }) : {},
        this.approveInspecIds.length ? Store.dispatch('Tenken/postUpdateTenkenReport', {
          ids: this.approveInspecIds,
          approvedRequestResult: 5,
          type: this.TYPE_REPORT_KIND_API.approveInspec,
          fieldId: this.fieldId
        }) : {},
      ]).then(async ([repairResult]) => {
        if(!repairResult.hasError) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: "更新しました",
          });
        }
      });
      await this.getListMachineDefault();
      this.dialogConfirmApprove = false;
    },
    //MMSV2-1573-end

    async getListMachineDefault() {
      let searchParamCurren = {
        "field_id": this.fieldId,
        "page": 1,
        "page_size": 25,
        'sort_value': 'report_date',
        'sort_by': 'ASC',
        'repair_status': [LIST_KEY_REPORT_TAB.notCompatible]
      };

      if (Object.keys(this.paramsSearch).length > 1) {
        searchParamCurren = this.paramsSearch;
        if (searchParamCurren?.repair_status && searchParamCurren.repair_status.length) {
          searchParamCurren.repair_status = searchParamCurren.repair_status.map(i => (i === 0 ? null : i));
        }
      }
      if (!searchParamCurren.repair_status) searchParamCurren['repair_status'] = [LIST_KEY_REPORT_TAB.notCompatible];
      await this.getListMachineReport(searchParamCurren);
      this.inspectionIds = [];
      this.repairIds = [];
      //MMSV2-1573-start
      this.approveIds = [];
      this.isDisableApprove = true;
      //MMSV2-1573-end
      this.isDisable = true;
      this.selected = [];
      this.showModelTenkenDetail = false;
      this.idSend = null;
      this.reportKindItem = null;
      this.repairStatusItem = null;
      this.machineId = null
      let params = {
        field_id: this.fieldId,
        role: this.roleUser
      }
      await this.$emit('check-flag-show-icon', params)
    },

    async getListMachineLoadTab() {
      
      let params = {
        "field_id": this.fieldId,
        "page": 1,
        "page_size": 25,
        'sort_value': 'report_date',
        'sort_by': 'ASC',
        'repair_status': [
          LIST_KEY_REPORT_TAB.notCompatible,
          LIST_KEY_REPORT_TAB.rentalArranged,
          LIST_KEY_REPORT_TAB.estimated,
          LIST_KEY_REPORT_TAB.reportedForRepair,
          ]
      };
      await this.getListMachineReport(params);

      this.idSend = null;
      this.reportKindItem = null;
      this.repairStatusItem = null;
      this.machineId = null
    },

    handelShowModalComment() {
      this.dialogSubmit = true
      let listMachineInspections = this.inspectionIds.map(id => this.desserts.find(i => i.report_id === id && i.report_kind == this.TYPE_REPORT_KIND.inspectionResult));
      let listMachineRequest = this.repairIds.map(id => this.desserts.find(i => i.report_id === id && i.report_kind == this.TYPE_REPORT_KIND.requestResult));
      let companyRental = [];
      for (let i = 0; i < listMachineInspections.length; i++) {
        companyRental[listMachineInspections[i].company_renta_id] = listMachineInspections[i];
      }

      for (let i = 0; i < listMachineRequest.length; i++) {
        companyRental[listMachineRequest[i].company_renta_id] = listMachineRequest[i];
      }

      companyRental.forEach((item) => {
        this.companyRental.push(item.company_renta_name);
      });
    },

    async openFormDetailModel(item) {
      this.showModelTenkenDetail = true;
      this.idSend = item.report_id;
      this.reportKindItem = item.report_kind;
      this.repairStatusItem = item.repair_status;
      this.machineId = item.field_machine_id;
      this.nameCompanyRental = item.company_renta_name;
      this.idFieldMachine = item.field_machine_id;
      this.TITLE =
        item.number_plate +
        " / " +
        item.floor_height +
        "m " +
        item.lifting_method +
        " " +
        item.undercarriage +
        " " +
        item.working_floor;
    },

    async closeFormDetailModel(value) {
      this.idSend = null;
      this.repairStatusItem = null;
      this.reportKindItem = null;
      this.showModelTenkenDetail = value;
      this.nameCompanyRental = null;
      this.idFieldMachine = null;
    },

    onChangeSearchParams({ name, value }) {
      let commentData = { ...this.commentData };
      commentData[name] = value;
      this.commentData = commentData;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-content {
  max-height: 60px;
  overflow-y: auto;
}
.layout-machine-container {
  .tab-select-header {
    height: 56px !important;
  }
  .report-list  {
    position: relative;
    padding-top: 10px;

    ::v-deep   {
      .v-simple-checkbox--disabled {
        opacity: 0;
      }
      td {
        line-height: 24px;
      }
    }

    .status {
      width: 100px;
      font-size: 12px !important;
      color: #FFFFFF;
      font-weight: 400;
      text-align: center;
      border-radius: 16px;
      height: 32px;
      line-height: 15px;
      white-space: normal;
      word-wrap: break-word;
      pointer-events: none;

      ::v-deep .v-btn__content {
        flex: unset;
        line-height: unset;
      }
    }

    .act-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      $circle: 25px;
      height: $circle;
      width: $circle;

      > .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #ffffff;
      }

      > .block-frame {
        width: 100%;
      }
    }
  }
  .tableBody {
    .btn-delete:before {
      background-color: white !important;
    }
  }
  .btn-delete-padding {
    padding: 0 6px;
  }
}

.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;

      .text {
        padding-left: 10px;
        font-size: 16px !important;
        color: #000000;
      }
    }
  }

  .btn {
    margin-right: 12px;
  }

  .title {
    font-size: 20px !important;
    font-weight: 600;
    padding-left: 8px;
    color: rgba(39, 79, 194, 1);
  }

  .textAr {
    .lb-texAr {
      font-size: 12px !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .title {
      padding-left: 15px
    }
    .body .text-wrap .text {
      padding-left: 15px;
    }
  }
}
</style>