import Vue from "vue";
import Vuex from "vuex";
import { cruds } from "@/api/modules/crud";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = cruds;

export const Cruds = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    crud: {},
    document: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CRUD(state, payload) {
      state.crud = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getDetail({ commit }, payload) {
      const response = await ENTITY.getDetail(payload);
      const entries = response.data.contents.entries;
      commit("SET_CRUD", entries);
      return response;
    },

    async edit(_context, payload) {
      return await ENTITY.edit(payload);
    },

    async add(_context, payload) {
      return await ENTITY.add(payload);
    },

    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getCrud: (state) => {
      return state.crud;
    },
  },
};
