<template>
  <div class="FormMain mt-5">
    <div class="FormMainBody">
      <v-card-text>
        <ValidationObserver>
          <v-form autocomplete="off">
            <v-container>
              <v-row class="machine-mg-t">
                <v-col cols="12" sm="12" md="5">
                  <v-row>
                    <v-col>
                      <Label
                        label="現場"
                        :editable="editable"
                        class="title-input-item"
                        required
                      />
                      <Select
                        name="field_id"
                        :items="arrFieldNotFinish"
                        :editable="true"
                        :values="formValues"
                        @onInput="onInput($event)"
                        item_text="name"
                        validation_rules="required"
                        validation_label="現場"
                      />
                    </v-col>
                  </v-row>
              
                  <v-row>
                    <v-col>
                      <Label
                        label="ゲート"
                        :editable="editable"
                        class="title-input-item"
                      />
                      <Select
                        name="delivery_gate"
                        :items="arrGateByField"
                        :editable="true"
                        :values="formValues"
                        @onInput="onInput($event)"
                      />
                    </v-col>
                  </v-row>
              
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="使用開始日"
                          :editable="editable"
                          required
                          class="title-input-item"
                        >
                          <InputDatepicker
                            name="start_bookable_date"
                            :editable="editable"
                            :values="formValues"
                            validation_label="使用開始日"
                            :validation_rules="getRuleStartBookableDate()"
                            :flagNull="true"
                            @onInput="onInput($event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="使用終了日"
                          :editable="editable"
                          class="title-input-item"
                        >
                          <InputDatepicker
                            name="end_bookable_date"
                            :editable="editable"
                            :values="formValues"
                            validation_label="使用終了日"
                            :validation_rules="getRuleEndBookableDate()"
                            :flagNull="true"
                            :max="maxRangeDate"
                            @onInput="onInput($event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-list-item-content>
                        <Label
                          label="搬入予定日"
                          :editable="editable"
                          required
                          class="title-input-item"
                        >
                          <InputDatepicker
                            name="scheduled_delivery_date"
                            :editable="editable"
                            :values="formValues"
                            validation_label="搬入予定日"
                            :validation_rules="getRuleScheduledDeliveryDate()"
                            :flagNull="true"
                            @onInput="onInput($event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <Label
                        label="時間"
                        class="title-input-item"
                        :editable="editable"
                      />
                      <RadioGroup
                        name="scheduled_delivery_type"
                        :values="formValues"
                        :items="TIME"
                        :editable="editable"
                        @onInput="onInput($event)"
                        :checkRow="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="formValues?.scheduled_delivery_type == SCHEDULE_DELIVERY_TYPE">
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="指定時間"
                          :editable="editable"
                          class="title-input-item"
                        />
                        <Select
                          name="scheduled_delivery_time"
                          :items="ENTER_DAY_TIME_ARRAY"
                          :editable="true"
                          :values="formValues"
                          @onInput="onInput($event)"
                          :menu-props="{ auto: true }"
                        />
                      </v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-list-item-content>
                        <Label/>
                        <p>頃</p>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                  <div class="div-height-hidden"
                       v-if="formValues?.scheduled_delivery_type !== SCHEDULE_DELIVERY_TYPE"></div>
                </v-col>
                <v-col cols="12" sm="12" md="7" class="mt-n9">
                  <TableLayout class="tableDeliveryInfo">
                    <template #tableHeader="{ updateHeader }">
                      <TableSortWrapper>
                        <TotalItemTable
                          :total_item="itemMachineSelected.length"
                          primary
                        />
                      </TableSortWrapper>
                    </template>
                    <template #tableBody="{ tableHeight }">
                      <v-data-table
                        :headers="TABLE_LABELS"
                        :items="itemMachineSelected"
                        height="480px"
                        fixed-header
                        :items-per-page="itemMachineSelected.length"
                        disable-sort
                        hide-default-footer
                        class="elevation-0 v-data-table__wrapper mt-n3"
                        sort-by="updatedAt"
                        :noDataText="NO_DATA_MESSAGE"
                      >
                        <template v-slot:[`item.product_code`]="{ item }">
                          {{
                            item.product_code
                          }}
                        </template>
                        <template v-slot:[`item.machine_number`]="{ item }">
                          {{
                            item.machine_number
                          }}
                        </template>
                        <template v-slot:[`item.product_code`]="{ item }">
                          {{
                            item.product_code
                          }}
                        </template>
                      </v-data-table>
                    </template>
                  </TableLayout>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
        <Popup width="480px" :dialog="isShowConfirmDialog">
          <ConfirmCloseDialog
            title="フォームを閉じる確認"
            text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
            text2="このページから移動してもよろしいですか？"
            @close="onClickBtnCancelForm"
            @yes="closeForm"
            warning
          />
        </Popup>
      </v-card-text>
    </div>
  </div>
</template>
<script>
// import {convertName} from "@/common/helper";
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import {  TABLE_LABELS,
          TIME,
          ENTER_DAY_TIME_ARRAY,
          SCHEDULE_DELIVERY_TYPE,
          WIDTH_DEFAULT } from "@/constants/MACHINE";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TotalItemTable from "@/components/forms/machine/components/TotalItemTable";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "../../../common/Popup.vue";
import _ from "lodash";
import { Store } from "@/store/Store";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";
import { format, parseISO } from "date-fns";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      TIME,
      selectItems: [],
      TABLE_LABELS,
      desserts: [],
      arrFieldNotFinish: [],
      defaultOtion: { id: null, name: "" },
      arrGateByField: [{id: null, name: ""}],
      ENTER_DAY_TIME_ARRAY,
      NO_DATA_MESSAGE,
      isShowConfirmDialog: false,
      defaultWidth: WIDTH_DEFAULT,
      SCHEDULE_DELIVERY_TYPE,
      endOfField: null
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
    TableLayout,
    TableHeader,
    TableSortWrapper,
    TotalItemTable,
    Popup,
    ConfirmCloseDialog,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    itemMachineSelected: Array,
  },
  async mounted() {
    this.callApiFieldNotFinish();

    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    maxRangeDate() {
      return this.endOfField ? format(parseISO(this.endOfField[0].end_use_field_date), "yyyy-MM-dd") : null;
    },
  },
  methods: {
    onInput({ name, value }) {
      if (name === "field_id" && value) {
        this.callApiListGateByField(value);
      }
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async callApiFieldNotFinish() {
      let fieldNotFinish = await Store.dispatch("Fields/getListRelatedFieldByUser");
      let data = fieldNotFinish?.data?.contents?.entries;
      this.arrFieldNotFinish = [this.defaultOtion, ...data];
    },

    async callApiListGateByField(id) {
      let fieldNotFinish = await Store.dispatch("Fields/getListGateByField", {
        field_id: id,
      });
      let data = fieldNotFinish?.data?.contents?.entries;
      this.arrGateByField = [this.defaultOtion, ...data];
      //Get end of field
      let endOfField = await Store.dispatch("Fields/getEndUseOfField", {
        field_id: id,
      });
      this.endOfField = endOfField?.data?.contents?.entries;
    },

    getRuleStartBookableDate() {
      let scheduleDate = `${String(
        this.formValues?.scheduled_delivery_date
      ).replaceAll("-", "/")}`;
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let rule = "";
      let ruleOther = "";
      if (this.formValues?.scheduled_delivery_date) {
        ruleOther = `err-end-date:${scheduleDate},使用開始日,搬入予定日`;
      }
      return (
        "required|" + `err-005:${now},使用開始日` + "|" + rule + "|" + ruleOther
      );
    },

    getRuleEndBookableDate() {
      let date = `${String(this.formValues?.start_bookable_date).replaceAll(
        "-",
        "/"
      )}`;
      let rule = "";
      if (this.formValues?.start_bookable_date) {
        rule = `err-end-date:${date},使用終了日,使用開始日`;
      }
      return rule;
    },

    getRuleScheduledDeliveryDate() {
      let date = `${String(this.formValues?.start_bookable_date).replaceAll(
        "-",
        "/"
      )}`;
      let rule = "";
      if (this.formValues?.start_bookable_date) {
        rule = `err-start-date:${date},搬入予定日,使用開始日`;
      }
      return "required|" + rule;
    },
    closeForm() {
      this.isShowConfirmDialog = false;
      this.editable = false;
    },
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      });
      this.$emit("submit");
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}

.date {
  color: #000000;
}

.tableDeliveryInfo .tableBody thead tr th span {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.tableDeliveryInfo .theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #fff;
}

.tableDeliveryInfo
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87);
}
.FormMainBody {
  overflow: unset !important;
}
.v-card__text {
  background: #ffff !important;
}
.v-input--radio-group {
  margin-top: 0 !important;
}
.machine-mg-t {
  margin-top: 9px;
}
</style>
  