<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off" @submit="onSubmit">
            <v-container>
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <v-list-item>
                    <v-list-item-content>
                      <GateSetting
                        name="field_gates"
                        :values="formValues"
                        :editable="editable"
                        @onInput="onInput"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputTextArea from "../elements/InputTextArea";
import GateSetting from "./components/GateSetting.vue";
import InputText from "@/components/forms/elements/InputText";

import _ from "lodash";
export default {
  data: () => {
    return {
      formValues: {},
    };
  },
  components: {
    Label,
    GateSetting,
    InputTextArea,
    InputText
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = _.cloneDeep(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    onInput({ name, value }) {
      let _formValues = { ...this.formValues };
      _formValues[name] = value;
      this.formValues['field_gates'] = value;
      this.$emit("formUpdate", _formValues);
    },

    onSubmit(e) {
      e.preventDefault();
    }
  },
};
</script>
