<template>
  <div>
    <Loading v-if="isLoading" />
    <v-data-table v-model="selected" :noDataText="NO_DATA" :headers="headers" :items-per-page="desserts.length"
      :items="enrichedDesserts" hide-default-footer fixed-header disable-sort :height="tableHeight - 46"
      item-key="id_row_table" show-select class="additional-requirements" @update:page="$vuetify.goTo(0)" :key=page
      style="position: relative; padding-top: 10px;">
      <template v-slot:top class="top-additional-request">
        <AddRequestForm :fieldId="fieldId" :searchParams="searchParams" :isAddRequest="isAddRequest" :isBooking="false"
          @reload-machine="setDataTable" style="position: absolute; height: 70px; left: 30px;" />
        <v-btn class="action-header-top" style="margin-left: 137px; width: 100px;" color="primary" :disabled="isDisable"
          v-if="roleUser != LEADER_ROLE" @click="openInfoModal()" depressed>
          発注
        </v-btn>
        <v-btn class="action-header-top" @click="dialogSubmit = true, commentData.message = ''" v-if="roleUser != LEADER_ROLE"
          style="margin-left: 25px; width: 154px; color: white; background-color: #FF7B52;" :disabled="isDisable" depressed>
          要求者へコメント
          <Popup width="480px" :dialog="dialogSubmit">
            <v-card class="dialog">
              <ValidationObserver v-slot="{ invalid }" ref="observer">
                <v-card-title>
                  <div class="title">要求者へコメント</div>
                  <v-spacer></v-spacer>
                  <Popup width="480px" :dialog="isShowCancel">
                    <ConfirmCloseDialog v-model="isShowCancel" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
                      :text2="WRN_0002.TEXT2" @close="isShowCancel = false" @yes="cancelUserData()" warning />
                  </Popup>
                  <v-icon @click="isShowCancel = true">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="body mb-5" style="padding: 0px 20px;">
                  <Label label="メッセージ" class="title-input-item">
                    <InputTextArea
                      :values="commentData"
                      name="message"
                      validation_label="メッセージ"
                      validation_rules="minmax:0,255"
                      @onInput="onChangeSearchParams"
                      :editable="true">
                    </InputTextArea>
                  </Label>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed outlined class="btn" color="warning" @click="isShowCancel = true">
                    やめる
                  </v-btn>
                  <v-btn depressed class="btn" color="warning" @click="commentMachine" :disabled="isDisableBtn || isSubmitted">
                    送信
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </Popup>
        </v-btn>
        <template  v-if="roleUser !== LEADER_ROLE">
        <v-btn class="btn-delete btn-delete-padding" :disabled="isDisable" icon @click="dialogDel = true" v-if="roleUser == LEADER_ROLE"
          style="margin-left: 125px;">
          <Popup width="480px" :dialog="dialogDel">
            <ConfirmCloseDialog v-model="dialogDel" :title="WRN_0001.TITLE" :text1="WRN_0001.TEXT1"
              :text2="WRN_0001.TEXT2" textSubmit="解除" :warning="true" @close="dialogDel = false"
              @yes="deleteMachine(ids)" />
          </Popup>
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-btn class="btn-delete btn-delete-padding" :disabled="isDisable" icon @click="dialogDel = true" v-else
          style="margin-left: 15px;">
          <Popup width="480px" :dialog="dialogDel">
            <ConfirmCloseDialog v-model="dialogDel" :title="WRN_0001.TITLE" :text1="WRN_0001.TEXT1"
              :text2="WRN_0001.TEXT2" textSubmit="解除" :warning="true" @close="dialogDel = false"
              @yes="deleteMachine(ids)" />
          </Popup>
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        </template >
        <OrderInfoModal v-if="isShowModalInfo" :isShow="isShowModalInfo" @close="onCloseModalInfo" :requestIds="ids">
        </OrderInfoModal>
      </template>
      <template v-slot:item.machine_request_detail_status="{ item }">
        <v-chip
          class="item-status"
          v-bind:class="{ 'item-status-color': [0, 1].includes(item.machine_request_detail_status) }"
          :color="getColor(item.machine_request_detail_status)"
          dark
        >
        {{ listStatus[item.machine_request_detail_status] }}
        </v-chip>
      </template>
      <template v-slot:item.use_start_date="{ item }">
        {{ formattedDate(item.use_start_date) }}
      </template>
      <template v-slot:item.request_date="{ item }">
        {{ formattedDate(item.request_date) }}
      </template>
      <template v-slot:item.requester="{ item }">
        <p>{{ item.company_name }} {{ item.user_name_sei }} {{ item.user_name_mei }}</p>
        <p> {{ item.user_phone }} </p>
      </template>
      <template v-slot:item.name="{ item }">
        <p>{{ getFloor(item.floor_height_min, item.floor_height_max) }}</p>
        <p>{{ item.lifting_method }} {{ item.undercarriage }} {{ item.working_floor }} </p>
      </template>
      <template v-slot:item.id="{ item }">
        {{ item.count_machine_request_detail }}
      </template>
      <template v-slot:item.special_feature="{ item }">
        <v-tooltip bottom color="#0082d8" max-width="384px" v-if="item.special_feature">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="black">mdi-information-outline</v-icon>
          </template>
          <span style="word-wrap: break-word; white-space: pre-wrap;">{{ item.special_feature }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { NO_DATA, SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
import { format, parseISO } from "date-fns";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import AddRequestForm from "@/components/forms/booking/AddRequestForm.vue";
import OrderInfoModal from "./components/OrderInfoModal.vue";
import {
  STATUS_MACHINE_REQUEST_DETAIL,
  HEADER_ADDITIONAL_REQUIREMENT,
  LEADER_ROLE
} from "@/constants/MACHINE";
import { WRN_0001, WRN_0002 } from "@/constants/MESSAGE";
import Loading from "@/components/loading/Loading";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import InputTextArea from "@/components/forms/elements/InputTextArea";
import Label from "@/components/forms/elements/Label";

export default {
  data() {
    return {
      NO_DATA,
      LEADER_ROLE: LEADER_ROLE,
      isDisable: true,
      dialogDel: false,
      selected: [],
      checkboxAll: false,
      listStatus: STATUS_MACHINE_REQUEST_DETAIL,
      headers: HEADER_ADDITIONAL_REQUIREMENT,
      desserts: [],
      ids: [],
      dialogSubmit: false,
      commentData: {
        message: ""
      },
      fieldId: null,
      isAddRequest: true,
      // Only use to rerender modal info
      isShowModalInfo: false,
      searchParams: {
        designated_date: new Date(),
        check_screen: 'machine'
      },
      WRN_0001: WRN_0001,
      WRN_0002: WRN_0002,
      isLoading: false,
      indexPagination: 1,
      oldCurrentData: 1,
      roleUser: null,
      isShowCancel: false,
      SUBMIT_DELAY_TIMEOUT,
      isSubmitted: false
    }
  },

  components: {
    ConfirmCloseDialog,
    Popup,
    AddRequestForm,
    OrderInfoModal,
    Loading,
    ValidationProvider,
    ValidationObserver,
    InputTextArea,
    Label
  },

  computed: {
    enrichedDesserts() {
      return this.desserts.map((x, index) => ({ ...x, id_row_table: index + this.indexPagination, isSelectable: x.machine_request_detail_status === 0 }));
    },
    isDisableBtn() {
      return !this.commentData.message.trim().length > 0;
    }
  },

  props: {
    paramsSearch: {
      type: Object,
    },
    tableHeight: {
      type: Number
    },
    page: {
      type: Number
    }
  },

  watch: {
    selected(listSelect) {
      this.ids = [];
      listSelect.forEach(item => {
        this.ids.push(item.id);
      });
      (listSelect.length) ? this.isDisable = false : this.isDisable = true;
    },

    paramsSearch: {
      handler: function (params) {
        this.oldCurrentData = params.oldCurrent;
        delete this.paramsSearch.oldCurrent;
        if (!params.field_id) this.fetchData();
        else this.getListMachine(params);
      },
      deep: true
    },
  },

  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.roleUser = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    this.fetchData();
  },
  mounted() {
    this.$watch(
      () => Store.getters["Machines/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    formattedDate(date) {
      return date ? format(parseISO(date), "yyyy/MM/dd") : "";
    },

    fetchData() {
      let params = {
        "field_id": this.fieldId,
        "sort_value": "use_start_date",
        "sort_by": "ASC",
        "status": [0, 5],
        "page": 1,
        "page_size": 25,
      };
      this.getListMachine(params);
    },

    async getListMachine(params) {
      const result = await Store.dispatch(`Machines/getListMachineRole`, params);
      if (!result.hasError) {
        this.desserts = result.contents.entries;
        const dataPagination = result.contents.pagination;
        this.isDisable = true;
        this.selected = [];
        await this.$emit('check-flag-show-icon', this.fieldId)
        this.$emit("pagination-data", dataPagination)
      }
    },

    getColor(status) {
      const notOrdering = 0;
      const done = 1;
      const arranged = 2;
      const ordered = 3;
      const allotted = 4;

      if (status === notOrdering) return '#E5951D'
      else if (status === done) return '#32C36C'
      else if (status === arranged) return '#274FC2'
      else if (status === ordered) return 'rgba(0, 0, 0, 0.38)'
      else if (status === allotted) return '#FF7B52'
    },

    async deleteMachine(ids) {
      const params = {
        "ids": ids,
      };
      const result = await Store.dispatch(`Machines/deleteMachineList`, params);
      if (!result.hasError) {
        this.setDataTable();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.dialogDel = false;
        this.isDisable = true;
        this.selected = [];
      } else if (result.data.status_code === 400) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    async commentMachine() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      const params = {
        "ids": this.ids,
        "message": this.commentData.message,
        "fieldId": this.fieldId
      };
      let response = await Store.dispatch(`Machines/commentMachineRequests`, params);
      if (!response.hasError) {
        this.setDataTable();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      }
      this.dialogSubmit = false;
      this.selected = [];
    },

    openInfoModal() {
      this.isShowModalInfo = true;
    },

    cancelSendData() {
      this.commentData.message = "";
      this.dialogSubmit = false;
    },

    setDataTable() {
      let params = {
        "field_id": this.fieldId,
        "sort_value": "use_start_date",
        "sort_by": "ASC",
        "status": [0, 5],
        "page": 1,
        "page_size": 25
      };
      if (this.paramsSearch) {
        params = {
          ...params,
          ...this.paramsSearch
        };
      }
      this.selected = [];
      this.getListMachine(params);
    },
    cancelUserData() {
      this.isShowCancel = false;
      this.commentData.message = "";
      this.dialogSubmit = false;
    },
    onCloseModalInfo(needUpdate) {
      this.isShowModalInfo = false;
      if (needUpdate && needUpdate !== 'close') {
        this.$emit('search');
      } else if (needUpdate !== 'close') this.setDataTable();
    },
    onChangeSearchParams({ name, value }) {
      let commentData = { ...this.commentData };
      commentData[name] = value;
      this.commentData = commentData;
    },
    getFloor(floorHeightMin, floorHeightMax) {
      if (floorHeightMin === null && floorHeightMax !== null) {
        return `～${ floorHeightMax }m`;
      } else if (floorHeightMax === null & floorHeightMin !== null) {
        return `${ floorHeightMin }m～`;
      } else if (floorHeightMin !== null && floorHeightMax !== null) {
        return `${ floorHeightMin }m～${ floorHeightMax }m`;
      } else {
        return ``;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .tab-select-header {
    height: 56px !important;
  }

  .additional-requirements {
    ::v-deep table {
      .v-simple-checkbox--disabled {
        opacity: 0;
      }

      td {
        line-height: 24px;

        .item-status {
          width: 60px;
          font-size: 12px !important;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          border-radius: 16px;
          height: 32px;
          line-height: 32px;
        }

        .item-status-color {
          color: rgba(0, 0, 0, 0.87);
          pointer-events: none;
        }

        p {
          margin-bottom: 3px !important;
          line-height: 15px;
        }

        .v-chip__content {
          font-weight: 400 !important;
          font-size: 12px !important;
        }
      }
    }
  }

  .tableBody {
    .btn-delete:before {
      background-color: white !important;
    }
  }
  .btn-delete-padding {
    padding: 0 6px;
  }
}

.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;

      .text {
        padding-left: 30px;
        font-size: 16px !important;
        color: #000000;
      }
    }
  }

  .btn {
    margin-right: 12px;
  }

  .title {
    font-size: 20px !important;
    font-weight: 600;
    padding-left: 8px;
    color: #ff9c7d;
  }

  .textAr {
    .lb-texAr {
      font-size: 12px !important;
    }
  }
}
</style>
