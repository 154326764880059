import Api, { Mock } from "../api";

const fieldPath = "fields";
const reportPath = "reports";
const fieldURL = `${process.env.VUE_APP_API_BASE_URL}/${fieldPath}`;
const reportURL = `${process.env.VUE_APP_API_BASE_URL}/${reportPath}`;
const GET_QR_URL = `${fieldURL}/get_field_qr_code`;
const GET_FIELD_INFO_DASHBOARD_URL = `${fieldURL}/get_field_info_dashboard`;
const GET_EXPORT_FIELD_QR_URL = `${reportURL}/export_field_qr_code`;

if (Mock) {
  //TODO add mock
}

export const dashboardPortal = {
  getQRCode: (params) => {
    return Api.get(GET_QR_URL, { params: params });
  },
  getFieldInfoDashboard: (params) => {
    return Api.get(GET_FIELD_INFO_DASHBOARD_URL, { params: params });
  },
  getExportFieldQRPDF: (params) => {
    return Api.get(GET_EXPORT_FIELD_QR_URL, {
      params: params,
      responseType: "blob",
    });
  },
};
