<template>
  <div class="fd-gallery active my-5">
    <div class="fd-gallery-content">
      <div class="fd-gallery-content-show">
        <div class="next-and-back">
          <v-btn icon class="fd-gallery-content-show-prev-icon" v-show="isActive" v-on:click="prevShow()">
            <v-icon style="color: black;">mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <div class="fd-gallery-content-show-centent">
          <img v-if="show.type == 'image'" class="block-frame" v-bind:src="show.src" alt="" style="max-height: 550px;"/>
          <iframe v-else class="block-frame w-100" v-bind:src="show.src" autoplay="" loop="" muted="" controls="">
          </iframe>
        </div>
        <div class="next-and-back">
          <v-btn icon class="fd-gallery-content-show-next-icon" v-show="isActive !== (listVideo.length - 1)" v-on:click="nextShow()">
            <v-icon style="color: black;">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="fd-gallery-content-list">
        <VueSlickCarousel ref="carousel" v-bind="settings">
          <div v-for="(value, index) in listVideo" v-bind:key="index">
            <div class="fd-gallery-content-list-item" v-bind:class="{ active: isActive == index }"
              v-on:click="showFile(index)">
              <img v-if="value.type == 'image'" class="block-frame" v-bind:src="value.src" alt=""/>
              <div v-else-if="value.type == 'pdf'" class="block-frame positon-relative">
                <iframe class="block-frame" v-bind:src="value.src" alt="" />
                <div class="overlay-frame" v-on:click="showFile(index)" />
              </div>
              <iframe v-else class="block-frame w-100" v-bind:src="value.src" loop="" muted="">
              </iframe>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Gallery',
  components: {
    VueSlickCarousel
  },
  props: {
    listVideo: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      show: {},
      isActive: "",
      settings: {
        slidesToShow: 5,
        focusOnSelect: true,
        swipeToSlide: true,
        infinite: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              centerMode: true,
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.show = this.listVideo[0];
    this.isActive = 0;
  },
  methods: {
    showFile(index) {
      this.show = this.listVideo[index]
      this.isActive = index
    },
    prevShow() {
      if (this.isActive) {
        this.isActive = this.isActive - 1
        this.show = this.listVideo[this.isActive]
      } else {
        this.isActive = this.listVideo.length - 1
        this.show = this.listVideo[this.isActive]
      }
      this.$refs.carousel.goTo(this.isActive)
    },
    nextShow() {
      if (this.isActive !== this.listVideo.length - 1) {
        this.isActive = this.isActive + 1
        this.show = this.listVideo[this.isActive]
      } else {
        this.isActive = 0
        this.show = this.listVideo[this.isActive]
      }
      this.$refs.carousel.goTo(this.isActive)
    }
  }
}
</script>
<style lang="scss">
.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
}

.fd-gallery {
  width: 75%;
  &.active {
    .fd-gallery-content {
      z-index: 2001;
      max-width: 100%;
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: #0000008f;
    z-index: -1;
  }
  &-content {
    background-color: #fff;
    border-radius: 30px;
    z-index: -1;
    align-items: center;
    &-show {
      display: flex;
      &-index {
        font-weight: 700;
      }
      .next-and-back {
        text-align: -webkit-center;
        padding: 0px 35px;
        padding-top: 16%;
        width: 112px;
        .v-icon.notranslate {
          font-size: 81px;
        }
      }
      &-centent {
        margin: auto;
        width: 100%;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        & .block-frame {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &-list {
      width: 82%;
      margin: 0 auto;
      height: 100px;
      &-item {
        height: 100px;
        margin: 0 5px;
        position: relative;
        & .block-frame {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & .overlay-frame {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }
        &-overlay {
          position: absolute;
          background: #0000009e;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 30px;
            border: 3px solid #fff;
            display: none;
            justify-content: center;
            align-items: center;
            color: #fff;
          }
        }
        &.active {
          border: 5px solid #32C36C;
          .fd-gallery-content-list-item-overlay {
            background: unset;
            &-icon {
              display: flex;
            }
          }
        }
      }
    }
  }
}
</style>