import {
  MENU_SCHEDULE_ITEMS,
  MENU_ENTRANCE_ITEMS,
  MENU_DEVICE_ITEMS,
  MENU_CHAT_ITEMS,
} from "@/constants/COMMON.js";

import { getDropDownMenuVisibility, ENV_CONFIG_RENTAL } from "./ENV_CLIENT";

// ポータルの種類
const PORTAL_LIST = {
  INHOUSE_PORTAL: { id: 1, name: "自社ポータル" },
  SITE_PORTAL: { id: 2, name: "現場ポータル" },
  SP: { id: 3, name: "モバイル" },
};

// 現ポータル
const CURRENT_PORTAL = { id: 2, name: "現場ポータル" };

// タブメニュー項目 自社ポータル
const HEADER_MENU_ITEMS_INHOUSE = {
  PORTAL: { id: 0, name: "掲示板", path: "/portal/dashboard" },
  SITES: { id: 1, name: "CRUD", path: "/crud" },
  // BOOKING_STATUS: { id: 1, name: "予約状況", path: "/" },
  FIELD_MACHINE: { id: 2, name: "現場機械", path: "/machine" },
  RESULT_TEST: { id: 3, name: "点検実績", path: "/" },
  FORM_OUTPUT: { id: 4, name: "帳票出力", path: "/" },
  USERS: { id: 5, name: "利用者一覧", path: "/field-user" },
};

const HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE = {
  PORTAL: { id: 0, name: "ポータル", path: "/portal" },
  SITES: { id: 1, name: "現場", path: "/master/sites" },
  EMPLOYEES: { id: 2, name: "社員", path: "/master/employees" },
  COMPANY_MACHINES: { id: 3, name: "機材", path: "/master/companyMachines" },
};

const HEADER_MENU_ITEMS_INHOUSE_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_INHOUSE
).map((key) => {
  return HEADER_MENU_ITEMS_INHOUSE[key];
});

const HEADER_MENU_ITEMS_INHOUSE_ARRAY_WITHOUT_OFFICE = Object.keys(
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE
).map((key) => {
  return HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE[key];
});

// タブメニュー項目 現場ポータル
const HEADER_MENU_ITEMS_SITE = {
  PORTAL: { id: 0, name: "掲示板", path: "/portal/dashboard" },
  CHART: { id: 1, name: "体制管理", path: "/portal/chart" },
  SAFETY: { id: 2, name: "労務管理", path: "/safety/chart" },
  SCHEDULE: { id: 3, name: "予定管理", path: "/schedule/works" },
  ENTRANCE: { id: 4, name: "入退場管理", path: "/entrance/new_worker" },
  DEVICE_MANAGEMENT: { id: 5, name: "資機材管理", path: "/device/stock" },
  PLACEMENT: { id: 6, name: "配置計画", path: "/oekaki/" },
  TEXTCHAT: {
    id: 7,
    name: "チャット管理",
    path: "/portal/chatmanage/siterooms",
  },
};

// レンタル会社メニュー
const HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY = {
  PORTAL: { id: 0, name: "掲示板", path: "/portal/dashboard" },
  DEVICE_MANAGEMENT: { id: 5, name: "資機材管理", path: "/device/stock" },
};

const HEADER_MENU_ITEMS_SITE_ARRAY = Object.keys(HEADER_MENU_ITEMS_SITE).map(
  (key) => {
    return HEADER_MENU_ITEMS_SITE[key];
  }
);

const HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY = Object.keys(
  HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY
).map((key) => {
  return HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY[key];
});

/**
 * ドロップダウンを表示する項目
 */
const MENU_SCHEDULE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_SCHEDULE_ITEMS");
  return MENU_SCHEDULE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_ENTRANCE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_ENTRANCE_ITEMS");
  return MENU_ENTRANCE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_DEVICE_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_DEVICE_ITEMS");
  return MENU_DEVICE_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const MENU_CHAT_ITEMS_VIS = () => {
  const VISIBILITY = getDropDownMenuVisibility("MENU_CHAT_ITEMS");
  return MENU_CHAT_ITEMS.map((item) => {
    const vis = VISIBILITY.find((_item) => _item.id === item.id);
    return { ...item, ...vis };
  });
};

const HEADER_MENU_DROPDOWN = [
  {
    id: HEADER_MENU_ITEMS_SITE.SCHEDULE.id,
    menu: MENU_SCHEDULE_ITEMS_VIS(),
  },
  { id: HEADER_MENU_ITEMS_SITE.ENTRANCE.id, menu: MENU_ENTRANCE_ITEMS_VIS() }, // COMMON.jsの予定管理一覧
  {
    id: HEADER_MENU_ITEMS_SITE.DEVICE_MANAGEMENT.id,
    menu: MENU_DEVICE_ITEMS_VIS(),
  },
  {
    id: HEADER_MENU_ITEMS_SITE.TEXTCHAT.id,
    menu: MENU_CHAT_ITEMS_VIS(),
  },
];

// レンタル会社のドロップダウンメニューを環境に左右されないので、固定で返す
const HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY = [
  {
    id: HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY.DEVICE_MANAGEMENT.id,
    menu: ENV_CONFIG_RENTAL.MENU_DEVICE_ITEMS.map((item) => {
      const findItem = MENU_DEVICE_ITEMS.find((_item) => _item.id === item.id);
      return Object.assign(findItem, item);
    }),
  },
];

// ローカルストレージで保持
const STORAGE_KEY = "MMS_HEADERS";

const MENU_ENTRANCE_ITEMS_MAP = {
  REGISTER_FACE: { id: 0, name: "顔画像登録" },
  HISTORY: { id: 1, name: "入退場履歴" },
  RECORDS: { id: 2, name: "入退場実績" },
  NEW_WORKER: { id: 3, name: "新規入場者" },
  SUMMARY: { id: 4, name: "集計" },
};

// header menu items admin
const HEADER_MENU_ITEMS_ADMIN = {
  SITES: { id: 0, name: "現場", path: "/master/fields" },
  COMPANY: { id: 1, name: "会社", path: "/master/companies", isRoute: true},
};

const HEADER_MENU_ITEMS_ADMIN_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_ADMIN
).map((key) => {
  return HEADER_MENU_ITEMS_ADMIN[key];
});

// header menu items field
const HEADER_MENU_ITEMS_FIELD = {
  SITES: { id: 0, name: "現場", path: "/master/fields" },
};

const HEADER_MENU_ITEMS_FIELD_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD[key];
});

// header menu items rental
const HEADER_MENU_ITEMS_RENTAL = {
  SITES: { id: 0, name: "現場", path: "/master/fields" },
  MACHINE: { id: 1, name: "商品", path: "/master/machines" },
  ORDER: { id: 2, name: "受注", path: "/master/receive-order" },
};

const HEADER_MENU_ITEMS_RENTAL_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_RENTAL
).map((key) => {
  return HEADER_MENU_ITEMS_RENTAL[key];
});


const HEADER_MENU_ITEMS_FIELD_PORTAL = {
  BULLENTIN_BOARD: { id: 0, name: "掲示板", path: "/portal/dashboard", key: "bullentin_board" },
  RESERVATION_STATUS: { id: 1, name: "予約状況", path: "/booking", key: "reservation_status" },
  FIELD_MACHINE: { id: 2, name: "現場機械", path: "/machine", key: "field_machine" },
  INSPECTION_RESULTS: { id: 3, name: "点検実績", path: "/inspection", key: "inspection_result" },
  FORM_OUTPUT: { id: 4, name: "帳票出力", path: "/reports", key: "form_output" },
  USER_LIST: { id: 5, name: "利用者一覧", path: "/field-user", key: "user_list" },
}

const HEADER_MENU_ITEMS_FIELD_PORTAL_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD_PORTAL
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD_PORTAL[key];
});


// header menu items field unfinished
const HEADER_MENU_ITEMS_FIELD_UNFINISHED = {
  BULLENTIN_BOARD: { id: 0, name: "掲示板", path: "/" },
  RESERVATION_STATUS: { id: 1, name: "予約状況", path: "/" },
  FIELD_MACHINE: { id: 2, name: "現場機械", path: "/machine" },
  INSPECTION_RESULTS: { id: 3, name: "点検実績", path: "/" },
  FORM_OUTPUT: { id: 4, name: "帳票出力", path: "/" },
  USER_LIST: { id: 5, name: "利用者一覧", path: "/field-user" },
};

const HEADER_MENU_ITEMS_FIELD_UNFINISHED_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD_UNFINISHED
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD_UNFINISHED[key];
});

// header menu items field unfinished
const HEADER_MENU_ITEMS_FIELD_FINISHED = {
  FORM_OUTPUT: { id: 0, name: "帳票出力", path: "/" },
};

const HEADER_MENU_ITEMS_FIELD_FINISHED_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD_FINISHED
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD_FINISHED[key];
});

// header menu items field partner
const HEADER_MENU_ITEMS_FIELD_PARTNER = {
  RESERVATION_STATUS: { id: 0, name: "予約状況", path: "/" },
  FIELD_MACHINE: { id: 1, name: "現場機械", path: "/machine" },
  USER_LIST: { id: 2, name: "利用者一覧", path: "/field-user" },
};


const HEADER_MENU_ITEMS_FIELD_PARTNER_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD_PARTNER
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD_PARTNER[key];
});

export {
  PORTAL_LIST,
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE,
  HEADER_MENU_ITEMS_INHOUSE_ARRAY,
  HEADER_MENU_ITEMS_INHOUSE_ARRAY_WITHOUT_OFFICE,
  HEADER_MENU_ITEMS_SITE,
  HEADER_MENU_ITEMS_SITE_ARRAY,
  HEADER_MENU_DROPDOWN,
  HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,
  CURRENT_PORTAL,
  STORAGE_KEY,
  HEADER_MENU_ITEMS_SITE_FOR_USER_RENTAL_COMPANY,
  HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
  MENU_ENTRANCE_ITEMS_MAP,
  HEADER_MENU_ITEMS_ADMIN,
  HEADER_MENU_ITEMS_FIELD,
  HEADER_MENU_ITEMS_RENTAL,
  HEADER_MENU_ITEMS_FIELD_UNFINISHED,
  HEADER_MENU_ITEMS_FIELD_FINISHED,
  HEADER_MENU_ITEMS_FIELD_PARTNER,
  HEADER_MENU_ITEMS_ADMIN_ARRAY,
  HEADER_MENU_ITEMS_FIELD_ARRAY,
  HEADER_MENU_ITEMS_RENTAL_ARRAY,
  HEADER_MENU_ITEMS_FIELD_UNFINISHED_ARRAY,
  HEADER_MENU_ITEMS_FIELD_FINISHED_ARRAY,
  HEADER_MENU_ITEMS_FIELD_PARTNER_ARRAY,
  HEADER_MENU_ITEMS_FIELD_PORTAL,
  HEADER_MENU_ITEMS_FIELD_PORTAL_ARRAY
};
