const FIELD_USER_TAB_SELECT = {
  FIELD_USER_INFO_TAB: {id: 1, name: "現場所属ユーザー"},
  FIELD_USER_NOT_APPROVE_TAB: {id: 2, name: "利用申請"}
}
const USER_TABLE_LABELS = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "name",
    width:"20%",
  },
  {
    text: "氏名",
    value: "name_sei",
    align: "left",
    sortable: false,
    width:"12%",
  },
  {
    text: "役職",
    value: "role",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "携帯番号",
    value: "phone",
    align: "left",
    sortable: false,
    width:"8%",
  },
  {
    text: "解除日時",
    value: "deletedAt",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "強制移動",
    value: "flg_forced_move",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "",
    value: "select_forced_move",
    align: "left",
    sortable: false,
    width:"20px",
  },
  {
    text: "荷受と割付・在庫登録",
    value: "flg_receipt_allocation",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "",
    value: "select_receipt_allocation",
    align: "left",
    sortable: false,
    width:"20px",
  },
];

const USER_TABLE_NOT_APPROVE_LABELS = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "name",
    width:"20%",
  },
  {
    text: "氏名",
    value: "name_sei",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "役職",
    value: "role",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "携帯電話",
    value: "phone",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "申請日時",
    value: "createdAt",
    align: "left",
    sortable: false,
    width:"15%",
  },
];

const USER_TABLE_LABELS_PARTNER = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "name",
    width:"20%",
  },
  {
    text: "氏名",
    value: "name_sei",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "役職（所属班）",
    value: "role",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "携帯番号",
    value: "phone",
    align: "left",
    sortable: false,
    width:"15%",
  },
  {
    text: "解除日時",
    value: "deletedAt",
    align: "left",
    sortable: false,
    width:"15%",
  },
];

const ROLE_TYPE = {
  NUMBER_ONE: {id: 2, name: "職長"},
  NUMBER_TWO: {id: 3, name: "作業員"},
  NUMBER_THREE: {id: 4, name: "レンタル会社"},
};
const ROLE_TYPE_RENTA = {
  NUMBER_ONE: {id: 2, name: "職長"},
  NUMBER_TWO: {id: 3, name: "作業員"},
};
const ROLE_TYPE_DISPLAY = {
  NUMBER_ONE: {id: 1, name: "元請監督"},
  NUMBER_TWO: {id: 2, name: "協力職長"},
  NUMBER_THREE: {id: 3, name: "協力作業員"},
  NUMBER_FOR: {id: 4, name: "レンタル"},
};
const ROLE_TYPE_NOT_APPROVE = {
  NUMBER_ONE: {id: 1, name: "管理者"},
  NUMBER_TWO: {id: 2, name: "職長"},
  NUMBER_THREE: {id: 4, name: "レンタル会社"},
};
const FIELD_SORT_ITEMS = [
  {
    id: "sort_company",
    name: "会社名", //company_name name_kana
  },
  {
    id: "sort_full_name",
    name: "氏名", //users.kana_sei, users.kana_mei
  },
  {
    id: "sort_role",
    name: "役職", //field_users.role
  },
];
const FIELD_SORT_ITEMS_FOR_RENTAL = [
  {
    id: "sort_full_name",
    name: "氏名", //users.kana_sei, users.kana_mei
  },
  {
    id: "sort_role",
    name: "役職", //field_users.role
  },
];
const FIELD_SORT_ITEMS_NOT_APPROVE = [
  {
    id: "sort_company",
    name: "会社名", //company_name name_kana
  },
  {
    id: "sort_full_name",
    name: "氏名", //users.kana_sei, users.kana_mei
  },
  {
    id: "sort_role",
    name: "役職", //field_users.role
  },
  {
    id: "created_at",
    name: "申請日時", //field_users.created_at
  },
];
const FIELD_SORT_ITEMS_NOT_APPROVE_FOR_RENTAL = [
  {
    id: "sort_full_name",
    name: "氏名", //users.kana_sei, users.kana_mei
  },
  {
    id: "created_at",
    name: "申請日時", //field_users.created_at
  },
];
const ROLE_NAME = {
  1: "管理者",
  2: "職長",
  3: "作業員",
  4: "レンタル会社",
}
const LEADER_ROLE = 2
const FIRST_LOAD = 0

const FORCED_MOVE = {
  0: "未設定",
  1: "永久権限付与",
  2: "１日限定権限付与",
}

const FORCED_MOVE_VIEW = {
  0: "",
  1: "承認済（永久）",
  2: "承認済（1日）",
}

const RECEIPT_ALLOCATION = {
  0: "未設定",
  1: "永久権限付与",
  2: "１日限定権限付与",
}

const RECEIPT_ALLOCATION_VIEW = {
  0: "",
  1: "承認済（永久）",
  2: "承認済（1日）",
}
const MESSAGES = {
  ERR_REQUIRE_SELECT_BUILDING_COMPANY : "現場関係会社を選択してください",
  ERR_EXISTS_EMPLOYEE_WITHOUT_LEADER : "職長の作業班に所属していない作業員がいます",
}

const USER_INIT = {
  email: '',
  phone: '',
  name_sei: '',
  name_mei: '',
  kana_sei: '',
  kana_mei: '',
}

export {
  FIELD_USER_TAB_SELECT,
  USER_TABLE_LABELS,
  ROLE_TYPE,
  ROLE_TYPE_RENTA,
  ROLE_TYPE_NOT_APPROVE,
  FIELD_SORT_ITEMS,
  FIELD_SORT_ITEMS_FOR_RENTAL,
  FIELD_SORT_ITEMS_NOT_APPROVE,
  FIELD_SORT_ITEMS_NOT_APPROVE_FOR_RENTAL,
  USER_TABLE_NOT_APPROVE_LABELS,
  USER_TABLE_LABELS_PARTNER,
  ROLE_NAME,
  MESSAGES,
  LEADER_ROLE,
  FIRST_LOAD,
  ROLE_TYPE_DISPLAY,
  USER_INIT,
  FORCED_MOVE,
  FORCED_MOVE_VIEW,
  RECEIPT_ALLOCATION_VIEW,
  RECEIPT_ALLOCATION
}
