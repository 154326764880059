<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" :flagSupervisor="flagSupervisor" />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading" />
        <TableLayout class="layout-machine-container" :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item" ref="myTableLayout">
          <template #tableHeader="{ updateHeader }">
            <MachineHeader ref="tableHeader" :pageTitle="PAGE_TITLE" :updateHeader="updateHeader"
              :searchParams="searchParams" :isAddNew="false" :isDelete="false" :selectedTab="selectedTab"
              @onInput="onChangeSortParams">
              <AdditionalFormTab @search-tab-add="getListDataSearch" @has-error="getHasError"
                v-if="selectedTab === 1" />
              <PlanToPaySearchTab @search-tab-add="getListDataSearch" @has-error="getHasError"
                v-else-if="selectedTab === 4" />
              <ReportFormTab @search-report-tab="getListDataSearch" v-else-if="selectedTab === 3" @has-error="getHasError" />
              <SearchFormWrapper v-else-if="selectedTab === 0">
                <div class="mr-2 mb-sm-4 mb-lg-0">
                  <LabelSearchMachine label="ナンバー" :editable="true" class="title-input-item">
                    <InputText :editable="true" name="number_plate" :single_line="false" :values="searchParams"
                      height="06px" @onInput="onChangeSearchParams">
                    </InputText>
                  </LabelSearchMachine>
                </div>
                <div class="mr-2 mb-sm-4 mb-lg-0">
                  <LabelSearchMachine label="レンタル会社" :editable="true" class="title-input-item">
                    <Select :items="LIST_COMPANY" :editable="true" name="company_id" :values="searchParams"
                      :single_line="false" @onInput="onChangeSearchParams">
                    </Select>
                  </LabelSearchMachine>
                </div>
                <div class="mr-2 mb-sm-4 mb-lg-0">
                  <LabelSearchMachine label="留置場所" :editable="true" class="title-input-item">
                    <Select :items="LIST_SPACES" :editable="true" :single_line="false" :values="searchParams"
                      name="field_place_id" @onInput="onChangeSearchParams">
                    </Select>
                  </LabelSearchMachine>
                </div>
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-1">予約状況</div>
                  <TabSelect name="bookings" :items="MACHINE_STATUS" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-1">昇降</div>
                  <TabSelect name="lifting_method" :items="LIFTING_METHOD" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-1">足回り</div>
                  <TabSelect name="undercarriage" :items="UNDERCARRIAGE" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-1">作業床</div>
                  <TabSelect name="working_floor" :items="WORKING_FLOOR" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-10" color="primary" @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
              <PlanSearch v-else-if="selectedTab === 2" @search-tab-plan="getListDataSearch" @has-error="getHasError"/>
              <SearchFormWrapper v-else>
                <div class="mr-2 header-filer">
                  <v-textarea filled auto-grow rows="2" row-height="15" label="ナンバー"></v-textarea>
                </div>
              </SearchFormWrapper>
            </MachineHeader>
            <!--
                (共通)
                ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <div class="tableSortWrapper ml-2">
              <div class="sortLabel">並び順:</div>
              <div class="sortElement">
                <v-select v-model="filterParams.sort_value" dense :items="getSortValue()" item-text="name"
                  item-value="id" @change="changeFilter"></v-select>
              </div>
              <div class="sortElement">
                <v-select name="sort_order" v-model="filterParams.sort_by" dense :items="SORT_OPTIONS" item-text="name"
                  item-value="id" @change="changeFilter"></v-select>
              </div>
              <v-spacer></v-spacer>
              <div class="sortLabel">表示件数:</div>
              <div class="sortElement">
                <v-select v-model="filterParams.page_size" dense :items="PAGE_COUNT_OPTIONS" @change="changeFilter">
                </v-select>
              </div>
              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">{{ paramsPagination?.total_item || 0 }}件</div>
            </div>
            <v-row class="cst-row ml-2">
              <v-col cols="12" :sm="8" :md="8" class="cst-col">
                <!-- タブ -->
                <v-tabs v-model="selectedTab" v-if="userRole" ref="tabs">
                  <v-tab>
                    {{ MACHINE_TAB_SELECT.INVENTORY_LIST_TAB.name }}
                  </v-tab>
                  <v-tab>
                    {{ MACHINE_TAB_SELECT.ADDITIONAL_REQUIREMENTS_TAB.name }}
                    <v-icon v-if="flagShowAskedAddTab" style="color: #FF0000;">mdi-alert-circle</v-icon>
                  </v-tab>
                  <v-tab v-show="userRole !== 2">
                    {{ MACHINE_TAB_SELECT.DELIVERY_SCHEDULE_TAB.name }}
                  </v-tab>
                  <v-tab>
                    {{ MACHINE_TAB_SELECT.LIST_REPORT_TAB.name }}
                    <v-icon v-if="flagShowIconReportTab" style="color: #FF0000;">mdi-alert-circle</v-icon>
                  </v-tab>
                  <v-tab v-show="userRole !== 2">
                    {{ MACHINE_TAB_SELECT.RETURNS_TAB.name }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
            -->
          <template #tableBody="{ tableHeight }">
            <template v-if="TAB_SELECT[selectedTab].id === MACHINE_TAB_SELECT.INVENTORY_LIST_TAB.id">
              <div>
                <InventoryListTab :page="searchParams.page" :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight" :LIST_DATA_MACHINE="LIST_DATA_MACHINE" 
                 :fieldId="fieldId" 
                 @reload="getItems"/>
              </div>
            </template>
            <template v-else-if="TAB_SELECT[selectedTab].id === MACHINE_TAB_SELECT.ADDITIONAL_REQUIREMENTS_TAB.id">
              <div>
                <AdditionalRequirements :page="searchParams.page" :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight" @pagination-data="getPagination"
                  @check-flag-show-icon="getCountMachineRequestNotOrder"
                  :paramsSearch="paramsSearch" @search="getListDataSearch" />
              </div>
            </template>
            <template v-else-if="TAB_SELECT[selectedTab].id === MACHINE_TAB_SELECT.DELIVERY_SCHEDULE_TAB.id">
              <div>
                <PlanListTab :page="searchParams.page" :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight" @pagination-data="getPagination" :paramsSearch="paramsSearch" />
              </div>
            </template>
            <template v-else-if="TAB_SELECT[selectedTab].id === MACHINE_TAB_SELECT.LIST_REPORT_TAB.id">
              <div>
                <ReportListTab :page="searchParams.page" :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight" @pagination-report="getPagination"
                   @check-flag-show-icon="getCountAbnormalInspectionAndRepairRequest"
                  :paramsSearch="paramsSearch" />
              </div>
            </template>
            <template v-else-if="TAB_SELECT[selectedTab].id === MACHINE_TAB_SELECT.RETURNS_TAB.id">
              <div>
                <PlanToPay :page="searchParams.page" :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight" @pagination-data="getPagination" :paramsSearch="paramsSearch" />
              </div>
            </template>
          </template>
          <template #tableFooter v-if="paramsPagination?.total > 1">
            <!-- (共通) ページネーション -->
            <Pagination :current="paramsPagination?.current" :total="paramsPagination?.total"
              @pageUpdate="pageUpdate" style="background: white; position: fixed; width: 100%; bottom: 0;"/>
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <CrudForm :flag="true" :item="editedItem" :isNewItem="isNewItem" :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted" :companyBranches="companyBranches" @formUpdate="formUpdate" @submit="submitForm"
        @cancel="closeItemForm" :key="isResetForm" />
    </Popup>

  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import MachineHeader from "./components/MachineHeader";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import CrudForm from "@/components/forms/crud/CrudForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  CRUD_SORT_ITEMS,
  CRUD_INITIAL_ITEM,
} from "@/constants/CRUDS"; //絞り込みフォームで使用

import {
  MACHINE_TAB_SELECT,
  MACHINE_STATUS,
  LIFTING_METHOD,
  UNDERCARRIAGE,
  WORKING_FLOOR,
  TITLE_OPTIONS_TAB_ONE,
  TITLE_OPTIONS,
  TITLE_REPORT_OPTIONS,
  TITLE_PLAN_PAY_OPTIONS,
  TITLE_PLAN_OPTIONS
} from "@/constants/FIELD_MACHINE"
import { HEADER_MENU_ITEMS_INHOUSE, HEADER_MENU_ITEMS_FIELD_PORTAL } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import { TABS } from "@/constants/MACHINE";
import _ from "lodash";
import { convertName, dateFormat } from "@/common/helper";
import InventoryListTab from "./components/machineTab/InventoryListTab.vue";
import AdditionalRequirements from "./components/additionalRequirements/AdditionalRequirements.vue";
import ReportListTab from "./components/reportTab/ReportListTab.vue";
import AdditionalFormTab from "./components/formSearchTab/additionalFormTab.vue";
import ReportFormTab from "./components/formSearchTab/ReportFormTab";
import PlanSearch from "./components/formSearchTab/PlanSearch.vue";
import PlanListTab from "./components/machineTab/PlanListTab.vue";
import PlanToPay from "./components/planToPay/PlanToPay.vue";
import PlanToPaySearchTab from "./components/formSearchTab/PlanToPaySearchTab.vue";
import LabelSearchMachine from "../../components/forms/elements/LabelSearchMachine.vue";
import Loading from "@/components/loading/Loading";
/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "現場機械";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Cruds";


//ソート要素
const SORT_ITEMS = CRUD_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = CRUD_INITIAL_ITEM;

const NO_ITEM_COUNT = 0;

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      dates: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      PAGE_TITLE,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],
      partners: [],
      fieldPlaces: [],

      /**
       * (共通)
       * checkbox選択item
       */

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,
      SORT_OPTIONS: [
        { id: "ASC", name: "昇順" },
        { id: "DESC", name: "降順" }
      ],

      // 検索パラメータ
      searchParams: {
        field_id: null,
        number_plate: null,
        company_id: null,
        field_place_id: null,
        is_booking: null,
        bookings: null,
        lifting_method: null,
        undercarriage: null,
        working_floor: null,
        field_identification_number: null,
        pageCount: PAGE_COUNT,
        sort_value: "status",
        sort_by: "ASC",
        totalPage: 1,
        page: 1,
        page_size: 25
      },

      filterParams: {
        "sort_by": "ASC",
        "sort_value": "status",
        "page": 1,
        "page_size": 25
      },
      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      MACHINE_TAB_SELECT,
      selectedTab: 0,
      listDate: [],
      totalDate: 30,
      paramsSearch: {},
      latestSearch: {},
      paramsPagination: {
        current: 1
      },
      fieldId: null,
      LIST_COMPANY: [],
      LIST_SPACES: [],
      LIST_DATA_MACHINE: [],
      ROLE: 4,
      isLoading: false,
      flagUserRentalCompany: false,
      flagSupervisor: false,
      flagShowIconReportTab: false,
      flagShowAskedAddTab: false,
      roleUser: null,
    };
  },
  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    CrudForm,
    InputText,
    Select,
    TabSelect,
    Label,
    MachineHeader,
    InventoryListTab,
    AdditionalRequirements,
    ReportListTab,
    AdditionalFormTab,
    ReportFormTab,
    PlanSearch,
    PlanListTab,
    PlanToPay,
    PlanToPaySearchTab,
    LabelSearchMachine,
    Loading
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.FIELD_MACHINE.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.$watch(
      () => Store.getters["Machine/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_SUPERVISOR) {
          this.flagSupervisor = true;
        } else if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      () => {
          this.$nextTick(() => {
            this.$refs.tableHeader.updateHeaderHeight();
          });
      },
      {
          immediate: true,
          deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */

  computed: {
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.name,
        description: this.searchParams.description,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.page,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
    MACHINE_STATUS() {
      return Object.keys(MACHINE_STATUS).map((key) => {
        return MACHINE_STATUS[key];
      });
    },
    LIFTING_METHOD() {
      return Object.keys(LIFTING_METHOD).map((key) => {
        return LIFTING_METHOD[key];
      });
    },
    UNDERCARRIAGE() {
      return Object.keys(UNDERCARRIAGE).map((key) => {
        return UNDERCARRIAGE[key];
      });
    },
    WORKING_FLOOR() {
      return Object.keys(WORKING_FLOOR).map((key) => {
        return WORKING_FLOOR[key];
      });
    },
    TAB_SELECT() {
      return Object.keys(MACHINE_TAB_SELECT).map((key) => {
        return MACHINE_TAB_SELECT[key];
      });
    },
    userRole() {
      return Store.getters["GlobalHeader/getCurrentSite"] ? Store.getters["GlobalHeader/getCurrentSite"].role : JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    }
  },

  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.roleUser = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    this.searchParams.field_id = this.fieldId;
    this.getListCompany();
    this.getListSpace();
    this.getItems();
    this.getCountAbnormalInspectionAndRepairRequest({field_id: this.fieldId, role: this.roleUser});
    this.getCountMachineRequestNotOrder(this.fieldId);
  },
  watch: {
    selectedTab() {
      this.filterParams.sort_value = null;
      this.filterParams.sort_by = null;
      this.filterParams.page_size = 25;
      this.filterParams.page = 1;
      this.searchParams.sort_value = null;
      this.searchParams.sort_by = null;
      this.searchParams.page_size = 25;
      this.searchParams.page = 1;
      this.searchParams.number_plate = null;
      this.searchParams.company_id = null;
      this.searchParams.field_place_id = null;
      this.searchParams.is_booking = null;
      this.searchParams.bookings = null;
      this.searchParams.lifting_method = null;
      this.searchParams.undercarriage = null;
      this.searchParams.working_floor = null;
      this.paramsSearch.status = [0, 5];
      this.pageUpdate(1); // Reset page
      this.LIST_DATA_MACHINE = [];
      if (this.selectedTab === 0) {
        this.filterParams = {
          "sort_by": "ASC",
          "sort_value": 'status',
          "page": 1,
          "page_size": 25
        },
          this.getItems();
      } else if (this.selectedTab === 1) {
        this.filterParams.sort_by = "ASC";
        this.filterParams.sort_value = 'use_start_date';
      } else if (this.selectedTab === 3) {
        this.paramsSearch = this.initSearchParamsReportTab();
        this.filterParams.sort_by = "ASC";
        this.filterParams.sort_value = 'report_date';
      } else if (this.selectedTab === 4) {
        this.filterParams.sort_by = "ASC";
        this.filterParams.sort_value = 'name_kana';
      } else if (this.selectedTab === 2) {
        this.filterParams.sort_by = "ASC";
        this.filterParams.sort_value = 'scheduled_delivery_date';
      }
    },
    flagShowAskedAddTab(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$refs.tabs?.callSlider();
      }
    },

    flagShowIconReportTab(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$refs.tabs?.callSlider();
      }
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      const oldCurrent = this.paramsPagination.current;
      let paramsPagination = { ...this.paramsPagination };
      paramsPagination.current = n;
      this.paramsPagination = paramsPagination;
      const dataPagination = {
        "field_id": this.fieldId,
        "page": paramsPagination.current,
        "oldCurrent": oldCurrent,
        "page_size": this.filterParams.page_size
      }
      let paramsSearch = this.paramsSearch;
      if (this.selectedTab === 0) {
        paramsSearch["sort_by"] = "ASC";
        paramsSearch["sort_value"] = "status";
      } else if (this.selectedTab === 1) {
        paramsSearch["sort_by"] = "ASC";
        paramsSearch["sort_value"] = "use_start_date";
        if (!paramsSearch.page) {
          paramsSearch["status"] = [0, 5];
        } else {
          paramsSearch["status"] = this.paramsSearch["status"];
        }
      } else if (this.selectedTab === 4) {
        paramsSearch["sort_by"] = "ASC";
        paramsSearch["sort_value"] = "name_kana";
      } else if (this.selectedTab === 2) {
        paramsSearch["sort_by"] = "ASC";
        paramsSearch["sort_value"] = "scheduled_delivery_date";
      } else if (this.selectedTab === 3) {
        paramsSearch["sort_by"] = "ASC";
        paramsSearch["sort_value"] = "report_date";
      }
      this.paramsSearch = {
        ...paramsSearch,
        ...dataPagination
      };
      let searchParams = { ...this.searchParams, ...dataPagination };
      searchParams.page = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    onSearch() {
      this.searchParams["page"] = 1;
      this.getItems();
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.searchParams.is_booking = this.searchParams.bookings.length == 2 ? null : this.searchParams.bookings[0];
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["page"] = 1) : "";
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },


    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */



    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id }
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          { params }
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.isEmitted = !this.isEmitted;
    },
    async getListCompany() {
      const params = {
        "field_id": this.fieldId,
        "role": this.ROLE
      };
      const response = await Store.dispatch(`Machine/getListFieldCompanyByRole`, params);
      if (!response.hasError) {
        let _data = [...response.data.contents.entries];
        this.LIST_COMPANY = [{ id: null, name: "" }, ..._data];
      }
    },
    async getListSpace() {
      const params = {
        "field_id": this.fieldId,
      };
      const response = await Store.dispatch(`Machine/getListSpaceByFieldId`, params);
      if (!response.hasError) {
        let _data = [...response.data.contents.entries];
        _data = _data.map(e => {
          e.name = e.display_name;
          return e;
        });
        this.LIST_SPACES = [{ id: null, name: "" }, ..._data];
      }
    },
    async getItems() {
      if (this.selectedTab === 0) {
        const response = await Store.dispatch('Machine/getListFieldInventory', this.searchParams);
        if (!response.hasError) {
          this.LIST_DATA_MACHINE = response.data.contents.entries;
          this.paramsPagination = response.data.contents.pagination;
        }
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },

    getListDataSearch(objSearch) {
      const filterParams = this.filterParams;
      this.latestSearch = objSearch ?? this.latestSearch;
      this.paramsSearch = {
        ...this.latestSearch,
        ...filterParams
      };
    },
    getListDataMachine() {
      const filterParams = this.filterParams;
      this.searchParams = {
        ...this.searchParams,
        ...filterParams,
      };
    },
    getPagination(data) {
      this.paramsPagination = data;
    },

    changeFilter() {
      const filterParams = {
        "field_id": this.fieldId,
        'page': this.filterParams.page,
        'page_size': this.filterParams.page_size,
        'sort_by': this.filterParams.sort_by,
        'sort_value': this.filterParams.sort_value,
      };
      const paramsSearch = this.paramsSearch;
      if (this.selectedTab === 1) {
        if (this.paramsSearch?.status === 0 || (!this.paramsSearch?.status)) this.paramsSearch["status"] = [0, 5];
      }
      this.paramsSearch = {
        ...paramsSearch,
        ...filterParams
      };
      const searchParams = this.searchParams;
      this.searchParams = {
        ...searchParams,
        ...filterParams
      };
      this.searchParams["page"] = 1;
      this.getItems();
    },
    getSortValue() {
      if (this.selectedTab === TABS.TAB_FOUR) {
        return TITLE_REPORT_OPTIONS;
      } else if (this.selectedTab === TABS.TAB_TWO) {
        return TITLE_OPTIONS;
      } else if (this.selectedTab === TABS.TAB_FIVE) {
        return TITLE_PLAN_PAY_OPTIONS;
      }
      else if (this.selectedTab === TABS.TAB_THREE) {
        return TITLE_PLAN_OPTIONS;
      }
      return TITLE_OPTIONS_TAB_ONE;
    },
    async getCountAbnormalInspectionAndRepairRequest(params) {
      const result = await Store.dispatch(
          `Machines/getCountAbnormalInspectionAndRepairRequest`,
          params
      );
      if (!result.hasError) {
        this.flagShowIconReportTab = result.contents.entries > NO_ITEM_COUNT
      }
    },
    async getCountMachineRequestNotOrder(fieldId) {
      let params = {
        field_id: fieldId,
        role: this.roleUser
      }
      const result = await Store.dispatch(
          `Machines/getCountMachineRequestNotOrder`,
          params
      );
      if (!result.hasError) {
        this.flagShowAskedAddTab = result.contents.entries > NO_ITEM_COUNT
      }
    },
    getHasError() {
      this.$nextTick(() => {
        this.$refs.myTableLayout.onChangeWindowSize()
      });
    },
    initSearchParamsReportTab() {
      return {
        field_id: this.fieldId,
        report_date_from: null,
        report_date_to: null,
        number_plate: null,
        company_partner_id: null,
        company_renta_id: null,
        field_place_id: null,
        kinds: [],
        repair_status: [0,2,3,4],
        page: 1,
        page_size: 25,
        sort_by: "ASC",
        sort_value: "report_date"
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .tab-select-header {
    font-weight: 400;
    font-size: 12px !important;
    height: 56px !important;
    color: #000000;
  }

  .tableSortWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
    height: 32px;

    .sortLabel {
      font-size: 12px;
      height: 32px;
      margin-right: 16px;
    }

    .sortElement {
      max-width: 128px;
      margin-right: 24px;
    }

    .orderTitle {
      width: 298px;
      margin-right: 12px;
    }

    .orderSort {
      width: 128px;
      margin-right: 12px;
    }

    .total_item {
      padding-bottom: 15px;
    }
  }

  .title-input-item .label {
    color: #000000 !important;
  }

  ::v-deep .SearchFormWrapper .tabSelectwrapper {
    height: 10px;

    .tabLabel {
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;
    }
  }
}
</style>
