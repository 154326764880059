const USER_INITIAL_ITEM = {
   email: "",
   phone: "",
   name_sei: "",
   name_mei: "",
   kana_sei: "",
   kana_mei: "",
   postal_code: "",
   prefecture_id: "",
   address_detail: "",
};

const USER_ACTIVE = 1;
const USER_DEACTIVE = 2;
const ADMIN_USER = 1;
const DEFAULT_SELECT = {
   id: null,
   name: "選択してください"
};
const DEFAULT_SELECT_BLANK = {
   id: null,
   name: ""
};

export {
   USER_INITIAL_ITEM,
   USER_ACTIVE,
   USER_DEACTIVE,
   ADMIN_USER,
   DEFAULT_SELECT,
   DEFAULT_SELECT_BLANK,
};