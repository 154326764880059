<template>
  <v-row>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <v-dialog v-model="dialog" persistent max-width="1300px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" class="mt-3 custom-opacity" v-bind="attrs" v-on="on" @click="validateCompany"
            :disabled="!isAddRequest" depressed>
            追加要求
          </v-btn>
        </template>
        <v-card class="dialog custom-color">
          <div class="header">
            <v-card-title style="height: 45px; padding: 0px; padding-bottom: 48px;">
              <span class="title">追加要求</span>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn class="ma-2 pl-5 pr-5 dialog-button-default" depressed outlined color="primary" @click="isShowCancel = true">
                  やめる
                </v-btn>
                <v-btn class="ma-2 pl-7 pr-7 dialog-button-default" depressed color="primary" @click="add()" :disabled="invalid || isSubmitted">
                  保存
                </v-btn>
                <Popup width="480px" :dialog="isShowCancel">
                  <ConfirmCloseDialog v-model="isShowCancel" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
                    :text2="WRN_0002.TEXT2" :warning="true" @close="isShowCancel = false" @yes="cancel()" />
                </Popup>
                <v-icon @click="isShowCancel = true" class="ml-10">mdi-close</v-icon>
              </v-card-actions>
            </v-card-title>
            <template style="height: 40px;">
              <v-tabs align-with-title class="ml-n4">
                <v-tab class="tab-title">基本情報</v-tab>
              </v-tabs>
            </template>
            <v-divider></v-divider>
          </div>
          <v-card-text style="height: auto">
            <v-container>
              <v-row>
                <v-col cols="12" lg="6" md="12">
                  <v-row class="custom-radio">
                    <v-col lg="6" md="6" class="mt-5">
                      <Label label="日付（開始）" :editable="true" required>
                        <template>
                          <v-icon color="black">mdi-calendar-today-outline</v-icon>
                        </template>
                        <v-menu offset-y nudge-left="20" nudge-bottom="5" v-model="isDateStart" min-width="auto"
                          :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider v-slot="{ errors, valid }" name="日付（開始）" :rules="getRuleStartDate()" ref="startDate">
                              <v-text-field class="input-date pl-2 pr-2" v-model="computedDateStartFormatted" filled
                                readonly v-bind="attrs" v-on="on" :error-messages="errors" :success="valid" dense>
                              </v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker no-title scrollable v-model="machine.use_start_date"
                            @input="isDateStart = false"
                            :day-format="(use_start_date) => new Date(use_start_date).getDate(use_start_date)"
                            locale="ja">
                            <v-spacer></v-spacer>
                            <v-btn text color="black" @click="clearDateStart">
                              クリア
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                        <v-radio-group v-model="machine.use_start_time" mandatory class="custom-inline" dense>
                          <v-radio v-for="item in formatDate" :key="item.value" :value="item.value" @click="setError">
                            <template #label>
                              <span class="rd-time">{{ item.label }}</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <span class="custom-inline headline mt-5">~</span>
                      </Label>
                    </v-col>
                    <v-col sm="6" md="6" class="mt-5">
                      <Label label="日付（終了）" :editable="true" required>
                        <template>
                          <v-icon color="black">mdi-calendar-today-outline</v-icon>
                        </template>
                        <v-menu offset-y nudge-left="20" nudge-bottom="5" v-model="isDateEnd" min-width="auto"
                          :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider v-slot="{ errors, valid }" name="日付（終了）" :rules="getRuleEndDate()" ref="endDate">
                              <v-text-field class="input-date pl-2 pr-2" v-model="computedDateEndFormatted" filled
                                readonly v-bind="attrs" v-on="on" :error-messages="errors" :success="valid" dense>
                              </v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker no-title scrollable v-model="machine.use_end_date" @input="isDateEnd = false"
                            :day-format="(use_end_date) => new Date(use_end_date).getDate(use_end_date)" locale="ja">
                            <v-spacer></v-spacer>
                            <v-btn text color="black" @click="clearDateEnd">
                              クリア
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                        <v-radio-group v-model="machine.use_end_time" mandatory class="custom-inline" dense>
                          <v-radio v-for="item in formatDate" :key="item.value" :value="item.value" @click="setError">
                            <template #label>
                              <span class="rd-time">{{ item.label }}</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </Label>
                    </v-col>
                  </v-row>
                  <ValidationProvider v-slot="{ errors, valid }" name="使用会社">
<!--                    <Label label="使用会社" :editable="true" required>-->
<!--                      <v-select class="select mr-6" v-model="machine.use_company" :items="listCompany" filled-->
<!--                        item-text="name" @change="getLeader()" item-value="id" :disabled="disableSelected"-->
<!--                        :error-messages="errors" :success="valid" dense>-->
<!--                      </v-select>-->
<!--                    </Label>-->
                    <Label label="使用会社">
                      <InputText
                        name="companyName"
                        :values="dataCompany"
                      />
                    </Label>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors, valid }" name="職長">
<!--                    <Label label="職長" :editable="true" required>-->
<!--                      <v-select v-model="machine.requestor_id" class="select mr-6" filled :items="listLeader"-->
<!--                        :item-text="getFullName" item-value="id" :disabled="disableSelected" :error-messages="errors"-->
<!--                        :success="valid" dense>-->
<!--                      </v-select>-->
<!--                    </Label>-->
                    <Label label="職長">
                      <InputText
                        name="requestor_id"
                        :values="machine"
                      />
                    </Label>
                  </ValidationProvider>
                  <v-breadcrumbs class="floor-wrap">
                    <ValidationProvider v-slot="{ errors, valid }" name="床高（m）" :rules="getRuleFrom()">
                      <Label label="床高（m）" :editable="true">
                        <v-text-field class="input-field" v-model="machine.floor_height_min" filled type="text"
                          :error-messages="errors" :success="valid" dense>
                        </v-text-field>
                      </Label>
                    </ValidationProvider>
                    <div class="custom-line">
                      <span class="headline1">~</span>
                    </div>
                    <ValidationProvider v-slot="{ errors, valid }" name="床高（m）" :rules="getRuleTo()">
                      <Label label="床高（m）" :editable="true">
                        <v-text-field class="input-field" v-model="machine.floor_height_max" filled type="text"
                          :error-messages="errors" :success="valid" dense>
                        </v-text-field>
                      </Label>
                    </ValidationProvider>
                    <div class="ml-12">
                      <ValidationProvider v-slot="{ errors, valid }" name="台数"
                        rules="required|minmax-pulldown:1,100|integerOnly">
                        <Label label="台数" :editable="true" required>
                          <v-text-field class="input-field" v-model="machine.request_number" filled type="text"
                            :error-messages="errors" :success="valid" dense>
                          </v-text-field>
                        </Label>
                      </ValidationProvider>
                    </div>
                  </v-breadcrumbs>
                  <v-row class="radio-bt mt-1">
                    <v-col lg="4" md="4" class="pt-0">
                      昇降方式
                      <v-radio-group v-model="machine.lifting_method" >
                        <div class="pb-2" v-for="item in liftingMethod" :key="item.value">
                          <v-radio :value="item.value" style="margin-bottom: 0">
                            <template v-slot:label>
                              <div class="rd-time">{{ item.name }}</div>
                            </template>
                          </v-radio>
                        </div>
                      </v-radio-group>
                    </v-col>
                    <v-col lg="4" md="4" class="pt-0">
                      足回り
                      <v-radio-group v-model="machine.undercarriage">
                        <div class="pb-2" v-for="item in undercarriages" :key="item.value">
                          <v-radio :value="item.value">
                            <template v-slot:label>
                              <div class="rd-time">{{ item.name }}</div>
                            </template>
                          </v-radio>
                        </div>
                      </v-radio-group>
                    </v-col>
                    <v-col lg="4" md="4" class="pt-0">
                      作業床
                      <v-radio-group v-model="machine.working_floor">
                        <div class="pb-2" v-for="item in workingFloors" :key="item.value">
                          <v-radio :value="item.value">
                            <template v-slot:label>
                              <div class="rd-time">{{ item.name }}</div>
                            </template>
                          </v-radio>
                        </div>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6" md="12" class="mt-lg-5 mt-md-n12">
                  <ValidationProvider v-slot="{ errors, valid }" name="特殊機能" rules="minmax:0,255">
                    <Label label="特殊機能">
                      <v-textarea filled v-model="machine.special_feature" rows="5" :error-messages="errors"
                        :success="valid" class="mr-md-6 mr-lg-0 size-textarea" dense ></v-textarea>
                    </Label>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </v-row>
</template>
<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import { machine } from "@/api/modules/reqAdd";
import { Store } from "@/store/Store";
import { format, parseISO } from "date-fns";
import { FOMAT_DATE } from "@/constants/BOOKING";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Label from "@/components/forms/elements/Label.vue";
import { WRN_0002 } from "@/constants/MESSAGE";
import InputText from "@/components/forms/elements/InputText";

const ROLE_LEADER = 2;

export default {
  components: {
    ConfirmCloseDialog,
    Popup,
    Label,
    ValidationProvider,
    ValidationObserver,
    InputText
  },
  data: () => ({
    machine: {
      field_id: "",
      requestor_id: "",
      use_start_date: "",
      use_start_time: FOMAT_DATE[0].value,
      use_end_date: "",
      use_end_time: FOMAT_DATE[1].value,
      use_company: "",
      floor_height_min: "",
      floor_height_max: "",
      lifting_method: "0",
      undercarriage: "1",
      working_floor: "1",
      special_feature: "",
      request_number: "1",
    },
    info: {},
    company: "",
    dialog: false,
    isError: false,
    isDateStart: false,
    isDateEnd: false,
    isShowCancel: false,
    disableSelected: false,
    listCompany: [],
    listLeader: [],
    liftingMethod: [
      { name: "垂直昇降", value: '0' },
      { name: "直伸ブーム", value: '1' },
      { name: "屈折ブーム", value: '2' },
    ],
    undercarriages: [
      { name: "ホイール", value: '1' },
      { name: "クローラ", value: '2' },
      { name: "どちらでも良い", value: '0' },
    ],
    workingFloors: [
      { name: "普通デッキ", value: '1' },
      { name: "スライドデッキ", value: '2' },
      { name: "どちらでも良い", value: '0' },
      { name: "バケット", value: '3' },
    ],
    formatDate: FOMAT_DATE,
    userId: "",
    companyId: "",
    userRole: "",
    roleLeader: ROLE_LEADER,
    WRN_0002: WRN_0002,
    SUBMIT_DELAY_TIMEOUT,
    isSubmitted: false,
    end_use_field_date: null,
    dataCompany: {
      companyName: "",
    }
  }),
  props: {
    searchParams: {
      type: Object,
    },
    fieldId: {
      type: Number,
    },
    isAddRequest: {
      type: Boolean,
    },
    isBooking: {
      type: Boolean,
      default: true,
    }
  },
  created() {
    this.getUserId();
    this.getUserCompanyId();
    this.getUserRole();
  },
  watch: {
    fieldId() {
      if (this.fieldId) {
        this.getInfo();
        this.getCompany();
      }
    },
    searchParams() {
      this.checkDate();
    },
  },
  computed: {
    //表示用にyyyy/MM/ddに変換
    computedDateStartFormatted() {
      return this.machine.use_start_date ? format(parseISO(this.machine.use_start_date), "yyyy/MM/dd") : "";
    },
    computedDateEndFormatted() {
      return this.machine.use_end_date ? format(parseISO(this.machine.use_end_date), "yyyy/MM/dd") : "";
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name && formValues[name]) {
          //v-datepickerがyyyy-MM-dd形式で処理する
          const _date = new Date(formValues[name]);
          this.val = format(_date, "yyyy-MM-dd");
        } else {
          this.val = "";
        }
      },
      { immediate: true, deep: true }
    );

    this.$watch(
      () => this.dialog,
      () => this.checkDate(),
      { immediate: true, deep: true }
    );

    this.$watch(
      () => this.fieldId,
      (fieldId) => {
        if (fieldId) {
          this.getInfo();
          this.getCompany();
        }
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    getRuleFrom() {
      let rule = "";
      if (this.machine.floor_height_min && this.machine.floor_height_max) {
        rule = `compare-min:${this.machine.floor_height_max},床高（m）（左）,床高（m）（右）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    getRuleTo() {
      let rule = "";
      if (this.machine.floor_height_min && this.machine.floor_height_max) {
        rule = `compare-max:${this.machine.floor_height_min},床高（m）（右）,床高（m）（左）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    async getInfo() {
      let response = await machine.getInfo({ field_id: this.fieldId });
      if (response.data && response.data.status_code === 200) {
        this.info = response.data.contents;
        this.end_use_field_date = this.info.end_use_field_date
        this.checkDate();
      }
    },
    async getCompany() {
      const response = await machine.getCompanyId({ field_id: this.fieldId });
      if (response.data && response.data.status_code === 200) {
        this.listCompany = response.data.contents.entries;
      }
    },
    async getLeader() {
      this.machine.requestor_id = "";
      let param = this.machine.use_company;
      const response = await machine.getListPartnerLeader({ field_id: this.fieldId, company_id: param });
      if (response.data && response.data.status_code === 200) {
        this.listLeader = response.data.contents.entries;
      }
    },
    async addMachine() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.machine.field_id = this.fieldId;
      let response = await machine.postAddMachine(this.machine);
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.addComplete();
        this.dialog = false;
      }
      if (!this.isBooking) {
        this.$emit("reload-machine");
      }
    },
    add: function () {
      this.addMachine()
    },
    cancel: function () {
      this.isShowCancel = false;
      this.dialog = false;
      this.disableSelected = false;
      this.defaultMachine();
    },
    defaultMachine: function () {
      this.machine = {
        field_id: "",
        requestor_id: "",
        use_start_date: "",
        use_start_time: FOMAT_DATE[0].value,
        use_end_date: "",
        use_end_time: FOMAT_DATE[1].value,
        use_company: "",
        floor_height_min: "",
        floor_height_max: "",
        lifting_method: "0",
        undercarriage: "1",
        working_floor: "1",
        special_feature: "",
        request_number: "1",
      }
      this.listLeader = [];
    },
    addComplete: function () {
      this.defaultMachine();
      this.getInfo();
    },
    momentFormat: function (date) {
      return moment(new Date(date)).format('YYYY-MM-DD');
    },
    getFullName: function (item) {
      return `${item.name_sei}  ${item.name_mei}`
    },
    checkDate: function () {
      let date = new Date();
      this.searchParams.designated_date = this.momentFormat(this.searchParams.designated_date);
      if (moment(this.searchParams.designated_date).valueOf() > moment(this.momentFormat(date)).valueOf()) {
        this.machine.use_start_date = this.momentFormat(this.searchParams.designated_date);
      } else {
        this.machine.use_start_date = this.momentFormat(date.setDate(date.getDate() + 1));
      }
      this.machine.use_end_date = this.momentFormat(this.end_use_field_date);
      if (this.machine.use_start_date && this.end_use_field_date) {
        let startDate = new Date(this.machine.use_start_date);
        let endDate = new Date(this.end_use_field_date);
        let periodTime = endDate.getTime() - startDate.getTime();
        let numberOfDays = periodTime / (1000 * 3600 * 24);
        if (numberOfDays > this.info.max_bookable_days) {
          this.machine.use_end_date = new Date(this.machine.use_start_date);
          this.machine.use_end_date.setDate(this.machine.use_end_date.getDate() + this.info.max_bookable_days - 1);
          this.machine.use_end_date = this.momentFormat(this.machine.use_end_date);
        }
      }
    },
    getUserId() {
      this.userId = JSON.parse(sessionStorage.getItem("USER")).Login.user.id;
    },
    getUserRole() {
      this.userRole = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    },
    getUserCompanyId() {
      this.companyId = JSON.parse(sessionStorage.getItem("USER")).Login.user.company_id;
    },
    getCompanyName() {
      this.dataCompany.companyName = JSON.parse(sessionStorage.getItem("USER")).Login.user.company.name
      this.machine.use_company = JSON.parse(sessionStorage.getItem("USER")).Login.user.company.id
      this.machine.requestor_id = "";
    },
    validateCompany() {
      this.getUserId();
      this.getUserRole();
      this.getUserCompanyId();
      this.getCompany();
      this.getCompanyName();
      this.$refs.observer.reset();
      if (this.userRole === this.roleLeader) {
        this.machine.use_company = this.companyId;
        this.getLeader();
        this.machine.requestor_id = this.userId;
        this.disableSelected = true;
      }
      this.checkDate();
    },
    getRuleStartDate() {
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let rule = "";
      if (this.machine?.use_end_date) {
        const dateEnd = format(parseISO(this.machine?.use_end_date), "yyyy/MM/dd");
        rule = `err-start-date-am-pm:${dateEnd}, ${this.machine?.use_start_time}, ${this.machine?.use_end_time}, 日付（開始）,日付（終了）`;
      }
      return (
        "required|" + `err-005:${now},日付（開始）` + "|" + rule
      );
    },
    getRuleEndDate() {
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let rule = "";
      if (this.machine?.use_start_date) {
        const dateEnd = format(parseISO(this.machine?.use_start_date), "yyyy/MM/dd");
        rule = `err-end-date-am-pm:${dateEnd}, ${this.machine?.use_start_time}, ${this.machine?.use_end_time}, 日付（終了）,日付（開始）`;
      }
      return (
        "required|" + `err-005:${now},日付（終了）` + "|" + rule
      );
    },
    clearDateStart() {
      this.isDateStart = false;
      this.machine.use_start_date = "";
    },
    clearDateEnd() {
      this.isDateEnd = false;
      this.machine.use_end_date = "";
    },
    setError() {
      if (moment(this.machine.use_start_date).valueOf() === moment(this.machine.use_end_date).valueOf() && this.machine.use_start_time > this.machine.use_end_time) {
        this.$nextTick(() => {
          this.$refs.startDate.setErrors(['日付（開始）は日付（終了）より前の日付を指定してください']);
          this.$refs.endDate.setErrors(['日付（終了）は日付（開始）以降の日付を指定してください']);
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  font-family: 'Roboto';
  font-style: normal;
  padding: 10px;

  .color-label {
    color: rgba(176, 0, 32, 1);
  }

  .labelWrapper {
    position: relative;
  }

  .header {
    background-color: white;

    .title {
      font-size: 20px !important;
      line-height: 24px;
      padding-left: 10px;
      align-items: center;
      color: #274FC2;
      font-weight: 600;
    }

    .v-card__actions {
      padding: 0px 8px 0px 0px !important;
    }
    
    .dialog-button-default {
      height: 28px !important;
      min-width: 50px !important;
      padding: 0 12.4444444444px !important;
      font-size: .75rem !important;
    }

    .tab-title {
      height: 16px;
      top: calc(50% - 16px/2);
      font-weight: 500;
      padding-left: 20px;
      font-size: 14px !important;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #274FC2;
    }
  }

  .input-date {
    border-radius: 2px;
    width: 166px;
    min-height: 52px;
    height: auto;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
    display: inline-block;
  }

  .custom-inline {
    display: inline-block;
    position: absolute;
    top: 0;
    margin-top: 32px !important;
  }

  v-radio {
    color: #000000;
    font-weight: bold !important;
  }

  .headline {
    width: 20px;
    font-size: 30px !important;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-left: 80px;
    margin-top: 50px !important
  }

  .custom-line {
    margin-left: 10px;
    margin-right: 10px;
    top: 0;
    margin-top: 45px !important;
  }

  .headline1 {
    width: 50px;
    font-size: 30px !important;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #000000;
  }

  .input-field {
    border-radius: 2px;
    width: 165px;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    flex-grow: 0;
  }

  @media screen and (max-width: 1024px) {
    .input-field {
      width: auto;
    }
  }

  .floor-wrap {
    align-items: flex-start;
    padding-bottom: 10px;
    margin-left: -20px;
    padding-top: 0;

    .headline {
      margin-top: 16px;
    }

    .input-field {
      min-height: 54px;
      height: auto;
    }
  }

  .rd-time {
    font-weight: 400;
    line-height: 24px;
    color: #000000;
  }

  .radio-bt {
    font-size: 12px !important;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #000000;
  }

  .size-textarea {
    font-size: $font-size-root;
  }
}


.dialog.custom-color ::v-deep {
  padding: 0px !important;
  .label {
    color: #000;
  }

  .v-messages__message {
    line-height: 18px;
  }
}

.custom-opacity.theme--light.v-btn:focus:before {
  background-color: transparent !important;
}
.custom-radio ::v-deep .v-input--radio-group--column .v-radio{
  margin-bottom: 0px !important;
}
</style>
