import Vue from "vue";
import Vuex from "vuex";
import { tenken } from "@/api/modules/tenken";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = tenken;
export const Tenken = {
  namespaced: true,

  state: {
    checkBeforeWorkings: [],
    monthlyChecks: [],
    //MMSV2-1573-start
    checkRepairRequests: [],
    //MMSV2-1573-end
    isLoading: {},
  },
  mutations: {
    SET_DATA_CHECK_BEFORE(state, payload) {
      state.checkBeforeWorkings = payload;
    },
    SET_DATA_CHECK_MONTH(state, payload) {
      state.monthlyChecks = payload;
    },
    //MMSV2-1573-start
    SET_DATA_CHECK_REPAIR(state, payload) {
      state.checkRepairRequests = payload;
    },
    //MMSV2-1573-end
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async getDataTenken({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListInspection(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const check_before_workings =
          response.data.contents.entries.pre_opeation_check.data;
        const monthly_Checks =
          response.data.contents.entries.monthly_check.data;
        //MMSV2-1573-start
        const check_repair_requests =
          response.data.contents.entries.check_repair_requests.data;
        //MMSV2-1573-end
        commit("SET_DATA_CHECK_BEFORE", check_before_workings);
        commit("SET_DATA_CHECK_MONTH", monthly_Checks);
        //MMSV2-1573-start
        commit("SET_DATA_CHECK_REPAIR", check_repair_requests);
        //MMSV2-1573-end
      }
      return response;
    },
    async postDeleteTenken(_context, payload) {
      const response = await ENTITY.postDeleteInspection({ ids: payload });
      if (response.hasError) {
        return response;
      }
      return response;
    },
    async postUpdateTenken(_context, payload) {
      const response = await ENTITY.postUpdateHandle(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },
    async postSenDataTenken(_context, payload) {
      const response = await ENTITY.postSenData(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },
    async postUpdateTenkenReport(_context, payload) {
      const response = await ENTITY.postUpdateHandle(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },
    async postSenDataTenkenReport(_context, payload) {
      const response = await ENTITY.postSenData(payload);
      if (response.hasError) {
        return response;
      }
      return response;
    },
  },
  getters: {
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
