<template>
  <!-- テーブルヘッダー -->
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title primary--text" v-if="isTitle"> 
        {{ pageTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isSearch" class="mx-2" small icon @click="openSearch">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        v-if="isDelete"
        class="mx-2"
        small
        icon
        @click="$emit('openRemoveDialog')"
        :disabled="multiRemoveStatus"
      >
        <v-icon > mdi-delete-outline </v-icon>
      </v-btn>
      <v-btn
        v-if="isFormInfo"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openFormInfoDialog')"
      >
        一括登録
      </v-btn>
      <v-btn
        v-if="isInvite"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openInviteDialog')"
        :disabled="multiRemoveStatus"
      >
        招待
      </v-btn>
      <v-btn
        v-if="isClassify"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openClassifyDialog')"
      >
        分類
      </v-btn>
      <v-btn
        v-if="isOpenFormApprove"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openFormApprove')"
        :disabled="approveStatus"
      >
        承認
      </v-btn>
      <v-btn
        v-if="isAddNew"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openItemForm')"
        data-testid="add-new-btn"
      >
        新規作成
      </v-btn>
      <v-btn
        v-if="isAddForm"
        depressed
        :disabled="disableBtnForm"
        class="mx-2"
        color="primary"
        @click="$emit('openItemForm')"
        data-testid="add-new-btn"
      >
        帳票作成
      </v-btn>
      <v-btn
        v-if="isOpenFormRelate"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openItemForm')"
      >
        新規登録
      </v-btn>
      <v-btn
        v-if="isOpenFormContractors"
        depressed
        class="mx-2"
        color="primary"
        @click="$emit('openItemForm')"
      >
        元請として現場を作成する
      </v-btn>
    </v-toolbar>
    <div v-show="isShowSearch">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      isShowSearch: false,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    multiRemoveStatus: {
      type: Boolean,
      default: false,
    },
    approveStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    // タイトルを表示
    isTitle: {
      type: Boolean,
      default: true,
    },
    // 検索ボタンを表示
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 削除ボタンを表示
    isDelete: {
      type: Boolean,
      default: true,
    },
    // 新規追加ボタンを表示
    isAddNew: {
      type: Boolean,
      default: true,
    },
    isOpenFormRelate: {
      type: Boolean,
      default: false,
    },
    isOpenFormContractors: {
      type: Boolean,
      default: false,
    },
    // 招待ボタンを表示
    isInvite: {
      type: Boolean,
      default: false,
    },
    // 帳票作成ボタンを表示
    isAddForm: {
      type: Boolean,
      default: false
    },
    disableBtnForm: {
      type: Boolean,
      default: false
    },
    isFormInfo: {
      type: Boolean,
      default: false,
    },
    isClassify: {
      type: Boolean,
      default: false,
    },
    isOpenFormApprove: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
</style>
