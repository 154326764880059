import Vue from "vue";
import Vuex from "vuex";
import { register } from "@/api/modules/register";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = register;

export const Register = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    listCompany: {},
    companyInfo: {},
    isLoading: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_LiST_COMPANY(state, payload) {
      state.listCompany = payload;
    },
    SET_COMPANY_INFO(state, payload) {
      state.companyInfo = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getCompany({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getCompany(payload);
      const entries = response.data;
      commit("SET_LiST_COMPANY", entries);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getCompanyInfoByCorporateNumber({ commit }, payload) {
      const response = await ENTITY.getCompanyInfoByCorporateNumber(payload);
      const entries = response.data.contents;
      commit("SET_COMPANY_INFO", entries);
      return response;
    },

    async edit({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.edit(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async add({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.add(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async delete({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.delete(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getListCompany: (state) => {
      return state.listCompany;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
