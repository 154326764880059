<template>
  <div>
    <DefaultLayout class="field-user-custom-class-css">
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany"/>
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="selectedTab ? hideFooterNotAppRove : hideFooterAppRove"
            ref="myTableLayoutTab"
          >
            <template #tableHeader="{ updateHeader }" class="pa-0">
              <FieldUserHeader
                :key="keyHeader"
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isAddNew="false"
                :isDelete="false"
              >
                <SearchFormWrapper class="p-0">
                  <Label v-if="roleLogin === COMPANY_SCENE" label="会社名" class="title-input-item">
                    <div style="min-width: 80px">
                      <InputText
                        name="company_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </div>
                  </Label>
                  <Label label="氏名" class="title-input-item">
                    <div style="min-width: 80px">
                      <InputText
                        name="full_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </div>
                  </Label>
                  <!-- select id leader -->
                  <Label
                    v-if="roleLogin === COMPANY_RENTAL && selectedTab === TAB_LIST_APPROVED_USER" label="所属班"
                    class="title-input-item"
                  >
                    <div style="min-width:80px" class="mr-2">
                      <Select
                        :items="leaderTeam"
                        :editable="true"
                        name="field_user_id_leader"
                        :values="searchParams"
                        :single_line="false"
                        @onInput="onChangeSearchParams"
                      ></Select>
                    </div>
                  </Label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="email"
                  >
                    <Label label="メールアドレス" class="title-input-item" style="max-width: 210px; min-width: 135px">
                      <InputText
                        name="email"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="phone"
                  >
                    <Label label="携帯番号" class="title-input-item" style="max-width: 210px; min-width: 135px">
                      <InputText
                        name="phone"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                  </ValidationProvider>

                  <Label v-if="selectedTab === TAB_LIST_APPROVED_USER" label="種別"
                         class="title-input-item role-method">
                    <TabSelect
                      v-if="roleLogin === COMPANY_SCENE"
                      name="role"
                      :items="ROLE_METHOD"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                    <TabSelect
                      v-if="roleLogin === COMPANY_RENTAL"
                      name="role"
                      :items="ROLE_RENTA_METHOD"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label
                    v-if="selectedTab === TAB_LIST_NOT_APPROVE_USER && roleLogin === COMPANY_SCENE" label="種別"
                    class="title-input-item role-method"
                  >
                    <TabSelect
                      name="role"
                      :items="ROLE_METHOD_NOT_APPROVE"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label v-if="selectedTab === TAB_LIST_APPROVED_USER" label="" class="title-input-item">
                    <div style="min-width: 200px">
                      <v-switch
                        class="mt-3"
                        v-model="searchParams.soft_delete"
                        :label="`解除したユーザーも表示`"
                      ></v-switch>
                    </div>
                  </Label>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </FieldUserHeader>
              <TableSortWrapper>
                <!--
                  (共通) ソート
                  ソート項目、ソート順、表示件数の選択、総件数の表示
                -->
                <TableSort
                  :key="keyHeader"
                  v-show="selectedTab"
                  :values="searchParams"
                  :sort_items="roleLogin === COMPANY_SCENE ? SORT_ITEMS_NOT_APPROVE : SORT_ITEMS_NOT_APPROVE_FOR_RENTAL"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_itemNotApprove"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
                <TableSort
                  :key="keyHeader + 1"
                  v-show="!selectedTab"
                  :values="searchParams"
                  :sort_items="roleLogin === COMPANY_SCENE ? SORT_ITEMS : SORT_ITEMS_FOR_RENTAL"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_itemApprove"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>

              <v-row class="cst-row">
                <v-col cols="12" class="cst-col ml-4">
                  <!-- タブ -->
                  <v-tabs grow v-model="selectedTab">
                    <v-tab
                      v-for="item in TAB_SELECT" :key="item.id" @click="getTabData()"
                      class="tab-select-filed-user-screen" :class="item.id === 2 ? 'pl-5 pr-5' : 'pr-5 pl-5'"
                    >
                      {{ item.name }}
                      <template v-if="countNotApproveItems">
                        <v-icon v-if="item.id === 2" class="alert-circle">
                          mdi-alert-circle
                        </v-icon>
                      </template>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
            </template>
            <template #tableBody="{ tableHeight }">
              <template v-if="TAB_SELECT[selectedTab].id === FIELD_USER_TAB_SELECT.FIELD_USER_INFO_TAB.id">
                <div class="d-flex mt-5 ml-4">
                  <v-btn
                    v-if="roleLogin === COMPANY_SCENE"
                    class="mr-6 mb-3"
                    depressed
                    color="primary"
                    @click=openUploadDialog
                  >
                    一括登録
                  </v-btn>
                  <template v-if="roleLogin === COMPANY_SCENE">
                    <v-btn
                      class="mr-6"
                      depressed
                      color="primary"
                      @click=openPartnerCompanyForm
                    >
                      協力会社追加
                    </v-btn>
                  </template>
                  <template v-if="roleLogin === COMPANY_RENTAL">
                    <ModalEditTeam
                      :fieldId="fieldId"
                      @updateTeamSuccess="updateTeamSuccess()"
                    />
                  </template>
                  <v-btn
                    v-if=""
                    class="mb-3"
                    depressed
                    :disabled="isShowButtonDelete"
                    color="warning"
                    @click=openRemoveDialog
                  >
                    連携解除
                  </v-btn>

                </div>
                <v-data-table
                  item-key="id"
                  v-model="selectedItems"
                  :headers=" roleLogin === COMPANY_SCENE ? USER_TABLE_LABELS: USER_TABLE_LABELS_PARTNER"
                  :items="items"
                  :items-per-page="searchParams.pageCount"
                  :height="hideFooterAppRove ? tableHeight - 55 : tableHeight - 100"
                  fixed-header
                  hide-default-footer
                  disable-sort
                  class="elevation-0 v-data-table__wrapper"
                  sort-by="updatedAt"
                  show-select
                  :noDataText="NO_DATA_MESSAGE"
                  :item-class="itemRowBackground"
                  style="box-shadow: none !important;"
                  @update:searchParams.currentPage="$vuetify.goTo(0)"
                  :key=searchParams.currentPage
                >
                  <template v-slot:[`item.name_sei`]="{ item }">
                    {{ convertFullName(item) }}
                  </template>
                  <template v-slot:[`item.role`]="{ item }">
                    {{ ROLE_NAME[item.role] }}
                    <span
                      v-if="roleLogin !== COMPANY_SCENE && (item.leader_name_sei || item.leader_name_mei) && item.role === ROLE_SUPERVISOR">
                    ({{ convertFullName(item, true) }})
                  </span>
                  </template>
                  <template v-slot:[`item.deletedAt`]="{ item }">
                  <span v-if="item.deletedAt">
                    {{ convertFieldEnDate(item.deletedAt) }}
                  </span>
                  </template>
                  <template v-slot:[`item.email`]="{ item }">
                    <div
                      style="
                      display: inline-block;
                      word-wrap: break-word;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      "
                    >
                      {{ item.email }}
                    </div>
                  </template>
                  <template v-slot:[`item.flg_forced_move`]="{ item }">
                    {{ !checkFieldUser(item) ? FORCED_MOVE_VIEW[item.flg_forced_move] : '' }}
                  </template>
                  <template v-slot:[`item.select_forced_move`]="{ item }">
                    <v-btn
                      v-if="!checkFieldUser(item)"
                      depressed
                      :id="'menu-activator'+item.id"
                      style="background: none; min-width: 36px; padding: 0"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    <v-menu :activator="'#menu-activator'+item.id">
                      <v-list>
                        <v-list-item
                          v-for="(value, index) in FORCED_MOVE"
                          :key="index+item.id"
                          :value="value"
                          @click="setStateForcedMove(item, index)"
                        >
                          <v-list-item-title>{{ value }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:[`item.flg_receipt_allocation`]="{ item }">
                    {{ !checkFieldUser(item) ? RECEIPT_ALLOCATION_VIEW[item.flg_receipt_allocation] : '' }}
                  </template>
                  <template v-slot:[`item.select_receipt_allocation`]="{ item }">
                    <v-btn
                        v-if="!checkFieldUser(item)"
                        depressed
                        :id="'menu-activator-ReceiptAllocation'+item.id"
                        style="background: none; min-width: 36px; padding: 0"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    <v-menu :activator="'#menu-activator-ReceiptAllocation'+item.id">
                      <v-list>
                        <v-list-item
                            v-for="(value, index) in RECEIPT_ALLOCATION"
                            :key="index+item.id"
                            :value="value"
                            @click="setStateReceiptAllocation(item, index)"
                        >
                          <v-list-item-title>{{ value }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </template>
              <template v-else-if="TAB_SELECT[selectedTab].id === FIELD_USER_TAB_SELECT.FIELD_USER_NOT_APPROVE_TAB.id">
                <div class="d-flex mt-5 ml-4">
                  <v-btn
                    class="mr-6 mb-3"
                    :disabled=" !approvalManual ? true : approveCheck"
                    depressed
                    color="primary"
                    @click=openConfirmDialog
                  >
                    承認
                  </v-btn>
                  <v-btn
                    :disabled=approveCheck
                    class="mx-2"
                    small
                    icon
                    @click="openRemoveNotApproveDialog"
                  >
                    <v-icon class="mt-1"> mdi-delete-outline </v-icon>
                  </v-btn>
                </div>
                <v-data-table
                  item-key="id"
                  v-model="selectedNotApproveItems"
                  :headers="USER_TABLE_NOT_APPROVE_LABELS"
                  :items="notApproveItems"
                  :items-per-page="searchParams.pageCount"
                  :height="hideFooterNotAppRove ? tableHeight - 55 : tableHeight - 100"
                  fixed-header
                  hide-default-footer
                  disable-sort
                  class="elevation-0 v-data-table__wrapper"
                  sort-by="updatedAt"
                  show-select
                  :noDataText="NO_DATA_MESSAGE"
                  :item-class="itemRowBackground"
                  @update:searchParams.currentPage="$vuetify.goTo(0)"
                  :key=searchParams.currentPage
                >
                  <template v-slot:item.data-table-select="{ item, isSelected, select}">
                    <v-simple-checkbox 
                      v-if="item.can_approve === 1" 
                      :value="isSelected"
                      @input="select($event)">
                    </v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.name_sei`]="{ item }">
                    {{ convertFullName(item) }}
                  </template>
                  <template v-slot:[`item.email`]="{ item }">
                    <div
                      style="
                      display: inline-block;
                      word-wrap: break-word;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      "
                    >
                      {{ item.email }}
                    </div>
                  </template>
                  <template v-slot:[`item.role`]="{ item }">
                    {{ ROLE_NAME[item.role] }}
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                  <span v-if="item.createdAt">
                    {{ convertFieldEnDate(item.createdAt) }}
                  </span>
                  </template>
                </v-data-table>
              </template>

            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <template v-if="TAB_SELECT[selectedTab].id === FIELD_USER_TAB_SELECT.FIELD_USER_INFO_TAB.id">
                <Pagination
                  :current="searchParams.currentPageApprove"
                  :total="searchParams.totalPageApprove"
                  @pageUpdate="pageUpdate"
                  style="background: white; position: fixed; width: 100%; bottom: 0"/>
              </template>
              <template v-else-if="TAB_SELECT[selectedTab].id === FIELD_USER_TAB_SELECT.FIELD_USER_NOT_APPROVE_TAB.id">
                <Pagination
                  :current="searchParams.currentPageNotApprove"
                  :total="searchParams.totalPageNotApprove"
                  @pageUpdate="pageUpdateNotApprove"
                  style="background: white; position: fixed; width: 100%; bottom: 0;"/>
              </template>
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="popups.isShowErorDialog">
      <ErrorDialog
        @yes="closeErrorDialog"
        @close="closeErrorDialog"
        :text="textDialogError"
        title="エラー"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowUploadForm">
      <UploadFieldUserInfoDialog
        @close="closeUploadDialog"
        @yes="openConfirmUploadDialog"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="解除確認"
        text="選択したユーザーの現場連携を解除します。"
        text2="本当に解除してもよろしいですか？"
        text3="この処理を元に戻すことはできません。"
        :items="selectedItems"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <Confirm
        title="現場の利用申請を承認"
        text1="現場の利用申請を承認します。"
        text2="承認されたユーザーは現場にログインすることができます。"
        @close="onClickBtnCancelShowConfirm"
        @yes="saveApproveData"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveNotApproveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveNotApproveDialog()"
        @yes="deleteNotApproveItems()"
        title="選択項目を削除"
        text="選択したデータを削除します。"
        text2="本当に削除してもよろしいですか？"
        text3="この処理を元に戻すことはできません。"
        :items="selectedItems"
        warning
      />
    </Popup>

    <Popup :dialog="popups.isShowPartnerCompanyForm">
      <PartnerCompanyForm
        :fieldId=this.fieldId
        @getItems="getItems"
        @closePartnerCompanyForm="closePartnerCompanyForm"
      />
    </Popup>

    <!--confirm upload file-->
    <Popup width="480px" :dialog="popups.isShowConfirmUploadDialog">
      <ConfirmBulkUploadDialog
        @close="closeConfirmUploadDialog"
        @yes="submitUploadInfo"
        title="ユーザーを一括で招待"
        :content="[
            '招待メールが送信されるまで時間がかかる場合がありますので、しばらくお待ちください。',
        ]"
        primary
        nameBtnSave="招待"
      />

    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import {Store} from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import TabSelect from "@/components/forms/fieldUsers/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./popup/ConfirmRemoveDialog";
import Confirm from "./popup/Confirm.vue";
import FieldUserHeader from "./components/FieldUserHeader";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import PartnerCompanyForm from "@/components/forms/fieldUsers/PartnerCompanyForm"
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import {
  COMPANY_RENTAL,
  COMPANY_SCENE,
  ROLE_SUPERVISOR,
  NO_DATA_MESSAGE,
  TABLE_SORT_ORDERS,
  TABLES_PER_PAGE
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  FIELD_SORT_ITEMS,
  FIELD_SORT_ITEMS_FOR_RENTAL,
  FIELD_SORT_ITEMS_NOT_APPROVE,
  FIELD_SORT_ITEMS_NOT_APPROVE_FOR_RENTAL,
  FIELD_USER_TAB_SELECT,
  FIRST_LOAD,
  LEADER_ROLE,
  ROLE_NAME,
  FORCED_MOVE,
  FORCED_MOVE_VIEW,
  ROLE_TYPE,
  ROLE_TYPE_NOT_APPROVE,
  ROLE_TYPE_RENTA,
  USER_TABLE_LABELS,
  USER_TABLE_LABELS_PARTNER,
  USER_TABLE_NOT_APPROVE_LABELS,
  RECEIPT_ALLOCATION_VIEW,
  RECEIPT_ALLOCATION
} from "@/constants/FIELD_USERS"
import {
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_FIELD_PORTAL,
} from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー
import ModalEditTeam from "./components/modalEditTeam";
import UploadFieldUserInfoDialog from "./components/UploadFieldUserInfoDialog.vue";
import {convertName, dateFormat} from "@/common/helper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import Loading from "@/components/loading/Loading";
import ConfirmBulkUploadDialog from "@/views/fieldUser/popup/ConfirmBulkUploadDialog";
import ErrorDialog from "../../components/dialog/ErrorDialog";
import {ValidationProvider, ValidationObserver} from "vee-validate";
//タイトル
let PAGE_TITLE = "利用者一覧";

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;
const SORT_ITEMS = FIELD_SORT_ITEMS;
const SORT_ITEMS_FOR_RENTAL = FIELD_SORT_ITEMS_FOR_RENTAL;
const SORT_ITEMS_NOT_APPROVE = FIELD_SORT_ITEMS_NOT_APPROVE;
const SORT_ITEMS_NOT_APPROVE_FOR_RENTAL = FIELD_SORT_ITEMS_NOT_APPROVE_FOR_RENTAL;
//１ページあたりのテーブル件数
const PAGE_COUNT = 25;
const TAB_LIST_APPROVED_USER = 0;
const TAB_LIST_NOT_APPROVE_USER = 1;
const TAB_NOT_APPROVE_USER = 2

const STORE = "FieldUsers";
export default {

  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      ROLE_TYPE,
      NO_DATA_MESSAGE,
      FIELD_USER_TAB_SELECT,
      USER_TABLE_LABELS,
      USER_TABLE_NOT_APPROVE_LABELS,
      USER_TABLE_LABELS_PARTNER,
      USER_LABELS: {},
      SORT_ITEMS,
      SORT_ITEMS_FOR_RENTAL,
      SORT_ITEMS_NOT_APPROVE,
      SORT_ITEMS_NOT_APPROVE_FOR_RENTAL,
      ROLE_NAME,
      FORCED_MOVE,
      FORCED_MOVE_VIEW,
      LEADER_ROLE,
      FIRST_LOAD,
      RECEIPT_ALLOCATION_VIEW,
      RECEIPT_ALLOCATION,
      /**
       * (共通)
       * 一覧データ
       */
      idFieldLogin: 0,
      userLogin: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.id,
      fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id,
      companyId: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.company_id,
      totalFieldUserInfo: 0,
      totalFieldUserNotApprove: 0,
      isShowBulkAddUsers: false,
      items: [],
      notApproveItems: [],
      selectedNotApproveItems: [],
      selectedItems: [],
      changeSortBy: 0,
      searchParams: {
        field_id: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role == COMPANY_SCENE ? "sort_company" : "sort_full_name",
        asc: true,
        total_item: 1,
        company_name: "",
        full_name: "",
        email: "",
        phone: "",
        role: [],
        soft_delete: false,
        field_user_id_leader: "",
      },
      firstLoad: 0,
      firstLoadNotApprove: 0,
      fixDataApprove: false,
      selectedTab: 0,
      popups: {
        // 削除確認ダイアログ表示
        isShowUploadForm: false,
        isShowRemoveDialog: false,
        isShowConfirmDialog: false,
        isShowRemoveNotApproveDialog: false,
        isShowPartnerCompanyForm: false,
        isShowConfirmUploadDialog: false,
        isShowErorDialog: false
      },
      isShowButtonDelete: true,
      approveCheck: true,
      roleLogin: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role,
      flagUserRentalCompany: true,
      leaderTeam: [],
      leaderTeamIds: [],
      allUsers: [],
      COMPANY_SCENE,
      COMPANY_RENTAL,
      ROLE_SUPERVISOR,
      TAB_LIST_APPROVED_USER,
      TAB_LIST_NOT_APPROVE_USER,
      TAB_NOT_APPROVE_USER,
      isLoading: false,
      hideFooterAppRove: true,
      hideFooterNotAppRove: true,
      textDialogError: "",
      fileUpload: [],
      countNotApproveItems: 0,
      keyHeader: 0,
      approvalManual: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).approval_manual === 0 ? false : true,
    }
  },
  components: {
    CompanyHeader,
    FieldUserHeader,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TabSelect,
    Popup,
    UploadFieldUserInfoDialog,
    ConfirmRemoveDialog,
    Confirm,
    TableSortWrapper,
    TableSort,
    InputText,
    Label,
    ModalEditTeam,
    PartnerCompanyForm,
    convertName,
    dateFormat,
    Select,
    SitePortalHeader,
    Loading,
    ConfirmBulkUploadDialog,
    ErrorDialog,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.USER_LIST.id,
    });

    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    }

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    await this.getCountNotApprove();
    this.$watch(
      () => [
        Store.getters[`${STORE}/getCountFieldUserNotApprove`],
      ],
      (data) => {
        this.countNotApproveItems = data[0];
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.getUserLabel();
    this.getItems();
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
        Store.getters[`${STORE}/leaderIds`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((item) => ({
          ...item
        }));
        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        searchParams.totalPageApprove = data[1].total;
        searchParams.currentPageApprove = data[1].current;
        searchParams.total_itemApprove = data[1].total_item;
        this.searchParams = searchParams;
        if (this.firstLoad === FIRST_LOAD) {
          this.totalFieldUserInfo = searchParams.total_item;
          this.firstLoad = 1;
        }
        this.hideFooterAppRove = searchParams.total_itemApprove <= searchParams.pageCount;
        const idFieldLogin = _items.filter((item) => item.user_id === this.userLogin);
        if (idFieldLogin.length) {
          this.idFieldLogin = idFieldLogin[0].id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        Store.getters[`${STORE}/getDataNotApprove`],
        Store.getters[`${STORE}/getPaginationNotApprove`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.notApproveItems = _items.map((item) => ({
          ...item, isSelectable: item.can_approve === 1
        }));
        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        searchParams.totalPageNotApprove = data[1].total;
        searchParams.currentPageNotApprove = data[1].current;
        searchParams.total_itemNotApprove = data[1].total_item;
        this.searchParams = searchParams;
        if (this.firstLoadNotApprove === FIRST_LOAD) {
          this.totalFieldUserNotApprove = searchParams.total_itemNotApprove;
        }
        this.hideFooterNotAppRove = searchParams.total_itemNotApprove <= searchParams.pageCount;
      },
      {
        immediate: true,
        deep: true,
      },
    );

    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    if (this.roleLogin === this.COMPANY_RENTAL) {
      this.getListPartnerLeaderInField();
      this.$watch(
        () => Store.getters[`${STORE}/getLeaderIds`],
        (data) => {
          this.leaderTeam = data;
          this.leaderTeam = this.leaderTeam.map((item) => {
            return {
              id: item.id,
              name: this.convertFullName(item),
            };
          });
          this.leaderTeam.unshift({
            id: "",
            name: ""
          });
        },
        {
          immediate: true,
          deep: true,
        }
      );
    }
  },
  watch: {
    selectedNotApproveItems: function (newValue) {
      if (newValue.length) {
        this.approveCheck = false;
      } else {
        this.approveCheck = true;
      }
    },
    selectedItems() {
      const selectedItems = this.selectedItems.filter((item) => item.deletedAt)
      if (selectedItems.length === this.selectedItems.length) {
        this.isShowButtonDelete = true;
      } else {
        this.isShowButtonDelete = !this.selectedItems.length;
      }
    },
    selectedTab() {
      this.keyHeader++
      this.searchParams.pageCount = PAGE_COUNT;
      this.searchParams.currentPage = 1;
      this.searchParams.totalPage = 1;
      this.searchParams.asc = true;
      this.searchParams.total_item = 1;
      this.searchParams.company_name = "";
      this.searchParams.full_name = "";
      this.searchParams.email = "";
      this.searchParams.phone = "";
      this.searchParams.role = [];
      this.searchParams.soft_delete = false;
      this.searchParams.field_user_id_leader = "";
      this.searchParams.sort = "sort_company";
      if (this.roleLogin === COMPANY_RENTAL) {
        this.searchParams.sort = "sort_full_name";
      }
      if (this.selectedTab === TAB_LIST_APPROVED_USER) {
        this.PAGE_TITLE = "利用者一覧"
        this.getItems();
      } else {
        this.PAGE_TITLE = "利用申請"
        this.getNotApproveItems();
      }
      document.title = "AirLiza" + " | " + this.PAGE_TITLE
    }
  },
  computed: {
    TAB_SELECT() {
      return Object.keys(FIELD_USER_TAB_SELECT).map((key) => {
        return FIELD_USER_TAB_SELECT[key];
      });
    },
    apiParams() {
      return {
        field_id: this.searchParams.field_id,
        page_count: this.searchParams.pageCount,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        company_name: this.searchParams.company_name,
        full_name: this.searchParams.full_name,
        email: this.searchParams.email,
        phone: this.searchParams.phone,
        role: this.searchParams.role.join(','),
        soft_delete: this.searchParams.soft_delete ? 1 : 0,
        field_user_id_leader: this.searchParams.field_user_id_leader ? this.searchParams.field_user_id_leader : "",
      };
    },
    apiParamsCountNotApprove() {
      return {
        field_id: this.searchParams.field_id,
      };
    },
    apiGetLeadersParams() {
      return {
        field_id: this.fieldId,
        company_id: this.companyId,
      };
    },
    ROLE_METHOD() {
      return Object.keys(ROLE_TYPE).map((key) => {
        return ROLE_TYPE[key];
      });
    },
    ROLE_RENTA_METHOD() {
      return Object.keys(ROLE_TYPE_RENTA).map((key) => {
        return ROLE_TYPE_RENTA[key];
      });
    },
    ROLE_METHOD_NOT_APPROVE() {
      return Object.keys(ROLE_TYPE_NOT_APPROVE).map((key) => {
        return ROLE_TYPE_NOT_APPROVE[key];
      });
    },
  },

  methods: {
    convertFieldEnDate(item) {
      return item.replaceAll("-", "/").slice(0, 16);
    },
    openUploadDialog() {
      this.popups.isShowUploadForm = true;
    },
    closeUploadDialog() {
      this.textDialogError = "";
      this.fileUpload = [];
      this.popups.isShowUploadForm = false;
    },
    openPartnerCompanyForm() {
      this.popups.isShowPartnerCompanyForm = true;
    },
    closePartnerCompanyForm() {
      this.popups.isShowPartnerCompanyForm = false;
    },
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
    },
    openConfirmDialog() {
      this.popups.isShowConfirmDialog = true;
    },
    onClickBtnCancelShowConfirm() {
      this.popups.isShowConfirmDialog = false;
    },
    openRemoveNotApproveDialog() {
      this.popups.isShowRemoveNotApproveDialog = true;
    },
    closeRemoveNotApproveDialog() {
      this.popups.isShowRemoveNotApproveDialog = false;
    },
    closeConfirmUploadDialog() {
      this.popups.isShowConfirmUploadDialog = false;
    },
    closeErrorDialog() {
      this.popups.isShowErorDialog = false;
    },
    openConfirmUploadDialog(param) {
      this.fileUpload = param;
      this.popups.isShowConfirmUploadDialog = true;
    },
    async saveApproveData(isShowLoading = true) {
      const ids = this.selectedNotApproveItems.map((items) => items.id);
      const fieldId = this.fieldId;
      const result = await Store.dispatch(`${STORE}/approveFieldUserByIds`, {ids, fieldId}, isShowLoading);
      await this.getCountNotApprove();
      if (!result.hasError) {
        this.selectedNotApproveItems = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.onClickBtnCancelShowConfirm();
        //成功したら値を更新
        await this.getItems();
        await this.getNotApproveItems();
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
    },
    async updateTeamSuccess() {
      this.firstLoad = FIRST_LOAD;
      await this.getItems();
    },
    async deleteNotApproveItems(isShowLoading = true) {
      const ids = this.selectedNotApproveItems.map((items) => items.id);
      this.closeRemoveNotApproveDialog();
      const result = await Store.dispatch(`${STORE}/deleteFieldUserByIds`, {ids}, isShowLoading);
      await this.getCountNotApprove();
      if (!result.hasError) {
        this.selectedNotApproveItems = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        await this.getNotApproveItems();
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
    },
    // データ取得
    getTabData() {
      this.getCountNotApprove();
    },

    async getItems(isShowLoading = true) {
      const result = await Store.dispatch(`${STORE}/getListFieldUserInfo`, {params: this.apiParams}, isShowLoading);
      if (result.hasError === true) {
        this.items = [];
        this.totalFieldUserInfo = 0;
      }
      this.selectedItems = [];
    },
    async getNotApproveItems() {
      const result = await Store.dispatch(`${STORE}/getListFieldUserNotApprove`, {params: this.apiParams});
      if (result.hasError === true) {
        this.notApproveItems = [];
        this.totalFieldUserNotApprove = 0;
      }
      this.selectedNotApproveItems = [];
    },
    async getCountNotApprove() {
      const result = await Store.dispatch(`${STORE}/getCountFieldUserNotApprove`, {params: this.apiParamsCountNotApprove});
      if (result.hasError === true) {
        this.countNotApproveItems = 0;
      }
    },
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const userIds = this.selectedItems.map((items) => items.user_id);
      const fieldId = this.fieldId;
      this.closeRemoveDialog();
      const result = await Store.dispatch(`${STORE}/deleteFieldUserByIds`, {ids, userIds, fieldId});
      let checkDeleteThisMySelf = [];
      if (!result.hasError) {
        this.selectedItems = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        //成功したら値を更新
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
          checkDeleteThisMySelf = result?.data?.contents?.ids.filter((item) => item === this.idFieldLogin);
          if (checkDeleteThisMySelf.length) {
            await this.$router.push('master/fields')
          }
        }
      }
      if (!checkDeleteThisMySelf.length) {
        await this.getItems();
      }
    },

    pageUpdate(n) {
      let searchParams = {...this.searchParams};
      searchParams.currentPage = n;
      searchParams.currentPageApprove = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    pageUpdateNotApprove(n) {
      let searchParams = {...this.searchParams};
      searchParams.currentPage = n;
      searchParams.currentPageNotApprove = n;
      this.searchParams = searchParams;
      this.getNotApproveItems();
    },
    convertName(str, length) {
      return convertName(str, length);
    },
    convertFullName(item, leader = false) {
      if (leader) {
        // eslint-disable-next-line no-irregular-whitespace
        return `${item.leader_name_sei}　${item.leader_name_mei}`;
      }
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`;
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({name, value}) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab.onChangeWindowSize()
    },
    onChangeSortParams({name, value}, invalid) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
        if (this.selectedTab === TAB_LIST_APPROVED_USER) {
          this.getItems();
        } else {
          this.getNotApproveItems();
        }
      }
    },
    /**
     * (共通)
     * 検索
     */
    onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
        if (this.selectedTab === TAB_LIST_APPROVED_USER) {
          this.getItems();
        } else {
          this.getNotApproveItems();
        }
      }
    },
    itemRowBackground: function (item) {
      return item.deletedAt ? 'background-gray-field-user' : ''
    },
    async getUserLabel() {
      if (this.roleLogin === COMPANY_SCENE) {
        this.USER_LABELS = this.USER_TABLE_LABELS
      } else {
        this.USER_LABELS = this.USER_TABLE_LABELS_PARTNER
      }
      return this.USER_LABELS;
    },
    async getListPartnerLeaderInField(isShowLoading = true) {
      await Store.dispatch(`${STORE}/getListPartnerLeaderInField`, {params: this.apiGetLeadersParams}, isShowLoading);
    },

    async submitUploadInfo() {
      this.closeConfirmUploadDialog();
      const formData = new FormData();
      formData.append('file', this.fileUpload);
      formData.append('field_id', this.fieldId);
      const rs = await Store.dispatch(`${STORE}/bulkAddFieldUserInfo`, formData);
      if (!rs.hasError && rs.data.status_code == 200) {
        this.getItems();
        await Store.dispatch("Toast/show", {
          status: 200,
          message: rs?.data?.message,
        });
        this.popups.isShowUploadForm = false;
      } else {
        if (rs?.response?.status == 400) {
          this.textDialogError = rs.response?.data?.detailErrMsg;
          this.popups.isShowErorDialog = true;
        }
      }
    },

    async setStateForcedMove (item, status) {
      if (this.roleLogin !== COMPANY_SCENE) {
        await Store.dispatch("Error/show", {
          status: 200,
          message: 'ログインユーザーは元請に所属していません。',
        });
        return
      }

      const params = {
        field_user_id: item.id,
        flg_forced_move: parseInt(status),
        field_id: this.fieldId
      }

      const result = await Store.dispatch(`${STORE}/setStatusApprove`, params);
      if (!result.hasError) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
        this.getItems();
      }
    },

    checkFieldUser (item) {
      return item.role === 4 || item.role === 1
    },

    async setStateReceiptAllocation(item, status) {
      if (this.roleLogin !== COMPANY_SCENE) {
        await Store.dispatch("Error/show", {
          status: 200,
          message: 'ログインユーザーは元請に所属していません。',
        });
        return
      }

      const params = {
        field_user_id: item.id,
        flg_receipt_allocation: parseInt(status),
        field_id: this.fieldId
      }

      const result = await Store.dispatch(`${STORE}/setStatusReceiptAllocation`, params);
      if (!result.hasError) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
        this.getItems();
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.tab-select-header {
  height: 56px !important;
}

.sortLabel {
  font-size: 12px;
  height: 32px;
  margin-right: 16px;
}

.sortElement {
  max-width: 128px;
  margin-right: 24px;
}

.total_item {
  padding-bottom: 15px;
}

.cst-row {
  border-bottom: 1px solid;
  padding-top: 12px;
  border-color: #e0e0e0;
}

.cst-col {
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0 0 0 16px;
  width: calc(100% + 32px);
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:first-child {
  padding: 0 0 0 16px !important;
  width: 60px !important;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}
@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 
}
</style>
<style>
tr.background-gray-field-user {
  background-color: #eee;
}

.tab-select-filed-user-screen {
  flex: 0 auto !important;
}
.field-user-custom-class-css .v-data-table thead.v-data-table-header tr th{
  min-width: 130px;
}
.alert-circle{
  color: #FF0000 !important;
}
</style>
