<template>
  <div>
    <DefaultLayout>
      <Loading v-if="isLoading"/>
      <v-container id="register">
        <v-row>
          <v-col>
            <v-card-subtitle
              class="head-title text-center"
            >
              <img src="/images/Airliza.png" alt="-image" width="330" height="60" class=""/>
            </v-card-subtitle>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            :cols="12"
            md="7"
            sm="12"
            xs="12"
          >
            <v-card
              class="card text-center"
              elevation="2"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    現場関係会社
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    <v-btn
                        icon
                        fab
                        small
                        dark
                        depressed
                        class="success accent-2"
                      >
                      </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>予約・在庫管理を行います</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions class="justify-center">
                <v-btn
                  outlined
                  text
                  class="primary"
                  @click="openItemForm(false)"
                >
                  現場関係会社として利用登録する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col
            :cols="12"
            md="5"
            sm="12"
            xs="12"
          >
            <v-card
              class="card text-center"
              elevation="2"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    レンタル会社
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    <v-btn
                        icon
                        fab
                        small
                        dark
                        depressed
                        class="primary accent-2"
                      >
                      </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>レンタル会社ユーザーとして受注管理を行います</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions class="justify-center">
                <v-btn
                  outlined
                  text
                  class="primary"
                  @click="openItemForm(true)"
                >
                  レンタル会社として利用登録する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col
            class="action text-center"
            :cols="12"
          >
          <router-link
            class="portal-summary__link"
            :to="'/'"
            >
            <v-btn
              outlined
              text
              class="primary"
            >
              ログイン
            </v-btn>
          </router-link>
          </v-col>
        </v-row>
      </v-container>
    </DefaultLayout>
    <Popup :dialog="isShowItemForm">
      <FieldCompany
        @cancelSuccess="openConFirmOk"
        @formUpdate="formUpdate"
        :isRental="isRental"
        @cancel="closeItemForm"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowConfirmSuccess">
      <ConfirmSuccess
        title="利用登録をしました"
        :text1="dataEmail"
        text2="メールに記載のURLからログインし、利用を開始してください。"
        @yes="confirmOk"
      />
    </Popup>
  </div>
</template>
<script>
import Popup from "@/components/common/Popup.vue";
import FieldCompany from "@/components/register/FieldCompany/FieldCompany.vue";
import ConfirmSuccess from "./popup/ConfirmSuccess.vue";
import {Store} from "@/store/Store";
import Loading from "@/components/loading/Loading";

const STORE = "Register";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: "利用登録" };
    },
  },
  data() {
    return {
      item: {},
      isShowItemForm: false,
      isShowConfirmSuccess: false,
      isRental: Boolean,
      dataEmail: null,
      isLoading: false,
    };
  },

  components: {
    Popup,
    FieldCompany,
    ConfirmSuccess,
    Loading,
  },

  async mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        if (data == true) {
          this.isLoading = data;
        } else {
          this.isLoading = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    openItemForm(item) {
      this.isRental = item;
      this.isShowItemForm = true;
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },

    openConFirmOk() {
      this.isShowConfirmSuccess = true;
    },

    confirmOk() {
      this.isShowConfirmSuccess = false;
    },
    formUpdate(data) {
      this.dataEmail = "入力したメールアドレス " + data.users.email + " にログイン情報を記載したメールが送信されます。";
    }
  }
}
</script>
<style lang="scss" scoped>
  .portal-summary__link {
    text-decoration: none;
    padding: 16px;
    box-sizing: border-box;
    color: #f8f8f8;
  }

  #register {
    padding: 50px;
    max-width: 1440px !important;
  }
  #register .head-title {
    margin-top: calc(100px - 16px);
    margin-bottom: calc(100px - 16px);
    font-size: 36px !important;
  }
  #register .head-title h2 {
    color: #274fc2 !important;
  }
  #register .card {
    padding: 30px;
    min-height: 300px;
    margin-left: 25px;
    margin-right: 25px;
  }
  #register .card .text-overline {
    font-size: 48px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    align-items: center;
    text-align: center;
  }
  #register .card .v-list-item__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 20px;
  }
  #register .action button {
    margin: 70px 25px 100px 25px;
    padding: 16px 116px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  #register .primary {
    color: #f8f8f8 !important;
  }
  #register .success {
    background-color: #6CA955 !important;
    border-color: #6CA955 !important;
  }
  @media only screen and (min-width: 960px) and (max-width: 1250px) {
    #register .card {
      min-height: 320px;
    }
    #register .card .text-overline {
      font-size: 34px !important;
    }
    #register .card .v-list-item__subtitle {
      font-size: 10px;
    }
  }
</style>
