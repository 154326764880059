<template>
  <div class="ml-1">
    <div class="SearchFormWrapper" :class="$sp ? 'sp' : ''">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    SPenable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.SearchFormWrapper {
  display: flex;
  align-items: center;
  padding: 0 8px;
  align-items: flex-start;

  &.sp {
    flex-direction: column;
  }

  &::v-deep .labelWrapper {
    padding: 4px !important;
    @media (max-width:1024px) {
      height: 80px !important;
      max-height: 80px !important;
    }
  }
}
</style>
