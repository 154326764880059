<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー --> 
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
        >
          <template #tableHeader="{ updateHeader }"> 
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isClassify="false"
              :isDelete="false"
              :isAddNew="false"
              @openRemoveDialog="openRemoveDialog"
            >
              <SearchFormWrapper class="ml-1">
                <!-- 文字入力 -->
                <Label label="商品コード" class="title-input-item">
                  <InputText
                    name="productCode"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <!-- ロール -->
                <Label label="機番" class="title-input-item">
                  <InputText
                    name="machineNumber"
                    :values="searchParams"
                    :editable="true"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!-- 
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--  
                (共通) ソート 
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
                primary
                class="ml-3"
              />
            </TableSortWrapper>
          </template>
          <!-- 
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                <v-tab v-for="tab in TAB_MACHINE" :key="tab.id" @click="changeTab(tab.id) " color="primary" class="tab-machine">{{tab.name}}</v-tab>
              </v-tabs>
            </template> 
            <template>
              <v-btn class="ml-4 mr-5 " color="primary" depressed @click="openUploadForm">
                一括登録
              </v-btn>               
              <v-btn class="mr-5 " color="primary" depressed @click="openNewItemForm">
                新規作成
              </v-btn>               
              <v-btn class="mr-5 " color="primary" depressed @click="openInfomationForm" :disabled="disableRemoveBtn">
                出庫手続き
              </v-btn>
              <v-btn class="mt-4 pb-3" depressed icon :disabled="disableRemoveBtn" @click="popups.isShowRemoveDialog = true">
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
              <v-btn class="mt-3 float-right mr-8" color="primary" outlined @click="openConfirmDialog">
                CSV出力
              </v-btn>
            </template>
            <v-data-table
              item-key="machine_id"
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 115: tableHeight - 145"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-1 v-data-table__wrapper"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @toggle-select-all="selectAllItems"
              @click:row="openItemForm"
              @update:searchParams.currentPage="$vuetify.goTo(0)"
              :key=searchParams.currentPage
            >
              <template v-slot:[`item.product_code`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 220px;
                    white-space: nowrap;
                  "
                >
                  {{ convertName(item.product_code, 50) }}
                </div>
              </template>
              <template v-slot:[`item.machine_number`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 220px;
                    white-space: nowrap;
                  "
                >
                  {{ convertName(item.machine_number, 50) }}
                </div>
              </template>
              <template v-slot:[`item.number_plate`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 220px;
                    white-space: nowrap
                  "
                >
                  {{ convertName(item.number_plate, 65) }}
                </div>
              </template>
              <template v-slot:[`item.machine_name`]="{ item }">
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                    white-space: nowrap
                  "
                >
                  {{convertName(item.machine_name, 100)}}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
              class="mb-5"
              style="background: white; position: fixed; width: 100%; bottom: -20px;"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemWarehousingForm">
      <MachineInformation
        :flag="true"
        :item="editedItem"
        :originItem="originItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        :key="isResetForm"
      />
    </Popup>
    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowInfomationForm">
      <MachineDeliveryInformation
        :flag="true"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        @formUpdate="formUpdate"
        @submit="submitFormDelivery"
        @cancel="closeItemForm"
        :key="isResetForm"
        :selectedItems="selectedItems"
      />
    </Popup>
    <Popup max-width="480" :dialog="popups.isShowUploadForm">
      <BulkRegistration
        @close="closeUploadDialog"
        :flagError="flagError"
        @yes="submitUploadInfo"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
    
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmDialog
        v-model="isShowCancel"
        title="CSV出力"
        text="システムに登録している全ての商品データを出力します。"
        text2="CSVファイルの作成にはしばらく時間がかかる場合があります。"
        textClose="やめる"
        textSubmit="出力"
        @close="popups.isShowConfirmDialog = false"
        @yes="exportCsv()"
      />
    </Popup>
    
    <Popup width="480px" :dialog="popups.isShowErorDialog">
      <ErrorDialog
        @yes="closeErrorDialog"
        @close="closeErrorDialog"
        :text="textDialogError"
        title="エラー"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog"; //削除確認ダイアログ 
import ErrorDialog from "../../../../components/dialog/ErrorDialog.vue";

import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import CrudForm from "@/components/forms/crud/CrudForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  MACHINE_TABLE_LABELS_TAB_1,
  MACHINE_SORT_ITEMS_TAB_1,
  MACHINE_INITIAL_ITEM,
  TAB_MACHINE,
  MACHINE_DELIVERY_IMFO_ITEM,
  CREATE,
  EDIT
} from "@/constants/MACHINE"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_RENTAL } from "@/constants/GLOBALHEADER";
import Loading from "@/components/loading/Loading";
import _ from "lodash";
import {convertName, dateFormat} from "@/common/helper";
import MachineInformation from "../../../../components/forms/machine/MachineInformation"
import MachineDeliveryInformation from "../../../../components/forms/machine/MachineDeliveryInformation"
import BulkRegistration from "../../../../components/forms/machine/components/BulkRegistration"
import ConfirmDialog from "./popup/ConfirmDialog.vue";
/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "商品リスト";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Machines";

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_TABLE_LABELS_TAB_1;

//ソート要素
const SORT_ITEMS = MACHINE_SORT_ITEMS_TAB_1;

const INITIAL_ITEM = MACHINE_INITIAL_ITEM

const INITIAL_ITEM_DELIVERY_INFO = MACHINE_DELIVERY_IMFO_ITEM

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TAB_MACHINE,
      MACHINE_TABLE_LABELS_TAB_1,
      CREATE,
      EDIT,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},
      originItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        productCode: '',
        machineNumber: '',
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: 1,
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemWarehousingForm: false,
        // 追加/編集フォーム
        isShowInfomationForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
        isShowUploadForm: false,
        isShowConfirmDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      tab: 0,
      flagError: false,
      isShowCancel: false,
      isLoading: false,
      textDialogError: ''
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    CrudForm, 
    InputText,
    Select,
    Label,
    MachineInformation,
    MachineDeliveryInformation,
    ConfirmRemoveDialog,
    Loading,
    ConfirmDialog,
    ErrorDialog,
    BulkRegistration
  },

  async mounted() {
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_RENTAL.MACHINE.id,
    });

        //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
    history.go(1);
    }

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0]?.data];
        this.items = _items.map((x) => ({
          ...x
        }));
        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: false,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        product_code: this.searchParams.productCode,
        machine_number: this.searchParams.machineNumber,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        
      };
    },
  },

  methods: {    
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch() {
      this.searchParams["currentPage"] = 1;
      await this.getItems();
      this.selectedItems = [];
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
      this.selectedItems = [];
    },
    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
     openNewItemForm() {
      this.actions = CREATE;
      let editedItem = INITIAL_ITEM?.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.originItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemWarehousingForm = true;
    },
    openUploadForm() {
      this.popups.isShowUploadForm = true;
    },
    async openItemForm(item) {
      this.actions = EDIT;
      let params = { id: item.machine_id}
      const siteInfo = await Store.dispatch(
        `${STORE}/getMasterMachineInfoById`,
        {params}
      );
      // Show Detail Form
      if (!siteInfo.hasError) {
        this.editedItem = _.cloneDeep(siteInfo?.data?.contents?.entries);
        this.originItem = _.cloneDeep(siteInfo?.data?.contents?.entries);
        this.isNewItem = false;
        this.popups.isShowItemWarehousingForm = true;
      } else {
        this.getItems();
      }
    },
    openInfomationForm() {
      this.actions = CREATE;
      let editedItem = INITIAL_ITEM_DELIVERY_INFO?.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.editedItem.end_bookable_date = null;
      this.isNewItem = true;
      this.popups.isShowInfomationForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemWarehousingForm = false;
      this.popups.isShowInfomationForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems(isShowLoading = true) {
      await Store.dispatch(`${STORE}/getListMachineInStockOfRental`, {params: this.apiParams}, isShowLoading);
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.machine_id);
      const result = await Store.dispatch(`${STORE}/deleteMasterMachineByIds`, { ids });

      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
      }
      this.closeRemoveDialog();
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/updateMasterMachineInfo` : `${STORE}/addMasterMachineInfo`,
        editedItem
      );
      let message = result?.data?.message;
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: message,
        });
        let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getMasterMachineInfoById`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.originItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: message,
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    async submitFormDelivery() {
      const ids = this.selectedItems.map((items) => items.machine_id);
      const editedItem = _.cloneDeep(this.editedItem);
      editedItem.ids = ids;
      const result = await Store.dispatch(`${STORE}/addListDeliveryInfo`,
        editedItem
      );
      let message = result?.data?.message;
      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else {
        this.selectedItems = [];
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: message,
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.machine_id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.machine_id);
          }
        });
      }
    },
    openConfirmDialog(){
      this.popups.isShowConfirmDialog = true;
    },
    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },

    changeTab(id) {
      Store.dispatch(`${STORE}/setSelected`, id);
    },
    closeUploadDialog() {
      this.popups.isShowUploadForm = false;
    },
    // change dispatch
    async submitUploadInfo(param) {
      const params = {...param};
      const rs = await Store.dispatch("Machines/bulkAddMasterMachineInfo", params);
      if(!rs.hasError) {
        this.getItems();
        this.closeUploadDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: rs?.data?.message,
        });
      } else {
        if (rs?.response?.status == 422) {
          this.popups.isShowErorDialog = true;
          let message = rs.response.data.message_detail;
          this.textError = message.split("\n");
          this.textDialogError = message;
        }
        this.flagError = !this.flagError;
      }
    },
    closeErrorDialog() {
      this.popups.isShowErorDialog = false;
      this.flagError = !this.flagError;
    },
    cancelBooking() {
      this.popups.isShowConfirmDialog = false;
    },
    async exportCsv() {
      const response = await Store.dispatch(
        `Machines/exportMachine`,
        {}
      );
      const { file, file_name } = response.contents;
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const url = window.URL.createObjectURL(new Blob([byteArray]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
      this.popups.isShowConfirmDialog = false;
    },
  },
};
</script>
<style scoped>
.tab-machine {
  color: #274FC2 !important;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper table thead tr th:first-child {
  padding: 0;
  width: 5% !important;
  min-width: 5% !important;
  text-align: center !important;
}


::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}
/* ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
} */
::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}

@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 
}
</style>
