const CRUD_INITIAL_ITEM = {
  entries: {
    d2t_crud: {
      id: null,
      name: null,
      description: null,
      sex: 1,
      age: null,
      file: null,
    }
  },
};

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "CRUD Form";
const CRUD_TITLE = "cruds";

const CRUD_TABLE_LABELS = [
  {
    text: "Name",
    align: "left",
    sortable: true,
    value: "name",
  },
  {
    text: "Descriptions",
    value: "description",
    align: "left",
    sortable: false,
  },
  {
    text: "Age",
    value: "age",
    align: "center",
    sortable: false,
  },
  {
    text: "Sex",
    value: "sex",
    align: "center",
    sortable: false,
  },
  {
    text: "Created date",
    value: "created_at",
    align: "center",
    sortable: true,
  },
];
const CRUD_SORT_ITEMS = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "age",
    name: "Age",
  },
  {
    id: "sex",
    name: "Sex",
  },
  {
    id: "created_at",
    name: "Created date",
  },
];

export {
  FORM_TITLE,
  CRUD_TABLE_LABELS,
  CRUD_SORT_ITEMS,
  CRUD_TITLE,
  CRUD_INITIAL_ITEM
};
