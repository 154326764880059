import Api, { Mock } from "../api";
import mock_machines from "./mock/machines.json";
import mock_detail_machine from "./mock/machine_detail.json";

const PATH = "machines";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const GET_LIST_URL = `${URL}/list`;
const DELETE_URL = `${URL}/delete`;
const GET_DETAIL_MACHINE_URL = `${URL}/detail`;
const CREATE_MACHINE_URL = `${URL}/create`;
const UPDATE_MACHINE_URL = `${URL}/update`;
const GET_LIST_MACHINE_BY_TYPE_URL = `${URL}/list_machine_by_type`;
const GET_LIST_MACHINE_REQUEST = `${process.env.VUE_APP_API_BASE_URL}/machine_requests/get_list_machine_request_by_role`;
const GET_LIST_MACHINE_REQUEST_DETAIL = `${process.env.VUE_APP_API_BASE_URL}/machine_requests/get_list_machine_request_detail`;
const GET_LIST_FIELD_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_field_company_by_role`;
const GET_LIST_INSPECTION_AND_REPAIR_REQUEST = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_inspection_and_repair_request`;
const MACHINES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machines`;
const GET_LIST_MACHINE_IN_STOCK_OF_RENTAL = `${MACHINES_BASE_URL}/get_list_machine_in_stock_of_rental`;
const RECEIPT_MACHINE_FROM_FIELD = `${MACHINES_BASE_URL}/receipt_machine_from_field`;
const RETURN_MACHINES = `${process.env.VUE_APP_API_BASE_URL}/fields/return_machines`;
const GET_LIST_MACHINE_WORKING_OF_RENTAL = `${MACHINES_BASE_URL}/get_list_machine_working_of_rental`;
const ADD_MASTER_INFO = `${MACHINES_BASE_URL}/add_master_machine_info`;
const UPDATE_MASTER_INFO = `${MACHINES_BASE_URL}/update_master_machine_info`;
const GET_MASTER_MACHINE_INFO_BY_ID = `${MACHINES_BASE_URL}/get_master_machine_info_by_id`;
const DELETE_LIST_URL = `${MACHINES_BASE_URL}/delete_master_machine_by_ids`;
const DELETE_MACHINE_DELIVERY_BY_IDS = `${MACHINES_BASE_URL}/delete_machine_delivery_by_ids`;
const ADD_LIST_DELIVERY_INFO = `${MACHINES_BASE_URL}/add_list_delivery_info`;
const BULK_ADD_MASTER_MACHINE_INFO = `${MACHINES_BASE_URL}/bulk_add_master_machine_info`;
const GET_LIST_MACHINE_DELIVERY_OF_RENTAL = `${MACHINES_BASE_URL}/get_list_machine_delivery_of_rental`;
const GET_INSPECTION_BEFORE_DELIVERY = `${MACHINES_BASE_URL}/get_inspection_before_delivery_by_id`;
const GET_ON_SITE_MACHINE_INFO = `${MACHINES_BASE_URL}/get_on_site_machine_info`;
const UPDATE_ON_SITE_MACHINE_INFO = `${MACHINES_BASE_URL}/update_on_site_machine_info`;
const DELETE_MACHINE_REQUESTS = `${process.env.VUE_APP_API_BASE_URL}/machine_requests/delete_machine_requests`;
const COMMENT_MACHINE_REQUESTS = `${process.env.VUE_APP_API_BASE_URL}/machine_requests/comment_machine_requests`;
const GET_LIST_RETURN_REQUEST_ON_FIELD = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_return_request_on_field`;
const DELETE_REPAIR_REQUEST_BY_IDS = `${process.env.VUE_APP_API_BASE_URL}/fields/delete_repair_request_by_ids`;
const GET_COUNT_ABNORMAL_INSPECTION_AND_REPAIR_REQUEST = `${process.env.VUE_APP_API_BASE_URL}/fields/count_abnormal_inspection_and_repair_request`;
const GET_COUNT_MACHINE_REQUEST_NOT_ORDER = `${process.env.VUE_APP_API_BASE_URL}/machine_requests/count_machine_request_not_order`;
const EXPORT_MACHINE = `${MACHINES_BASE_URL}/export_machine`;
const GET_DETAIL_MACHINE_INVENTORY = `${MACHINES_BASE_URL}/get_detail_machine_inventory`;
const UPDATE_DETAIL_MACHINE_INVENTORY = `${MACHINES_BASE_URL}/update_detail_machine_inventory`;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(new RegExp(`${GET_DETAIL_MACHINE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_detail_machine,
  });

  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machines,
  });

  Mock.onPost(CREATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(new RegExp(`${GET_LIST_MACHINE_BY_TYPE_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      entries: [
        {
          id: 1,
          machine_name: "machine A"
        },
        {
          id: 2,
          machine_name: "machine B"
        }
      ]
    },
  });
}
export const machines = {
  getListMachineInStockOfRental: (params) => {
    return Api.get(GET_LIST_MACHINE_IN_STOCK_OF_RENTAL, params);
  },
  getListMachineWorkingOfRental: (params) => {
    return Api.get(GET_LIST_MACHINE_WORKING_OF_RENTAL, params);
  },
  addMasterMachineInfo: (params) => {
    return Api.post(ADD_MASTER_INFO, params);
  },
  getMasterMachineInfoById: (params) => {
    return Api.get(GET_MASTER_MACHINE_INFO_BY_ID, params);
  },
  updateMasterMachineInfo: (params) => {
    return Api.post(UPDATE_MASTER_INFO, params);
  },
  deleteMasterMachineByIds: (params) => {
    return Api.post(DELETE_LIST_URL, params);
  },
  deleteMachineDeliveryByIds: (params) => {
    return Api.post(DELETE_MACHINE_DELIVERY_BY_IDS, params);
  },
  addListDeliveryInfo: (params) => {
    return Api.post(ADD_LIST_DELIVERY_INFO, params);
  },
  bulkAddMasterMachineInfo: (params) => {
    return Api.post(BULK_ADD_MASTER_MACHINE_INFO, params);
  },
  receiptMachineFromField: (params) => {
    return Api.post(RECEIPT_MACHINE_FROM_FIELD, params);
  },
  returnMachines: (params) => {
    return Api.post(RETURN_MACHINES, params);
  },
  getListMachineDeliveryOfRental: (params) => {
    return Api.get(GET_LIST_MACHINE_DELIVERY_OF_RENTAL, params);
  },
  getInspectionBeforeDeliveryById: (params) => {
    return Api.get(GET_INSPECTION_BEFORE_DELIVERY, params);
  },
  getOnSiteMachineInfo: (params) => {
    return Api.get(GET_ON_SITE_MACHINE_INFO, params);
  },
  updateOnSiteMachineInfo: (params) => {
    return Api.post(UPDATE_ON_SITE_MACHINE_INFO, params);
  },
  getListMachine: (params) => {
    return Api.get(GET_LIST_MACHINE_REQUEST, {params});
  },
  getListMachineRequestDetail: (params) => {
    return Api.get(GET_LIST_MACHINE_REQUEST_DETAIL, {params});
  },
  getListFieldCompany: (params) => {
    return Api.get(GET_LIST_FIELD_COMPANY, {params});
  },
  deleteMachine: (params) => {
    return Api.post(DELETE_MACHINE_REQUESTS, params);
  },
  commentMachineRequests: (params) => {
    return Api.post(COMMENT_MACHINE_REQUESTS, params);
  },
  getListRequestField: (params) => {
    return Api.get(GET_LIST_RETURN_REQUEST_ON_FIELD, {params});
  },
  getListInspectionAndRepairRequest: (params) => {
    return Api.get(GET_LIST_INSPECTION_AND_REPAIR_REQUEST, {params});
  },
  deleteMachineRepairRequest: (params) => {
    return Api.post(DELETE_REPAIR_REQUEST_BY_IDS, params);
  },

  getCountAbnormalInspectionAndRepairRequest: (params) => {
    return Api.get(GET_COUNT_ABNORMAL_INSPECTION_AND_REPAIR_REQUEST, {params});
  },
  getCountMachineRequestNotOrder: (params) => {
    return Api.get(GET_COUNT_MACHINE_REQUEST_NOT_ORDER, {params});
  },
  exportMachine: (params) => {
    return Api.get(EXPORT_MACHINE, {params});
  },
  getDetailMachineInventory: (params) => {
    return Api.get(GET_DETAIL_MACHINE_INVENTORY, params);
  },
  updateDetailMachineInventory: (params) => {
    return Api.post(UPDATE_DETAIL_MACHINE_INVENTORY, params);
  },
};
