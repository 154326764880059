const BOOKING_STATUS = {
  UNAVAILABLE: { id: 1, name: "予約あり" },
  AVAILABLE: { id: 0, name: "予約なし" },
};

const KINDS_STATUS = {
  POSSESSION: { id: 0, name: "占有機" },
  SHARED_MACHINE: { id: 1, name: "共用機" },
};

const LIFTING_METHOD = {
  VERTICAL_LIFT: { id: 0, name: "垂直昇降" },
  NAOSHINOBU: { id: 1, name: "直伸" },
  REFRACTION: { id: 2, name: "屈折" },
};

const UNDERCARRIAGE = {
  WHEEL: { id: 1, name: "ホイール" },
  CRAWLER: { id: 2, name: "クローラ" },
};

const WORKING_FLOOR = {
  ORDINARILY: { id: 1, name: "普通" },
  SLIDE: { id: 2, name: "スライド" },
  BUCKET: { id: 3, name: "バケット" },
};

const BOOKING_TAB_SELECT = {
  BOOKING_TAB: { id: 1, name: "予約" },
  ACHIEVEMENT_TAB: { id: 2, name: "実績" }
}
const TENKEN_TAB_SELECT = {
  BOOKING_TAB: { id: 1, name: "作業前点検" },
  //MMSV2-1573-start
  ACHIEVEMENT_TAB: { id: 2, name: "月例点検" },
  REQUEST_REPAIR_TAB: { id: 3, name: "修理要請" }
  //MMSV2-1573-end
}

const BOOKING_TAB_TABLE_LABELS = [
  {
    text: "ナンバー / 機種",
    align: "left",
    sortable: false,
    value: "model",
    width: '330px'
  },
  {
    text: "留置場所",
    align: "left",
    sortable: false,
    value: "place_name",
    width: '120px'
  },
  {
    text: "date",
    value: "date",
    class:"px-0 mx-0",
    cellClass: "px-0 mx-0",
    align: "left",
    sortable: false,
  },
];

const ACHIEVEMENT_TAB_TABLE_LABELS = [
  {
    text: "ナンバー / 機種",
    align: "left",
    sortable: false,
    value: "model",
    width: '330px'
  },
  {
    text: "最終更新",
    align: "left",
    sortable: false,
    value: "booking_updated_at",
    width: '120px'
  },
  {
    text: "予約率",
    align: "left",
    class: "sticky",
    cellClass: "sticky",
    sortable: false,
    value: "booking_rate",
    width: '100px'
  },
  {
    text: "date",
    value: "date",
    class:"px-0 mx-0",
    cellClass: "px-0 mx-0",
    align: "left",
    sortable: false,
  },
];

const FOMAT_DATE = [
  { label: "AM", value: 0 },
  { label: "PM", value: 1 },
];

const HEADER_BOOKING_FORM = [
  { text: "カーナンバー" },
  { text: "床高（m）" },
  { text: "昇降方式" },
  { text: "足回り" },
  { text: "作業床" },
]

const FORM_LIST_TENKEN_LABEL = [
  {
    text: "",
    isCheckbox: true,
    width: "5%",
    align: "left",
  },
  {
    text: "報告者",
    align: "start",
    width: "12%",
    value: "name",
  },
  {
    text: "",
    align: "start",
    width: "7%",
    isAct: true,
  },
  {
    text: "",
    align: "start",
    width: "10%",
    value: "model"
  },
  {
    text: "",
    align: "start",
    width: "13%",
    value: "phone",
  },
  {
    text: "報告日",
    align: "start",
    width: "33%",
    value: "inspection_at",
  },
  {
    text: "状態",
    align: "start",
    width: "20%",
    isSituation: true,
  },
];
//MMSV2-1573-start
const FORM_LIST_REPAIR_LABEL = [
  {
    text: "",
    isCheckbox: true,
    width: "5%",
    align: "left",
  },
  {
    text: "報告者",
    align: "start",
    width: "12%",
    value: "name",
  },
  {
    text: "",
    align: "start",
    width: "7%",
    isAct: true,
  },
  {
    text: "",
    align: "start",
    width: "10%",
    value: "model"
  },
  {
    text: "",
    align: "start",
    width: "13%",
    value: "phone",
  },
  {
    text: "報告日",
    align: "start",
    width: "33%",
    value: "request_at",
  },
  {
    text: "状態",
    align: "start",
    width: "20%",
    isSituation: true,
  },
];
//MMSV2-1573-end

const BOOKING_REPORT_STATUS = {
  'NO_REPORT': 0,
  'NORMAL': 1,
  'HAVE_NON_PROCESS': 2,
  'ALL_PROCESSED': 3,
  //MMSV2-1573-start
  'BLOCK_PROCESS_BOOKING': 4,
  //MMSV2-1573-end
}

export {
  BOOKING_STATUS,
  KINDS_STATUS,
  LIFTING_METHOD,
  UNDERCARRIAGE,
  WORKING_FLOOR,
  BOOKING_TAB_SELECT,
  BOOKING_TAB_TABLE_LABELS,
  ACHIEVEMENT_TAB_TABLE_LABELS,
  FOMAT_DATE,
  HEADER_BOOKING_FORM,
  FORM_LIST_TENKEN_LABEL,
  //MMSV2-1573-start
  FORM_LIST_REPAIR_LABEL,
  //MMSV2-1573-end
  TENKEN_TAB_SELECT,
  BOOKING_REPORT_STATUS
}
