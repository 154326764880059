import Vue from "vue";
import Vuex from "vuex";
import { companies } from "@/api/modules/companies";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = companies;

export const Companies = {
  namespaced: true,

  state: {
    data: [],
    listUserByCompany: [],
    pagination: {},
    userPagination: {},
    company: {},
    document: {},
    dataBuilding: {},
    listBookingInfoBySubLeader: [],
    listCompanyRental: [],
    user: {},
    isLoading: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_LIST_USER_BY_COMPANY(state, payload) {
      state.listUserByCompany = payload;
    },
    SET_LIST_USER_PAGINATION(state, payload) {
      state.userPagination = payload;
    },
    SET_DATA_BUILDING(state, payload) {
      state.dataBuilding = payload;
    },
    SET_DATA_LIST_COMPANY(state, payload) {
      state.getListCompany = payload;
    },
    SET_DATA_LIST_COMPANY_RENTAL(state, payload) {
      state.getBookingInfoByFieldMachine = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async get({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.get(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response?.data?.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getListUserByCompany({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListUserByCompany(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_LIST_USER_BY_COMPANY", entries);
      commit("SET_LIST_USER_PAGINATION", pagination);
      return response;
    },

    async getUserInfoById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getUserInfoById(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_USER", entries);
      return response;
    },
    async updateUserInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateUserInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async deactiveUser({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deactiveUser(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async activeUser({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.activeUser(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async addUserInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addUserInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async deleteUserByIds({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteUserByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getDetail({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getDetail(payload);
      const entries = response?.data?.contents.entries;
      commit("SET_IS_LOADING", false);
      commit("SET_COMPANY", entries);
      return response;
    },

    async edit({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.edit(payload)
      commit("SET_IS_LOADING", false);
      return response;
    },

    async add({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.add(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async delete({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.delete(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async addUsersInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addUsersInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getDataBuilding({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListBuilding(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries} = response?.data?.contents;

      commit("SET_DATA_BUILDING", entries);
      return response;
    },
    async getBookingInfoBySubLeader({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getBookingInfoBySubLeader(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_DATA_LIST_COMPANY", entries);
      return response;
    },
    async getBookingInfoByFieldMachine({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getBookingInfoByFieldMachine(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_DATA_LIST_COMPANY_RENTAL", entries);
      return response;
    },
    async checkFieldCompanyByCorporateNumber({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.checkFieldCompanyByCorporateNumber(payload);
      commit("SET_IS_LOADING", false);
      return response;
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getCompany: (state) => {
      return state.company;
    },
    getListUserByCompany: (state) => {
      return state.listUserByCompany;
    },
    getUserPagination: (state) => {
      return state.userPagination;
    },
    getDataBuilding: (state) => {
      return state.dataBuilding;
    },
    getUser: (state) => {
      return state.user;
    },
    getBookingInfoBySubLeader: (state) => {
      return state.listBookingInfoBySubLeader;
    },
    getBookingInfoByFieldMachine: (state) => {
      return state.listCompanyRental;
    },
    getDetailCompany: (state) => {
      return state.company;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
