<template>
 <div class="upload-file">
   <v-card class="dialog">
     <v-card-title>
       <div class="primary--text">一括登録</div>
       <v-spacer></v-spacer>
       <v-btn small icon text @click="$emit('close')">
         <v-icon>mdi-close</v-icon>
       </v-btn>
     </v-card-title>
     <v-card-text class="body">
       <div class="text-wrap">
         <v-btn
           depressed
           color="primary"
           class="btn"
           @click="onDownloadFormInfo"
         >
           フォーマットをダウンロード
         </v-btn>
       </div>
       <div class="file-input-area" @drop.prevent="addFile" @dragover.prevent>
         <div class="text">ここにファイルを <br> ドラッグ＆ドロップ</div>
         <div class="file-name">
           <div v-for="(file, index) in files" :key="index" class="file-name-detail">
             {{ file.name }}
           </div>
           <v-btn small icon text v-if="files.length > 0" class="btn-delete" @click="removeFile(files[0])" title="削除">
             <v-icon>mdi-delete-outline</v-icon>
           </v-btn>
         </div>
         <div class="center">
           <v-btn depressed color="primary" class="btn" @click="onUpload">
             ファイルから選択
           </v-btn>
         </div>
         <form ref="form">
           <input
             style="display: none"
             ref="input_drawing_file"
             type="file"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
             @change="onImageInput()"
           />
         </form>
       </div>
     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn
         depressed
         outlined
         color="primary"
         class="btn"
         @click="onClose"
       >
         やめる
       </v-btn>
       <v-btn
         depressed
         class="btn"
         color="primary"
         :disabled="files.length == 0"
         :key="flagError"
         @click="onConfirm"
       >
         登録
       </v-btn>
     </v-card-actions>
   </v-card>
   <Popup width="480px" :dialog="popups.isShowConfirm">
    <ConfirmActiveUser
      @close="onCloseConfirm()"
      @yes="onSubmit()"
      title="ユーザーを一括で招待"
      :content="[
        '招待メールが送信されるまで時間がかかる場合がありますので、しばらくお待ちください。',
      ]"
      primary
      nameBtnSave="招待"
    />
  </Popup>
 </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { BULK_ADD_USER_EXCEL_TEMPLATE_NAME_COMPANY } from "@/constants/COMMON";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog";
import ConfirmActiveUser from "../listUserByCompany/components/ConfirmActiveUser.vue";

const URL_DOWNLOAD_FILE = `${process.env.VUE_APP_API_BASE_URL}/file/read_file`;
export default {
  data() {
    return {
      files: [],
      popups: {
        isShowConfirm: false,
      }
    };
  },
  components : {
    Popup,
    ConfirmDialog,
    ConfirmCloseDialog,
    ConfirmActiveUser
},
  props: {
    flagError: Boolean,
  },
  mounted() {
    this.$watch(
      () => Store.getters["File/getDataTemplate"],
      (data) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            BULK_ADD_USER_EXCEL_TEMPLATE_NAME_COMPANY
          );
          document.body.appendChild(link);
          link.click();
          Store.dispatch("File/clearData");
        }
      },
      {
        deep: true,
      }
    );
  },
  methods: {
    async onDownloadFormInfo() {
      const params = {
        folder: "template",
        path: "",
        name: "事務局が所属会社ユーザーを一括登録する.xlsx",
      };
      const configParams = {
        url: URL_DOWNLOAD_FILE,
        params: params
      }
      await Store.dispatch("File/downloadTemplate", configParams);
    },
    addFile(e) {
      let files = [];
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        files.push(f);
      });
      this.setFile(files[files.length - 1]);
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },
    onUpload() {
      this.$refs.input_drawing_file.click();
    },
    onImageInput() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      this.setFile(file);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onSubmit() {
      this.getBase64(this.files[0])
        .then((data64) => {
          let params = {
            data: data64,
          };
          this.$emit("yes", params);
        })
        .catch(() => {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "ファイルアップロードエラー",
            },
            { root: true }
          );
          this.$emit("close");
        });
      this.onCloseConfirm()
    },
    onCloseConfirm(){
      this.popups.isShowConfirm = false;
    },
    onConfirm(){
      this.popups.isShowConfirm = true;
    },
    onClose(){
      this.$emit('close');
    },
    setFile(file) {
      if (file.name.toLocaleLowerCase().match(/\.(xls|xlsx)$/)) {
        if (file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大10MBまでです。",
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "EXCELファイルをアップロードしてください。" },
          { root: true }
        );
        return;
      }
      this.files = [file];
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      font-size: 18px;
      text-align: center;
    }
  }
  .file-input-area {
    height: 230px;
    background: #e5e5e5;
    border-style: dashed;
    .text {
      text-align: center !important;
      position: relative;
      width: 200px;
      margin-left: 25%;
      margin-top: 28px;
      font-size: 20px;
      color: black;
    }
    .center {
      text-align: center;
    }
    .file-name {
      height: 60px;
      margin-top: 35px;
      position: relative;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-name-detail {
      white-space: nowrap;
      overflow-x: hidden;
      max-width: 90%;
      text-overflow: ellipsis;
    }
    .btn-delete {
      margin-left: 10px;
    }
  }
}
.upload-file {
  min-height: 450px;
}
</style>
