<template>
  <div class="labelWrapper" :style="style">
    <div class="label" :class="warning ? 'text__warning' : ''">
      {{ label }}
      <div v-if="required && editable" class="required">必須</div>
    </div>
    <div><slot /></div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `width:${this.width}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.labelWrapper {
  padding: 0;
}
.label {
  font-size: 16px;
  font-weight: 600;
  padding: 4px;
  color: $color_primary;
  margin-top: 24px;
  &.text__warning {
    color: $color_warning;
  }
}
.required {
  font-size: 12px;
  font-weight: normal;
  color: #ff5252 !important;
  background-color: transparent;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
}
</style>
