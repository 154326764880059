<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container class="my-3">
                <v-row>
                  <v-col cols="6" sm="6" md="6" class="mt-3">
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <Label
                            label="商品コード"
                            class="title-input-item"
                            :editable="editable"
                            required
                          >
                            <InputText
                              name="product_code"
                              :values="formValues"
                              :editable="editable"
                              validation_label="商品コード"
                              validation_rules="max:16|required"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                      <v-col>
                        <v-list-item-content>
                          <Label
                            label="機番"
                            class="title-input-item"
                            :editable="editable"
                            required
                          >
                            <InputText
                              name="machine_number"
                              :values="formValues"
                              :editable="editable"
                              validation_label="機番"
                              validation_rules="max:16|required"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <Label
                            label="使用開始日"
                            class="title-input-item"
                            :editable="editable"
                            required
                          >
                            <InputDatepicker
                              name="start_bookable_date"
                              :editable="editable"
                              :values="formValues"
                              validation_label="使用開始日"
                              :validation_rules="`required|compare-machine-used-start-date:${formValues.end_bookable_date}`"
                              :flagNull="true"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                      <v-col>
                        <v-list-item-content>
                          <Label
                            label="使用終了日"
                            class="title-input-item"
                            :editable="editable"
                          >
                            <InputDatepicker
                              name="end_bookable_date"
                              :editable="editable"
                              :values="formValues"
                              validation_label="使用終了日"
                              :validation_rules="`compare-machine-used-end-date:${formValues.start_bookable_date}`"
                              :flagNull="true"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <Label
                            label="商品名"
                            class="title-input-item"
                            :editable="editable"
                            required
                          >
                            <InputText
                              name="name"
                              :values="formValues"
                              :editable="editable"
                              validation_label="商品名"
                              validation_rules="max:255|required"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
<!--                    Begin 675-->
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <Label
                              label="メーカー"
                              class="title-input-item"
                              :editable="editable"
                              required
                          >
                            <InputText
                                name="maker"
                                :values="formValues"
                                :editable="editable"
                                validation_label="メーカー"
                                validation_rules="required|max:255"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                      <v-col>
                        <v-list-item-content>
                          <Label
                              label="型式"
                              class="title-input-item"
                              :editable="editable"
                              required
                          >
                            <InputText
                                name="model"
                                :values="formValues"
                                :editable="editable"
                                validation_label="型式"
                                validation_rules="required|max:16"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <Label
                              label="ナンバープレート"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <InputText
                                name="number_plate"
                                :values="formValues"
                                :editable="editable"
                                validation_label="ナンバープレート"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                      <v-col>
                        <v-list-item-content>
                          <Label
                              label="シリアル番号"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <InputText
                                name="ser"
                                :values="formValues"
                                :editable="editable"
                                validation_label="シリアル番号"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item-content>
                          <Label
                              label="Macアドレス"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <InputText
                                name="mac_id"
                                :values="formValues"
                                :editable="editable"
                                validation_label="Macアドレス"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mt-3">
                    <v-row v-if="windowWidth > 1024">
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                                label="床高（m）"
                                class="title-input-item"
                                :editable="editable"
                                required
                            >
                              <InputText
                                  name="floor_height"
                                  :values="formValues"
                                  :editable="editable"
                                  validation_label="床高（m）"
                                  validation_rules="required|decimal|decimalLimitOther"
                                  @onInput="onInput( $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth <= 1024">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                                label="床高（m）"
                                class="title-input-item"
                                :editable="editable"
                                required
                            >
                              <InputText
                                  name="floor_height"
                                  :values="formValues"
                                  :editable="editable"
                                  validation_label="床高（m）"
                                  validation_rules="required|decimal|decimalLimitOther"
                                  @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth > 1024">
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                              label="昇降"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <RadioGroup
                                name="lifting_method"
                                :values="formValues"
                                :items="LIFTING"
                                :editable="editable"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                              label="足回り"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <RadioGroup
                                name="undercarriage"
                                :values="formValues"
                                :items="UNDERCARRIAGE"
                                :editable="editable"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                              label="作業床"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <RadioGroup
                                name="working_floor"
                                :values="formValues"
                                :items="WORKING_FLOOR"
                                :editable="editable"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                              label="駆動"
                              class="title-input-item"
                              :editable="editable"
                          >
                            <RadioGroup
                                name="drive"
                                :values="formValues"
                                :items="DRIVE"
                                :editable="editable"
                                @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth <= 1024">
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <Label
                                label="昇降"
                                class="title-input-item"
                                :editable="editable"
                            >
                              <RadioGroup
                                  name="lifting_method"
                                  :values="formValues"
                                  :items="LIFTING"
                                  :editable="editable"
                                  @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <Label
                                label="足回り"
                                class="title-input-item"
                                :editable="editable"
                            >
                              <RadioGroup
                                  name="undercarriage"
                                  :values="formValues"
                                  :items="UNDERCARRIAGE"
                                  :editable="editable"
                                  @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <v-list-item-content>
                              <Label
                                  label="作業床"
                                  class="title-input-item"
                                  :editable="editable"
                              >
                                <RadioGroup
                                    name="working_floor"
                                    :values="formValues"
                                    :items="WORKING_FLOOR"
                                    :editable="editable"
                                    @onInput="onInput($event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <Label
                                label="駆動"
                                class="title-input-item"
                                :editable="editable"
                            >
                              <RadioGroup
                                  name="drive"
                                  :values="formValues"
                                  :items="DRIVE"
                                  :editable="editable"
                                  @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                                label="特殊機能"
                                class="title-input-item"
                                :editable="editable"
                            >
                              <InputTextArea
                                  name="special_feature"
                                  :values="formValues"
                                  :editable="editable"
                                  validation_label="特殊機能"
                                  @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!--                    End 675-->
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
  <script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import {  TABLE_LABELS,
          CREATE,
          DRIVE,
          LIFTING,
          UNDERCARRIAGE,
          WORKING_FLOOR,
} from "@/constants/MACHINE";
import _ from "lodash";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      selectItems: [],
      TABLE_LABELS,
      CREATE,
      DRIVE,
      LIFTING,
      UNDERCARRIAGE,
      WORKING_FLOOR,
      windowWidth: window.innerWidth
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    InputDatepicker,
    // Popup,
    RadioGroup,
    InputTextArea
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
    beforeUpdate: Object
  },
  async mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.actions,
      (data) => {
        if (data == CREATE) {
          const formValues = { ...this.formValues };
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.beforeUpdate,
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    initData(data) {
      if(data) {
        const formValues = data;
        this.formValues = {...formValues};
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
  