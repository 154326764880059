import { render, staticRenderFns } from "./NotificationDrawer.vue?vue&type=template&id=4419f565&scoped=true&"
import script from "./NotificationDrawer.vue?vue&type=script&lang=js&"
export * from "./NotificationDrawer.vue?vue&type=script&lang=js&"
import style0 from "./NotificationDrawer.vue?vue&type=style&index=0&id=4419f565&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4419f565",
  null
  
)

export default component.exports