<template>
    <v-card class="box-shadow-dashboard">
      <v-data-table
        :headers="TABLE_LABELS"
        :items="data"
        :items-per-page="data ? data.length : 0"
        :noDataText="NO_DATA"
        :loading="isLoading"
        width="100%"
        :height="`${matchHeight}px`"
        hide-default-header
        hide-default-footer
        fixed-header
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="h in headers" :style="`width: ${h.width}`">
                <span class="table-header">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, headers }">
          <tr>
            <td v-for="h in headers" class="data-text">
              <span v-if="!h.isSpec">
                {{ item[h.value] }}
              </span>
              <span v-else>{{ convertSpec(item) }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { NO_DATA } from "@/constants/COMMON";

const TABLE_LABELS = [
  { text: "機種", value: "spec", width: "60%", align: "left", isSpec: true },
  { text: "台数", value: "count_machine", width: "20%", align: "left" },
  { text: "遊休", value: "count_not_booking", width: "20%", align: "left" },
];
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
    matchHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      TABLE_LABELS,
      NO_DATA,
    };
  },
  async mounted() {},
  methods: {
    convertSpec({
      floor_height,
      lifting_method,
      undercarriage,
      working_floor,
    }) {
      return `${floor_height}m${lifting_method}${undercarriage}${working_floor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";
</style>
