<template>
  <div class="mobileError">
    <div class="errorTitle">AirLiza</div>
    <div class="errorMessage">
      スマートフォンをご利用の場合<br />アプリをダウンロードしてください。
    </div>
  </div>
</template>
<script>
export default {
  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.mobileError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
}
.errorTitle {
  text-align: center;
  font-size: 48px !important;
  font-weight: 600;
  color: blue;
  margin: 32px auto;
}
.errorMessage {
  padding: 32px;
  text-align: center;
  font-size: 16px !important;
}
</style>
