import Api, { Mock } from "../api";

const PATH = "machine_requests";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;
const ORDER_MACHINE_REQUEST_URL = `${URL}/order_machine_requests`;

if (Mock) {
  // TODO: Implement mock
}

export const machineRequests = {
  orderMachineRequests: (params) => {
    return Api.post(ORDER_MACHINE_REQUEST_URL, params);
  },
};
