<template>
  <div>
    <template>
      <MachineTab1 v-if="selectedTab == STOCK_MACHINE"/>
      <MachineTab2 v-if="selectedTab == SCHEDULED_DELIVERY_MACHINE"/>
      <MachineTab3 v-if="selectedTab == WORKING_MACHINE"/>
    </template>
  </div>
</template>

<script>
import MachineTab1 from "./components/MachineTab1"
import MachineTab2 from "./components/MachineTab2"
import MachineTab3 from "./components/MachineTab3"
import {Store} from "@/store/Store.js";

const STORE = "Machines";
const STOCK_MACHINE = 0;
const SCHEDULED_DELIVERY_MACHINE = 1;
const WORKING_MACHINE = 2;

export default {
  data() {
    return {
      selectedTab: 0,
      STOCK_MACHINE,
      SCHEDULED_DELIVERY_MACHINE,
      WORKING_MACHINE
    };
  },

  components: {
    MachineTab1,
    MachineTab2,
    MachineTab3,
  },

  mounted() {
    Store.dispatch("Companies/getDataBuilding");
    Store.dispatch("Fields/getListFieldNotFinish");

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.selectedTab = data;
        Store.dispatch(`${STORE}/resetData`);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  
  methods: {
    restoreTabSelect() {
      Store.dispatch(`${STORE}/restoreTabSelect`);
    }
  },
  
  destroyed() {
    this.restoreTabSelect()
  }
  
}
</script>
