<template>
  <div>
    <v-container class="container">
      <v-row no-gutters>
        <v-col cols="12" sm="9" md="9">
          <v-list-item>
            <v-list-item-content>安全管理</v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-list-item>
            <v-list-item-content>
              <v-icon @click.stop="showScheduleItem()">
                {{ isShowMenuScheduleItem ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <div v-if="isShowMenuScheduleItem">
        <v-main v-for="(item, index) in MENU_SAFETY_ITEMS" :key="index">
          <v-row no-gutters @click="onClickOpen(item.path)">
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-main>
      </div>
    </v-container>
    <v-container class="container">
      <v-row no-gutters>
        <v-col cols="12" sm="9" md="9">
          <v-list-item>
            <v-list-item-content>マスター</v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-list-item>
            <v-list-item-content>
              <v-icon @click.stop="showMenuMasterItem()">
                {{ isShowMenuMasterItem ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <div v-if="isShowMenuMasterItem">
        <v-main v-for="(item, index) in MENU_MASTER_ITEMS" :key="index">
          <v-row no-gutters @click="onClickOpen(item.path)">
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-main>
      </div>
    </v-container>
    <v-container class="container">
      <v-row no-gutters>
        <v-col cols="12" sm="9" md="9">
          <v-list-item>
            <v-list-item-content>書類管理</v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-list-item>
            <v-list-item-content>
              <v-icon @click.stop="showFieldDocumentItem()">
                {{ isShowFieldDocumentItem ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <div v-if="isShowFieldDocumentItem">
        <v-main v-for="(item, index) in MENU_DOCUMENT_ITEMS" :key="index">
          <v-row no-gutters @click="onClickOpen(item.path)">
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-main>
      </div>
    </v-container>
    
  </div>
</template>
<script>
import { MENU_SAFETY_ITEMS, MENU_MASTER_ITEMS, MENU_DOCUMENT_ITEMS } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      isShowMenuScheduleItem: false,
      MENU_MASTER_ITEMS: MENU_MASTER_ITEMS,
      MENU_SAFETY_ITEMS: MENU_SAFETY_ITEMS,
      MENU_DOCUMENT_ITEMS: MENU_DOCUMENT_ITEMS,
      isShowMenuMasterItem: false,
      isShowFieldDocumentItem: false
    };
  },
  methods: {
    //画面遷移
    onClickOpen(url) {
      this.$emit("onClickOpen", url);
    },
    showScheduleItem() {
      this.isShowMenuScheduleItem = !this.isShowMenuScheduleItem;
    },
    showMenuMasterItem() {
      this.isShowMenuMasterItem = !this.isShowMenuMasterItem;
    },
    showFieldDocumentItem() {
      this.isShowFieldDocumentItem = !this.isShowFieldDocumentItem;
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  border-top: 1px solid #E5E5E5;
}
</style>
