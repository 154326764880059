<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title" style="color: #FF8560">{{ title }}</div>
      <v-spacer></v-spacer>
      <v-btn small icon text @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap" v-if="text.replaceAll('\\n', '\n').split('\n').length">
        <div class="text text-left" v-for="item in text.replaceAll('\\n', '\n').split('\n')">{{ item }}</div>
      </div>
      <div class="text-wrap" v-else>
        <div class="text">{{ text }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="btn" color="warning" @click="$emit('yes')">
        {{ textSubmit }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
    },
    textClose: {
      default: 'いいえ',
      type: String,
    },
    textSubmit: {
      default: 'はい',
      type: String,
    }
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
