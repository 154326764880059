<template>
    <div class="inspection-page">
    <DefaultLayout>
        <template #mainHeader>
          <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" :flagSupervisor="flagSupervisor" />
        </template>
        <template #page="{ layoutParams }">
        <TableLayout
          class="custom-color layout-inspection-container"
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
          ref="myTableLayout"
        >
            <template #tableHeader="{ updateHeader }">
            <InspectionHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :updateHeader="updateHeader"
                :searchParams="searchParams"
                :isAddNew="false"
                :isDelete="false"
                :selectedTab="selectedTab"
                @onInput="onChangeSortParams"
            >
              <ValidationObserver v-slot="{ invalid }" ref="observer">
                <SearchFormWrapper>
                  <div class="d-flex">
                    <div class="mr-2">
                      <LabelSearchMachine class="title-input-item custom-input-date" label="点検月" :editable="true">
                        <InputMonthpicker
                            name="tenken_date_from"
                            :editable="true"
                            :values="searchParams"
                            @onInput="onChangeSearchParams"
                            :validation_rules="getRuleStartDate()"
                            validation_label="点検月"
                            :flagNull="true"
                            ref="tenken_date_from"
                        />
                      </LabelSearchMachine>
                    </div>
                    <span class="mt-12 ml-3">
                    <v-icon>mdi-tilde</v-icon>
                  </span>
                    <div class="mr-2">
                      <LabelSearchMachine class="title-input-item custom-input-date" label="点検月" :editable="true">
                        <InputMonthpicker
                            name="tenken_date_to"
                            :editable="true"
                            :values="searchParams"
                            @onInput="onChangeSearchParams"
                            :validation_rules="getRuleEndDate()"
                            validation_label="点検月"
                            :flagNull="true"
                            ref="tenken_date_to"
                        />
                      </LabelSearchMachine>
                    </div>

                    <div class="mr-2">
                      <LabelSearchMachine class="title-input-item" label="ナンバー" :editable="true">
                        <InputText
                            name="number_plate"
                            :editable="true"
                            :values="searchParams"
                            :outlined="true"
                            @onInput="onChangeSearchParams"
                        />
                      </LabelSearchMachine>
                    </div>
                  </div>
                  <div class="d-flex">
                    <v-switch
                        class="mt-12 mr-2 custom-switch-label"
                        v-model="searchParams.flag_not_approved"
                        :label="`未承認のみ表示`"
                    ></v-switch>
                    <v-switch
                        class="mt-12 custom-switch-label"
                        v-model="searchParams.flag_result_error"
                        :label="`異常報告のみ表示`"
                    ></v-switch>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn class="mr-6 btn-search" color="primary" depressed @click="onSearch" :disabled="invalid">
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </ValidationObserver>
            </InspectionHeader>
            <!-- 
                (共通)
                ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <div class="tableSortWrapper ml-2">
                <v-spacer></v-spacer>
                <div class="sortLabel" >表示件数:</div>
                <div class="sortElement" >
                <v-select
                  v-model="filterParams.page_size"
                  dense
                  :items="PAGE_COUNT_OPTIONS"
                  @change="changeFilter"
                ></v-select>
                </div>
                <div class="sortLabel">総件数:</div>
                <div class="sortElement total_item">{{ paramsPagination?.total_item || 0 }} 件</div>
            </div>
            <v-row class="cst-row ml-2">
              <v-col cols="12" :sm="8" :md="8" class="cst-col">
                <!-- タブ -->
                <v-tabs v-model="selectedTab">
                  <v-tab class="tab-item" v-for="item in TAB_SELECT" :key="item.id">
                    {{ item.name }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            </template>
            <template #tableBody="{ tableHeight }" ref="contentTable">
              <template>
                <div>
                  <InspectionList
                    :tableHeight="paramsPagination?.total > 1 ? tableHeight - 45 : tableHeight"
                    :LIST_DATA_INSPECTION="LIST_DATA_INSPECTION"
                    :loading="loading"
                    :selectedTab="selectedTab"
                    :getItems="getItems"
                    :page="paramsPagination?.current"
                  />
                </div>
              </template>
            </template>
            <template #tableFooter v-if="paramsPagination?.total > 1">
            <!-- (共通) ページネーション -->
            <Pagination
                :current="paramsPagination?.current"
                :total="paramsPagination?.total"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;"
            />
            </template>
        </TableLayout>
        </template>
    </DefaultLayout>

    </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import InspectionHeader from "./components/InspectionHeader";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import {
    TABLES_PER_PAGE,
    NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import InputTextAreaLabel from "@/components/forms/elements/InputTextAreaLabel";

import {
    INSPECTION_TAB_SELECT,
    INSPECTION_STATUS,
    IS_FLG,
    NOT_FLG,
} from "@/constants/INSPECTION"
import { HEADER_MENU_ITEMS_FIELD_PORTAL, HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import InspectionList from "./components/InspectionList.vue";

import { format, parseISO } from "date-fns";
import moment from "moment";
import LabelSearchMachine from "../../components/forms/elements/LabelSearchMachine.vue";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import { ValidationObserver } from "vee-validate";

//タイトル
const PAGE_TITLE = "点検実績";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      flagUserRentalCompany: false,
      flagSupervisor: false,
      // 検索パラメータ
      isDateStart: false,
      isDateEnd: false,
      searchParams: {
        field_id: null,
        tenken_date_from: moment().startOf('month').format('YYYY-MM-DD'),
        tenken_date_to: moment().endOf('month').format('YYYY-MM-DD'),
        inspection_frequency: 0,
        number_plate: null,
        flag_not_approved: 0,
        flag_result_error: 0,
        // pageCount: PAGE_COUNT,
        page: 1,
        page_size: PAGE_COUNT
      },
      filterParams: {
        tenken_date_from: moment().startOf('month').format('YYYY-MM-DD'),
        tenken_date_to: moment().endOf('month').format('YYYY-MM-DD'),
        inspection_frequency: 0,
        page: 1,
        page_size: PAGE_COUNT
      },
      INSPECTION_TAB_SELECT,
      selectedTab: 0,
      paramsSearch: null,
      searchLasted: {},
      paramsPagination: {},
      fieldId: null,
      LIST_DATA_INSPECTION: [],
      loading: true
    };
  },
  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    InputText,
    Select,
    TabSelect,
    Label,
    InputTextAreaLabel,
    InspectionHeader,
    InspectionList,
    LabelSearchMachine,
    InputMonthpicker,
    ValidationObserver
  },

  async mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

     Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.INSPECTION_RESULTS.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.currentSite = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));

    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_SUPERVISOR) {
          this.flagSupervisor = true;
        } else if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */

  computed: {
    computedDateStartFormatted() {
      return this.searchParams.tenken_date_from ? format(parseISO(this.searchParams.tenken_date_from), "yyyy/MM") : "";
    },
    computedDateEndFormatted() {
      return this.searchParams.tenken_date_to ? format(parseISO(this.searchParams.tenken_date_to), "yyyy/MM") : "";
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
    INSPECTION_STATUS() {
      return Object.keys(INSPECTION_STATUS).map((key) => {
          return INSPECTION_STATUS[key];
      });
    },
    TAB_SELECT() {
      return Object.keys(INSPECTION_TAB_SELECT).map((key) => {
          return INSPECTION_TAB_SELECT[key];
      });
    },
  },
  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.searchParams.field_id = this.fieldId;
    this.searchLasted = this.searchParams;
    this.getItems();
  },
  watch: {
    selectedTab() {
      this.loading = true;
      this.filterParams.page_size = PAGE_COUNT_OPTIONS[0];
      this.searchParams = { ...this.filterParams, field_id: this.fieldId }
      this.searchLasted = this.searchParams;
      this.LIST_DATA_INSPECTION = [];
      this.getItems();
    }
  },

  methods: {
    clearDateStart() {
      this.isDateStart = false;
      this.searchParams.tenken_date_from = "";
    },
    clearDateEnd() {
      this.isDateEnd = false;
      this.searchParams.tenken_date_to = "";
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let paramsPagination = { ...this.paramsPagination };
      paramsPagination.current = n;
      this.paramsPagination = paramsPagination;
      const dataPagination = {
        "field_id": this.fieldId,
        "page": paramsPagination.current,
        "page_size": this.filterParams.page_size
      }
      const paramsSearch = this.paramsSearch;
      this.paramsSearch = {
        ...paramsSearch,
        ...dataPagination
      };
      let searchParams = { ...this.searchParams,  ...dataPagination};
      searchParams.page = n;
      this.searchLasted = searchParams;
      this.getItems();
    },
    onSearch() {
      this.searchParams.page = 1;
      this.searchLasted = this.searchParams;
      this.getItems();
    },
    
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    async onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayout.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["page"] = 1) : "";
      this.searchParams = searchParams;
    },

    async getItems() {
      let params = { ...this.searchLasted };
      params.tenken_date_from = params.tenken_date_from ? moment(params.tenken_date_from, "YYYY-MM").startOf("month").format("YYYY-MM-DD") : moment().startOf("month").format("YYYY-MM-DD");
      params.tenken_date_to = params.tenken_date_to ? moment(params.tenken_date_to, "YYYY-MM").endOf("month").format("YYYY-MM-DD") : moment().endOf("month").format("YYYY-MM-DD");
      if (typeof params.flag_not_approved == "boolean") {
        params.flag_not_approved = params.flag_not_approved === true ? IS_FLG : NOT_FLG;
      }
      if (typeof params.flag_result_error == "boolean") {
        params.flag_result_error = params.flag_result_error === true ? IS_FLG : NOT_FLG;
      }
      params.inspection_frequency = this.selectedTab;
      const response = await Store.dispatch('Inspection/getListInspectionResults', params);
      if (!response.hasError) {
        this.loading = false;
        this.LIST_DATA_INSPECTION = response.data.contents.entries;
        this.paramsPagination = response.data.contents.pagination;
      }
    },

    getListDataSearch(objSearch) {
      const filterParams = this.filterParams;
      this.paramsSearch = {
        ...objSearch,
        ...filterParams
      };
    },

    getPagination(data) {
      this.paramsPagination = data;
    },
    
    changeFilter() {
      const filterParams = {
        "field_id": this.fieldId,
        'page': this.filterParams.page,
        'page_size': this.filterParams.page_size,
      };
      const paramsSearch = this.paramsSearch;
      this.paramsSearch = {
        ...paramsSearch,
        ...filterParams
      };
      const searchParams = this.searchLasted;
      this.searchParams = {
        ...searchParams,
        ...filterParams
      };
      this.searchParams["page"] = 1;
      this.searchLasted = this.searchParams;
      this.getItems();
    },

    getRuleStartDate() {
      let rule = "";
      if (this.searchParams?.tenken_date_from && this.searchParams?.tenken_date_to) {
        let dateEnd = this.searchParams?.tenken_date_to;
        dateEnd = moment(new Date(dateEnd)).format("YYYY/MM");
        rule = `err-start-date:${dateEnd},点検月（左）, 点検月（右）`;
      }
      return (
          rule
      );
    },

    getRuleEndDate() {
      let rule = "";
      if (this.searchParams?.tenken_date_from && this.searchParams?.tenken_date_to) {
        let dateEnd = this.searchParams?.tenken_date_from;
        dateEnd = moment(new Date(dateEnd)).format("YYYY/MM");
        rule = `err-end-date:${dateEnd},点検月（右）,点検月（左）`;
      }
      return (
          rule
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.inspection-page {
  ::v-deep .SearchFormWrapper {
    flex-wrap: wrap;
    position: relative;

    .btn-search {
      margin-top: 32px;
    }

    .custom-input-date {
      max-width: 250px !important;
      width: 250px !important;
    }

    .title-input-item .label {
      color: #000000 !important;
    }

    .custom-switch-label .v-label {
      color: #000000 !important;
    }
  }
  @media (max-width: 1061px) {
    ::v-deep .SearchFormWrapper {

      .v-input--switch {
        margin-top: 0 !important;
      }
      .btn-search {
        margin-top: -16px;
      }
    }
  }
  @media (max-width: 1024px) {
    ::v-deep .SearchFormWrapper {

      .v-input--switch {
        margin-top: 16px !important;
      }
      .btn-search {
        margin-top: 0;
      }
    }
  }

  .input-date {
    border-radius: 2px;
    width: 166px;
    height: 52px;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    flex: none;
  }
  .headline {
    width: 50px;
    line-height: 54px !important;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .tab-select-header {
    font-size: 12px !important;
    height: 56px !important;
  }
  .tableSortWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
    height: 32px;
    ::v-deep .v-select__selection--comma {
      color: rgba(0, 0, 0, 0.87);
    }
    .sortLabel {
      font-size: 12px;
      height: 32px;
      margin-right: 16px;
    }
    .sortElement {
      max-width: 128px;
      margin-right: 24px;
    }
    .orderTitle {
      width: 298px;
      margin-right: 12px;
    }
    .orderSort {
      width: 128px;
      margin-right: 12px;
    }
    .total_item {
      padding-bottom: 15px;
    }
    .cst-row {
      border-bottom: 1px solid;
      padding-top: 12px;
      border-color: #e0e0e0;
    }

    .cst-col {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .header-filter {
      width: 200px;
    }
    .tabLabel[data-v-2f7ce329] {
      height: 30px;
      font-size: 14px !important;
      padding: 4px 8px;
      background-color: #f8f8f8;
      color: rgba(0, 0, 0, 0.38);
    }
    .tab-item {
      max-width: 120px !important;
    }
  }
  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding: 0;
    text-align: center !important;
  }
  
  ::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:first-child {
    padding: 0;
    width: 70px !important;
    text-align: center !important;
  }
  
  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
    padding-left: 0;
  }
  ::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
    padding-left: 0 !important;
  }
}
</style>
