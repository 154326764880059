<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text }}</div>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="text">
        <span class="item" v-for="item in items" :key="item.id">
          {{item.machine_name ?? item.name_machine}}</span
        >
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="mx-2" @click="$emit('close')"> やめる </v-btn>
      <v-btn depressed class="mx-2" :color="getColor" @click="$emit('yes')">
        削除
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
    items: {
      type: Array,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
  .item {
    &:after {
      content: ",";
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
</style>
