<template>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
        <div class="search-plan">
            <SearchFormWrapper>
                <div class="mr-2">
                    <Label label="搬入予定日" :editable="true" class="title-input-item" style="max-width: 150px">
                        <InputDatepicker name="scheduled_delivery_date_from" :editable="true" :values="searchParams"
                            @onInput="onChangeSearchParams" :flagNull="true" :validation_rules="getRuleStartDate()" />
                    </Label>
                </div>
                <span class="mt-12 ml-3">
                    <v-icon>mdi-tilde</v-icon>
                </span>
                <div class="mr-2">
                    <Label label="搬入予定日" :editable="true" class="title-input-item" style="max-width: 150px">
                        <InputDatepicker name="scheduled_delivery_date_to" :editable="true" :values="searchParams"
                            @onInput="onChangeSearchParams" :flagNull="true" :validation_rules="getRuleEndDate()" />
                    </Label>
                </div>
                <div class="mr-2">
                    <Label label="レンタル会社" :editable="true" class="title-input-item" style="max-width: 200px">
                        <Select name="company_renta_id" :items="LIST_COMPANY" :editable="true" :values="searchParams"
                            :single_line="false" :outlined="true" @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <div class="mr-2">
                    <Label label="搬入ゲート" :editable="true" class="title-input-item" style="max-width: 200px">
                        <Select name="gate_id" :items="LIST_GATE" :editable="true" :single_line="false"
                            :values="searchParams" :outlined="true" @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <div class="mr-2">
                    <Label label="ナンバー" :editable="true" class="title-input-item" style="max-width: 150px">
                        <InputText name="number_plate" :editable="true" :values="searchParams" :outlined="true"
                            @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <div class="tab-select-header">
                    <Label label="昇降" class="title-input-item">
                        <TabSelect name="lifting_method" :items="LIFTING_METHOD" :editable="true" :values="searchParams"
                            @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <div class="tab-select-header">
                    <Label label="足回り" class="title-input-item">
                        <TabSelect name="undercarriage" :items="UNDERCARRIAGE" :editable="true" :values="searchParams"
                            @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <div class="tab-select-header">
                    <Label label="作業床" class="title-input-item">
                        <TabSelect name="working_floor" :items="WORKING_FLOOR" :editable="true" :values="searchParams"
                            @onInput="onChangeSearchParams" />
                    </Label>
                </div>
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-10" color="primary" @click="onSearchPlan" :disabled="invalid">
                    検索
                </v-btn>
            </SearchFormWrapper>
        </div>
    </ValidationObserver>
</template>
<script>
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TabSelect from "@/components/forms/elements/TabSelect";
import Select from "@/components/forms/elements/Select";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import {
    LIFTING_METHOD,
    UNDERCARRIAGE,
    WORKING_FLOOR
} from "@/constants/FIELD_MACHINE"
import Label from "../../../../components/forms/elements/Label.vue";
import { Store } from "@/store/Store.js";
import InputText from "../../../../components/forms/elements/InputText.vue";
import { ValidationObserver } from "vee-validate";
export default {
    components: {
        SearchFormWrapper,
        TabSelect,
        Select,
        Label,
        InputText,
        InputDatepicker,
        ValidationObserver
    },
    data() {
        return {
            searchParams: {
                field_id: null,
                scheduled_delivery_date_from: null,
                scheduled_delivery_date_to: null,
                company_renta_id: null,
                gate_id: null,
                number_plate: null,
                lifting_method: null,
                undercarriage: null,
                working_floor: null,
                sort_value: null,
                sort_by: "DESC",
                page: 1,
                page_size: 25
            },
            LIST_COMPANY: [],
            LIST_GATE: [],
            ROLE: 4,
        }
    },
    computed: {
        LIFTING_METHOD() {
            return Object.keys(LIFTING_METHOD).map((key) => {
                return LIFTING_METHOD[key];
            });
        },
        UNDERCARRIAGE() {
            return Object.keys(UNDERCARRIAGE).map((key) => {
                return UNDERCARRIAGE[key];
            });
        },
        WORKING_FLOOR() {
            return Object.keys(WORKING_FLOOR).map((key) => {
                return WORKING_FLOOR[key];
            });
        },
    },
    created() {
        this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
        this.roleId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
        this.searchParams.field_id = this.fieldId;
        this.getListCompany();
        this.getListGate();
    },
    methods: {
        async getListCompany() {
            const params = {
                "field_id": this.fieldId,
                "role": this.ROLE
            };
            const response = await Store.dispatch(`Machine/getListFieldCompanyByRole`, params);
            if (!response.hasError) {
                let _data = [...response.data.contents.entries];
                this.LIST_COMPANY = [{ id: null, name: "" }, ..._data];
            }
        },
        async getListGate() {
            const params = {
                "field_id": this.fieldId,
            };
            const response = await Store.dispatch(`Machine/getListGateByFieldId`, params);
            if (!response.hasError) {
                let _data = [...response.data.contents.entries];
                this.LIST_GATE = [{ id: null, name: "" }, ..._data];
            }
        },
        async onChangeSearchParams({ name, value }) {
            let searchParams = { ...this.searchParams };
            searchParams[name] = value;
            this.searchParams = searchParams;
            this.$emit("has-error");
        },
        onSearchPlan() {
            this.$emit("search-tab-plan", this.searchParams);
            return
        },
        getRuleStartDate() {
            let rule = "";
            if (this.searchParams?.scheduled_delivery_date_to) {
                const dateTo = this.searchParams?.scheduled_delivery_date_to;
                rule = `err-start-date:${dateTo},搬入予定日（左）, 搬入予定日（右）`;
            }
            return rule;
        },
        getRuleEndDate() {
            let rule = "";
            if (this.searchParams?.scheduled_delivery_date_from) {
                const dateFrom = this.searchParams?.scheduled_delivery_date_from;
                rule = `err-end-date:${dateFrom},搬入予定日（右）,搬入予定日（左）`;
            }
            return rule;
        },
    }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
    .search-plan {
        ::v-deep .SearchFormWrapper {
            flex-wrap: wrap;
            position: relative;
        }

        .tab-select-header {
            font-size: 12px !important;
            color: #000000;
            font-weight: 400;
            height: 56px;
        }

        @media screen and (max-width: 1024px) {
            margin-bottom: 8px;
        }
    }

    .title-input-item .label {
        color: #000000 !important;
    }
}
</style>