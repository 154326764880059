<template>
  <div class="tableSortWrapper">
    <v-spacer></v-spacer>
    <div class="sortLabel">総件数:</div>
    <div class="sortElement total_item">{{ total_item }}件</div>
  </div>
</template>


<script>

export default {
  data() {
    return {
    };
  },
  props: {
    total_item: {
      type: Number,
    },
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  .sortLabel {
    font-size: 12px;
    margin-right: 5px;
  }
  .sortElement {
    max-width: 128px;
    margin-right: 24px;
    color: #000000;
  }
  .total_item {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
  }
}
</style>
