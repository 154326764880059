<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text1 }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ text2 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined class="btn-no" @click="$emit('yes')"> 閉じる </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    title: {
      type: String,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#274FC2;" : "";
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
.btn-yes {
  color: #ffffff !important;
}
.btn-no {
  color: #274FC2 !important;
  border-color: #274FC2;
}
.title {
  color: #274FC2;
}
</style>
