<template>
    <div ref="self">
    <!-- PCメニュー -->
        <v-toolbar v-if="!$sp" flat>
            <v-toolbar-title class="worksHeader-title primary--text">
            {{ pageTitle }}
            </v-toolbar-title>
            <div class="flex-row">
                <div class="inputDatepicker ml-5 mt-5" style="min-width: 150px">
                <InputDatepicker
                    v-if="typePicker == 'date'"
                    name="designated_date"
                    :editable="true"
                    :values="searchParams"
                    filled
                    id="designated_date"
                    @onInput="onChangeSearchParams"
                />
                <InputMonthpicker
                    v-else-if="typePicker == 'month'"
                    name="designated_date"
                    :editable="true"
                    :values="searchParams"
                    filled
                    id="designated_date"
                    @onInput="onChangeSearchParams"
                />
                </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" small icon @click="toggleSearch">
            <v-icon> mdi-magnify </v-icon>
            </v-btn>
        </v-toolbar>
        <div v-show="isShowSearch">
            <slot />
        </div>
    </div>
</template>
<script>
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker";
import { Store } from "@/store/Store.js";
export default {
    data: () => {
    return {
        isShowSearch: false,
    };
    },
    props: {
    pageTitle: {
        type: String,
    },
    multiSelectStatus: {
        type: Boolean,
        default: false,
    },
    updateHeader: {
        type: Object,
    },
    //検索パラメータ
    searchParams: {
        type: Object,
    },
    isDirector: {
        type: Boolean,
        default: false,
    },
    typePicker: {
        type: String,
        default: 'date',
    },
    },
    components: {
        InputDatepicker,
        InputMonthpicker,
    },

    mounted() {
        setTimeout(() => {
            this.updateHeaderHeight();
        }, 200);

    //検索フォームの開閉
        this.$watch(
            () => Store.getters["ScheduleSearch/getActive"],
                (value) => {
                this.isShowSearch = value;
                this.$nextTick(() => {
                    this.updateHeaderHeight();
                });
            },
            {
                immediate: true,
                deep: true,
            }
        );
    },

    computed: {
    },

    methods: {
    //検索パラメータを更新
    onChangeSearchParams({ name, value }) {
        this.$emit("onInput", { name, value });
    },
    toggleSearch() {
      //検索フォームを開く
        this.isShowSearch = !this.isShowSearch;
        this.$nextTick(() => {
            this.updateHeaderHeight();
        });
    },
    closeSearch() {
        this.isShowSearch = false;
        this.$nextTick(() => {
            this.updateHeaderHeight();
        });
    },
    updateHeaderHeight() {
        const height = this.$refs.self?.getBoundingClientRect().height;
        this.updateHeader.setHeaderHeight(height);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate(params) {
      const { valid } = params;
      this.valid = valid;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
    margin-left: 0px;
    margin-right: 0px;
}
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    padding: 0 8px;
    box-sizing: border-box;
}

.footer__btn {
    .footer__btn-icon {
        fill: $color_warning;
    }
    &.enable {
        .footer__btn-icon {
            fill: rgba(0, 0, 0, 0.26);
        }
    }
}

.label {
    font-size: 8px;
}

.v-toolbar__title.worksHeader-title {
    font-size: 24px !important;
    font-weight: 600 !important;
}

.textBold {
    font-size: 24px !important;
    font-weight: bold !important;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
</style>
