<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div
        text
        color="primary"
        v-bind="attrs"
        @click="close"
        v-on="on"
        class="header_dropdown_btn"
      >
        {{ label }}
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="item in menu"
        v-show="item.visibility"
        :key="item.id"
        @click="onClickOpen(item.path)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { Store } from "@/store/Store.js";
export default {
  props: {
    label: {
      type: String,
    },
    menu: {
      type: Array,
    },
  },
  methods: {
    onClickOpen(url) {
      this.$router.push(url);
    },
    close() {
      Store.dispatch("Drawer/close");
    },
  },
};
</script>
<style lang="scss" scoped>
.header_dropdown_btn {
  padding: 0 !important;
}
</style>
