<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <Label label="Name" :editable="editable" required>
                          <InputText
                            name="name"
                            :values="formValues"
                            :editable="editable"
                            placeholder="Enter name"
                            validation_label="発注者名"
                            validation_rules="required|max:255"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-list-item>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="Phone" :editable="editable">
                              <InputText
                                name="phone"
                                :values="formValues"
                                :editable="editable"
                                placeholder="0333 032 794"
                                validation_label="Enter phone"
                                validation_rules="max:15"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="Sex" :editable="editable">
                              <Select
                                name="sex"
                                :values="formValues"
                                :items="sexs"
                                item_text="prefecture"
                                :editable="editable"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="12">
                            <Label label="Description" :editable="editable">
                              <InputText
                                name="description"
                                :values="formValues"
                                :editable="editable"
                                validation_label="備考"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="Age" :editable="editable">
                              <InputText
                                name="age"
                                :values="formValues"
                                :editable="editable"
                                validation_label="Enter age"
                                validation_rules="max:15"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import {CRUD_TITLE} from "@/constants/CRUDS";
import FileInput from "@/components/forms/elements/FileInput";

export default {
  data: () => {
    return {
      formValues: {},
      description: {},
      company_branches: [],
      sexs: [
        { id: null, prefecture: "Choose Sex" },
        { id: 1, prefecture: "Male" },
        { id: 2, prefecture: "Female" },
        { id: 0, prefecture: "None" },
      ],
      resetValue: {},
      CRUD_TITLE
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
  },
  props: {
    item: Object,
    companyBranches: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [this.item],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    }
  },
  methods: {
    initData(data) {
      const formValues = data[0];
      this.formValues = { ...formValues };
      console.log(this.formValues)
    },
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (parent_name) {
        formValues[parent_name][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePhone({ value }) {
      const formValues = { ...this.formValues };
      formValues.phone = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getFileInputStyle(fieldName) {
      console.log('this.formValues', this.formValues, fieldName)
      return !this.editable
        ? "display:none;"
        : "";
    },
    judgeDisplayFileInput(fieldName) {
      return (
        this.editable &&
        this.fileValid && this.fileValid[fieldName] &&
        this.formValues && this.formValues[fieldName]
      );
    },
    onRemoveFile({ name }) {
      const formValues = { ...this.formValues };
      delete formValues.fields[name];
      delete formValues.fields[`${name}_data`];
      if (formValues.fields[`${name}_url`])
        formValues.fields[`${name}_deleted`] = 1;
      this.formValues = formValues;
      this.resetValue[name] = !this.resetValue[name];
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
