<template>
  <v-card class="dialog">
    <form ref="form">
      <input
        style="display: none"
        ref="input_drawing_file"
        type="file"
        @change="onSelectFile()"
      />
    </form>
    <v-card-title>
      <div class="title ml-4">ファイルアップロード</div>
    </v-card-title>
    <ValidationObserver ref="observer" v-slot="observer">
      <v-card-text class="body">
        <v-list-item>
          <v-list-item-content>
            <Label label="タイトル" :editable="true" required>
              <InputText
                name="name"
                :values="itemAdd"
                :editable="true"
                validation_label="タイトル"
                validation_rules="required|max:255"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col sm="1" md="1">
                <v-icon class="mt-10" color="green" @click="onUploadFile()">mdi-paperclip</v-icon>
              </v-col>
              <v-col sm="11" md="11">
                <Label label="ファイル" :editable="true" required>
                  <span @click="onUploadFile()">
                  <InputText
                    name="document"
                    :values="itemAdd"
                    :editable="true"
                    validation_label="ファイル"
                    validation_rules="required"
                    @onInput="onInput"
                  />
                  </span>
                </Label>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <ValidationCallback :observer="observer" @callback="updateValidate" />
    </ValidationObserver>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined color="primary" class="btn" @click="$emit('close')">
        やめる
      </v-btn>
      <v-btn depressed class="btn" color="primary" @click.once="handleConfirm" :disabled="!valid">
        送信
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Label from "@/components/forms/elements/Label.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../../elements/ValidationCallback.vue";

export default {
  data() {
    return {
      itemAdd: {
        name: "",
        document: "",
        document_data: null,
        status : true
      },
      valid: null
    };
  },
  components: {
    InputText,
    InputTextArea,
    Label,
    ValidationObserver,
    ValidationCallback
  },
  props: {},
  methods: {
    updateValidate({ valid }) {
      this.valid = valid;
    },
    handleConfirm() {
      this.$emit("yes", this.itemAdd);
    },
    onInput({ name, value }) {
      const _itemAdd = { ...this.itemAdd };
      if (name == "document") {
        _itemAdd[name] = "";
        this.onUploadFile();
      } else {
        _itemAdd[name] = value;
      }
      this.itemAdd = _itemAdd;
    },
    onUploadFile() {
      this.$refs.input_drawing_file.click();
    },
    onSelectFile() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      this.setFile(file);
    },
    setFile(file) {
      if (file.size >= 10 * 1024 * 1024) {
        Store.dispatch(
          "Error/show",
          {
            status: "400",
            message: "アップロードのファイルは、最大10MBまでです。"
          },
          { root: true }
        );
        return;
      }
      this.getBase64(file).then((dataBase64) => {
        this.itemAdd.document = file.name;
        this.itemAdd.document_data = dataBase64;
      });
    },

    // Base64変換
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  color: green;
  font-size: 20px !important;
}
</style>