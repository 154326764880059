<template>
  <div class="FormMain">
    <div ref="mainBody" class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-container>
          <v-row no-gutters>
            <v-col
              sm="3"
              md="4"
              :style="`overflow: auto;height:${mainHeight - 100}px`"
            >
              <!-- 履歴 -->
              <div class="mt-3" v-for="(item, index) in histories" :key="index">
                <div v-if="item.is_delete !== true" class="historyItem mt-3">
                  <div
                    class="col_name"
                    style="cursor: pointer"
                    @click="clickFilePdf(item)"
                  >
                    <span
                      :style="`color:blue;font-size:14px;${
                       (!!currentPdf.id ? item.id == currentPdf.id : (item.key == currentPdf.key))
                          ? 'text-decoration: underline'
                          : ''
                      }`"
                      class="historyItem__date"
                    >
                      {{ item.file_name }}
                    </span>
                  </div>
                  <div class="">
                    <span class="historyItem__date">
                      {{ dateFormat(item.created_at) }}
                    </span>
                  </div>
                  <!-- download file -->
                  <div
                    class="ml-2"
                    @click="downloadDocument(item)"
                    style="cursor: pointer"
                  >
                    <v-icon :disabled="isSubmitted">mdi-download</v-icon>
                  </div>
                  <!-- delete file -->
                  <div
                    class="ml-2"
                    @click="deleteItem(item)"
                    style="cursor: pointer"
                  >
                    <v-icon v-if="editable" :disabled="isSubmitted"
                      >mdi-close</v-icon
                    >
                  </div>
                </div>
                <div
                  class="mt-3"
                  v-for="(
                    element, index
                  ) in item.machine_company_field_estimate_order_report_comments ||
                  []"
                  :key="index"
                >
                  <span class="historyItem__date ml-4">{{
                    element.created_at
                  }}</span>
                  <v-icon
                    class="ml-5"
                    @click="$emit('showMessage', { comment_id: element.id })"
                    >mdi-message-text-outline</v-icon
                  >
                </div>
              </div>
            </v-col>
            <v-col sm="9" md="8" class="pl-3 text-caption">
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="items && items.length > 0"
              >
                <div class="pdfNavigation__pages">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                ref="pdfNavigation"
                class="pdfNavigation"
                v-if="!items || items.length == 0 || !histories.length"
              >
                <div v-if="!editable">
                  {{ NO_DATA_MESSAGE }}
                </div>
              </div>
              <div
                ref="pdfViewer"
                class="pdfViewer"
                :style="pdfPage.style"
              >
                <v-card outlined tile v-if="url_view && histories.length">
                  <!-- PDF -->
                  <pdf
                    :src="url_view"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="popups.isShowRemoveDialog = false"
        @yes="deleteFile()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :item="history.item"
        warning
      />
    </Popup>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { Store } from "@/store/Store.js";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import ConfirmRemoveDialog from "../popup/ConfirmRemoveDialog";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ

export default {
  data: () => {
    return {
      NO_DATA_MESSAGE,
      pdfPage: {
        pageCount: 0,
        currentPage: 1,
        selectPage: 1,
        style: "",
      },
      isSubmitted: false,
      timeout: null,
      histories: [],
      currentPdf: {},
      url_view: null,
      fileUploads: [],
      history: {
        item: {},
      },
      popups: {
        isShowRemoveDialog: false,
      },
    };
  },
  components: {
    pdf,
    ConfirmRemoveDialog,
    Popup,
  },
  props: {
    mainHeight: {
      type: Number,
    },
    items: {
      type: Array,
    },
    deleteDocument: Function,
    editable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$watch(
      () => this.mainHeight,
      () => {
        this.updatePdfViewerHeight();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.items,
      async (data) => {
        if (Array.isArray(data)) {
          this.histories = [...data];
          if (this.histories.length > 0) {
            this.currentPdf = this.histories[0];
            this.url_view = "";
            await this.getUrlView(this.currentPdf);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight - 70}px;`;
      return style;
    },
  },
  methods: {
    async clickFilePdf(item) {
      this.pdfPage.currentPage = 1;
      this.pdfPage.selectPage = 1;
      this.currentPdf = item;
      await this.getUrlView(this.currentPdf);
    },
    updatePdfViewerHeight() {
      const mainBody = this.$refs.mainBody;
      const pdfViewer = this.$refs.pdfViewer;
      if (pdfViewer && mainBody) {
        const mainBodyTop = mainBody.getBoundingClientRect().top;
        const pdfViewerTop = pdfViewer.getBoundingClientRect().top;
        const marginTop = mainBodyTop - pdfViewerTop;
        const pdfPage = { ...this.pdfPage };
        pdfPage.style = `height:${this.mainHeight + marginTop - 70}px;`;
        this.pdfPage = pdfPage;
      }
    },
    pdfNaviPrev() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage = pdfPage.selectPage > 1 ? pdfPage.selectPage - 1 : 1;
      this.pdfPage = pdfPage;
    },
    pdfNaviNext() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage =
        pdfPage.selectPage < pdfPage.pageCount
          ? pdfPage.selectPage + 1
          : pdfPage.pageCount;
      this.pdfPage = pdfPage;
    },

    async getUrlView(item) {
      let data = null;
      let _url = _.cloneDeep(item.url);
      let response = null;
      if (_url) {
        const params = getParamsOfUrlReadFile(_url);
        response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          data = response.data;
        }
      } else {
        let arrayBase64 = item?.file?.split(",");
        let base64result = arrayBase64[1];
        const byteCharacters = atob(base64result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        data = byteArray;
      }

      this.url_view = window.URL.createObjectURL(
        new Blob([data], {
          type: response?.headers["content-type"] || null,
        })
      );
    },

    async downloadDocument(item) {
      this.isSubmitted = true;
      let _item = _.cloneDeep(item);
      let response = null;
      let data = null;
      if (_item?.url) {
        const params = getParamsOfUrlReadFile(_item.url);
        response = await Store.dispatch("File/readFile", params);
        data = response.data;
      } else {
        let arrayBase64 = item?.file?.split(",");
        let base64result = arrayBase64[1];
        const byteCharacters = atob(base64result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        data = byteArray;
      }
      const url = window.URL.createObjectURL(
        new Blob([data], { type: response?.headers["content-type"] || null })
      );
      this.isSubmitted = false;
      let link = document.createElement("a");
      link.download = _item.file_name;
      link.target = "_blank";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteFile() {
      this.$emit("deleteDocument", this.history.item);
      this.popups.isShowRemoveDialog = false;
    },
    dateFormat(date) {
      return format(parseISO(date), "yyyy/MM/dd HH:mm");
    },
    deleteItem(item) {
      this.popups.isShowRemoveDialog = true;
      this.history.item = item;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/components/forms/style/forms.scss";

.FormMainBody {
  overflow: hidden;
}

.pdfViewer {
  overflow: auto;
}

.pdfNavigation {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 153px;
  color: #9e9e9e;
}

.historyItem {
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0;
}

.col_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
}

.col_date {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}

.col_buttons {
  width: 40px;
  display: flex;
  justify-content: space-between;
}

.historyItem__date {
  // font-size: 12px;
  margin: 0 4px;
}
</style>
