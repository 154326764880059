<template>
  <FormDialog> 
    <template #header>
      <!--
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="primary--text">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && item?.fields?.field_role === 1 &&  item?.fields?.active_flg === 1"
          depressed
          small
          :disabled="!isLoadedData"
          outlined
          class="mr-5"
          color="rgba(176, 0, 32, 1)"
          @click="onOpenDialogEndUse"
        >
          終了申請
        </v-btn>
        <v-btn
          v-if="getConditionButtonSave(editable, item?.fields?.field_role, item?.fields?.active_flg, admin_flg)"
          depressed
          small
          :disabled="!isLoadedData"
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          outlined
          color="primary"
          class="mr-5"
          @click="onStopEdit"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid || isSubmitted || !item?.fields?.manager_user_id || (!item?.fields?.approval_manual && !item?.fields?.approval_auto) || disableButtonEdit()"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FIELDS_TAB" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!--
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items  v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <FieldFormPage1
              :editable="editable"
              :isNewItem="isNewItem"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              :admin_flg="admin_flg"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <FieldFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <!-- 外国人受入企業に関する事項 -->
          <v-tab-item>
            <FieldFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdate"
            />
          </v-tab-item>

          <!-- 女性及び年少者の就業制限  -->
          <v-tab-item>
            <FieldFormPage4
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @updateItemsBefore="updateItemsBefore"
            />
          </v-tab-item>
        </v-tabs-items>
        <!--
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate"/>
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
          入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="clickClosePopup"
          warning
        />
      </Popup>

      <Popup width="480px" :dialog="isShowEndUseDialog">
        <EndUseDialog
          @close="closePopup"
          @yes="endUse()"
          title="利用終了する"
          text1="現場の利用を終了します。"
          text2="元請ユーザーは現場の帳票出力のみ可能となり、竣工後に現場ログインができなくなります。"
          acceptBtn="利用終了"
          warning
        />
      </Popup>

      <Popup width="480px" :dialog="isChangeApprovalAuto">
        <ConfirmCloseDialog
          :title="approvalAutoChangePopupTitle"
          :text1="approvalAutoChangePopupMessage"
          textSubmit="OK"
          @close="closePopup"
          @yes="confirmChangeApprovalAuto"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>

import {ValidationObserver} from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import FieldFormPage1 from "@/components/forms/fields/FieldFormPage1.vue";
import FieldFormPage2 from "@/components/forms/fields/FieldFormPage2.vue";
import FieldFormPage3 from "@/components/forms/fields/FieldFormPage3.vue";
import FieldFormPage4 from "@/components/forms/fields/FieldFormPage4.vue";
import {
  FIELDS_TAB,
  FIELDS_TAB_3,
  FIELDS_TAB_4
} from "@/constants/FIELDS";
import {SUBMIT_DELAY_TIMEOUT} from "@/constants/COMMON.js";
import EndUseDialog from "@/components/dialog/EndUseDialog";
import {Store} from "@/store/Store";

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "現場情報";

const STORE = "Fields";

const ACTIVE_FLG_NONE = 0;
const ACTIVE_FLG_IN_USE = 1;
const ACTIVE_FLG_APPLY_FOR_TERMINATION = 2;

export default {
  data: () => {
    return {
      FIELDS_TAB,
      FIELDS_TAB_4,
      FIELDS_TAB_3,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      isBack: false,
      isLoadedData: true,
      isShowConfirmDialog: false,
      isShowEndUseDialog: false,
      isChangeApprovalAuto: false,
      editedItem: {
        fields: {}
      },
      beforeUpdateItem: {},
      errors: {},
      isSubmitted: false,
      timeout: null,
      formValues: {},
      editable: false,
      approvalAutoValue: 0,
      approvalAutoChangePopupTitle: '',
      approvalAutoChangePopupMessage: '',
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    FieldFormPage1,
    FieldFormPage2,
    FieldFormPage3,
    FieldFormPage4,
    EndUseDialog,
  },
  props: {
    companyId: Number,
    isNewItem: Boolean,
    item: Object,
    admin_flg: Number,
    searchParams: Object,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    approvalAuto: Number,
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getDataDetail`],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = {...data};
          this.$emit("beforeUpdateItem", this.beforeUpdateItem);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  
    /**
     * Catch after submitted
     */
    this.$watch(
      () => this.isEmitted,
      () => {
        if (!this.isErrorSubmit) {
          this.editable = false;
        }
      }
    );

    this.$watch(
      () => this.errors,
      (data) => {
        this.valid = true;
        const arrErr = Object.entries({...data});
        for (let i = 0; i < arrErr.length; i++) {
          const item = arrErr[i];
          if (item[1].length > 0) {
            this.valid = false;
            return;
          }
        }
      }
    );

    this.approvalAutoValue = this.approvalAuto;
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return FORM_TITLE;
    },
  },
  methods: {
    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      if (this.editable) {
        this.isShowConfirmDialog = true;
      } else {
        this.editable = true;
      }
    },
    onStopEdit() {
      this.isShowConfirmDialog = true;
      this.isBack = true;
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      //this.$refs.observer.validate();
      this.$emit("formUpdate", params);
    },

    onSubmit() {
      if(!this.item.fields?.approval_frequency && this.item.fields.approval_auto === 1) {
        this.item.fields.approval_frequency = '0.5';
      }
      if(this.item.fields.approval_auto === 0) {
        this.item.fields.approval_frequency = null;
      }
      if(this.item.fields.approval_auto !== this.approvalAutoValue) {
        if(this.approvalAutoValue === 0) {
          this.approvalAutoChangePopupTitle = '自動承認ON';
          this.approvalAutoChangePopupMessage = '申請は自動承認されます。よろしいですか？'
        }
        if(this.approvalAutoValue === 1) {
          this.approvalAutoChangePopupTitle = '自動承認OFF';
          this.approvalAutoChangePopupMessage = '申請は手動での承認が必要になります。よろしいですか？'
        }
        this.isChangeApprovalAuto = true;
      } else {
        this.approvalAutoValue = this.item.fields.approval_auto;
        this.isSubmitted = true;
        this.$emit("submit");
        // Re-enable after submit
        this.timeout = setTimeout(() => {
          this.isSubmitted = false
        }, SUBMIT_DELAY_TIMEOUT); 
      }
    },

    confirmChangeApprovalAuto() {
      this.approvalAutoValue = this.item.fields.approval_auto;
      this.isChangeApprovalAuto = false;
      this.isSubmitted = true;
        this.$emit("submit");
        // Re-enable after submit
        this.timeout = setTimeout(() => {
          this.isSubmitted = false
        }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate(params) {
      const {valid, errors} = params;
      this.valid = valid;
      this.errors = errors;
      this.errors = errors;
    },

    closePopup() {
      this.isShowConfirmDialog = false;
      this.isShowEndUseDialog = false;
      this.isChangeApprovalAuto = false;
      this.isBack = false;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    clickClosePopup() {
      if (this.isBack && this.editable && !this.isNewItem) {
        this.editable = false;
        this.editedItem = {...this.beforeUpdateItem};
        this.formUpdate(this.editedItem);
      } else {
        this.$emit("cancel");
      }
      this.isBack = false;
      this.isShowConfirmDialog = false;
    },
    onClose() {
      if (this.editable) {
        this.isShowConfirmDialog = true;
        this.isBack = false;
      } else {
        this.clickClosePopup();
      }
    },

    async endUse() {
      let endUseParams = {
        admin_flg: 1,
        field_id: this.item.fields.id,
        role: 1
      };
      await Store.dispatch(`${STORE}/endUse`, endUseParams);
      this.isShowEndUseDialog = false;
      let params = {id: this.item.fields.id};
      let result = await Store.dispatch(
        `${STORE}/getMasterFieldInfo`,
        {
          params,
        }
      );
      await Store.dispatch(`${STORE}/get`, {params: this.searchParams});
      this.$emit("formUpdate", result.data.contents.entries);
    },

    onOpenDialogEndUse() {
      this.isShowEndUseDialog = true;
    },

    updateItemsBefore(data) {
      this.$emit("updateItemsBefore", { items:data.items });
    },

    getConditionButtonSave(editItem, fieldRole, activeFlg, adminFlg) {
      return !editItem && (fieldRole === 1 || adminFlg === 1) && (activeFlg === ACTIVE_FLG_IN_USE ||
        activeFlg === ACTIVE_FLG_NONE || activeFlg === ACTIVE_FLG_APPLY_FOR_TERMINATION);
    },

    disableButtonEdit() {
      let startUseFieldDate = this.item?.fields?.start_use_field_date;
      let endFieldDate = this.item?.fields?.end_use_field_date;
      let fieldStartDate = this.item?.fields?.field_start_date;
      let fieldEndDate = this.item?.fields?.field_end_date;
      return !startUseFieldDate || !endFieldDate || !fieldStartDate || !fieldEndDate;
    }

  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right

.title-popup
  color: #274fc2

div::v-deep .label
  color: #000000 !important
  font-weight: 400

</style>
