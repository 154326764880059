<template>
  <div>
    <DefaultLayout :key="keyTab">
      <template #mainHeader>
        <CompanyHeader/>
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab3"
          >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              :isClassify="false"
              :isDelete="false"
              :isAddNew="false"
            >
              <SearchFormWrapper class="receive-order-custom-search-form-wrapper">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-start-order-date:${apiParams.request_to}`"
                >
                  <Label label="受注日" class="title-input-item" style="width: 250px">
                    <InputDatepicker
                      name="request_from"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-start-order-date:${apiParams.request_to}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <span class="mt-12">
                  <v-icon>mdi-tilde</v-icon>
                </span>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-end-order-date:${apiParams.request_from}`"
                >
                  <Label label="受注日" class="title-input-item" style="width: 250px">
                    <InputDatepicker
                      name="request_to"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-end-order-date:${apiParams.request_from}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <Label label="商品コード" class="title-input-item">
                  <InputText
                    name="product_code"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>
            <TableSortWrapper>
              <!--
                (共通) ソート
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                class="ml-2"
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams(...arguments, invalid)"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="cst-row" :key="keyTab">
                <v-tab
                  v-for="tab in TAB_RECEIVE_ORDER"
                  :key="tab.id"
                  @click="changeTab(tab.id)"
                  class="tab-machine"
                >
                  <span>
                    {{ tab.name }}
                  </span>
                  <span>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[0].id && totalNotResponseRequests > 0">
                      mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[1].id && totalNotReportRequest > 0">
                      mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[2].id && totalListReturnRequestToRental > 0">
                      mdi-alert-circle
                    </v-icon>
                  </span>
                </v-tab>
              </v-tabs>
            </template>
            <div class="d-flex mt-2 ml-4">
              <v-btn
                :disabled="isShowButtonAccept"
                class="mr-6 mb-3"
                depressed
                color="primary"
                @click="onClickShowConfirm"
              >
                入庫確定
              </v-btn>
              <v-btn
                :disabled="isShowButtonDelete"
                class="mx-2"
                small
                icon
                @click="showRemoveDialog"
              >
                <v-icon class="mt-1"> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
            <v-data-table
              v-model="selectedItems"
              :items="items"
              :headers="TABLE_LABEL"
              :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 100: tableHeight - 145"
              :items-per-page="searchParams.pageCount"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-1 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              :noDataText="NO_DATA_MESSAGE"
              @update:searchParams.currentPage="$vuetify.goTo(0)"
              :key=searchParams.currentPage
              style="box-shadow: none !important;"
            >
              <template v-slot:[`item.scheduled_return_date`]="{ item }">
                {{ convertScheduleTime(item) }}
              </template>
              <template v-slot:[`item.request_at`]="{ item }">
                {{ convertDate(item.request_at, 10) }}
              </template>
              <template v-slot:[`item.company_name`]="{ item }">
                {{ convertCompanyScene(item) }}
                <br>
                {{ item.phone }}
              </template>
              <template v-slot:[`item.comment`]="{ item }">
                <v-tooltip
                  bottom color="#0082D8"
                  max-width="400px"
                  content-class="custom-color-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.comment"
                      v-bind="attrs"
                      v-on="on"
                      color="#000000"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <span class="comment-txt">{{ item.comment }}</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage" :total="searchParams.totalPage" @pageUpdate="pageUpdate"
              style="background: white; position: fixed; width: 100%; bottom: 0;"/>
          </template>

        </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <Confirm
        title="入庫手続き"
        text1="返却された機械の検品を行い、返却完了とします。"
        text2=""
        cancel="やめる"
        accept="確定"
        @close="onClickCancelShowConfirm"
        @yes="acceptReturnReq"
        warning
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="解除確認"
        text="選択した入庫依頼を解除します。"
        text2="本当に解除してもよろしいですか？"
        text3="この処理を元に戻すことはできません。"
        :items="selectedItems"
        warning
      />
    </Popup>
  </div>
</template>

<script>
import TableLayout from "@/components/layout/TableLayout";
import {Store} from "@/store/Store";
import {HEADER_MENU_ITEMS_RENTAL} from "@/constants/GLOBALHEADER";
import DefaultLayout from "@/components/layout/DefaultLayout";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import Loading from "@/components/loading/Loading";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Pagination from "@/components/masterTable/elements/Pagination";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "../popup/ConfirmRemoveDialog";
import Confirm from "../popup/Confirm.vue";

import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  TAB_RECEIVE_ORDER,
  RECEIVE_ORDERS_SORT_TAB_3,
  RECEIVE_ORDERS_LABEL_TAB_3,
} from "@/constants/RECEIVE_ORDERS";
import {ValidationProvider, ValidationObserver} from "vee-validate";

const STORE = "ReceiveOrders";
const PAGE_TITLE = "受注";
const PAGE_COUNT = 25;
const SORT_ORDERS = TABLE_SORT_ORDERS;
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
const SORT_ITEMS = RECEIVE_ORDERS_SORT_TAB_3;
const TABLE_LABEL = RECEIVE_ORDERS_LABEL_TAB_3;
const TAB_SELECT = 2;

export default {
  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },

  data() {
    return {
      items: [],
      selectedItems: [],
      isLoading: false,
      listFields: [],
      listCompanies: [],
      tab: 0,
      searchParams: {
        sort: "request_at", //sortする項目
        asc: true,
        pageCount: PAGE_COUNT,
        currentPage: 0,
        totalPage: 0,
        total_item: 0,
        product_code: "",
        request_from: "",
        request_to: "",
      },
      PAGE_TITLE,
      TAB_RECEIVE_ORDER,
      NO_DATA_MESSAGE,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      SORT_ITEMS,
      TABLE_LABEL,
      removeCheck: true,
      acceptCheck: true,
      popups: {
        isShowConfirmDialog: false,
        isShowRemoveDialog: false,
      },
      isShowButtonDelete: true,
      isShowButtonAccept: true,
      totalNotResponseRequests: 0,
      totalNotReportRequest: 0,
      totalListReturnRequestToRental: 0,
      keyTab: 0,
    }
  },

  components: {
    TableLayout,
    //共通のコンポーネント
    DefaultLayout,
    CompanyHeader,
    Loading,
    SearchFormWrapper,
    InputDatepicker,
    Label,
    TableSortWrapper,
    TableSort,
    TableHeader,
    InputText,
    Pagination,
    Popup,
    Confirm,
    ConfirmRemoveDialog,
    ValidationProvider,
    ValidationObserver,
  },

  async mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * グローバルヘッダーメニューを更新
     */
    await Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_RENTAL.ORDER.id,
    });

    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotResponseRequests`],
      (data) => {
        this.totalNotResponseRequests = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotReportRequest`],
      (data) => {
        this.totalNotReportRequest = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalListReturnRequestToRental`],
      (data) => {
        this.totalListReturnRequestToRental = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    await this.getItems();

    this.$watch(
      () => [
        Store.getters[`${STORE}/getDataReturnRequestToRental`],
        Store.getters[`${STORE}/getPaginationReturnRequestToRental`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((item) => ({
          ...item
        }));

        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

  },

  watch: {
    selectedItems() {
      this.isShowButtonDelete = !this.selectedItems.length;
      this.isShowButtonAccept = !this.selectedItems.length;
    },
  },
  computed: {
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
    apiParams() {
      return {
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page: this.searchParams.currentPage,
        page_count: this.searchParams.pageCount,
        page_size: this.searchParams.pageCount,
        product_code: this.searchParams.product_code,
        request_from: this.searchParams?.request_from ? this.searchParams?.request_from?.replaceAll("/", "-") : "",
        request_to: this.searchParams?.request_to ? this.searchParams?.request_to?.replaceAll("/", "-") : "",
      };
    }
  },

  methods: {
    // データ取得
    async getItems() {
      const result = await Store.dispatch(`${STORE}/getListReturnRequestToRental`, {
        params: this.apiParams
      });
      if (result.hasError === true) {
        this.items = [];
        this.totalFieldUserInfo = 0;
      }
      this.selectedItems = [];
    },

    pageUpdate(n) {
      let searchParams = {...this.searchParams};
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    pageCountUpdate() {
      let searchParams = {...this.searchParams};
      searchParams.currentPage = 1;
      this.searchParams = searchParams;
      this.getItems();
    },

    changeTab(id) {
      this.getListTotalRequest();
      this.selectedTab = id;
      Store.dispatch(`${STORE}/setSelectedTab`, id);
      if (id === TAB_SELECT) {
        this.getItems()
      }
    },

    onSearch(invalid) {
      if (!invalid) {
        this.searchParams["currentPage"] = 1;
        this.getItems();
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({name, value}) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab3.onChangeWindowSize()
    },
    onChangeSortParams({name, value}, invalid) {
      if (!invalid) {
        let searchParams = {...this.searchParams};
        searchParams[name] = value;
        name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
        this.searchParams = searchParams;
        this.getItems();
        this.selectedItems = [];
      }
    },
    onClickShowConfirm() {
      this.popups.isShowConfirmDialog = true
    },
    onClickCancelShowConfirm() {
      this.popups.isShowConfirmDialog = false
    },
    async acceptReturnReq(isShowLoading = true) {
      this.popups.isShowConfirmDialog = false

      const machineIds = this.selectedItems.map((item)=> item.machine_id)
      const returnInformationIds = this.selectedItems.map((item)=> item.return_information_id)
      const result = await Store.dispatch(`${STORE}/receiptMachineReturn`, {
        "machineIds" : machineIds,
        "returnInformationIds": returnInformationIds
      }, isShowLoading);

      if (!result.hasError) {

        //ダイアログ閉じる
        //成功したら値を更新
        if (result?.data?.contents?.machineIds.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
      await this.getListTotalRequest()
      await this.getItems();
    },
    convertDate(item, quantity = 16) {
      if (item){
        return item.replaceAll("-", "/").slice(0, quantity);
      }
      return "";
    },
    convertCompanyScene(item) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.company_name}　${item.name_sei} ${item.name_mei}`;
    },
    convertScheduleTime(item) {
      let time = '';
      switch (item.scheduled_return_type) {
        case 0:
          time = '当日中'
          break;
        case 1:
          time = '午前'
          break;
        case 2:
          time = '午後'
          break;
        case 3:
          time = this.convertDate(item.scheduled_return_time, 5)
          break;
        default:
          time = ''
      }
      return this.convertDate(item.scheduled_return_date, 10) + '　' + time
    },
    showRemoveDialog(){
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog(){
      this.popups.isShowRemoveDialog = false;
    },
    async removeItems (isShowLoading = true) {
      this.popups.isShowRemoveDialog = false;
      const ids = this.selectedItems.map((item)=> item.return_information_id)
      const result = await Store.dispatch(`${STORE}/deleteReturnInformationsByIds`, {
        "ids" : ids
      }, isShowLoading);
      if (!result.hasError) {
        if (result?.data?.contents?.ids.length > 0) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
      }
      await this.getListTotalRequest()
      await this.getItems();
    },
    async getListTotalRequest(){
      let totalNotResponseRequestsResponse = await Store.dispatch(`${STORE}/countListDeliveryRequestNotResponse`);
      let totalNotResponseRequests = totalNotResponseRequestsResponse?.data?.contents?.totalNotResponseRequests;
      this.totalNotResponseRequests = totalNotResponseRequests;
      let totalNotReportRequestResponse = await Store.dispatch(`${STORE}/countListRepairRequestNotReport`);
      let totalNotReportRequest = totalNotReportRequestResponse?.data?.contents?.totalNotReportRequest;
      this.totalNotReportRequest = totalNotReportRequest;
      let totalListReturnRequestToRentalResponse = await Store.dispatch(`${STORE}/countListReturnRequestToRental`);
      let totalListReturnRequestToRental = totalListReturnRequestToRentalResponse?.data?.contents?.totalListReturnRequestToRental;
      this.totalListReturnRequestToRental = totalListReturnRequestToRental;
      this.keyTab = this.keyTab + 1;
    }
  }
}

</script>

<style scoped>
  .cst-row {
    border-bottom: 1px solid #cccccc;
    padding-left: 16px;
  }
  .cst-row .tab-machine, .cst-row .tab-machine .icon-active, .cst-row .tab-machine span {
    color: #274FC2;
  }
  .icon-active{
    color: #FF0000 !important;
  }

  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  }
  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper .v-messages .v-messages__message {
    line-height: 16px;
  }
  .custom-color-tooltip {
    opacity: 1!important;
  }
  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding: 0;
    width: calc(100% + 32px);
    text-align: center !important;
  }
  ::v-deep .v-data-table>.v-data-table__wrapper table thead tr th:first-child {
  padding: 0;
  width: 5% !important;
  min-width: 5% !important;
  text-align: center !important;
}


  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
    padding-left: 0;
  }

  ::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
    padding-left: 0 !important;
  }
  
  .comment-txt {
    white-space: pre-line;
  }
</style>
