import Vue from "vue";
import Vuex from "vuex";
import { dashboardPortal } from "@/api/modules/dashboardPortal.js";

Vue.use(Vuex);

const ENTITY = dashboardPortal;

export const DashboardPortal = {
  namespaced: true,
  actions: {
    async getQRCode(_context, payload) {
      const { data } = await ENTITY.getQRCode(payload);
      return data?.contents?.entries;
    },
    async getFieldInfoDashboard(_context, payload) {
      const { data } = await ENTITY.getFieldInfoDashboard(payload);
      return data?.contents;
    },
    async getExportFieldQRPDF(_context, payload) {
      const { data } = await ENTITY.getExportFieldQRPDF(payload);
      return data;
    },
  },
};
