export const DELIVERY_TYPE = [
    {
        value: 0,
        label: "当日中",
    },
    {
        value: 1,
        label: "午前",
    },
    {
        value: 2,
        label: "午後",
    },
    {
        value: 3,
        label: "時間指定",
    },
];

export const ELEVATION_LIST = [
    {
        id: "0",
        name: "垂直昇降",
    },
    {
        id: "1",
        name: "直伸",
    },
    {
        id: "2",
        name: "屈折",
    },
];

export const WHEEL_LIST = [
    {
        id: "1",
        name: "ホイール",
    },
    {
        id: "2",
        name: "クローラ",
    },
];

export const FLOOR_LIST = [
    {
        id: "1",
        name: "普通",
    },
    {
        id: "2",
        name: "スライド",
    },
    {
        id: "3",
        name: "バケット",
    },
];
