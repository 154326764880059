<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :width="width" :max-width="maxWidth" @click:outside="$emit('clickOutSite')">
      <!-- コンポーネントを毎回削除 -->
      <div v-if="dialog">
        <slot />
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    width: {
      type: String,
      defaultValue: "100%",
    },
    maxWidth: {
      type: String,
      defaultValue: "100%",
    },
  },
};
</script>
