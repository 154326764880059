<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <v-card class="dialog">
        <div class="header">
          <span>発注</span>
          <v-icon @click="isShowCancel = true">mdi-close</v-icon>
        </div>
        <v-tabs v-model="tab" align-with-title style="display: none">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="(item, index) in data" :key="index">{{ item.rental_company_name }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(_, index) in data" :key="index">
            <div class="form-container">
              <div class="radio-wrapper">
                <Label label="レンタル会社" class="title-input-item"></Label>
                  <v-text-field
                    :value="data[index].rental_company_name"
                    class="title-input-item"
                    disabled
                  ></v-text-field>
                </div>
              <div class="datepicker-container" style="margin-bottom: -10px">
                <v-menu
                  offset-y
                  v-model="formData[index].isShowDatepicker"
                  min-width="auto"
                  :close-on-content-click="false"
                  nudge-right="35"
                  nudge-top="20"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <Label label="搬入希望日" class="title-input-item">
                      <ValidationProvider
                        v-slot="{ errors, valid }"
                        name="搬入希望日"
                        :rules="getRuleStartDate()"
                      >
                        <v-text-field
                          :value="formattedDate(index)"
                          filled
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                          :success="valid"
                          dense
                        >
                          <template slot="prepend">
                            <v-icon color="black">mdi-calendar-today-outline</v-icon>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </Label>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    v-model="formData[index].desired_delivery_date"
                    @input="updateDate(index, $event)"
                    :day-format="(date) => new Date(date).getDate(date)"
                    locale="ja"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="black" @click="clearDatepicker(index)">
                      クリア
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="radio-wrapper">
                <Label label="搬入ゲート" class="title-input-item">
                  <v-select
                    filled
                    v-model="formData[index].delivery_gate"
                    item-text="name"
                    item-value="id"
                    :items="gateList"
                    dense
                  >
                  </v-select>
                </Label>
              </div>
              <div class="radio-wrapper">
                <Label label="時間" class="title-input-item">
                  <v-radio-group row v-model="formData[index].desired_delivery_type" class="radio-container">
                    <v-radio
                      v-for="option in DELIVERY_TYPE"
                      :key="`Delivery-radio-${option.value}`"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </Label>
              </div>
              <div class="time-wrapper" v-if="formData[index].desired_delivery_type === 3">
                <Label label="指定時間" class="title-input-item time-imput-label">
                  <v-select
                    filled
                    v-model="formData[index].desired_delivery_time"
                    :items="timeList"
                    :item-text="formatTime"
                    item-value="time"
                    dense
                    :menu-props="{ auto: true }"
                  >
                  </v-select>
                </Label>
                <span class="time-message" style="padding-top: 42px">頃</span>
              </div>
              <div class="message-container">
                <div class="input-container">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="メッセージ"
                    rules="minmax:0,255"
                  >
                    <Label label="メッセージ" class="title-input-item">
                      <v-textarea
                        v-model="formData[index].message"
                        filled
                        type="text"
                        height="132px"
                        no-resize
                        :error-messages="errors"
                        :success="valid"
                      ></v-textarea>
                    </Label>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <div class="button-wrapper">
          <div></div>
          <div class="button-container">
            <v-btn
              outlined
              color="primary"
              :disabled="isSubmitting"
              @click="isShowCancel = true"
              depressed
            >
              やめる
            </v-btn>
          <!--  <v-btn
              v-if="data.length > 1 && tab > 0"
              outlined
              color="primary"
              :disabled="isSubmitting"
              @click="goToPreviousTab"
              depressed
            >
              戻る
            </v-btn>  -->
            <v-btn
              depressed
              color="primary"
              :loading="isSubmitting"
              @click="onSubmit"
              :disabled="invalid"
            >
              {{ tab === data.length - 1 ? '発注' : '次へ' }}
            </v-btn>
          </div>
        </div>
        <div>
          <Popup width="480px" :dialog="isShowCancel">
            <ConfirmCloseDialog
              v-model="isShowCancel"
              :title="WRN_0002.TITLE"
              :text1="WRN_0002.TEXT1"
              :text2="WRN_0002.TEXT2"
              textSubmit="閉じる"
              :warning="true"
              @close="isShowCancel = false"
              @yes="close"
            />
          </Popup>
        </div>
      </v-card>
    </ValidationObserver>
  </div>
</template>
<script>
import { format, parseISO } from "date-fns";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Store } from "@/store/Store";
import { DELIVERY_TYPE } from "@/constants/ORDER_MODAL";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { WRN_0002 } from "@/constants/MESSAGE";
import Popup from "@/components/common/Popup.vue";
import moment from "moment";

const default_gate = {
  id: null,
  name: "",
};

export default {
  components: {
    ValidationProvider,
    Label,
    ConfirmCloseDialog,
    Popup,
    ValidationObserver,
  },
  props: {
    data: {
      type: Array,
    },
  },
  data: () => ({
    DELIVERY_TYPE,
    isSubmitting: false,
    WRN_0002,
    isShowCancel: false,
    timeList: [],
    formData: [],
    tab: 0,
    gateList: [],
  }),
  computed: {
    formattedDate() {
      return index => this.formData[index].desired_delivery_date
        ? format(parseISO(this.formData[index].desired_delivery_date), "yyyy/MM/dd")
        : "";
    },
  },
  created() {
    for (let i = 0; i < 24; i++) {
      this.timeList.push({ time: i < 10 ? '0' + i + ':00' : i + ':00' });
    }
    this.initializeFormData();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    initializeFormData() {
      this.formData = this.data.map(item => ({
        desired_delivery_date: moment(new Date(new Date().setDate(new Date().getDate() + 1))).format("YYYY-MM-DD"),
        desired_delivery_time: "08:00",
        desired_delivery_type: 0,
        message: item.message,
        delivery_gate: null
      }));
    },
    clearDatepicker(index) {
      this.formData[index].desired_delivery_date = "";
      this.formData[index].isShowDatepicker = false;
    },
    updateDate(index, val) {
      this.formData[index].desired_delivery_date = val;
      this.formData[index].isShowDatepicker = false;
    },
    async onSubmit() {
      if (this.tab === this.data.length - 1) {
        this.isSubmitting = true;

        const bodyMap = this.data.map((x, index) => ({
          ids: x.orderIds,
          rental_company_id: x.rental_company_id,
          desired_delivery_date: this.formData[index].desired_delivery_date,
          desired_delivery_time: this.formData[index].desired_delivery_time,
          desired_delivery_type: this.formData[index].desired_delivery_type,
          message: this.formData[index].message || '',
          delivery_gate: this.formData[index].delivery_gate,
          fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
        }));

        const requestBody = {
          order_machine_requests: bodyMap
        }

        await Store.dispatch("MachineRequests/orderMachineRequests", requestBody);

        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });

        this.isSubmitting = false;
        this.$emit("submit");
        this.isShowCancel = true;
      } else {
        this.goToNextTab();
      }
    },
    goToNextTab() {
      if (this.tab < this.data.length - 1) {
        this.tab++;
      }
    },
    goToPreviousTab() {
      if (this.tab > 0) {
        this.tab--;
      }
    },
    async fetchData() {
      const body = {
        field_id: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id,
      };

      const res = await Store.dispatch("Fields/getListGateByField", body);
      const coalesceArray = res?.data?.contents?.entries ?? [];
      this.gateList = [{ ...default_gate }, ...coalesceArray];
      this.formData.forEach(form => {
        form.delivery_gate = this.gateList[1]?.id ?? this.gateList[0].id;
      });
    },
    getRuleStartDate() {
      let now = new Date();
      now.setDate(now.getDate() + 1);
      now = format(now, "yyyy/MM/dd");
      return "required|" + `err-005:${now},搬入希望日 `;
    },
    formatTime(item) {
      if (typeof item.time === 'number') {
        return item.time < 10 ? '0' + item.time + ':00' : item.time + ':00';
      }
      return item.time;
    },
    close() {
      this.isShowCancel = false;
      this.$emit("close");
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/fonts.scss";
@import "@/assets/scss/themes.scss";

  .time-wrapper  {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .time-imput-label {
    max-width: 173px;
    margin-right: 21px;
  }
  .time-message {
    font-weight: 500;
    font-size: 20px;
    color: $color_text_main;
  }
* {
  font-family: $normal_font;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.v-window__container .form-container {
  display: flex;
  flex-direction: column;
  padding: 0 41px;
}

.dialog {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 19px;

  > .header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      font-size: 20px;
      font-weight: 600;
      color: $color_primary;
    }
  }

  > .v-window__container .form-container {
    display: flex;
    flex-direction: column;
    padding: 0 41px;

    > .datepicker-container {
      display: flex;
      align-items: center;

      .label-container {
        left: 50px;
      }
    }

    > .radio-wrapper {
      display: flex;
      margin-top: calc(27px - 30px); // 30px is default space under v-text-field
      flex-direction: column;

      > span {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $color_text_main;
      }

      > :first-child {
        margin-bottom: 5px;
      }
    }

    > .time-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      > :first-child {
        max-width: 173px;
        margin-right: 21px;
      }

      > span {
        font-weight: 500;
        font-size: 20px;
        color: $color_text_main;
      }
    }

    > .message-container {
      display: flex;
      align-items: center;
      margin-bottom: calc(111px - 25px);

      > :first-child {
        width: 100%;
      }
    }
  }

  > .button-wrapper {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;

    > .button-container {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  ::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 40px;
  }
}

:deep(.time-wrapper) {
  .v-input__slot {
    margin-bottom: 0px;
  }

  .v-text-field__details {
    display: none;
  }
}

:deep(.radio-container) {
  padding: 0;
  margin: 0;

  .v-input--radio-group__input {
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:last-child) {
      margin-right: 10px;
    }

    .v-input--selection-controls__input {
      margin-right: 10px;
    }

    .v-label {
      font-size: 16px !important;
      color: $color_text_main;
    }
  }

  .v-radio {
    margin-bottom: 0px;
  }

  .v-messages {
    display: none;
  }
}

.input-container {
  position: relative;

  > .label-container {
    padding: 1px 3px;
    background-color: #fff;
    position: absolute;
    top: -10px;
    left: 12px;
    z-index: 1;

    > .label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
      transition: 0.5s linear;

      &.focus {
        color: $color_primary;
      }

      &.label_error {
        color: $color_warning_dark_red;
      }
    }
  }
}

:deep(.datepicker-container) {
  .v-input__prepend-outer {
    margin: 0px;
    margin-top: 5px;
    margin-right: 5px;
  }

  .error--text {
    fieldset {
      border-color: $color_warning_dark_red !important;
      transition: 0.5s linear;
    }
  }
}

:deep(.focus) {
  fieldset {
    border-color: $color_primary !important;
    transition: 0.5s linear;
  }
}
</style>
