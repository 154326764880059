<template>
  <v-dialog v-model="isOpen" persistent max-width="90%" min-width="1000px" v-if="showModal">
    <Loading v-if="isLoading" />
    <v-card class="tenken-form-list-container">
      <div class="header">
        <v-toolbar-title color="primary" class="primary--text header-title">
          {{ TITLE }}
        </v-toolbar-title>
        <div class="header-button-container">
          <v-btn v-if="role === 1" icon @click="dialogDel = true" :disabled="isDisable" class="btn-delete-padding">
            <Popup width="480px" :dialog="dialogDel">
              <ConfirmCloseDialog v-model="dialogDel" :title="WRN_0001.TITLE" :text1="WRN_0001.TEXT1"
                :text2="WRN_0001.TEXT2" textSubmit="解除" :warning="true" @close="dialogDel = false"
                @yes="deleteSelected()">
              </ConfirmCloseDialog>
            </Popup>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <div class="header-action-button-container">
            <v-btn v-if="role === 1" depressed color="primary" @click="dialogConfirm = true" class="pr-10" :disabled="isDisable">
              現場で対応
              <Popup width="480px" :dialog="dialogConfirm">
                <ExtendDialog v-model="dialogConfirm" title="現場で対応" text1="現場で対応します。" text2="この処理を元に戻すことはできません。"
                  acceptBtn="確定" @close="dialogConfirm = false" @yes="updateHandle()">
                </ExtendDialog>
              </Popup>
            </v-btn>
            <v-btn v-if="role === 1" depressed color="warning" @click="setName" class="pr-10" :disabled="isDisable">
              レンタル会社を手配
              <Popup width="480px" :dialog="dialogSubmit">
                <v-card class="dialog">
                  <ValidationObserver v-slot="{ invalid }">
                    <v-card-title>
                      <div class="title">レンタル会社を手配</div>
                      <v-spacer></v-spacer>
                      <Popup width="480px" :dialog="isShowCancel">
                        <ConfirmCloseDialog v-model="isShowCancel" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
                          :text2="WRN_0002.TEXT2" @close="isShowCancel = false" @yes="cancelSenData()" warning />
                      </Popup>
                      <v-icon @click="isShowCancel = true">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text class="body">
                      <div class="text-wrap">
                        <div class="text">{{ NameCompanyRental }}</div>
                      </div>
                      <div class="textAr">
                        <v-col cols="6" lg="12" md="12">
                          <ValidationProvider v-slot="{ errors, valid }" name="メッセージ" rules="minmax:0,255">
                            <Label label="メッセージ" class="title-input-item">
                              <v-textarea v-model="message" dense filled :error-messages="errors" :success="valid" />
                            </Label>
                          </ValidationProvider>
                        </v-col>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn depressed outlined class="btn" color="primary" @click="isShowCancel = true">
                        やめる
                      </v-btn>
                      <v-btn depressed class="btn" color="primary" @click="senData" :disabled="invalid || isSubmitted">
                        手配
                      </v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </v-card>
              </Popup>
            </v-btn>
          </div>
          <Popup width="480px" :dialog="isShowCancelFormList">
            <ExtendDialog v-model="isShowCancelFormList" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
              :text2="WRN_0002.TEXT2" acceptBtn="閉じる" :warning="true" @close="isShowCancelFormList = false"
              @yes="close()" />
          </Popup>
          <v-btn icon @click="close">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="tab-container">
        <v-tabs v-model="selectedTab" align-with-title class="ml-n4">
          <v-tab v-for="item in TAB_SELECT" :key="item.id">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <div class="legends-container">
          <div class="legend">
            <span>レ</span>
            <span>良好</span>
          </div>
          <div class="legend">
            <span>◯</span>
            <span>修理して使用する</span>
          </div>
          <div class="legend">
            <span>×</span>
            <span>故障のため使用しない</span>
          </div>
        </div>
      </div>
      <!-- MMSV2-1573-start -->
      <div class="table-container" v-if="TAB_SELECT[selectedTab].id == 1 || TAB_SELECT[selectedTab].id == 2" style="overflow: hidden!important;">
      <!-- MMSV2-1573-end -->
        <v-data-table :noDataText="NO_DATA" :headers="FORM_LIST_TENKEN_LABEL" :items="
          TAB_SELECT[selectedTab].id === 1
            ? checkBeforeWorkings
            : monthlyChecks
        " height="500px" hide-default-header fixed-header hide-default-footer class="data-table" :items-per-page="-1">
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <template v-for="h in headers" :style="`width: ${h.width};`">
                  <th v-if="h.isCheckbox" :style="`width: ${h.width};padding: 0`" class="pl-5">
                    <v-checkbox class="mt-1 mr-2" v-if="role === 1" v-model="checkboxAll"></v-checkbox>
                  </th>
                  <th v-else-if="h.text === '報告者'" :style="`width: ${h.width};padding-left: 0`">
                    <span class="table-header font-weight-black">{{ h.text }}</span>
                  </th>
                  <th v-else>
                    <span class="table-header font-weight-black" :style="`width: ${h.width};`">
                      {{ h.text }}
                    </span>
                  </th>
                </template>
              </tr> 
            </thead>
          </template>
          <template v-slot:item="{ item, headers, index }">
            <tr style="cursor: pointer">
              <FormDetailTenken v-if="index === 0" :idSend="idSend" :titelModal="TITLE" :inspectorId="inspectorId"
                :idFieldMachine="idFieldMachine" :showModel="showModel" :nameCompanyRental="NameCompanyRental" :typeModal="'booking'"
                @close-formDetail-modal="closeFormDetailModel" @reload-data="reloadData" :itemResult="itemResult" />
              <template v-for="h in headers">
                <!-- MMSV2-1573-start-fixbug-detected -->
                <td v-if="h.isCheckbox" :style="`padding: 0`" class="pl-5">
                  <v-checkbox class="mt-1" v-if="item.repair_status == null && item.result && role === 1" @change="onCheckBoxChange(item.inspection_result_id)"
                    v-model="ids[index]">
                  </v-checkbox>
                </td>
                <!-- MMSV2-1573-end-fixbug-detected -->
                <th @click="
                  (idSend = item.inspection_result_id),
                  (inspectorId = item.inspector_id),
                  (showModel = true),
                  (itemResult = item.result)
                " v-else-if="h.text === '報告者'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ item[h.value] }}</span>
                </th>
                <td @click="
                  (idSend = item.inspection_result_id),
                  (inspectorId = item.inspector_id),
                  (showModel = true),
                  (itemResult = item.result)
                " v-else-if="h.value === 'model'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ item.name_sei }} {{ item.name_mei }}</span>
                </td>
                <td @click="
                  (idSend = item.inspection_result_id),
                  (inspectorId = item.inspector_id),
                  (showModel = true),
                  (itemResult = item.result)
                " v-else-if="h.value === 'inspection_at'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ formatDate(item.inspection_at) }}</span>
                </td>
                <td @click="
                  (idSend = item.inspection_result_id),
                  (inspectorId = item.inspector_id),
                  (showModel = true),
                  (itemResult = item.result)
                " class="data-text" v-else>
                  <span v-if="h.isAct">
                    <div class="act-circle" v-if="item.created_field_user_id != item.inspector_id">
                      <span class="text">代理</span>
                    </div>
                  </span>
                  <span v-else-if="h.isSituation">
                    <span v-if="item.result === 2">◯</span>
                    <span v-else-if="item.result === 1">×</span>
                    <span v-else>レ</span>
                    <span class="color-status">
                      <span v-if="item.repair_status === 1"> 現場で対応 </span>
                      <span v-else-if="
                        item.repair_status === 2 ||
                        item.repair_status === 3 ||
                        item.repair_status === 4 ||
                        item.repair_status === 5
                      ">
                        レンタル会社手配済み
                      </span>
                      <span v-else> &nbsp; </span>
                    </span>
                  </span>
                  <span v-else>{{ item[h.value] }}</span>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- MMSV2-1573-start -->
      <div class="table-container" v-if="TAB_SELECT[selectedTab].id == 3" style="overflow: hidden!important;">
        <v-data-table :noDataText="NO_DATA" :headers="FORM_LIST_REPAIR_LABEL" :items="checkRepairRequests" height="500px" hide-default-header fixed-header hide-default-footer class="data-table" :items-per-page="-1">
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <template v-for="h in headers" :style="`width: ${h.width};`">
                  <th v-if="h.isCheckbox" :style="`width: ${h.width};padding: 0`" class="pl-5">
                    <v-checkbox  v-if="role === 1" class="mt-1 mr-2" v-model="checkboxAll"></v-checkbox>
                  </th>
                  <th v-else-if="h.text === '報告者'" :style="`width: ${h.width};padding-left: 0`">
                    <span class="table-header font-weight-black">{{ h.text }}</span>
                  </th>
                  <th v-else>
                    <span class="table-header font-weight-black" :style="`width: ${h.width};`">
                      {{ h.text }}
                    </span>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item, headers, index }">
            <tr style="cursor: pointer">
              <template v-for="h in headers">
                <td v-if="h.isCheckbox" :style="`padding: 0`" class="pl-5">
                  <v-checkbox class="mt-1" v-if="item.repair_status == null && role === 1" @change="onCheckBoxChange(item.repair_requests_id)"
                    v-model="repairIds[index]">
                  </v-checkbox>
                </td>
                <th @click="
                  (idSend = item.repair_requests_id),
                  (repairStatusItem = item.repair_status),
                  (showModelTenkenDetail = true)
                " v-else-if="h.text === '報告者'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ item[h.value] }}</span>
                </th> 
                <td @click="
                  (idSend = item.repair_requests_id),
                  (repairStatusItem = item.repair_status),
                  (showModelTenkenDetail = true)
                " v-else-if="h.value === 'model'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ item.name_sei }} {{ item.name_mei }}</span>
                </td>
                <td @click="
                  (idSend = item.repair_requests_id),
                  (repairStatusItem = item.repair_status),
                  (showModelTenkenDetail = true)
                " v-else-if="h.value === 'request_at'" :style="`padding-left: 0`" class="data-text">
                  <span>{{ formatDate(item.request_at) }}</span>
                </td>
                <td @click="
                  (idSend = item.repair_requests_id),
                  (repairStatusItem = item.repair_status),
                  (showModelTenkenDetail = true)
                " class="data-text" v-else>
                  <span v-if="h.isAct">
                    <div class="act-circle" v-if="item.created_field_user_id != item.inspector_id">
                      <span class="text">代理</span>
                    </div>
                  </span>
                  <span v-else-if="h.isSituation">
                    <span>×</span>
                    <span class="color-status">
                      <span v-if="item.repair_status === 1"> 現場で対応 </span>
                      <span v-else-if="
                        item.repair_status === 2 ||
                        item.repair_status === 3 ||
                        item.repair_status === 4 ||
                        item.repair_status === 5
                      ">
                        レンタル会社手配済み
                      </span>
                      <span v-else> &nbsp; </span>
                    </span>
                  </span>
                  <span v-else>{{ item[h.value] }}</span>
                </td>
              </template>
            </tr>
            <FormDetailTenken v-if="index === 0" :idSend="idSend" :titelModal="TITLE" :repairStatusItem="repairStatusItem"
                :idFieldMachine="idFieldMachine" :showModel="showModelTenkenDetail" :nameCompanyRental="NameCompanyRental" :typeModal="'machine'"
                :callFromTable="'2'"
                @close-formDetail-modal="closeFormDetailModel" @reload-data="reloadDataRepair" />
          </template>
        </v-data-table>
      </div>
      <!-- MMSV2-1573-end -->
    </v-card>
  </v-dialog>
</template>
<script>
import { NO_DATA, SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
//MMSV2-1573-start
import { FORM_LIST_TENKEN_LABEL, FORM_LIST_REPAIR_LABEL } from "@/constants/BOOKING";
//MMSV2-1573-end
import { TYPE_REPORT_KIND_API } from "@/constants/MACHINE";
import Popup from "@/components/common/Popup.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import { Store } from "@/store/Store.js";
import { TENKEN_TAB_SELECT } from "@/constants/BOOKING";
import { WRN_0001, WRN_0002 } from "@/constants/MESSAGE";
import moment from "moment";
import ExtendDialog from "../../../components/dialog/ExtendDialog.vue";
import FormDetailTenken from "../components/FormDetailTenken.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Loading from "@/components/loading/Loading";
import Label from "@/components/forms/elements/Label";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    Popup,
    ConfirmDialog,
    ExtendDialog,
    FormDetailTenken,
    ConfirmCloseDialog,
    Loading,
    Label,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    NO_DATA,
    FORM_LIST_TENKEN_LABEL,
    //MMSV2-1573-start
    FORM_LIST_REPAIR_LABEL,
    //MMSV2-1573-end
    TITLE: null,
    isOpen: false,
    dialogDel: false,
    dialogSubmit: false,
    dialogConfirm: false,
    showModel: false,
    isShowCancel: false,
    isShowCancelFormList: false,
    ids: [],
    listId: [],
    selectedTab: 0,
    checkBeforeWorkings: [],
    monthlyChecks: [],
    //MMSV2-1573-start
    repairIds: [],
    checkRepairRequests: [],
    repairStatusItem: null,
    showModelTenkenDetail: false,
    //MMSV2-1573-end
    isDisable: true,
    idSend: null,
    message: "",
    checkboxAll: false,
    NameCompanyRental: null,
    params: {
      field_machine_id: null,
      tenken_date: null,
      half_day: null,
    },
    idFieldMachine: null,
    showDialog: false,
    inspectorId: null,
    WRN_0001: WRN_0001,
    WRN_0002: WRN_0002,
    isLoading: false,
    TYPE_REPORT_KIND_API,
    showModal: false,
    SUBMIT_DELAY_TIMEOUT,
    isSubmitted: false,
    itemResult: 0,
  }),
  computed: {
    TAB_SELECT() {
      return Object.keys(TENKEN_TAB_SELECT).map((key) => {
        return TENKEN_TAB_SELECT[key];
      });
    },
  },
  watch: {
    selectedTab() {
      this.isDisable = true;
      this.ids = [];
      //MMSV2-1573-start
      this.repairIds = [];
      //MMSV2-1573-end
      this.listId = [];
      this.message = "";
      this.checkboxAll = false;
    },
    checkboxAll() {
      if (this.checkboxAll) {
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
        if (this.TAB_SELECT[this.selectedTab].id === 1) {
          for (let i = 0; i < this.checkBeforeWorkings.length; i++) {
            if (this.checkBeforeWorkings[i].repair_status == null && this.checkBeforeWorkings[i].result !== 0) {
              this.ids[i] = !!this.checkBeforeWorkings[i].inspection_result_id;
              this.listId[i] = this.checkBeforeWorkings[i].inspection_result_id;
            }
          }
        //MMSV2-1573-start
        } else if (this.TAB_SELECT[this.selectedTab].id === 2) {
        //MMSV2-1573-end
          for (let i = 0; i < this.monthlyChecks.length; i++) {
            if (this.monthlyChecks[i].repair_status == null && this.monthlyChecks[i].result !== 0) {
              this.ids[i] = !!this.monthlyChecks[i].inspection_result_id;
              this.listId[i] = this.monthlyChecks[i].inspection_result_id;
            }
          }
        }
        //MMSV2-1573-start
        else {
          for (let i = 0; i < this.checkRepairRequests.length; i++) {
            if (this.checkRepairRequests[i].repair_status == null) {
              this.repairIds[i] = !!this.checkRepairRequests[i].repair_requests_id;
              this.listId[i] = this.checkRepairRequests[i].repair_requests_id;
            }
          }
        }
        //MMSV2-1573-end
      } else {
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
      }
      //MMSV2-1573-start
      if (this.ids.includes(true) || this.repairIds.includes(true)) {
        this.isDisable = false;
      } else {
        this.isDisable = true;
      }
      //MMSV2-1573-end
    },
    isOpen() {
      if (!this.isOpen) {
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
        this.message = "";
        this.checkboxAll = false;
        this.isDisable = true;
      }
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters[`Tenken/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    //call Api
    onCheckBoxChange(newValue) {
      const index = this.listId.findIndex(v => v === newValue);
      if (this.ids.includes(true)) {
        this.isDisable = false;
        if (!this.listId.includes(newValue)) {
          this.listId.push(newValue);
        } else {
          this.listId.splice(index, 1);
        }
      //MMSV2-1573-start
      } else if(this.repairIds.includes(true)) {
        this.isDisable = false;
        if (!this.listId.includes(newValue)) {
          this.listId.push(newValue);
        } else {
          this.listId.splice(index, 1);
        }
        
      } else {
        this.listId = [];
        this.isDisable = true;
      }
      //MMSV2-1573-end
    },
    async getItems(params) {
      let response = await Store.dispatch(`Tenken/getDataTenken`, params);
      if (response.data && response.data.status_code === 200) {
        this.checkBeforeWorkings =
          response.data.contents.entries.pre_opeation_check.data;
        this.monthlyChecks = response.data.contents.entries.monthly_check.data;
        //MMSV2-1573-start
        this.checkRepairRequests = response.data.contents.entries.check_repair_requests.data;
        //MMSV2-1573-end
      }
      this.showModal = true;
    },
    async deleteSelected() {
      //MMSV2-1573-start
      let response = null;
      if(this.repairIds.length) {
        response = await Store.dispatch(
          `Machines/deleteMachineRepairRequest`,
          { ids: this.getIds() }
        );
      } else {
        response = await Store.dispatch(
          `Tenken/postDeleteTenken`,
          this.getIds()
        );
      }
      //MMSV2-1573-end
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.getItems(this.params);
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
        this.checkboxAll = false;
      }
      this.dialogDel = false;
    },
    async updateHandle() {
      //MMSV2-1573-start
      let response = null;
      if(this.repairIds.length) {
        response = await Store.dispatch(`Tenken/postUpdateTenkenReport`,{
          ids: this.getIds(),
          type: this.TYPE_REPORT_KIND_API.requestResult,
          fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
        });
      } else {
        response = await Store.dispatch(`Tenken/postUpdateTenken`,{
          ids: this.getIds(),
          type: this.TYPE_REPORT_KIND_API.inspectionResult,
          fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
        });
      }
      //MMSV2-1573-end
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.getItems(this.params);
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
        this.checkboxAll = false;
      }
      this.dialogConfirm = false;
    },
    async senData() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      //MMSV2-1573-start
      let response = null
      if(this.repairIds.length) {
        response = await Store.dispatch(`Tenken/postSenDataTenkenReport`, {
          ids: this.getIds(),
          message: this.message,
          type: this.TYPE_REPORT_KIND_API.requestResult,
          fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
        });
      } else {
        response = await Store.dispatch(`Tenken/postSenDataTenken`, {
          ids: this.getIds(),
          message: this.message,
          type: this.TYPE_REPORT_KIND_API.inspectionResult,
          fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
        });
      }
      //MMSV2-1573-end
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.getItems(this.params);
        this.ids = [];
        //MMSV2-1573-start
        this.repairIds = [];
        //MMSV2-1573-end
        this.listId = [];
        this.checkboxAll = false;
        this.message = "";
      }
      this.dialogSubmit = false;
    },
    formatDate: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format("YYYY/MM/DD HH:mm:ss");
    },
    setName: function () {
      this.dialogSubmit = true;
    },
    getIds() {
      let listId = [];
      for (let i = 0; i < this.listId.length; i++) {
        if (this.listId[i] != null) {
          listId.push(this.listId[i]);
        }
      }
      return listId;
    },
    open() {
      this.isOpen = true;
    },
    openModalFormListTenken(data) {
      this.NameCompanyRental = data.machine.company_rental_name;
      this.idFieldMachine = data.field_machine_id;
      this.isOpen = true;
      this.selectedTab = 0;
      let date = moment(new Date(data.tenken_date)).format("YYYY-MM-DD");
      this.params = {
        field_machine_id: data.field_machine_id,
        tenken_date: date,
        half_day: data.half_day,
      };
      this.getItems(this.params);
      this.TITLE =
        data.machine.number_plate +
        " / " +
        data.machine.floor_height +
        "m " +
        data.machine.lifting_method +
        " " +
        data.machine.undercarriage +
        " " +
        data.machine.working_floor;
    },
    cancelSenData: function () {
      this.message = "";
      this.isShowCancel = false;
      this.dialogSubmit = false;
    },
    cancelForm: function () {
      this.ids = [];
      //MMSV2-1573-start
      this.repairIds = [];
      //MMSV2-1573-end
      this.checkboxAll = false;
      this.isShowCancelFormList = true;
    },
    close() {
      this.isShowCancelFormList = false;
      this.isOpen = false;
      this.checkBeforeWorkings = [];
      this.monthlyChecks = [];
      //MMSV2-1573-start
      this.checkRepairRequests = [];
      this.showModelTenkenDetail = false;
      //MMSV2-1573-end
      this.showModal = false;
    },
    closeFormDetailModel(value) {
      this.idSend = null;
      this.showModel = value;
      //MMSV2-1573-start
      this.showModelTenkenDetail = null;
      //MMSV2-1573-end
    },
    reloadData() {
      this.getItems(this.params);
    },
    reloadDataRepair() {
      this.getItems(this.params);
      //MMSV2-1573-start
      this.idSend = null;
      this.showModel = false;
      this.showModelTenkenDetail = false;
      //MMSV2-1573-end
    }
  },
  props: {
    role: {
      type: Number,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";

$border_bottom: 1px solid $color_border_accent;

.tenken-form-list-container {
  min-height: 80vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep .v-messages {
    display: none;
  }
  >.header {
    display: flex;
    padding: 8px 4px 22px 8px;
    justify-content: space-between;

    >.header-title {
      font-size: 20px !important;
      font-weight: 600;
    }

    >.header-button-container {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: 25px;
        @media (max-width:1074px) {
          margin-right: 8px;
        }
      }

      >.header-action-button-container {
        > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  >.tab-container {
    border-bottom: $border_bottom;
    display: flex;

    >.legends-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      padding-right: 112px;
      @media (max-width:1030px) {
        padding-right: 100px;
      }

      > :not(:last-child) {
        margin-right: 15px;
      }

      >.legend {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;

        > :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  >.table-container {
    padding: 10px 0px 36px;
    .data-table {
      .table-header {
        font-weight: 500;
        line-height: 24px;
        color: rgba(0,0,0,.6);
      }
    }
  }
  .btn-delete-padding {
    padding: 0 6px;
  }
}

.act-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  $circle: 30px;
  height: $circle;
  width: $circle;
  background-color: $color_table_highlight;
  border-radius: $circle;

  >.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $color_text_white;
  }
}

.color-status {
  margin-left: 5px;
  color: $color_register !important;
}

.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;

      .text {
        padding-left: 10px;
        color: #000000;
      }
    }
  }

  .btn {
    margin-right: 12px;
  }

  .title {
    font-size: 20px !important;
    font-weight: 600;
    padding-left: 8px;
    color: rgba(39, 79, 194, 1);
  }
}
</style>
