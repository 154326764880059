import Api from "../api";
const REPORTS_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/reports`;
const GET_LIST_MACHINE_HAS_INSPECTION_DAILY = `${REPORTS_BASE_URL}/get_list_machine_has_inspection_daily`;
const GET_LIST_MACHINE_HAS_INSPECTION_MONTHLY = `${REPORTS_BASE_URL}/get_list_machine_has_inspection_monthly`;
const GET_INVENTORY_RESULT_IN_FIELDS = `${REPORTS_BASE_URL}/get_inventory_result_in_fields`;
const EXPORT_INVENTORY_RESULT_IN_FIELDS = `${REPORTS_BASE_URL}/export_inventory_result_in_fields`;
const COMPANY_GET_BOOKING_INFO_BY_SUBCONTRACTOR = `${process.env.VUE_APP_API_BASE_URL}/companies/get_booking_info_by_subcontractor`;
const GET_BOOKING_INFO_BY_SUBCONTRACTOR = `${REPORTS_BASE_URL}/get_booking_info_by_subcontractor`;
const GET_LIST_MACHINE_HAS_INSPECTION_YEAR = `${REPORTS_BASE_URL}/get_list_machine_has_inspection_year`;
const DOWNLOAD_INSPECTION_YEARS_BY_MACHINE_IDS = `${REPORTS_BASE_URL}/download_inspection_years_by_machine_ids`;
const GET_LIST_MACHINE_HAS_INSPECTION_BEFORE_DELIVERY = `${REPORTS_BASE_URL}/get_list_machine_has_inspection_before_delivery`;
const EXPORT_REPORT_INSPECTION_BEFORE_DELIVERY = `${REPORTS_BASE_URL}/export_report_inspection_before_delivery`;
const GET_BOOKING_INFO_BY_SUB_LEADER = `${REPORTS_BASE_URL}/get_booking_info_by_sub_leader`;
const GET_RENTAL_RESULTS_BY_MACHINE = `${REPORTS_BASE_URL}/get_booking_info_by_field_machine`;
const EXPORT_BOOKING_INFO_BY_MACHINE= `${REPORTS_BASE_URL}/export_booking_info_by_machine`;
const EXPORT_BOOKING_INFO_BY_SUB_LEADER= `${REPORTS_BASE_URL}/export_booking_info_by_sub_leader`;
const EXPORT_BOOKING_INFO_BY_SUBCONTRACTOR= `${REPORTS_BASE_URL}/export_booking_info_by_subcontractor`;

export const reports = {
  getListMachineHasInspectionDaily: (params) => {
    return Api.get(GET_LIST_MACHINE_HAS_INSPECTION_DAILY, params);
  },
  getListMachineHasInspectionMonthly: (params) => {
    return Api.get(GET_LIST_MACHINE_HAS_INSPECTION_MONTHLY, params);
  },
  getInventoryResultInFields: (params) => {
    return Api.get(GET_INVENTORY_RESULT_IN_FIELDS, params);
  },
  exportInventoryResultInFields: (params) => {
    return Api.get(EXPORT_INVENTORY_RESULT_IN_FIELDS, params);
  },
  companyGetBookingInfoBySubContractor: (params) => {
    return Api.get(COMPANY_GET_BOOKING_INFO_BY_SUBCONTRACTOR, { params: params });
  },
  getBookingInfoBySubContractor: (params) => {
    return Api.get(GET_BOOKING_INFO_BY_SUBCONTRACTOR, params);
  },

  getListMachineHasInspectionYear: (params) => {
    return Api.get(GET_LIST_MACHINE_HAS_INSPECTION_YEAR, params);
  },

  downloadInspectionYearsByMachineIds: (params) => {
    return Api.post(DOWNLOAD_INSPECTION_YEARS_BY_MACHINE_IDS, params);
  },

  getListMachineHasInspectionBeforeDelivery: (params) => {
    return Api.get(GET_LIST_MACHINE_HAS_INSPECTION_BEFORE_DELIVERY, params);
  },
  exportReportInspectionBeforeDelivery: (params) => {
    return Api.post(EXPORT_REPORT_INSPECTION_BEFORE_DELIVERY, params);
  },
  getBookingInfoBySubLeader: (params) => {
    return Api.get(GET_BOOKING_INFO_BY_SUB_LEADER, params);
  },
  getRentalResultsByMachine: (params) => {
    return Api.get(GET_RENTAL_RESULTS_BY_MACHINE, params);
  },
  exportBookingInfoByMachine: (params) => {
    return Api.get(EXPORT_BOOKING_INFO_BY_MACHINE, {params});
  },
  exportBookingInfoBySubLeader: (params) => {
    return Api.get(EXPORT_BOOKING_INFO_BY_SUB_LEADER, {params});
  },
  exportBookingInfoBySubcontractor: (params) => {
    return Api.get(EXPORT_BOOKING_INFO_BY_SUBCONTRACTOR, {params});
  },
};
