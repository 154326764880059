const TABLE_LABELS = [
  {
    text: "ナンバー",
    align: "left",
    sortable: false,
    value: "number_plate",
    width: "20%",
  },
  {
    text: "機種",
    value: "model",
    align: "left",
    sortable: false,
    width: "35%",
  },
  {
    text: "未承認（全件）",
    value: "total",
    align: "left",
    sortable: false,
    width: "20%",
  },
  {
    text: "最終点検日時",
    value: "inspection_at",
    align: "left",
    sortable: false,
    width: "20%",
  },
];

const FORM_LIST_TENKEN_LABEL = [
  {
    text: "ステータス",
    align: "center",
    value: "approved_status",
    width: "140px",
  },
  {
    text: "",
    align: "left",
    value: "act",
    width: "40px",
  },
  {
    text: "点検実施者",
    align: "left",
    value: "name",
  },
  {
    text: "点検実施日",
    align: "left",
    value: "inspection_at",
  },
  {
    text: "状態",
    align: "left",
    value: "inspection_status",
    width: "220px"
  },
];

const INSPECTION_TAB_SELECT = {
  INSPECTION_DAY_TAB: { id: 0, name: "作業前点検" },
  INSPECTION_MONTH_TAB: { id: 1, name: "月例点検" },
};

const INSPECTION_STATUS = {
  AVAILABLE: { id: 1, name: "予約あり" },
  UNAVAILABLE: { id: 2, name: "予約なし" },
};

const MESSAGE_APPROVE_MODAL = {
  title: '承認',
  message: '点検結果を承認します。'
};

const MESSAGE_UNAPPROVE_MODAL = {
  title: '承認解除',
  message: '点検結果を承認解除します。'
};

const APPROVED_STATUS = {
  UNAPPROVED: { id: null, name: "未承認" },
  APPROVED: { id: 1, name: "承認済" },
};

const APPROVED_STATUS_KEY_NAME = {
  UNAPPROVED: null,
  APPROVED: 1,
};

const LIST_REPAIR = {
  0: "レ",
  1: "×",
  2: "〇"
};

const LIST_REPAIR_STATUS = {
  1: "現場で対応",
  2: "レンタル手配済み",
  3: "レンタル手配済み",
  4: "レンタル手配済み",
};

const IS_FLG = 1;
const NOT_FLG = 0;

const APPROVED_ACTION = 0;
const UNAPPROVED_ACTION = 1;

export {
  TABLE_LABELS,
  INSPECTION_TAB_SELECT,
  INSPECTION_STATUS,
  FORM_LIST_TENKEN_LABEL,
  MESSAGE_APPROVE_MODAL,
  MESSAGE_UNAPPROVE_MODAL,
  APPROVED_STATUS,
  LIST_REPAIR,
  LIST_REPAIR_STATUS,
  IS_FLG,
  NOT_FLG,
  APPROVED_ACTION,
  UNAPPROVED_ACTION,
  APPROVED_STATUS_KEY_NAME
};
