<template>
  <div>
    <template >
      <PreWorkInspection v-if="selectedTab == PREWORK_INSPECTION"/>
      <MonthlyInspection v-if="selectedTab == MONTHLY_INSPECTION"/>
      <AnnualInspection v-if="selectedTab == ANNUAL_INSPECTION"/>
      <PreShipmentInspection v-if="selectedTab == PRESHIPMENT_INSPECTION"/>
      <ResultsBySubcontractor v-if="selectedTab == RESULTS_BY_SUBCONTRACTOR"/>
      <ResultsByGroup v-if="selectedTab == RESULTS_BY_GROUP"/>
      <RentalResultsByMachine v-if="selectedTab == RENTAL_RESULTS_BY_MACHINE"/>
      <InventoryResult v-if="selectedTab == INVENTORY_RESULT"/>
      <Loading v-if="isLoading"/>
    </template>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout";
import PreWorkInspection from "./components/PreWorkInspection"
import MonthlyInspection from "./components/MonthlyInspection"
import AnnualInspection from "./components/AnnualInspection"
import PreShipmentInspection from "./components/PreShipmentInspection"
import ResultsBySubcontractor from "./components/ResultsBySubcontractor"
import ResultsByGroup from "./components/ResultsByGroup"
import RentalResultsByMachine from "./components/RentalResultsByMachine"
import InventoryResult from "./components/InventoryResult"
import {Store} from "@/store/Store.js";
import {
  HEADER_MENU_ITEMS_FIELD_PORTAL,
} from "@/constants/GLOBALHEADER";
import Loading from "@/components/loading/Loading";

const STORE = "Reports";
const PREWORK_INSPECTION = 0;
const MONTHLY_INSPECTION = 1;
const ANNUAL_INSPECTION = 2;
const PRESHIPMENT_INSPECTION = 3;
const RESULTS_BY_SUBCONTRACTOR = 4;
const RESULTS_BY_GROUP = 5;
const RENTAL_RESULTS_BY_MACHINE = 6;
const INVENTORY_RESULT = 7;
export default {
  data() {
    return {
      selectedTab: 0,
      isLoading: false,
      PREWORK_INSPECTION,
      MONTHLY_INSPECTION,
      ANNUAL_INSPECTION,
      PRESHIPMENT_INSPECTION,
      RESULTS_BY_SUBCONTRACTOR,
      RESULTS_BY_GROUP,
      RENTAL_RESULTS_BY_MACHINE,
      INVENTORY_RESULT
    };
  },

  components: {
    DefaultLayout,
    PreWorkInspection,
    MonthlyInspection,
    AnnualInspection,
    PreShipmentInspection,
    ResultsBySubcontractor,
    ResultsByGroup,
    RentalResultsByMachine,
    InventoryResult,
    Loading,
  },

  mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    
    Store.dispatch("Companies/getDataBuilding");
    Store.dispatch("Fields/getListFieldNotFinish");
  
    let fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    const params = {
      fieldId: fieldId,
    };
    Store.dispatch("Reports/companyGetBookingInfoBySubContractor", params);

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.selectedTab = data;
        Store.dispatch(`${STORE}/resetData`);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Reports/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.FORM_OUTPUT.id,
    });
  },

  methods: {
    restoreTabSelect() {
      Store.dispatch(`${STORE}/restoreTabSelect`);
    }
  },
  
  destroyed() {
    this.restoreTabSelect()
  }
}
</script>
<style>
@media only screen and (min-width: 1024px) {
  .tab-report {
    margin: 0 40px !important;
  }
  .sort_report {
    margin-top: 0 !important;
  }
}
@media only screen and (width: 1024px) {
  .tab-report {
    margin: 0 !important;
  }
}
</style>
