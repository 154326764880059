const ENV_CLIENT = {
  GREEN: "GREEN",
  OBAYASHI: "OBAYASHI",
};

/**
 * 設定のキーラベル
 */
const ENV_CONFIG_LABELS = {
  HEADER_MENU_ITEMS_INHOUSE: "HEADER_MENU_ITEMS_INHOUSE",
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE:
    "HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE",
  HEADER_MENU_ITEMS_SITE: "HEADER_MENU_ITEMS_SITE",
  HEADER_MENU_ITEMS_ADMIN: "HEADER_MENU_ITEMS_ADMIN",
  HEADER_MENU_ITEMS_FIELD: "HEADER_MENU_ITEMS_FIELD",
  HEADER_MENU_ITEMS_RENTAL: "HEADER_MENU_ITEMS_RENTAL",
  HEADER_MENU_ITEMS_FIELD_UNFINISHED: "HEADER_MENU_ITEMS_FIELD_UNFINISHED",
  HEADER_MENU_ITEMS_FIELD_FINISHED: "HEADER_MENU_ITEMS_FIELD_FINISHED",
  HEADER_MENU_ITEMS_FIELD_PARTNER: "HEADER_MENU_ITEMS_FIELD_PARTNER",
  HEADER_MENU_ITEMS_FIELD_PORTAL: "HEADER_MENU_ITEMS_FIELD_PORTAL",
};

/**
 * グリーンのメニュー
 */
const ENV_CONFIG_GREEN = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    BOOKING_STATUS: { visibility: 1 },
    FIELD_MACHINE: { visibility: 1 },
    RESULT_TEST: { visibility: 1 },
    FORM_OUTPUT: { visibility: 1 },
    USERS: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    BOOKING_STATUS: { visibility: 1 },
    FIELD_MACHINE: { visibility: 1 },
    RESULT_TEST: { visibility: 1 },
    FORM_OUTPUT: { visibility: 1 },
    USERS: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    BOOKING_STATUS: { visibility: 1 },
    FIELD_MACHINE: { visibility: 1 },
    RESULT_TEST: { visibility: 1 },
    FORM_OUTPUT: { visibility: 1 },
    USERS: { visibility: 1 },
    CHART: { visibility: 1 },
    SAFETY: { visibility: 1 },
    SCHEDULE: { visibility: 1 },
    MACHINE: { visibility: 0 },
    ENTRANCE: { visibility: 1 },
    DEVICE_MANAGEMENT: { visibility: 1 },
    PLACEMENT: { visibility: 1 },
    TEXTCHAT: { visibility: 1 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 }, // 使用予定
    { id: 5, visibility: 1 },
    { id: 6, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
  HEADER_MENU_ITEMS_ADMIN: {
    SITES: { visibility: 1 },
    COMPANY: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_FIELD: {
    SITES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_RENTAL: {
    SITES: { visibility: 1 },
    MACHINE: { visibility: 1 },
    ORDER: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_FIELD_UNFINISHED: {
    BULLENTIN_BOARD: { visibility: 1 },
    RESERVATION_STATUS: { visibility: 1 },
    FIELD_MACHINE: { visibility: 1 },
    INSPECTION_RESULTS: { visibility: 1 },
    FORM_OUTPUT: { visibility: 1 },
    USER_LIST: { visibility: 1 },
  },

  HEADER_MENU_ITEMS_FIELD_PORTAL: {
    BULLENTIN_BOARD: {visibility: 1},
    RESERVATION_STATUS: {visibility: 1},
    FIELD_MACHINE: {visibility: 1},
    INSPECTION_RESULTS: {visibility: 1},
    FORM_OUTPUT: {visibility: 1},
    USER_LIST: {visibility: 1},
  },

  HEADER_MENU_ITEMS_FIELD_FINISHED: {
    FORM_OUTPUT: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_FIELD_PARTNER: {
    RESERVATION_STATUS: { visibility: 1 },
    FIELD_MACHINE: { visibility: 1 },
    USER_LIST: { visibility: 1 },
  },
};

/**
 * 大林のメニュー
 */
const ENV_CONFIG_OBAYASHI = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
    OFFICES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    CHART: { visibility: 1 },
    SAFETY: { visibility: 1 },
    SCHEDULE: { visibility: 1 },
    MACHINE: { visibility: 0 },
    ENTRANCE: { visibility: 1 },
    DEVICE_MANAGEMENT: { visibility: 1 },
    PLACEMENT: { visibility: 1 },
    TEXTCHAT: { visibility: 1 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 0 },
    { id: 3, visibility: 0 },
    { id: 4, visibility: 1 }, // 使用予定
    { id: 5, visibility: 1 },
    { id: 6, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
};

/**
 * レンタル会社
 */
const ENV_CONFIG_RENTAL = {
  HEADER_MENU_ITEMS_INHOUSE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
    OFFICES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_INHOUSE_WITHOUT_OFFICE: {
    PORTAL: { visibility: 1 },
    SITES: { visibility: 1 },
    EMPLOYEES: { visibility: 1 },
    COMPANY_MACHINES: { visibility: 1 },
  },
  HEADER_MENU_ITEMS_SITE: {
    PORTAL: { visibility: 1 },
    DEVICE_MANAGEMENT: { visibility: 1 },
  },
  MENU_SCHEDULE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
    { id: 5, visibility: 1 },
  ],
  MENU_ENTRANCE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
    { id: 3, visibility: 1 },
    { id: 4, visibility: 1 },
  ],
  MENU_DEVICE_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
    { id: 2, visibility: 1 },
  ],
  MENU_CHAT_ITEMS: [
    { id: 0, visibility: 1 },
    { id: 1, visibility: 1 },
  ],
};

/**
 *
 * @param {string}} env
 * @param {string} configKeyName
 * @returns
 */
const getEnvConfigValue = (_ENV_CONFIG_LABEL) => {
  let _ENV_CONFIG = ENV_CONFIG_GREEN;
  //大林
  if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI)
    _ENV_CONFIG = ENV_CONFIG_OBAYASHI;

  return _ENV_CONFIG[_ENV_CONFIG_LABEL];
};

const getDropDownMenuVisibility = (key) => {
  //大林
  if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI) {
    return ENV_CONFIG_OBAYASHI[key];
  }

  //GREEN
  return ENV_CONFIG_GREEN[key];
};

export {
  ENV_CLIENT,
  ENV_CONFIG_LABELS,
  ENV_CONFIG_GREEN,
  ENV_CONFIG_OBAYASHI,
  ENV_CONFIG_RENTAL,
  getEnvConfigValue,
  getDropDownMenuVisibility,
};
