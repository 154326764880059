import Vue from "vue";
import Vuex from "vuex";
import {prefectures} from "../../../api/modules/prefectures";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = prefectures;

export const Prefectures = {
  namespaced: true,

  state: {
    data: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
  },
};
