<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-col cols="12" sm="12" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="会社名"
                          class="title-input-item"
                          :editable="editable"
                        >
                          <InputText
                            name="company_name"
                            :values="formValues"
                            :editable="false"
                            :disabled="true"
                            validation_label="会社名"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col v-if ="active_flg == USER_DEACTIVE">
                      <v-list-item-content>
                        <Label
                          label="氏名"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="name_sei_mei"
                            :values="formValues"
                            :editable="editable"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col v-if ="active_flg == USER_DEACTIVE">
                      <v-list-item-content>
                        <Label
                          label="カナ"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="name_kata_kana"
                            :values="formValues"
                            :editable="editable"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="メールアドレス"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="email"
                            :values="formValues"
                            :editable="editable"
                            validation_label="メールアドレス"
                            validation_rules="max:255|required|email"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="電話番号"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="phone"
                            :values="formValues"
                            :editable="editable"
                            validation_label="電話番号"
                            validation_rules="max:16|required|phone"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col v-if ="active_flg == USER_ACTIVE || actions == MODE_CREATE">
                      <v-list-item-content>
                        <Label
                          label="姓"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="name_sei"
                            :values="formValues"
                            :editable="editable"
                            validation_label="姓"
                            validation_rules="max:64|required"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col v-if ="active_flg == USER_ACTIVE || actions == MODE_CREATE">
                      <v-list-item-content>
                        <Label
                          label="名"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="name_mei"
                            :values="formValues"
                            :editable="editable"
                            validation_label="名"
                            validation_rules="max:64|required"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col v-if ="active_flg == USER_ACTIVE || actions == MODE_CREATE">
                      <v-list-item-content>
                        <Label
                          label="セイ"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="kana_sei"
                            :values="formValues"
                            :editable="editable"
                            validation_label="セイ"
                            validation_rules="max:128|required|kana"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col v-if ="active_flg == USER_ACTIVE || actions == MODE_CREATE">
                      <v-list-item-content>
                        <Label
                          label="メイ"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="kana_mei"
                            :values="formValues"
                            :editable="editable"
                            validation_label="メイ"
                            validation_rules="max:128|required|kana"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                  <v-row v-if ="active_flg == USER_ACTIVE || actions == MODE_CREATE">
                    <v-col cols="12" sm="12" md="6">
                      <v-list-item-content>
                        <Label
                          label="CCUS技能者ID"
                          class="title-input-item"
                          :editable="editable"
                        >
                          <InputText
                            name="ccus_id"
                            :values="formValues"
                            :editable="editable"
                            validation_label="CCUS技能者ID"
                            validation_rules="ccusid:CCUS技能者ID"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                  <v-row v-if="companyType == COMPANY_RENTAL">
                    <v-col cols="12" sm="12" md="6">
                      <v-list-item-content>
                        <Label label="種別" class="title-input-item" :editable="editable">
                          <RadioGroup
                            name="rental_user_type"
                            :values="formValues"
                            :items="RENTAL_USER_TYPE"
                            :editable="editable"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import { Store } from "@/store/Store.js";
import { COMPANY_RENTAL, MODE_CREATE } from "@/constants/COMMON";
import { COMPANY_TYPE, RENTAL_USER_TYPE, ENGINEERS } from "@/constants/COMPANIES";
import ConfirmActiveUser from "../../../../views/master/companies/listUserByCompany/components/ConfirmActiveUser.vue";
import Popup from "../../../common/Popup.vue";
import _ from "lodash";
import { USER_ACTIVE, USER_DEACTIVE } from "@/constants/USER";
import {kuroshiro} from "@/utils/convertKana";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      ccusIdCheck: false,
      plateNumberPrefixCheck: false,
      usersEmailCheck: false,
      usersPhoneCheck: false,
      usersNameSeiCheck: false,
      usersNameMeiCheck: false,
      usersKanaSeiCheck: false,
      usersKanaMeiCheck: false,
      COMPANY_TYPE: COMPANY_TYPE,
      RENTAL_USER_TYPE,
      company: {},
      companyType: null,
      COMPANY_RENTAL,
      ENGINEERS,
      MODE_CREATE,
      USER_ACTIVE,
      USER_DEACTIVE,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
    ConfirmActiveUser,
    Popup,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
  },
  async mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        if (data.active_flg == USER_DEACTIVE) {
          // eslint-disable-next-line no-irregular-whitespace
          data.name_sei_mei = `${data.name_sei}　${data.name_mei}`;
          // eslint-disable-next-line no-irregular-whitespace
          data.name_kata_kana = `${data.kana_sei}　${data.kana_mei}`;
        }
        if (!data.company_name) {
          data.company_name = data.company.name;
        }
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`Companies/getDetailCompany`],
      (data) => {
        this.company = data;
        this.companyType = this.company?.company_type;
        this.formValues.company_name = this.company?.name;
        this.formValues.company_id = this.company?.id;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.actions,
      (data) => {
        if (data == 'create') {
          const formValues = { ...this.formValues };
          formValues.rental_user_type = ENGINEERS;
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      formValues.company_name = this.company?.name;
      formValues.company_id = this.company?.id;
      if (parent_name) {
        formValues.users[name] = value;
      } else {
        formValues[name] = value;
      }
      if (name == 'name_sei' || name == 'name_mei') {
        this.getNameKana(name, formValues[name]);
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getNameKana(name, value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues =  _.cloneDeep(this.formValues);
      if (name == 'name_sei') {
        formValues.kana_sei = result;
      }
      if (name == 'name_mei') {
        formValues.kana_mei = result;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
