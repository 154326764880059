<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row class="doc-row">
                <v-btn v-if="editable" depressed color="primary" @click="onUploadPdfFile" class="ml-7">
                  アップロード
                </v-btn>
                <v-col cols="12" sm="12" md="12">
                  <DocumentViewer
                    :items="dataReports"
                    :editable="editable"
                    :mainHeight="mainHeight"
                    @deleteDocument="deleteDocument"
                  />
                </v-col>
              </v-row>
              <form ref="form">
                <input
                  style="display: none"
                  ref="input_drawing_file"
                  type="file"
                  accept="application/pdf"
                  @change="onSelectPdf()"
                />
              </form>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select.vue";
import _ from "lodash";
import DocumentViewer from "./DocumentViewer.vue";
import { Store } from "@/store/Store.js";
import { format, parseISO } from "date-fns";
import { CREATE } from "@/constants/MACHINE";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      selectItems: [],
      dataReports: [],
      CREATE
    };
  },
  components: {
    ValidationObserver,
    Label,
    Select,
    DocumentViewer,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
  },
  async mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
        this.dataReports = this.formValues?.inspection_years || [];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.actions,
      (data) => {
        if (data == CREATE) {
          const formValues = { ...this.formValues };
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      formValues.company_name = this.company?.name;
      formValues.company_id = this.company?.id;
      if (parent_name) {
        formValues.users[name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    onUploadPdfFile() {
      this.$refs.input_drawing_file.click();
    },
    onSelectPdf() {
      const file = this.$refs.input_drawing_file.files[0];
      if (!file) {
        return;
      }
      this.$refs.form.reset();
      this.setFile(file);
    },
    setFile(file) {
      if (file.name.toLocaleLowerCase().match(/\.(pdf)$/)) {
        if (file.size >= 10 * 1024 * 1024) {
          Store.dispatch(
            "Error/show",
            {
              status: "400",
              message: "アップロードのファイルは、最大10MBまでです。",
            },
            { root: true }
          );
          return;
        }
      } else {
        Store.dispatch(
          "Error/show",
          { status: "400", message: "PDFファイルをアップロードしてください。" },
          { root: true }
        );
        return;
      }
      let formValues = { ...this.formValues };
      let dataReports = [...this.dataReports];
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      this.getBase64(file).then((dataBase64) => {
        const fileTemp = {
          file_name: file.name,
          file: dataBase64,
          created_at: today.toISOString(),
          key: (Math.random() + 1).toString(36).substring(7),
        };
        formValues.inspection_years.push(fileTemp);
        dataReports.push(fileTemp);
        this.dataReports = dataReports;
        this.$emit("formUpdate", formValues);
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    dateFormat(date) {
      return format(parseISO(date), "yyyy/MM/dd");
    },
    deleteDocument(item) {
      let newFiles = this.formValues.inspection_years.filter(function (value) {
        return !((!!item?.id && value.id == item.id) || (!item?.id && item.key == value.key));
      });
      let formValues = { ...this.formValues };
      formValues.inspection_years = newFiles;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
.doc-row {
  padding: 20px;
}
</style>
