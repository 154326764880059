<template>
  <v-cardv v-click-outside="onClickOutside">
    <v-toolbar-title class="head-modal">
      会社選択
    </v-toolbar-title>
    <v-card class="dialog list-company">
      <template  class="grey lighten-5" v-for="(item, index) in listData">
        <v-list-item :key="index" @click="checkCompany(item, index)">
          <input type="radio" :checked="index == itemChecked" :id="item.id" :value="item.corporateNumber" class="item-radio">
          <v-row>
            <v-col cols="2 item-text">
              <span class="item company">{{ item.corporateNumber }}</span>
            </v-col>
            <v-col cols="4 item-text" >
              <span class="item company">{{ item.name }}</span>
            </v-col>
            <v-col cols="6 item-text">
              <span class="item company-address">{{ item.prefectureName + item.cityName + item.streetNumber }}</span>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
      <v-container class="bottom-modal">
        <v-row>
          <v-col cols="10">
            <v-list-item class="item-alert-circle">
              <v-icon class="alert-circle">mdi-alert-circle</v-icon>
              <span>法人番号検索のご注意</span>
            </v-list-item>
            <div class="item-note">
              <v-icon>mdi-circle-small</v-icon>
              <span>このサービスは国税庁法人番号システムのWeb-API機能を利用して取得した情報をもとに作成しています。</span>
            </div>
            <div class="item-note">
              <v-icon>mdi-circle-small</v-icon>
              <span>サービスの内容は国税庁によって保証されたものではありません。</span>
            </div>
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center">
            <v-btn class="primary" :disabled="itemChecked == null ? true :false" @click="handCheckCompany()">
              確定
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-cardv>
</template>

<script>
export default {
  data: () => {
    return {
      listData: null,
      dataCheck: null,
      itemChecked: null,
    };
  },

  props: {
    warning: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array
    },
  },

  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:red;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },

  mounted() {
    this.$watch(
      () => this.items,
      (newValue) => {
        if (!Array.isArray(newValue)) {
          this.listData = [newValue];
        } else {
          this.listData = newValue;
        }
      },
      { immediate: true, deep: true }
    );
  },

  methods: {
    checkCompany(item, index) {
      this.itemChecked = index;
      this.dataCheck = item;
    },
    handCheckCompany() {
      this.$emit("dataCheckCompany", this.dataCheck)
    },
    onClickOutside () {
      this.$emit("cancel");
    },

  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  overflow: hidden;
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
.item-radio {
  margin-right: 10px;
}
.head-modal{
  color: #274FC2;
  font-weight: 500;
  font-size: 20px !important;
  padding: 24px 20px;
  border-bottom: 1px solid #C6C6C6;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
}
.v-list-item__title{
  .item{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    &.small{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.bottom-modal{
  border-top: 1px solid #C6C6C6;
  padding-top: 12px !important;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  padding-bottom: 20px !important;
}
.alert-circle{
  color: #274FC2 !important;
}
.item-alert-circle{
  color: #274FC2 !important;
  i{
    margin-right: 12px;
  }
}
.item-note{
  padding: 0 16px;
}
.company-address {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.list-company{
  position: initial;
  height: calc(100% - 78px);
  overflow-y: auto;
  z-index: 1;
}
.company {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.item-text {
  display: flex;
  align-items: center;
}
</style>
