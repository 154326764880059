<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-file-input
      :resetValue="resetValue"
      v-model="val"
      :accept="accept"
      dense
      :disabled="!editable"
      :placeholder="placeholder"
      :error-messages="errors"
      :success="valid"
      :clearable="true"
      @change="onInput"
      :data-testid="testid"
    ></v-file-input>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    resetValue: {
      type: Boolean,
      default: false,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/*",
    },
    testid: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      val: null, // v-file-inputの場合は初期値は null
    };
  },
  mounted() {
    // reset input file
    this.$watch(
      () => this.resetValue,
      () => {
        this.val = null;
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput(e) {
      const file = e;
      if (file) {
        // Base64形式にフォーマットしてデータを返す
        this.getBase64(file)
          .then((data) => {
            this.$emit("onInput", {
              name: this.name,
              value: data,
              fileName: file.name,
            });
          })
          .catch();
      } else {
        this.$emit("onInput", { name: this.name, value: "", fileName: "" });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
