import Vue from "vue";
import Vuex from "vuex";
import { receiveOrders } from "@/api/modules/receiveOrders";

Vue.use(Vuex);
/**
/**
 * apiを指定
 */
const ENTITY = receiveOrders;
export const ReceiveOrders = {
  namespaced: true,

  state: {
    isLoading: {},
    data: [],
    pagination: {},
    listFields: [],
    listCompanies: [],
    repairRequets: {
      data: [],
      pagination: {}
    },
    selectedTab: 0,
    returnReqToRental: {
      data: [],
      pagination: {},
    },
    totalNotResponseRequests: 0,
    totalNotReportRequest: 0,
    totalListReturnRequestToRental: 0
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_LIST_FIELDS(state, payload) {
      state.listFields = payload;
    },
    SET_LIST_COMPANIES(state, payload) {
      state.listCompanies = payload;
    },
    SET_SELECTED_TAB(state, payload) {
      state.selectedTab = payload;
    },
    SET_DATA_RETURN_REQ_TO_RENTAL(state, payload) {
      state.returnReqToRental.data = payload;
    },
    SET_PAGINATION_RETURN_REQ_TO_RENTAL(state, payload) {
      state.returnReqToRental.pagination = payload;
    },
    SET_DATA_REPAIR_REQUESTS(state, payload) {
      state.repairRequets.data = payload;
    },
    SET_PAGINATION_REPAIR_REQUESTS(state, payload) {
      state.repairRequets.pagination = payload;
    },
    SET_COUNT_LIST_DELIVERY_REQUEST_NOT_RESPONSE(state, payload) {
      state.totalNotResponseRequests = payload;
    },
    SET_COUNT_LIST_REPAIR_REQUEST_NOT_REPORT(state, payload) {
      state.totalNotReportRequest = payload;
    },
    SET_COUNT_LIST_RETURN_REQUEST_TO_RENTAL(state, payload) {
      state.totalListReturnRequestToRental = payload;
    }
  },
  actions: {
    async getListDeliveryRequest({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListDeliveryRequest(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getListFieldMainContractorForRental({ commit }) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldMainContractorForRental();
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response?.data?.contents;
      commit("SET_LIST_FIELDS", entries.listRequestFields);
      commit("SET_LIST_COMPANIES", entries.listRequestCompanies);
      return response;
    },

    setSelectedTab({commit}, tabId) {
      commit("SET_SELECTED_TAB", tabId)
    },

    async getListReturnRequestToRental({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListReturnRequestToRental(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;
      commit("SET_DATA_RETURN_REQ_TO_RENTAL", entries);
      commit("SET_PAGINATION_RETURN_REQ_TO_RENTAL", pagination);
      return response;
    },

    async receiptMachineReturn({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.receiptMachineReturn(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteReturnInformationsByIds({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteReturnInformationsByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getListRepairRequestToRental({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListRepairRequestToRental(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;
      commit("SET_DATA_REPAIR_REQUESTS", entries);
      commit("SET_PAGINATION_REPAIR_REQUESTS", pagination);
      return response;
    },

    async getListDeliveryRequestDetail({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListDeliveryRequestDetail(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async responseMachineRequests({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.responseMachineRequests(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async countListDeliveryRequestNotResponse({ commit }, payload) {
      const response = await ENTITY.countListDeliveryRequestNotResponse(payload);
      commit("SET_COUNT_LIST_DELIVERY_REQUEST_NOT_RESPONSE", response.data.contents.totalNotResponseRequests);
      return response;
    },

    async countListRepairRequestNotReport({ commit }, payload) {
      const response = await ENTITY.countListRepairRequestNotReport(payload);
      commit("SET_COUNT_LIST_REPAIR_REQUEST_NOT_REPORT", response.data.contents.totalNotReportRequest);
      return response;
    },

    async countListReturnRequestToRental({ commit }, payload) {
      const response = await ENTITY.countListReturnRequestToRental(payload);
      commit("SET_COUNT_LIST_RETURN_REQUEST_TO_RENTAL", response.data.contents.totalListReturnRequestToRental);
      return response;
    },

    restoreTabSelect({ commit }){
      commit("SET_SELECTED_TAB", 0)
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },

    getPagination: (state) => {
      return state.pagination;
    },

    getIsLoading: (state) => {
      return state.isLoading;
    },

    getSelectedTab: (state) => {
      return state.selectedTab;
    },

    getDataReturnRequestToRental: (state) => {
      return state.returnReqToRental.data;
    },

    getPaginationReturnRequestToRental: (state) => {
      return state.returnReqToRental.pagination;
    },

    getListRepairRequestToRental: (state) => {
      return state.repairRequets.data;
    },

    getPaginationRepairRequestToRental: (state) => {
      return state.repairRequets.pagination;
    },

    getListFields: (state) => {
      return state.listFields;
    },

    getListCompanies: (state) => {
      return state.listCompanies;
    },

    getTotalNotResponseRequests: (state) => {
      return state.totalNotResponseRequests;
    },

    getTotalNotReportRequest: (state) => {
      return state.totalNotReportRequest;
    },

    getTotalListReturnRequestToRental: (state) => {
      return state.totalListReturnRequestToRental;
    }
  },
};
