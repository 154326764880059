import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_LIST_INSPECTION_OF_HALF_DAY = `${SITES_BASE_URL}/fields/get_list_inspection_of_half_day`;
const POST_DELETE_INSPECTION_BY_IDS = `${SITES_BASE_URL}/fields/delete_inspection_by_ids`;
const POST_UPDATE_HANDLE_ABNORMAL_ON_FIELD = `${SITES_BASE_URL}/fields/handle_inspection_abnormal_on_field`;
const POST_SEND_INSPECTION_TO_RENTAL = `${SITES_BASE_URL}/fields/send_inspection_to_rental`;

export const tenken = {
    getListInspection: async (params) => {
        return Api.get(GET_LIST_INSPECTION_OF_HALF_DAY, {params});
    },
    postDeleteInspection: async (params) => {
        return Api.post(POST_DELETE_INSPECTION_BY_IDS, params);
    },
    postUpdateHandle: async (params) => {
        return Api.post(POST_UPDATE_HANDLE_ABNORMAL_ON_FIELD, params);
    },
    postSenData: async (params) => {
        return Api.post(POST_SEND_INSPECTION_TO_RENTAL, params);
    },
};