<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title" style="font-size: 20px !important">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
      :validate-rule="validateRule"
      searchLabel="会社名"
    >
      <template>
        <v-list-item ripple>
          <v-list-item-content @click="$emit('close', true)">
            <v-list-item-title class="company_name"
              >未登録の会社を新規登録</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list
        two-line
        style="max-height: 600px; overflow-y: auto"
        id="infinite-login-company-list"
      >
        <div v-if="formValues.items.length > 0">
          <template v-for="(item, index) in formValues.items">
            <v-list-item :key="index" ripple>
              <v-list-item-content @click="onClick(item.company_id)">
                <v-list-item-title class="company_name">{{
                  item.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.address }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
        <div v-else-if="isNoDataMessage" style="text-align: center">
          <span class="no_data_message">{{ NO_DATA_MESSAGE }}</span>
        </div>
      </v-list>
      <transition name="fade">
        <div class="loading" v-show="loading">
          <span class="fa fa-spinner fa-spin"></span>Loading
        </div>
      </transition>
    </SearchList>
  </v-card>
</template>

<script>
import SearchList from "@/components/forms/elements/SearchList.vue";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";
import { Store } from "@/store/Store.js";

export default {
  components: {
    SearchList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        // 検索ワード
        keyword: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    },
    onSearch: Function,
    close: Function,
    isNoDataMessage: Boolean,
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE,
      isReset: false,
      loading: false,
      validateRule: ""
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["CompanyPortal/getListCompanyAll"],
      (newValue) => {
        if (newValue?.length == 0) {
          this.isReset = true;
        } else {
          this.isReset = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.checkScroll();
  },
  computed: {
    isCallAPI() {
      const paging = Store.getters["CompanyPortal/getPaginationCompany"];
      return (
        this.formValues.current_page < paging.total && paging.total_item != 0
      );
    },
  },
  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.validateRule = "search-value-require";
      this.$emit("onSearch", { keyword: this.keyword });
    },
    formUpdate(values) {
      this.keyword = values.keyword;
    },
    // idの更新
    onClick(id) {
      this.$emit("formUpdate", id);
    },
    checkScroll() {
      const listElm = document.getElementById("infinite-login-company-list");
      listElm.addEventListener("scroll", () => {
        if ((listElm.scrollTop + listElm.clientHeight >= (listElm.scrollHeight - 1)
          && this.isCallAPI && !this.isReset)) {
          this.loadMore();
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      });
    },
    loadMore() {
      this.$emit("onSearch", { isLoadMore: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.title {
  font-weight: bold !important;
  color: #1b9c4f;
}

.company_name {
  font-size: 16px !important;
  font-weight: bold !important;
}
.no_data_message {
  font-size: 16px !important;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: gray;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
