import Vue from "vue";
import Vuex from "vuex";
import { notification } from "@/api/modules/notification";

Vue.use(Vuex);

export const Notification = {
  namespaced: true,

  state: {
    items: [],
    isActive: false,
    isNewNoticeStatus: 0,
    isLoading: false
  },

  mutations: {
    ACTIVE(state, payload) {
      state.items = payload;
      state.isActive = true;
    },
    DEACTIVE(state) {
      state.items = [];
      state.isActive = false;
    },
    UPDATED(state, payload) {
      state.isNewNoticeStatus = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async show({ commit }, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await notification.get();
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (!response.hasError) {
        commit("ACTIVE", response.data.contents.entries);
      }
      return response;
    },
    close({ commit }) {
      commit("DEACTIVE");
    },
    async countUnreadNotification({ commit }) {
      const response = await notification.countUnreadNotification();
      if (!response.hasError) {
        commit("UPDATED", response.data.contents.entries.new_notice_status);
      }
    },

    async readNotificationByUser(_context, payload) {
      return await notification.readNotificationByUser(payload);
    },
  },

  getters: {
    getState: (state) => {
      return state;
    },
    getItems: (state) => {
      return state.items;
    },
    countUnreadNotification: (state) => {
      return state.isNewNoticeStatus;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
