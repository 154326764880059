import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_COMPANY_BY_ID = `${SITES_BASE_URL}/fields/get_list_partner_company_by_field_id`;
const GET_LIST_PARTNER_LEADER = `${SITES_BASE_URL}/fields/get_list_partner_leader_in_field`;
const POST_ADD_MACHINE = `${SITES_BASE_URL}/machine_requests/add_machine_request`;
const GET_INFO_ID = `${SITES_BASE_URL}/fields/get_field_info_by_id`;

export const machine = {
    getCompanyId: (params) => {
        return Api.get(GET_COMPANY_BY_ID, {params});
    },
    getListPartnerLeader: (params) => {
        return Api.get(GET_LIST_PARTNER_LEADER, {params});
    },
    postAddMachine: (newMachine) => {
        return Api.post(POST_ADD_MACHINE, newMachine);
    },
    getInfo: (params) => {
        return Api.get(GET_INFO_ID, {params});
    },
};