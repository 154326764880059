<template>
  <div>
    <div class="sticky">
      <v-row no-gutters>
        <v-col cols="9" sm="9" class="pa-3">
          <!-- 検索ワード -->
          <span class="input-item">
            <SearchInputText
              name="keyword"
              :values="formValues"
              :placeholder="title_search"
              validation_label="検索ワード"
              :validation_rules="rule"
              :editable="true"
              :clearable="true"
              prependInnerIcon="mdi-magnify"
              @onInput="onInput"
              @keyEnter="$emit('search')"
            />
          </span>
          <span class="text-required">入力した文字そのままで検索します。</span>
        </v-col>
        <v-col cols="3" sm="3" class="pt-3 pr-2">
          <!-- 検索ボタン -->
          <v-btn @click="$emit('search')" color="primary">検索</v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import SearchInputText from "@/components/forms/elements/SearchInputText.vue";

export default {
  data() {
    return {
      values: {
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    };
  },

  components: {
    SearchInputText,
  },

  props: {
    formValues: Object,
    validateRule: String,
    title_search: {
      type: String,
      default: "検索"
    }
  },

  async mounted() {
    // formに表示する値を更新
    this.$watch(
      () => this.formValues,
      (newValue) => {
        if (newValue) this.values = newValue;
      },
      { immediate: true, deep: true }
    );
  },

  computed: {
    rule() {
      return this.validateRule ? "max:255|" + this.validateRule : "max:255";
    },
  },

  methods: {
    // idの更新
    onClick(id) {
      this.values.selected = id;
      this.$emit("formUpdate", this.values);
    },

    onInput({ name, value }) {
      this.values[name] = value;
      this.$emit("formUpdate", this.values);
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: white;
}
.input-item {
  position: relative;
}
.text-required {
  position: absolute;
  margin-top: -22px;
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
}
</style>
