<template>
  <div ref="self" class="default">
    <div ref="mainHeader" class="mainHeader">
      <slot name="mainHeader" />
    </div>
    <div class="page" :class="$sp ? 'sp' : ''" :style="getPageStyle" @click="close">
      <slot name="page" :layoutParams="params" />
    </div>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";

export default {
  data: () => {
    return {
      headerHeight: 48, //mainHeaderのヘッダーの高さ
      params: {
        windowHeight: 0,
        mainHeaderHeight: 0,
        pageHeight: 0,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getWindowSize();
    });
    window.addEventListener("resize", this.getWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getWindowSize);
  },
  computed: {
    getPageStyle() {
      //全体の高さから、ヘッダーの高さを引く
      let style = `height:${this.params.pageHeight}px;`;
      return style;
    },
  },
  methods: {
    getWindowSize() {
      const self = this.$refs.self;
      const mainHeader = this.$refs.mainHeader;
      if (self && mainHeader) {
        //全体のサイズを取得
        const mainHeaderRect = mainHeader.getBoundingClientRect();
        //params全体を更新
        let _params = { ...this.params };
        //ヘッッダーの高さを引いてMainを算出
        _params.pageHeight = window.innerHeight - this.headerHeight;
        _params.mainHeaderHeight = mainHeaderRect.height;
        _params.windowHeight = window.innerHeight;
        this.params = _params;
      }
    },

    close() {
      Store.dispatch("Drawer/close");
    },
  },
};
</script>
<style lang="scss" scoped>
.mainHeader {
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  z-index: 99;
}
.page {
  margin-top: 48px;
}
</style>
