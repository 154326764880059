import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/prefectures`;
const GET_LIST_PREFECTURES = `${SITES_BASE_URL}/get_list_prefectures`;

// Branch master field
export const prefectures = {
  get: () => {
    return Api.get(GET_LIST_PREFECTURES);
  },
};
// end
