import { render, staticRenderFns } from "./AddFileDialog.vue?vue&type=template&id=c2365eb2&scoped=true&"
import script from "./AddFileDialog.vue?vue&type=script&lang=js&"
export * from "./AddFileDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddFileDialog.vue?vue&type=style&index=0&id=c2365eb2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2365eb2",
  null
  
)

export default component.exports