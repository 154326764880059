import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/cruds`;
const GET_LIST_URL = `${SITES_BASE_URL}/list`;
const DELETE_URL = `${SITES_BASE_URL}/delete`;
const GET_DETAIL_SITE_URL = `${SITES_BASE_URL}/detail`;
const EDIT_URL = `${SITES_BASE_URL}/update`;
const CREATE_URL = `${SITES_BASE_URL}/create`;

export const cruds = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  add: (params) => {
    return Api.post(CREATE_URL, params);
  },
  edit: async (params) => {
    return Api.post(EDIT_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  getDetail: async (params) => {
    return Api.get(`${GET_DETAIL_SITE_URL}`, params);
  }
};
