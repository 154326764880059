<template>
  <div>
    <DefaultLayout :key="keyTab">
      <template #mainHeader>
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab1"
          >
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :updateHeader="updateHeader"
              :isClassify="false"
              :isDelete="false"
              :isAddNew="false"
            >
              <SearchFormWrapper class="receive-order-custom-search-form-wrapper">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-start-desired-date:${apiParams.desired_delivery_date_to}`"
                >
                  <Label label="搬入希望日" class="title-input-item" style="max-width: 250px; min-width: 147px">
                    <InputDatepicker
                      name="desired_delivery_date_from"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-start-desired-date:${apiParams.desired_delivery_date_to}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <span class="mt-12">
                  <v-icon>mdi-tilde</v-icon>
                </span>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-end-desired-date:${apiParams.desired_delivery_date_from}`"
                >
                  <Label label="搬入希望日" class="title-input-item" style="max-width: 250px; min-width: 147px">
                    <InputDatepicker
                      name="desired_delivery_date_to"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-end-desired-date:${apiParams.desired_delivery_date_from}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-start-order-date:${apiParams.order_date_to}`"
                >
                  <Label label="受注日" class="title-input-item" style="max-width: 250px; min-width: 147px">
                    <InputDatepicker
                      name="order_date_from"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-start-order-date:${apiParams.order_date_to}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <span class="mt-12">
                  <v-icon>mdi-tilde</v-icon>
                </span>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`compare-end-order-date:${apiParams.order_date_from}`"
                >
                  <Label label="受注日" class="title-input-item" style="max-width: 250px; min-width: 147px">
                    <InputDatepicker
                      name="order_date_to"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-end-order-date:${apiParams.order_date_from}`"
                      :editable="true"
                      :flagNull="true"
                    />
                  </Label>
                </ValidationProvider>
                <Label label="元請" class="title-input-item">
                  <Select
                    name="request_company_id"
                    :items="listRequestCompanies"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="現場" class="title-input-item">
                  <Select
                    name="request_field_id"
                    :items="listRequestFields"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                  />
                </Label>
                <Label label="ステータス" class="title-input-item receive-order-status-select">
                  <TabSelect
                    class="status-item"
                    name="tmp_machine_request_details_status"
                    :items="STATUS_TAB"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeStatus"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!--
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--
                (共通) ソート
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                class="ml-2"
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams(...arguments, invalid)"
              />
            </TableSortWrapper>
          </template>

          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="cst-row" :key="keyTab">
                <v-tab
                  v-for="tab in TAB_RECEIVE_ORDER"
                  :key="tab.id"
                  @click="changeTab(tab.id)"
                  class="tab-machine"
                >
                  <span>
                    {{ tab.name }}
                  </span>
                  <span>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[0].id && totalNotResponseRequests > 0">
                    mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[1].id && totalNotReportRequest > 0">
                      mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[2].id && totalListReturnRequestToRental > 0">
                      mdi-alert-circle
                    </v-icon>
                  </span>
                </v-tab>
              </v-tabs>
            </template>

            <template>
              <v-btn
                class="mt-2 ml-4 primary"
                depressed
                @click="openModalRequestInformation()"
                :disabled="selectedItems?.length>0 ? false : true"
              >
                回答
              </v-btn>
            </template>

            <v-data-table
              item-key="idx"
              v-model="selectedItems"
              :items="items"
              :headers="TABLE_LABEL"
              :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 90: tableHeight - 135"
              :items-per-page="searchParams.pageCount"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-1 v-data-table__wrapper"
              show-select
              @item-selected="updateSelectedItems"
              @toggle-select-all="selectAllItems"
              :noDataText="NO_DATA_MESSAGE"
              @update:searchParams.currentPage="$vuetify.goTo(0)"
              :key=searchParams.currentPage
              style="box-shadow: none !important;"
            >
              <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-simple-checkbox
                  :value="isSelected"
                  v-if="item.status == DELIVERY_REQUEST_STATUS['STATUS_PENDING']"
                  @input="select($event)"
                >
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.status_flag`]="{ item }">
                <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                      white-space: nowrap
                    "
                >
                  <span
                    class="receive-order-status-text"
                    v-if="item.status == DELIVERY_REQUEST_STATUS['STATUS_PENDING']"
                    style="background-color: #E5951D; color: #FFFFFF;"
                  >
                    {{ convertName(MACHINE_REQUEST_DETAILS_STATUS[0].name, 40) }}
                  </span>
                  <span
                    class="receive-order-status-text"
                    v-if="item.status == DELIVERY_REQUEST_STATUS['STATUS_ACCEPT']"
                    style="background-color: #6CA955; color: #FFFFFF"
                  >
                    {{ convertName(MACHINE_REQUEST_DETAILS_STATUS[1].name, 40) }}
                  </span>
                  <span
                    class="receive-order-status-text"
                    v-if="item.status == DELIVERY_REQUEST_STATUS['STATUS_CANCEL']"
                    style="background-color: rgba(0, 0, 0, 0.38); color: #FFFFFF"
                  >
                    {{ convertName(MACHINE_REQUEST_DETAILS_STATUS[2].name, 40) }}
                  </span>
                </div>
              </template>
              <template v-slot:[`item.m_order_at`]="{ item }">
                <span style="vertical-align: middle">{{ formatDate(item.m_order_at) }}</span>
              </template>
              <template v-slot:[`item.field_name`]="{ item }">
                <span style="vertical-align: middle">{{ item.field_name }}</span>
              </template>
              <template v-slot:[`item.main_company_name`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                  "
                >
                  <span>
                    {{ convertName(item.main_company_name, 50) }}{{getSpaceFullSize()}}{{ getFullName(item) }}
                  </span>
                  <div>
                    {{ item.phone }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.m_desired_delivery_date`]="{ item }">
                <div v-if="item.status != DELIVERY_REQUEST_STATUS['STATUS_CANCEL']">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      vertical-align: middle;
                    "
                  >
                    {{ convertDate(item.m_desired_delivery_date) }}
                  </div>
                  <div v-if="item.m_desired_delivery_type != null">
                    <div v-if="item.m_desired_delivery_type == 3">
                      {{ formatTime(item.m_desired_delivery_time) }}
                    </div>
                    <div v-else >
                      {{ TIME[item.m_desired_delivery_type].name }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.m_order_at`]="{ item }">
                  {{ formatDate(item.m_order_at) }}
              </template>
              <template v-slot:[`item.main_company_name`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                  "
                >
                  <span>
                    {{ convertName(item.main_company_name, 50) }}{{getSpaceFullSize()}}{{ getFullName(item) }}
                  </span>
                  <div>
                    {{ item.phone }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.gate_name`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                    vertical-align: middle;
                  "
                  v-if="item.status != DELIVERY_REQUEST_STATUS['STATUS_CANCEL']"
                >
                  {{ item.gate_name }}
                </div>
              </template>
              <template v-slot:[`item.spec`]="{ item }">
                <div
                  style="
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                  "
                >
                  {{ getSpec(item) }}
                </div>
              </template>
              <template v-slot:[`item.special_feature`]="{ item }">
                <v-tooltip
                  bottom color="#0082D8"
                  max-width="400px"
                  content-class="custom-color-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.special_feature"
                      v-bind="attrs"
                      v-on="on"
                      color="#000000"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <div class="feature-text">
                    {{ item.special_feature }}
                  </div>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
              style="background: white; position: fixed; width: 100%; bottom: 0;"
            />
          </template>
        </TableLayout>
        </ValidationObserver>
        <ModalRequestInformation
          v-if="isOpenModalRequestInformation"
          :isShow="isOpenModalRequestInformation"
          :listMachineRequestId="selectedItems"
          @close="isOpenModalRequestInformation = false"
          @callGetItems="getItems"
          @callGetListTotalRequest="getListTotalRequest"
        />
      </template>
    </DefaultLayout>

  </div>
</template>

<script>
import TableLayout from "@/components/layout/TableLayout";
import {Store} from "@/store/Store";
import {HEADER_MENU_ITEMS_RENTAL} from "@/constants/GLOBALHEADER";
import DefaultLayout from "@/components/layout/DefaultLayout";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import Loading from "@/components/loading/Loading";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import ModalRequestInformation from "@/views/receiveOrder/components/ModalRequestInformation";

import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Pagination from "@/components/masterTable/elements/Pagination";

import moment from "moment/moment";
import { convertName } from "@/common/helper";

import {
TABLES_PER_PAGE,
TABLE_SORT_ORDERS,
NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import {
  TAB_RECEIVE_ORDER,
  RECEIVE_ORDERS_STATUS_TAB_1,
  RECEIVE_ORDERS_SORT_TAB_1,
  RECEIVE_ORDERS_LABEL_TAB_1,
  MACHINE_REQUEST_DETAILS_STATUS,
  SORT_PARAMS_TAB_DELIVERY_REQUEST,
  DELIVERY_REQUEST_STATUS
} from "@/constants/RECEIVE_ORDERS";

import {
  LIFTING,
  UNDERCARRIAGE,
  WORKING_FLOOR,
  TIME
} from "@/constants/RECEIVE_ORDERS";
import {ValidationProvider, ValidationObserver} from "vee-validate";

const STORE = "ReceiveOrders";
const PAGE_TITLE = "受注";
const SORT_ORDERS = TABLE_SORT_ORDERS;
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
const STATUS_TAB = RECEIVE_ORDERS_STATUS_TAB_1;
const SORT_ITEMS = RECEIVE_ORDERS_SORT_TAB_1;
const TABLE_LABEL = RECEIVE_ORDERS_LABEL_TAB_1;
const PAGE_COUNT = 25;
const TAB_SELECT = 0;

export default {
  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },

  data() {
    return {
      searchParams: {
        order_date_from: "",
        order_date_to: "",
        desired_delivery_date_from: "",
        desired_delivery_date_to: "",
        tmp_machine_request_details_status: [0],
        machine_request_details_status: [],
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: "", //sortする項目
        asc: true,
        request_field_id: null,
        request_company_id: null
      },
      PAGE_TITLE,
      isLoading: false,
      listRequestFields: [],
      listRequestCompanies: [],
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      STATUS_TAB,
      SORT_ITEMS,
      TAB_RECEIVE_ORDER,
      LIFTING,
      UNDERCARRIAGE,
      WORKING_FLOOR,
      TIME,
      MACHINE_REQUEST_DETAILS_STATUS,
      SORT_PARAMS_TAB_DELIVERY_REQUEST,
      tab: 0,
      items: [],
      selectedItems: [],
      TABLE_LABEL,
      DELIVERY_REQUEST_STATUS,
      defaultOption: { id: null, name: "" },
      isOpenModalRequestInformation: false,
      totalPendingItems: 0,
      totalNotResponseRequests: 0,
      totalNotReportRequest: 0,
      totalListReturnRequestToRental: 0,
      keyTab: 0,
    }
  },

  components: {
    SearchFormWrapper,
    TableHeader,
    TableLayout,
    InputDatepicker,
    Label,
    Select,
    TabSelect,
    TableSortWrapper,
    TableSort,
    Pagination,
    //共通のコンポーネント
    DefaultLayout,
    CompanyHeader,
    Loading,
    ModalRequestInformation,
    ValidationProvider,
    ValidationObserver,
  },

  async mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * グローバルヘッダーメニューを更新
     */
    await Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_RENTAL.ORDER.id,
    });

    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotResponseRequests`],
      (data) => {
        this.totalNotResponseRequests = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotReportRequest`],
      (data) => {
        this.totalNotReportRequest = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalListReturnRequestToRental`],
      (data) => {
        this.totalListReturnRequestToRental = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    await this.getItems();
    this.keyTab ++;

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.totalPendingItems = 0;
        this.items = _items.map((x, index) => {
          if (x.status == DELIVERY_REQUEST_STATUS['STATUS_PENDING']) {
            this.totalPendingItems += 1;
          }
          x.idx= index;
          return {...x}
        });

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    await this.getListFieldAndCompany();


  },

  computed: {
    apiParams() {
      return {
        machine_request_details_status: this.searchParams?.machine_request_details_status.length > 0 ? this.searchParams.machine_request_details_status : [DELIVERY_REQUEST_STATUS['STATUS_PENDING']],
        order_date_from: this.searchParams?.order_date_from ? this.searchParams?.order_date_from?.replaceAll("/", "-") : "",
        order_date_to: this.searchParams?.order_date_to ? this.searchParams?.order_date_to?.replaceAll("/", "-") : "",
        desired_delivery_date_from: this.searchParams?.desired_delivery_date_from ? this.searchParams?.desired_delivery_date_from?.replaceAll("/", "-") : "",
        desired_delivery_date_to: this.searchParams?.desired_delivery_date_to ? this.searchParams?.desired_delivery_date_to?.replaceAll("/", "-") : "",
        request_field_id: this.searchParams?.request_field_id,
        request_company_id: this.searchParams?.request_company_id,
        sort_value: this.searchParams?.sort ? SORT_PARAMS_TAB_DELIVERY_REQUEST[this.searchParams?.sort-1] : SORT_PARAMS_TAB_DELIVERY_REQUEST[0],
        sort_by: this.searchParams?.asc ? 1 : 0,
        page: this.searchParams?.currentPage,
        page_count: this.searchParams?.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    }
  },

  methods: {
    // データ取得
    async getItems() {
      this.selectedItems = [];
      await Store.dispatch(`${STORE}/getListDeliveryRequest`, {
        params: this.apiParams,
      });
    },

    formatDate(date) {
      if (date) {
        const format = "YYYY/MM/DD";
        const now = new Date(date);
        return moment(now).format(format);
      }
    },

    formatTime(time) {
      const format = "HH:mm";
      const oldFormat = "HH:mm:ss";
      return moment(time, oldFormat).format(format);
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    convertDate(date) {
      return date.replaceAll("-", "/");
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      if (name === "request_company_id") {
        if (value) {
          this.callApiListFieldByOwnerCompany(value);
        }
        if (!value) {
          this.listRequestFields = [this.defaultOption];
        }
        if (this.searchParams?.request_company_id !== value) {
          this.searchParams.request_field_id = null;
        }
      }
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab1.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     * @param invalid
     */
    onChangeSortParams({name, value}, invalid) {
      if (!invalid) {
        let searchParams = {...this.searchParams};
        searchParams[name] = value;
        name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
        this.searchParams = searchParams;
        this.getItems();
        this.selectedItems = [];
      }
    },

    getFloor(floorHeightMin, floorHeightMax) {
      if (floorHeightMin === null && floorHeightMax !== null) {
        return `～${ floorHeightMax }m`;
      } else if (floorHeightMax === null & floorHeightMin !== null) {
        return `${ floorHeightMin }m～`;
      } else if (floorHeightMin !== null && floorHeightMax !== null) {
        return `${ floorHeightMin }m～${ floorHeightMax }m`;
      } else {
        return ``;
      }
    },

    getSpec(item) {
      let spec = this.getFloor(item.floor_height_min, item.floor_height_max) + this.getSpaceFullSize() + LIFTING[item.lifting_method].name;
      if (item.undercarriage != 0) {
        spec += this.getSpaceFullSize() + UNDERCARRIAGE[item.undercarriage].name;
      }
      if (item.working_floor != 0) {
        spec += this.getSpaceFullSize() + WORKING_FLOOR[item.working_floor].name;
      }
      return spec;
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch(invalid) {
      if (!invalid) {
        this.searchParams["currentPage"] = 1;
        this.getItems();
      }
    },

    onChangeStatus({name, value}) {
      let machine_request_details_status = [];
      let temp_machine_request_details_status = [];
      if (value.length > 0) {
        if (value.includes(0)) {
          temp_machine_request_details_status.push(0);
          machine_request_details_status.push(DELIVERY_REQUEST_STATUS['STATUS_PENDING']);
        }
        if (value.includes(1)) {
          temp_machine_request_details_status.push(1);
          machine_request_details_status.push(DELIVERY_REQUEST_STATUS['STATUS_ACCEPT'], DELIVERY_REQUEST_STATUS['STATUS_CANCEL']);
        }
      } else {
        machine_request_details_status.push(DELIVERY_REQUEST_STATUS['STATUS_PENDING'], DELIVERY_REQUEST_STATUS['STATUS_ACCEPT'], DELIVERY_REQUEST_STATUS['STATUS_CANCEL'])
      }
      let searchParams = { ...this.searchParams };
      searchParams[name] = temp_machine_request_details_status;
      searchParams["machine_request_details_status"] = machine_request_details_status;
      this.searchParams = searchParams;
    },

    changeTab(id) {
      this.getListTotalRequest();
      Store.dispatch(`${STORE}/setSelectedTab`, id);
      if (id === TAB_SELECT) {
        this.getItems()
      }
    },

    getFullName: function (item) {
      return `${item.name_sei} ${item.name_mei}`
    },

    openModalRequestInformation() {
      this.isOpenModalRequestInformation = true;
    },
    getSpaceFullSize(){
      return "　";
    },
    selectAllItems(value) {
      if (this.selectedItems.length == this.totalPendingItems) {
        this.selectedItems = []
      } else {
        this.selectedItems = [];
        let idSet = new Set();
        if (value.value) {
          value.items.forEach((item) => {
            if (!idSet.has(item.idx) && item.status == DELIVERY_REQUEST_STATUS['STATUS_PENDING']) {
              this.selectedItems.push(item);
              idSet.add(item.idx);
            }
          });
        }
      }
    },
    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
          (element) => element.idx === value.item.idx && element.status === DELIVERY_REQUEST_STATUS['STATUS_PENDING']
        )
        : [];
      if (currentItems.length > 0) {
        this.selectedItems.push(currentItems[0]);
      }
      else {
        this.selectedItems = this.selectedItems.filter(
          (item) => item.idx !== value.item.idx
        );
      }
    },
    async getListFieldAndCompany(){
      this.listRequestFields = [this.defaultOption];
      let listMainConstructor = await Store.dispatch("Fields/getListMainConstructorByUser");
      let dataCompany = listMainConstructor?.data?.contents?.entries;
      this.listRequestCompanies = [this.defaultOption, ...dataCompany];
    },
    async callApiListFieldByOwnerCompany(id) {
      let field = await Store.dispatch("Fields/getListFieldByOwnerCompany", {
        company_id: id,
      });
      let dataField = field?.data?.contents?.entries;
      this.listRequestFields = [this.defaultOption, ...dataField];
    },
    async getListTotalRequest(){
      let totalNotResponseRequestsResponse = await Store.dispatch(`${STORE}/countListDeliveryRequestNotResponse`);
      let totalNotResponseRequests = totalNotResponseRequestsResponse?.data?.contents?.totalNotResponseRequests;
      this.totalNotResponseRequests = totalNotResponseRequests;
      let totalNotReportRequestResponse = await Store.dispatch(`${STORE}/countListRepairRequestNotReport`);
      let totalNotReportRequest = totalNotReportRequestResponse?.data?.contents?.totalNotReportRequest;
      this.totalNotReportRequest = totalNotReportRequest;
      let totalListReturnRequestToRentalResponse = await Store.dispatch(`${STORE}/countListReturnRequestToRental`);
      let totalListReturnRequestToRental = totalListReturnRequestToRentalResponse?.data?.contents?.totalListReturnRequestToRental;
      this.totalListReturnRequestToRental = totalListReturnRequestToRental;
      this.keyTab = this.keyTab + 1;
    }
  }
}

</script>

<style scoped>
  .feature-text {
    overflow-wrap: break-word;
    white-space: pre-line;
  }
  .cst-row {
    border-bottom: 1px solid #cccccc;
    padding-left: 16px;
  }
  .receive-order-status-select .status-item {
    height: 50px !important;
  }
  .cst-row .tab-machine, .cst-row .tab-machine .icon-active, .cst-row .tab-machine span {
    color: #274FC2;
  }
  .icon-active{
    color: #FF0000 !important;
  }
  .custom-color-tooltip {
    opacity: 1!important;
  }
  .receive-order-status-text {
    height: 36px;
    min-width: 80px;
    padding: 0px 20px;
    border-radius: 20px;
    display: inline-block;
    line-height: 36px;
  }
  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding: 0;
    width: calc(100% + 32px);
    text-align: center !important;
  }

  ::v-deep .v-data-table>.v-data-table__wrapper table thead tr th:first-child {
  padding: 0;
  width: 5% !important;
  min-width: 5% !important;
  text-align: center !important;
}


  ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
    padding-left: 0;
  }

  ::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
    padding-left: 0 !important;
  }
</style>
<style>
  .receive-order-status-select .status-item .tabLabel {
    height: 30px;
    line-height: 25px;
  }
  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  }
  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper .v-messages .v-messages__message {
    line-height: 16px;
  }
</style>
