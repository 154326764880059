<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6" md="6" class="mt-3">
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="商品コード"
                              class="title-input-item"
                            >
                              <InputText
                                name="product_code"
                                :values="formValues"
                                validation_label="商品コード"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="機番"
                              class="title-input-item"
                            >
                              <InputText
                                name="machine_number"
                                :values="formValues"
                                validation_label="機番"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="使用開始日"
                              class="title-input-item"
                            >
                              <InputText
                                name="start_bookable_date"
                                :values="formValues"
                                validation_label="商品コード"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="使用終了日"
                              class="title-input-item"
                            >
                              <InputText
                                name="end_bookable_date"
                                :values="formValues"
                                validation_label="機番"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="商品名"
                              class="title-input-item"
                            >
                              <InputText
                                name="name"
                                :values="formValues"
                                validation_label="商品名"
                                validation_rules="max:255"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="メーカー"
                              class="title-input-item"
                            >
                              <InputText
                                name="maker"
                                :values="formValues"
                                validation_label="メーカー"
                                validation_rules="max:255"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="型式"
                              class="title-input-item"
                            >
                              <InputText
                                name="model"
                                :values="formValues"
                                validation_label="型式"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="ナンバープレート"
                              class="title-input-item"
                            >
                              <InputText
                                name="number_plate"
                                :values="formValues"
                                validation_label="ナンバープレート"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="シリアル番号"
                              class="title-input-item"
                            >
                              <InputText
                                name="ser"
                                :values="formValues"
                                validation_label="シリアル番号"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="Macアドレス"
                              class="title-input-item"
                              :editable="editable"
                            >
                              <InputText
                                name="mac_id"
                                :values="formValues"
                                validation_label="Macアドレス"
                                validation_rules="max:16"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mt-3">
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="現場認識番号"
                              class="title-input-item"
                              :editable="editable"
                            >
                              <InputText
                                name="field_identification_number"
                                :values="formValues"
                                :editable="editable"
                                validation_label="現場認識番号"
                                validation_rules="max:20"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth > 1024">
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="床高（m）"
                              class="title-input-item"
                              required
                            >
                              <InputText
                                name="floor_height"
                                :values="formValues"
                                validation_label="床高（m）"
                                validation_rules="required|decimal|decimalLimitOther"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth <= 1024">
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="床高（m）"
                              class="title-input-item"
                              required
                            >
                              <InputText
                                name="floor_height"
                                :values="formValues"
                                validation_label="床高（m）"
                                validation_rules="required|decimal|decimalLimitOther"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth > 1024">
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                            label="昇降"
                            class="title-input-item"
                          >
                            <RadioGroup
                              name="lifting_method"
                              :values="formValues"
                              :items="LIFTING"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                            label="足回り"
                            class="title-input-item"
                          >
                            <RadioGroup
                              name="undercarriage"
                              :values="formValues"
                              :items="UNDERCARRIAGE"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                            label="作業床"
                            class="title-input-item"
                          >
                            <RadioGroup
                              name="working_floor"
                              :values="formValues"
                              :items="WORKING_FLOOR"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-list-item>
                          <Label
                            label="駆動"
                            class="title-input-item"
                          >
                            <RadioGroup
                              name="drive"
                              :values="formValues"
                              :items="DRIVE"
                              @onInput="onInput($event)"
                            />
                          </Label>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row v-if="windowWidth <= 1024">
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                              <Label
                                label="昇降"
                                class="title-input-item"
                              >
                                <RadioGroup
                                  name="lifting_method"
                                  :values="formValues"
                                  :items="LIFTING"
                                  @onInput="onInput($event)"
                                />
                              </Label>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                              <Label
                                label="足回り"
                                class="title-input-item"
                              >
                                <RadioGroup
                                  name="undercarriage"
                                  :values="formValues"
                                  :items="UNDERCARRIAGE"
                                  @onInput="onInput($event)"
                                />
                              </Label>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <v-list-item-content>
                              <Label
                                label="作業床"
                                class="title-input-item"
                              >
                                <RadioGroup
                                  name="working_floor"
                                  :values="formValues"
                                  :items="WORKING_FLOOR"
                                  @onInput="onInput($event)"
                                />
                              </Label>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-list-item>
                            <Label
                              label="駆動"
                              class="title-input-item"
                            >
                              <RadioGroup
                                name="drive"
                                :values="formValues"
                                :items="DRIVE"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-list-item-content>
                            <Label
                              label="特殊機能"
                              class="title-input-item"
                            >
                            <InputTextArea
                              name="special_feature"
                              :values="formValues"
                              @onInput="onInput($event)"
                            />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
 <script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import {  LIFTING,
          UNDERCARRIAGE,
          WORKING_FLOOR,
          DRIVE } from "@/constants/MACHINE";
import _ from "lodash";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";

export default {
  data: () => {
    return {
      formValues: {},
      LIFTING,
      UNDERCARRIAGE,
      WORKING_FLOOR,
      DRIVE,
      windowWidth: window.innerWidth,
    };
  },
  components: {
    ValidationObserver,
    Label,
    InputText,
    RadioGroup,
    InputTextArea
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  async mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
 <style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
 