import Vue from "vue";
import Vuex from "vuex";
import {users} from "@/api/modules/users"

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = users
export const Users = {

  namespaced: true,

  state: {
    data_user_active: {},
    isLoading: {},
    data_user:{},
  },

  mutations: {
    SET_USER_ACTIVE(state, payload) {
      state.data_user_active = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_USER(state, payload) {
      state.data_user = payload;
    },
  },

  actions: {
    async getActiveUser({commit}, payload) {
      let params = {};
      if (payload?.keyword) params.namesearch = payload.keyword;
      if (payload?.company_id) params.company_id = payload.company_id;
      const response = await ENTITY.getActiveUser(params);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_USER_ACTIVE", entries);
      return response;
    },
    async getUserInfoById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getUserInfoById(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_USER", entries);
      return response;
    },
  },

  getters: {
    getUserActive: (state) => {
      return state.data_user_active;
    },
    getUser: (state) => {
      return state.data_user;
    },
  },
};
