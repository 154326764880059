<template>
  <div class="ml-10 mt-5 mr-6">
    <v-btn color="primary" @click="isShowAddFileDialog = true" v-if="editable">アップロード</v-btn>
    <v-card flat>
      <v-card-text>
        <v-form autocomplete="off">
          <v-container>
            <v-data-table
              :headers="DOCUMENT_TABLE_LABELS"
              :items="dataItems"
              :items-per-page="dataItems.length"
              :height="mainHeight - 55"
              fixed-header
              :noDataText="NO_DATA_MESSAGE"
              hide-default-footer
              hide-default-header
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              item-key="key"
            >
              <template v-slot:[`item.document_url`]="{ item }">
                <span class="mr-4"><v-icon :key="flagKey" @click="downloadDocument(item)" :disabled="isSubmitted[item.key]" color="black" class="icon">mdi-download</v-icon></span>
                <span class="mr-4" v-if="item.status"><v-icon @click="deleteDocument(item)" :disabled="!editable" color="black" class="icon">mdi-close</v-icon></span>
                <span class="mr-10" v-else></span>
              </template>
            </v-data-table>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <Popup width="480px" :dialog="isShowAddFileDialog">
      <AddFileDialog
        @close="isShowAddFileDialog = false"
        @yes="addFile"
      />
    </Popup>
  </div>
</template>
<script>

import _ from "lodash";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import AddFileDialog from "./components/AddFileDialog";
import { DOCUMENT_TABLE_LABELS } from "@/constants/DOCUMENT";
import { NO_DATA_MESSAGE } from "@/constants/COMMON.js";

export default {
  data: () => {
    return {
      items: [],
      dataItems: [],
      DOCUMENT_TABLE_LABELS,
      isSubmitted: {},
      timeout: null,
      isShowAddFileDialog: false,
      NO_DATA_MESSAGE,
      flagKey : false
    };
  },
  components: {
    Popup,
    AddFileDialog
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    arrayImages: {
      type: Array
    },
    mainHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {},
  mounted() {
    this.$watch(
      () => this.arrayImages,
      (newValue) => {
        if (Array.isArray(newValue)) {
          this.items = _.cloneDeep(newValue);
          this.dataItems = this.items.filter(e => e.document_deleted !== 1);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    addFile(data) {
      let _data = { ...data };
      let _items = [...this.items];
      _data["key"] = _items.length == 0 ? 1 : _items.at(-1).key + 1;
      _items.push(_data);
      this.isShowAddFileDialog = false;
      this.$emit("updateItems", _items);
    },

    deleteDocument(item) {
      let _items = [...this.items];
      if (item.document_url) {
        _items.forEach(e => {
          if (e.key == item.key) {
            e.document_deleted = 1;
            delete e.document_url;
            delete e.document;
            delete e.name;
          }
        });
      } else {
        _items = _items.filter(e => e.key != item.key);
      }
      this.$emit("updateItems", _items);
    },

    actionDownload(data,value){
      const link = document.createElement("a");
      link.href = data;
      link.setAttribute(
        "download",
        `${value}`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async downloadDocument(item) {
      this.isSubmitted[item.key] = true;
      this.flagKey = !this.flagKey;
      this.timeout = setTimeout(() => {
        this.isSubmitted[item.key] = false;
        this.flagKey = !this.flagKey;
      }, 1000);
      let _item = _.cloneDeep(item);
      if (item.document_url) {
        const params = getParamsOfUrlReadFile(_item.document_url);
        const response = await Store.dispatch("File/readFile", params);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
        this.actionDownload(url,item.document);
      } else {
        this.actionDownload(item.document_data,item.document);
      }
    },
    beforeDestroy() {
      clearTimeout(this.timeout);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../style/forms.scss";
.icon {
  cursor: pointer;
}
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  word-break: break-all;
}
</style>
