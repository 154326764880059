<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
       -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          <span style="color: #1b9c4f"> {{ formtitle }} </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          small
          outlined
          color="primary"
          class="mr-5"
          @click="onStopEdit"
          data-testid="btn-back"
        >
          やめる
        </v-btn>
        <v-btn
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagError"
          @click.once="onSubmit"
          data-testid="btn-submit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onStopEdit" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in COMPANIES_TAB"
              :key="key"
              :data-testid="`companiesForm-tab-${key}`"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <!-- フォームtab -->
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <CompaniesForm1
              :editable="true"
              :item="item"
              :mainHeight="params.mainHeight"
              :errors="errors"
              @formUpdate="formUpdateData"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
          (共通)
          ValidationObserverのスロットプロパティを受け取って
          updateValidateメソッドを実行して、
          バリデーション結果をスクリプト側に渡してます
        -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="clickClosePopup"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import CompaniesForm1 from "@/components/forms/companies/CompaniesForm1.vue";
import { COMPANIES_TAB } from "@/constants/COMPANY_PORTAL";

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "自社情報";

export default {
  data: () => {
    return {
      COMPANIES_TAB,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      isShowConfirmDialog: false,
      errors: {},
    };
  },
  props: {
    flagError: Boolean,
    item: Object,
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    CompaniesForm1,
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return FORM_TITLE;
    },
  },
  methods: {
    onStopEdit() {
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async onSubmit() {
      const dataItem = { ...this.item };
      this.$emit("onSubmitCompany", dataItem);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },
    closePopup() {
      this.isShowConfirmDialog = false;
    },
    formUpdateData(data) {
      this.$emit("updateForm", data);
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    clickClosePopup() {
      this.$emit("closeFormInit");
      this.isShowConfirmDialog = false;
    },
    onClose() {
      this.clickClosePopup();
    },
  },
};
</script>
