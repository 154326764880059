<template data ? data.length : 0>
  <ValidationObserver v-slot="{ invalid }" ref="observer">
    <v-dialog v-model="dialog" persistent :max-width="dynamicMaxWidth">
      <Loading v-if="isLoading"/>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="mr-6 mb-3 pl-8 pr-8 custom-opacity" v-bind="attrs" v-on="on" :disabled="machine == null"
          @click="validateCompany" depressed>
          予約
        </v-btn>
      </template>
      <v-card class="dialog custom-color" v-if="dialog && !isHasPlaceName">
        <v-card-title class="pt-1 pr-0 mb-4">
          <span class="title primary--text header-title">予約</span>
          <v-spacer></v-spacer>
          <Popup width="480px" :dialog="isShowCancel">
            <ConfirmCloseDialog v-model="isShowCancel" :title="WRN_0002.TITLE" :text1="WRN_0002.TEXT1"
              :text2="WRN_0002.TEXT2" :warning="true" @close="isShowCancel = false"
              @yes="cancelBooking()" />
          </Popup>
          <Popup width="480px" :dialog="isShowConfirmEdit">
            <ConfirmCloseDialog v-model="isShowConfirmEdit" :title="'選択項目の変更'"
              :text1="'選択したデータを変更します。<br/>点検実績がある場合でも予約を変更しますが'"
              :text2="'本当によろしいでしょうか?'" textSubmit="変更"
              :warning="true" @close="isShowConfirmEdit = false"
              @yes="updateBookingId()" />
          </Popup>
          <v-icon @click="isShowCancel = true">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="custom-radio">
              <v-col cols="12" sm="6" md="6">
                <Label label="日付（開始）" :editable="true" required>
                  <template class="col-md-1" :disabled="modePreview">
                    <v-icon color="black">mdi-calendar-today-outline</v-icon>
                  </template>
                  <v-menu 
                  offset-y nudge-left="20" 
                  nudge-bottom="5" 
                  v-model="isDateStart" 
                  min-width="auto"
                  :close-on-content-click="false" 
                  :disabled="isDisable || modePreview || otherLeaderBooking" 
                  class="col-md-4">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider v-slot="{ errors, valid }" name="日付（開始）" :rules="getRuleStartDate()" ref="startDate">
                        <v-text-field class="input-date pl-2 pr-2" v-model="computedDateStartFormatted"
                          readonly v-bind="attrs" v-on="on" :error-messages="errors" :success="valid" filled
                          @click="resetValidate()" dense>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker v-model="booking.use_start_date" color="primary" locale="ja"
                      @input="isDateStart = false"
                      :day-format="(use_start_date) => new Date(use_start_date).getDate(use_start_date)" no-title
                      scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="black" @click="clearDateStart">
                        クリア
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-radio-group 
                  v-model="booking.use_start_time" 
                  mandatory 
                  :disabled="isDisable || modePreview || otherLeaderBooking"
                    class="custom-inline" dense>
                    <v-radio v-for="item in formatDate" :key="item.value" :value="item.value" @click="setError">
                      <template #label>
                        <span class="rd-time">{{ item.label }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <span class="custom-inline headline mt-5">~</span>
                </Label>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <Label label="日付（終了）" :editable="true" :required="!booking.is_shared">
                  <template :disabled="modePreview || otherLeaderBooking">
                    <v-icon color="black">mdi-calendar-today-outline</v-icon>
                  </template>
                  <v-menu 
                  offset-y nudge-left="20" 
                  nudge-bottom="5" 
                  v-model="isDateEnd" 
                  min-width="auto"
                  :close-on-content-click="false" 
                  :disabled="modePreview || otherLeaderBooking">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider v-slot="{ errors, valid }" name="日付（終了）" :rules="getRuleEndDate()" ref="endDate">
                        <v-text-field class="input-date pl-2 pr-2" v-model="computedDateEndFormatted" readonly
                          v-bind="attrs" v-on="on" :error-messages="errors" :success="valid" filled dense>
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker v-if="isDateEnd" v-model="booking.use_end_date" no-title scrollable
                      @input="isDateEnd = false" :first-day-of-week="0" :max="allowedDates()"
                      :day-format="(use_end_date) => new Date(use_end_date).getDate(use_end_date)" locale="ja">
                      <v-spacer></v-spacer>
                      <v-btn text color="black" @click="clearDateEnd">
                        クリア
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-radio-group 
                  v-model="booking.use_end_time"    
                  :mandatory="!booking.is_shared" 
                  :disabled="modePreview || otherLeaderBooking" 
                  class="custom-inline" 
                  dense>
                    <v-radio v-for="item in formatDate" :key="item.value" :value="item.value"
                      :checked="item.value == 1 && isDelete" @click="setError">
                      <template #label>
                        <span class="rd-time">{{ item.label }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </Label>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <ValidationProvider v-slot="{ errors, valid }" name="使用会社" :rules="!booking.is_shared ? 'required' : ''">
              <Label label="使用会社" :editable="true" :required="!booking.is_shared">
                <div class="select-switch">
                  <v-select class="select mb-5" v-model="booking.use_company_id" :items="listCompany"
                    item-text="name" item-value="id" :disabled="disableSelected || modePreview || !!booking.is_shared" @change="clearLeader()"
                    :error-messages="errors" :success="valid" filled dense>
                  </v-select>
                  <v-switch
                    v-if="userRole === COMPANY_SCENE"
                    v-model="booking.is_shared"
                    label="共用にする"
                    class="booking-switch"
                    @change="onChangeShare"
                  ></v-switch>
                </div>
              </Label>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors, valid }" name="職長" :rules="!booking.is_shared ? 'required' : ''">
              <Label label="職長" :editable="true" :required="!booking.is_shared">
                <v-select class="select mb-9" v-model="booking.booking_user_id" :items="listLeader"
                  :item-text="getFullName" item-value="id" :disabled="disableSelected || modePreview || !!booking.is_shared"
                  :error-messages="errors" :success="valid" filled dense>
                </v-select>
              </Label>
            </ValidationProvider>
            <table class="col-lg-12 col-sm-12">
              <thead>
                <th v-for="item in headerBooking">{{ item.text }}</th>
              </thead>
              <tbody>
                <td>{{ machine ? machine.number_plate : '' }}</td>
                <td>{{ machine ? machine.floor_height : '' }}</td>
                <td>{{ machine ? machine.lifting_method : '' }}</td>
                <td>{{ machine ? machine.undercarriage : '' }}</td>
                <td>{{ machine ? machine.working_floor : '' }}</td>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-5 mr-5">
          <v-btn v-if="isDelete && !modePreview" color="darken-1 btn-delete-padding" icon @click="dialogDel = true"
            :disabled="modePreview || disableDel || otherLeaderBooking">
            <Popup width="480px" :dialog="dialogDel">
              <ConfirmCloseDialog v-model="dialogDel" :title="WRN_0001.TITLE" :text1="(flgInspectionResult === 1 && allowedCancelBooking === 1) ? '選択したデータを削除します。<br />点検実績がある場合、予約をキャンセルしますが<br />本当によろしいでしょうか？' : WRN_0001.TEXT1"
                :text2="WRN_0001.TEXT2" textSubmit="解除" :warning="true" @close="dialogDel = false"
                @yes="deleteBooking()" />
            </Popup>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!modePreview" class="ma-2 pl-5 pr-5" depressed outlined color="primary" @click="isShowCancel = true">
            やめる
          </v-btn>
          <v-btn v-if="!modePreview" :disabled="invalid || otherLeaderBooking || isSubmitted" depressed class="ma-2 pl-7 pr-7" color="primary" @click="active()">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="popup" v-if="isHasPlaceName">
        <ConfirmCloseDialog  v-model="isHasPlaceName" title="予約不可のお知らせ"
          text1="まだ荷受けしていない（留置場所未設定）ため、予約できません。" :btnDisable="true"
          :warning="true" @yes="closePopUpUnKnowPlaceName"/>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>
<script>
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import { booking } from "@/api/modules/booking";
import { format, parseISO } from "date-fns";
import { Store } from "../../../store/Store";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { FOMAT_DATE, HEADER_BOOKING_FORM } from "@/constants/BOOKING";
import { WRN_0001, WRN_0002 } from "@/constants/MESSAGE";
import moment from "moment";
import Label from "@/components/forms/elements/Label.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loading from "@/components/loading/Loading";

const ROLE_LEADER = 2;
import {
  COMPANY_SCENE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

export default {
  components: {
    ConfirmCloseDialog,
    Popup,
    Label,
    ValidationProvider,
    ValidationObserver,
    Loading
  },
  props: {
    isShowModalBooking: {
      type: Boolean,
    },
    machine: {
      type: Object,
    },
    bookingInfo: {
      type: Object,
    },
    bookingId: {
      type: Number,
    },
    searchParams: {
      type: Object,
    },
    selectedPlaceName: {
      type: String,
    }
  },
  data: () => ({
    booking: {
      field_id: "",
      field_machine_id: "",
      use_company_id: "",
      booking_user_id: "",
      use_start_date: "",
      use_start_time: FOMAT_DATE[0].value,
      use_end_date: "",
      use_end_time: FOMAT_DATE[1].value,
      is_shared: false,
      return_date: ""
    },
    listCompany: [],
    listLeader: [],
    info: {},
    dialog: false,
    dialogDel: false,
    isDateStart: false,
    isDateEnd: false,
    isError: false,
    isShowCancel: false,
    isDelete: false,
    createOrUpdate: false,
    isDisable: false,
    disableSelected: false,
    otherLeaderBooking:false,
    disableDel: false,
    modePreview: false,
    resetValidation: false,
    dateCurrent: "",
    dayDisable: "",
    formatDate: FOMAT_DATE,
    headerBooking: HEADER_BOOKING_FORM,
    fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id,
    userId: "",
    companyId: "",
    userRole: "",
    roleLeader: ROLE_LEADER,
    WRN_0001: WRN_0001,
    WRN_0002: WRN_0002,
    isLoading: false,
    SUBMIT_DELAY_TIMEOUT,
    isSubmitted: false,
    COMPANY_SCENE,
    isHasPlaceName: false,
    dynamicMaxWidth: '650px',
    allowedCancelBooking: '',
    flgInspectionResult: '',
    isShowConfirmEdit: false
  }),
  created() {
    this.getDate();
    this.getCompany();
    this.getInfo();
    this.getUserId();
    this.getUserCompanyId();
    this.getUserRole();
  },
  watch: {
    isShowModalBooking(newValue) {
      if (newValue) {
        this.dialog = newValue;
      }
    },
    dialog(newValue) {
      if (newValue == false) {
        this.$emit("close-booking-modal");
      }
    },
    company_id() {
      if (this.booking.use_company_id) {
        this.getLeader();
      }
    },

    'booking.use_end_date' (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.booking.use_end_time = this.booking.use_end_time === null ? FOMAT_DATE[1].value : this.booking.use_end_time;
      }
    }
  },
  computed: {
    //表示用にyyyy/MM/ddに変換
    computedDateStartFormatted() {
      return this.booking.use_start_date ? format(parseISO(this.booking.use_start_date), "yyyy/MM/dd") : "";
    },
    computedDateEndFormatted() {
      return this.booking.use_end_date ? format(parseISO(this.booking.use_end_date), "yyyy/MM/dd") : "";
    },
    company_id() {
      return this.booking.use_company_id;
    }
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name && formValues[name]) {
          //v-datepickerがyyyy-MM-dd形式で処理する
          const _date = new Date(formValues[name]);
          this.val = format(_date, "yyyy-MM-dd");
        } else {
          this.val = "";
        }
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data?.field_id !== oldData?.field_id) {
          this.getUserRole();
          this.getUserId();
          this.getUserCompanyId();
          this.getFieldId();
          this.getCompany();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
  },
  methods: {
    allowedDates() {
      if (this.machine.return_date && this.booking.use_end_date <= this.machine.return_date) {
        return this.machine.return_date;
      }
      return "";
    } ,
    async getBookingInfo(param) {
      const currentTime = new Date();
      this.isDisable = false;
      this.isLoading = true;
      let response = await booking.getBookingInfoId({ id: param });
      if (response.data && response.data.status_code === 200) {
        this.booking = response.data.contents.entries;
        if (moment(this.booking.use_start_date).format('YYYY-MM-DD') < moment(this.dateCurrent).format('YYYY-MM-DD') ||
            (moment(this.booking.use_start_date).format('YYYY-MM-DD') === moment(this.dateCurrent).format('YYYY-MM-DD')
            && this.booking.use_start_time === 0 && currentTime.getHours() >= 12)
        ) {
          this.isDisable = true;
        }
        if (moment(this.booking.use_end_date) < moment(this.dateCurrent) ||
          (this.booking.booking_user_id !== this.userId && this.userRole !== 1) && this.createOrUpdate) {
          this.modePreview = true;
        }
      }
      this.getLeader();
      this.isLoading = false;
    },
    async getInfo() {
      let response = await booking.getInfo({ field_id: this.fieldId });
      if (response.data && response.data.status_code === 200) {
        this.info = response.data.contents;
      }
    },
    async getCompany() {
      let response = await booking.getCompanyId({ field_id: this.fieldId });
      if (response.data && response.data.status_code === 200) {
        this.listCompany = response.data.contents.entries;
      }
    },
    async getLeader() {
      if (!this.booking.use_company_id) return
      let param = this.booking.use_company_id;
      let response = await booking.getListPartnerLeader({ field_id: this.fieldId, company_id: param });
      if (response.data && response.data.status_code === 200) {
        this.listLeader = response.data.contents.entries;
      }
    },
    async addBookingId() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.booking.field_id = this.fieldId;
      this.formatParams();
      let response = await booking.postAddBooking(this.booking);
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        this.cancelBooking();
        this.dialog = false;
        this.$emit("reload-data");
      }
    },

    formatParams () {
      this.booking.is_shared = this.booking.is_shared ? 1 : 0;
      if (this.machine.return_date) {
        this.booking.return_date = this.machine.return_date;
      }
    },

    async updateBookingId() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.booking.field_id = this.fieldId;
      this.formatParams()
      this.booking.is_web = true;
      let response = await booking.postUpdateBooking(this.booking);
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.cancelBooking();
        this.dialog = false;
        this.$emit("reload-data");
      }
    },
    async deleteBookingId() {
      let response = await booking.postDeleteBooking({ id: this.booking.id, field_id: this.fieldId });
      if (response.data.status_code === 400 || response.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: response.data.message,
        });
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.cancelBooking();
        this.dialog = false;
      }
      this.dialogDel = false;
      this.$emit("reload-data");
    },
    momentFormat: function (date) {
      return moment(new Date(date)).format('YYYY-MM-DD');
    },
    getFullName: function (item) {
      return `${item.name_sei}  ${item.name_mei}`
    },
    getDate: function () {
      this.dialog = this.isShowModalBooking;
      let date = new Date();
      this.dateCurrent = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return this.dateCurrent + 1;
    },
    cancelBooking() {
      this.booking = {
        field_id: "",
        field_machine_id: "",
        use_company_id: "",
        booking_user_id: "",
        use_start_date: "",
        use_start_time: FOMAT_DATE[0].value,
        use_end_date: "",
        use_end_time: FOMAT_DATE[1].value
      }
      this.isShowCancel = false;
      this.dialog = false;
      this.isDelete = false;
      this.isDisable = false;
      this.createOrUpdate = false;
      this.disableSelected = false;
      this.otherLeaderBooking=false;
      this.listLeader = [];
      this.modePreview = false;
      this.disableDel = false;
      this.resetValidation = false;
      this.isShowConfirmEdit = false
    },
    active() {
      if (this.createOrUpdate) {
        if (this.allowedCancelBooking === 1 && this.flgInspectionResult === 1) {
          this.isShowConfirmEdit = true
        } else {
          this.updateBookingId()
        }
      }
      else {
        this.addBookingId()
      }
    },
    deleteBooking: function () {
      this.deleteBookingId()
    },
    setFlg(booked) {
      this.allowedCancelBooking = booked?.allowed_cancel_booking;
      this.flgInspectionResult = booked?.flg_inspection_result;
    },
    async openModalBooking(booking_id = null, bookingInfo = null, selectedPlaceName=null) {
      this.dialog = true;
      this.isDisable = false;
      if(!selectedPlaceName || !selectedPlaceName.trim()) {
          this.isHasPlaceName = true;
          this.dynamicMaxWidth = '480px'
      } else {
          this.isHasPlaceName = false;
          this.dynamicMaxWidth = '650px'
      }
      if (booking_id != null) {
        await this.getBookingInfo(booking_id);
        this.isDelete = true;
        this.createOrUpdate = true;
      }
      else {
        if (bookingInfo != null) {
          this.booking.field_id = this.fieldId;
          this.booking.use_start_date = this.momentFormat(bookingInfo.use_start_date);
          this.booking.use_end_date = this.momentFormat(bookingInfo.use_end_date);
          this.booking.use_start_time = Number(bookingInfo.use_start_time);
          this.booking.use_end_time = Number(bookingInfo.use_end_time);
          this.booking.field_machine_id = this.machine.field_machine_id;
          this.isDelete = false;
        }
      }
      if (this.userRole === this.roleLeader) {
        if (this.booking.use_company_id === "") {
          this.booking.use_company_id = this.companyId;
        }

        this.getLeader();

        if (this.booking.booking_user_id === "") {
          this.booking.booking_user_id = this.userId;
        }

        this.disableSelected = true;
        if (moment(this.booking.use_start_date).valueOf() < moment(this.dateCurrent).valueOf() || this.booking.booking_user_id != this.userId) {
          this.disableDel = true;
        }
        if(this.booking.booking_user_id != this.userId){
          this.otherLeaderBooking=true;
        }
      }
    },
    getUserId() {
      this.userId = JSON.parse(sessionStorage.getItem("USER")).Login.user.id;
    },
    getUserRole() {
      this.userRole = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    },
    getUserCompanyId() {
      this.companyId = JSON.parse(sessionStorage.getItem("USER")).Login.user.company_id;
    },
    getFieldId() {
      this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    },
    validateCompany() {
      if(!this.selectedPlaceName || !this.selectedPlaceName.trim()) {
          this.isHasPlaceName = true;
          this.dynamicMaxWidth = '480px'
      } else {
          this.isHasPlaceName = false;
          this.dynamicMaxWidth = '650px'
      }
      if(!this.isHasPlaceName) {
        this.searchParams.designated_date = this.momentFormat(this.searchParams.designated_date);
        let date = new Date();
        if (moment(this.searchParams.designated_date) >= moment(this.momentFormat(date))) {
          this.booking.use_start_date = this.searchParams.designated_date;
        } else {
          this.booking.use_start_date = this.momentFormat(date);
        }
        this.booking.field_machine_id = this.machine.field_machine_id;
        this.booking.use_end_date = new Date(this.booking.use_start_date);
        this.booking.use_end_date.setDate(this.booking.use_end_date.getDate() + this.info.max_bookable_days - 1);
        this.booking.use_end_date = this.momentFormat(this.booking.use_end_date);
        if (this.userRole === this.roleLeader) {
          this.booking.use_company_id = this.companyId;
          this.getLeader();
          this.booking.booking_user_id = this.userId;
          this.disableSelected = true;
        }
        if (this.booking.use_end_date > this.machine.return_date) {
          this.booking.use_end_date = this.machine.return_date
        }
      }
    },
    clearDateStart() {
      this.isDateStart = false;
      this.booking.use_start_date = "";
    },
    clearDateEnd() {
      this.isDateEnd = false;
      this.booking.use_end_date = null;
      if (this.booking.is_shared) this.booking.use_end_time = null;
    },
    onChangeShare (value) {
      if (value) {
        this.booking.booking_user_id = null;
        this.booking.use_company_id = null;
        if (!this.booking.use_end_date) this.booking.use_end_time = null;
      } else {
        this.booking.use_end_time = this.booking.use_end_time === null ? FOMAT_DATE[1].value : this.booking.use_end_time;
      }
    },
    clearLeader() {
      this.booking.booking_user_id = "";
      this.getLeader();
    },
    getRuleStartDate() {
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let rule = "";
      let ruleOrther = "";
      if (this.booking?.use_end_date) {
        const dateEnd = format(parseISO(this.booking?.use_end_date), "yyyy/MM/dd");
        rule = `err-start-date-am-pm:${dateEnd}, ${this.booking?.use_start_time}, ${this.booking?.use_end_time},日付（開始）,日付（終了）`;
      }
      if (!this.createOrUpdate || this.createOrUpdate && this.resetValidation) {
        ruleOrther = `err-005:${now},日付（開始）`;
      }
      return (
        "required|" + ruleOrther + "|" + rule
      );
    },
    getRuleEndDate() {
      let now = new Date();
      now = format(now, "yyyy/MM/dd");
      let rule = "";
      if (this.booking?.use_start_date) {
        const dateEnd = format(parseISO(this.booking?.use_start_date), "yyyy/MM/dd");
        rule = `err-end-date-am-pm:${dateEnd}, ${this.booking?.use_start_time}, ${this.booking?.use_end_time},日付（終了）,日付（開始）`;
      }
      return (
        (!this.booking.is_shared ? "required|" : '') + `err-005:${now},日付（終了）` + "|" + rule
      );
    },
    resetValidate() {
      this.resetValidation = true;
      if (this.isDisable) {
        this.resetValidation = false;
      }
    },
    setError() {
      if (moment(this.booking.use_start_date).valueOf() === moment(this.booking.use_end_date).valueOf() && this.booking.use_start_time > this.booking.use_end_time) {
        this.$nextTick(() => {
          this.$refs.startDate.setErrors(['日付（開始）は日付（終了）より前の日付を指定してください']);
          this.$refs.endDate.setErrors(['日付（終了）は日付（開始）以降の日付を指定してください']);
        })
      }
    },

    closePopUpUnKnowPlaceName() {
      this.dialog = false
      this.isHasPlaceName = false;
    },
  },
  emits: ['getHasPlaceName']
}
</script>
<style lang="scss" scoped>
.dialog {
  padding: 10px;

  .title {
    line-height: 24px;
    color: #274FC2;
    margin-left: -15px;
    font-size: 20px !important;
    font-weight: 600;

  }

  .labelWrapper {
    position: relative;
  }

  .input-date {
    border-radius: 2px;
    width: 146px;
    min-height: 52px;
    height: auto;
    font-size: 12px !important;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
  }

  .custom-inline {
    display: inline-block;
    position: absolute;
    top: 0;
    margin-top: 32px !important;
  }

  .headline {
    width: 20px;
    font-size: 30px !important;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-left: 85px;
    margin-top: 47px !important;
  }

  .rd-time {
    font-weight: 400;
    line-height: 24px;
    color: #000000;
  }

  .select {
    width: 298px;
    height: 54px;
  }

  table {
    height: 50px;
    text-align: justify;
    padding: 0;

    thead th {
      font-size: 12px !important;
      line-height: 16px;
      color: #000000;
      font-weight: 400;
    }

    tbody td {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  .btn-delete-padding {
    padding: 0 6px;
  }
}

.dialog.custom-color ::v-deep {
  .label {
    color: #000;
  }

  .v-messages__message {
    line-height: 18px;
  }
}
.theme--light.custom-opacity.v-btn:focus:before{
  background-color: transparent !important;
}
.custom-radio ::v-deep .v-input--radio-group--column .v-radio{
  margin-bottom: 0px !important;
}

.select-switch {
  display: inline-flex;
}

.booking-switch {
  margin: 8px 24px;
  padding: 0;
}

.popup .v-card{
  padding: 0px 0px 20px 0px!important;
}
</style>
