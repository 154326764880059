import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_LIST_INSPECTION_RESULTS = `${SITES_BASE_URL}/inspections/get_list_inspection_results`;
const DELETE_INSPECTION_RESULTS = `${SITES_BASE_URL}/inspections/delete_inspection_results`;
const UPDATE_INSPECTION_APPROVED_STATUS = `${SITES_BASE_URL}/inspections/update_inspection_approved_status`;
const GET_INSPECTION_RESULT_BY_IDS = `${SITES_BASE_URL}/inspections/get_inspection_result_by_ids`;

export const inspection = {
  getListInspectionResults: async (params) => {
    return Api.get(GET_LIST_INSPECTION_RESULTS, { params });
  },
  deleteInspectionResults: (params) => {
    return Api.post(DELETE_INSPECTION_RESULTS, params);
  },
  updateInspectionApprovedStatus: (params) => {
    return Api.post(UPDATE_INSPECTION_APPROVED_STATUS, params);
  },
  getInspectionResultByIds: (params) => {
    return Api.get(GET_INSPECTION_RESULT_BY_IDS, { params });
  },
};
