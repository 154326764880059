<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ fieldCode }}: {{ code }}</div>
      </div>
      <v-card-actions class="justify-center flex-column">
        <v-btn center @click="getFile" class="btn-download-qr" >
          QRコードをダウンロード
        </v-btn>
        <div class="img-qr">
          <img v-if="qrBase64" class="qr-image" :src="getBase64">
        </div>
      </v-card-actions>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="btn-qr-close" outlined color="#274FC2" @click="$emit('close')">
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {qr} from "@/api/modules/qr";

export default {
  props: {
    fieldCode: {
      type: String,
    },
    title: {
      type: String,
    },
    code: {
      type: String,
    },
    fieldId: {
      type: Number,
    },
    fieldName: {
      type: String,
    },
  },
  data: () => {
    return {
      qrBase64 : ''
    };
  },
  computed: {
    getBase64(){
      return this.qrBase64;
    }
  },
  async created() {
    await this.getQRCode();
  },
  async mounted() {

  },
  methods: {
    async getQRCode() {
      const response = await qr.getFieldQrCode({field_id : this.fieldId});
      if(response.data && response.data.status_code === 200){
        this.qrBase64 = response.data?.contents?.entries?.qr_code;
      }
    },
    async getFile() {
      await this.downloadQrCode({field_id: this.fieldId});
    },

    async downloadQrCode(params) {
      const response = await qr.exportFieldQrCode(params);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Field_QR_code_${this.code}_${this.fieldName}.pdf`;
      link.click();
      link.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  width: 480px;
  height: 570px;
  background: #FFFFFF;
  border-radius: 8px;
  .v-card__title{
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 37px 28px 30px 28px !important;
    color : #274FC2;
  }
  .btn-download-qr{
    background: #274FC2 !important;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    padding: 10px 30px !important;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .body {
    .text-wrap {
      .text {
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.5px;
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }
  }
  .btn-qr-close{
    color: #274FC2;
    padding: 10px 20px !important;
    position: absolute;
    bottom: 20px;
    right: 20px;
    letter-spacing: 1.25px;
    font-weight: 500;
  }
  .qr-image{
    width: 100%;
  }
  .img-qr {
    width: 225px;
    height: 225px;
    border: 1px solid #274FC2;
    padding: 25px;
  }
}
</style>
