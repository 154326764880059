<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayout"
          >
            <template #tableHeader="{ updateHeader }">
              <TableHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiRemoveStatus="disableRemoveBtn"
                :updateHeader="updateHeader"
                :isFormInfo="true"
                @openRemoveDialog="openRemoveDialog"
                @openItemForm="openNewItemForm"
                @openFormInfoDialog="openDialogBulkAddUserInfo"
              >
                <SearchFormWrapper>
                  <Label label="氏名" class="title-input-item">
                    <InputText
                      name="name"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="メールアドレス" class="title-input-item">
                    <InputText
                      name="email"
                      :values="searchParams"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="携帯番号" class="title-input-item">
                    <InputText
                      name="phone"
                      :values="searchParams"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <div class="user-switch">
                    <SwitchInput
                      name="active_flg"
                      :label="`停止したユーザーも表示`"
                      :values="searchParams"
                      :editable="true"
                      @onInput="onChangeSearchParams"
                    />
                  </div>
                  <v-spacer></v-spacer>
                  <!--  (共通) 検索ボタン -->
                  <v-spacer></v-spacer>
                  <Label>
                    <v-btn class="mr-4" color="primary" depressed @click="onSearch(invalid)">
                      検索
                    </v-btn>
                  </Label>
                </SearchFormWrapper>
              </TableHeader>
              <TableSortWrapper>
                <TableSort
                  :values="searchParams"
                  :sort_items="SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>
            </template>
            <template #tableBody="{ tableHeight }">
              <v-data-table
                item-key="id"
                v-model="selectedItems"
                :headers="USER_TABLE_LABELS"
                :items="items"
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight : tableHeight - 44"
                fixed-header
                hide-default-footer
                disable-sort
                class="elevation-1 v-data-table__wrapper"
                sort-by="updatedAt"
                show-select
                :noDataText="NO_DATA"
                @item-selected="updateSelectedItems"
                @toggle-select-all="selectAllItems"
                @click:row="openItemForm"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ convertName(item.company?.name, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 30px;
                    "
                  >
                    <v-avatar
                      v-if="item.active_flg === 2"
                      color="grey"
                      size="30"
                    >
                      <span class="white--text" style="font-size: 12px">停止</span>
                    </v-avatar>
                    <v-avatar
                      v-else-if="item.id === item.company?.first_user_id"
                      color="primary"
                      size="30"
                    >
                      <span class="white--text" style="font-size: 12px">管理</span>
                    </v-avatar>
                  </div>
                </template>
                <template v-slot:[`item.user_full_name`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      max-width: 470px;
                    "
                  >
                    {{ convertFullName(item) }}
                  </div>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item.email }}
                  </div>
                </template>
                <template v-slot:[`item.phone`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item.phone }}
                  </div>
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;     "
              />
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <User
        :flag="true"
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        :actions="actions"
        @formUpdate="formUpdate"
        @submit="submitForm"
        @cancel="closeItemForm"
        @getListUser="getItems"
        @reloadItem="reloadItem"
        :key="isResetForm"
        :company="company"
      />
    </Popup>

    <!--  (共通) 削除ダイアログ / 文言変えてもok -->
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="選択したデータを削除します。"
        text1="本当に削除してもよろしいですか？"
        text2="この処理を元に戻すことはできません。"
        :items="selectedItemsRemoved"
        warning
      />
    </Popup>
    
    <Popup width="480px" :dialog="popups.isShowUploadForm">
      <UploadInfoDialog
        @close="closeUploadDialog"
        :flagError="flagError"
        @yes="submitUploadInfo"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowErorDialog">
      <ErrorDialog
        @yes="closeErrorDialog"
        @close="closeErrorDialog"
        :text="textDialogError"
        title="エラー"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog"; //削除確認ダイアログ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA,
  LOADING_DATA,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";

import CrudForm from "@/components/forms/crud/CrudForm";
import UserForm from "@/components/forms/company/components/UserForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  USER_TABLE_LABELS,
  USER_SORT_ITEMS,
  USER_INITIAL_ITEM,
  TAB_COMPANIES,
} from "@/constants/COMPANIES"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import _ from "lodash";
import {convertName, dateFormat} from "@/common/helper";
import InputNumber from "@/components/forms/elements/InputNumber";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import SearchInputText from "@/components/forms/elements/SearchInputText";
import User from "../../../../components/forms/company/components/User.vue";
import UploadInfoDialog from "../components/UploadInfoDialog.vue";
import ConfirmActiveUser from "./components/ConfirmActiveUser.vue";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog.vue";
import ErrorDialog from "../../../../components/dialog/ErrorDialog.vue";
import Loading from "@/components/loading/Loading";
import {ValidationProvider, ValidationObserver} from "vee-validate";
/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "所属ユーザー";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Companies";

//ソート要素
const SORT_ITEMS = USER_SORT_ITEMS;

//フォーム初期値
const INITIAL_ITEM = USER_INITIAL_ITEM;

export default {
  head: {
    title() {
      return {
        inner: "AirLiza",
        separator: "|",
        complement: PAGE_TITLE,
      };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      USER_TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA,
      LOADING_DATA,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        active_flg: 0,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
        isShowUploadForm: false,
        isShowErorDialog: false,
      },
      textError: '',
      textDialogError: '',
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      selectTab: TAB_COMPANIES,
      isLoading: true,
      company: {},
      actions: '',
      flagError: false,
    };
  },

  components: {
    Loading,
    SearchInputText,
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    CrudForm,
    UserForm,
    InputText,
    Select,
    Label,
    TabSelect,
    InputNumber,
    SwitchInput,
    User,
    UploadInfoDialog,
    ConfirmActiveUser,
    ConfirmDialog,
    ErrorDialog,
    ValidationProvider,
    ValidationObserver
},

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });
    

    let params = { id: this.$route.query.id}
    const resultDetail = await Store.dispatch(
      `${STORE}/getDetail`,
      {params}
    );

    this.company = resultDetail?.contents?.entries;

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getListUserByCompany`],
        Store.getters[`${STORE}/getUserPagination`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((item) => ({
          ...item
        }));

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
        this.company = this.items[0]?.company;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.name,
        email: this.searchParams.email,
        phone: this.searchParams.phone,
        active_flg: this.searchParams.active_flg,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
          await this.getItems();
          this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayout.onChangeWindowSize();
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }, invalid) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
          this.getItems();
          this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      this.actions = 'create';
      let editedItem = {
        company_id: this.company?.id,
        company_name: this.company?.name,
      };
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      this.actions = 'edit';
      let params = { id: item.id}
      const siteInfo = await Store.dispatch(
        `${STORE}/getUserInfoById`,
        {params}
      );
      // Show Detail Form
      if (!siteInfo.hasError) {
        this.editedItem = _.cloneDeep(siteInfo?.data?.contents?.entries);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      } else {
        this.getItems();
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },
    listUserByCompany() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      let params = {...this.apiParams, id: this.$route.query.id}
      await Store.dispatch(`${STORE}/getListUserByCompany`, {
        params: params
      });
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/deleteUserByIds`, { ids, first_user_id: this.company?.first_user_id });
      //ダイアログ閉じる
      this.closeRemoveDialog();
      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getDetail`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
            (element) => element.id === value.item.id
          )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      }
      else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.id !== value.item.id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.id !== value.item.id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.id);
          }
        });
      }
    },

    async reloadItem () {
      let params = { id: this.editedItem.id}
        const resultDetail = await Store.dispatch(
          `${STORE}/getUserInfoById`,
          {params}
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    convertFullName(item) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`;
    },

    formatDate(date) {
      return dateFormat(date);
    },

    openDialogBulkAddUserInfo() {
      this.popups.isShowUploadForm = true;
    },
    closeUploadDialog() {
      this.popups.isShowUploadForm = false;
    },
    closeErrorDialog() {
      this.popups.isShowErorDialog = false;
    },
    async submitUploadInfo(param) {
      let params = {...param};
      params.company_id = this.$route.query.id;
      const rs = await Store.dispatch("Companies/addUsersInfo", params);
      if(!rs.hasError) {
        this.getItems();
        this.closeUploadDialog();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      } else {
        if (rs?.response?.status == 422) {
          this.popups.isShowErorDialog = true;
          let message = rs.response.data.message_detail;
          this.textError = message.split("\n");
          this.textDialogError = message;
        }
        this.flagError = !this.flagError;
      }
    },
  },
};
</script>
<style scoped>
.title-input-item .label{
  color: #000000;
}
.user-switch {
  margin-top: 30px;
  margin-left: 10px;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:first-child {
  padding: 0 !important;
  width: 70px !important;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}
</style>
