import Vue from "vue";
import Vuex from "vuex";
import { fields } from "@/api/modules/fields";
Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = fields;
export const Fields = {
  namespaced: true,

  state: {
    data: [],
    field: [],
    pagination: {},
    spaces: [],
    companies: [],
    machineBooking: {},
    document: {},
    dataDetail: [],
    isLoading: {},
    fieldNotFinish: {},
    gateByField: {},
    fieldRelatedByUser: {},
    mainConstructor: {},
    fieldByOwnerCompany: {},
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FIELD(state, payload) {
      state.field = payload;
    },
    SET_DATA_DETAIL(state, payload) {
      state.dataDetail = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_SPACE(state, payload) {
      state.spaces = payload;
    },
    SET_COMPANY(state, payload) {
      state.companies = payload;
    },
    SET_MACHINE_BOOKING(state, payload) {
      state.machineBooking = payload;
    },
    SET_FIELD_NOT_FINISH(state, payload) {
      state.fieldNotFinish = payload;
    },
    SET_FIELD_RELATED_BY_USER(state, payload) {
      state.fieldRelatedByUser = payload;
    },
    SET_MAIN_CONSTRUCTOR(state, payload) {
      state.mainConstructor = payload;
    },
    SET_GATE_BY_FIELD(state, payload) {
      state.gateByField = payload;
    },
    SET_LIST_FIELD_BY_OWNER_COMPANY(state, payload) {
      state.fieldByOwnerCompany = payload;
    },
  },

  actions: {
    async get({ commit }, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.get(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);

      return response;
    },

    async endUse({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response =  await ENTITY.endUse(payload);
      commit("SET_IS_LOADING", true);
      return response;
    },

    async cancelEndUse({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.cancelEndUse(payload);
      commit("SET_IS_LOADING", false);

      return response;
    },

    async permitOnSite({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.permitOnSite(payload);
      commit("SET_IS_LOADING", false);

      return response;
    },

    async endFormOutput({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.endFormOutput(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async approve({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.approve(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async add({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.add(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteMasterFieldInfoById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteMasterFieldInfoById(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async addMasterFieldInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addMasterFieldInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async updateMasterFieldInfoById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateMasterFieldInfoById(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getMasterFieldInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getMasterFieldInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const entries = response.data.contents.entries;
      commit("SET_DATA_DETAIL", entries);
      return response;
    },
    async getListPartnerCompanyByFieldId({ commit }, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListPartnerCompanyByFieldId(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;

      commit("SET_COMPANY", entries);
      return response;
    },
    async getListSpaceByFieldId({ commit }, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListSpaceByFieldId(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;

      commit("SET_SPACE", entries);
      return response;
    },
    async getListMachineForBooking({ commit }, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListMachineForBooking(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_MACHINE_BOOKING", {
        data: entries,
        pagination
      });
      return response;
    },    
    async getListFieldNotFinish({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldNotFinish(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_FIELD_NOT_FINISH", entries);
      return response;
    },

    async getListRelatedFieldByUser({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListRelatedFieldByUser(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_FIELD_RELATED_BY_USER", entries);
      return response;
    },

    async getListMainConstructorByUser({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMainConstructorByUser(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_MAIN_CONSTRUCTOR", entries);
      return response;
    },

    async getListGateByField({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListGateByField(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_GATE_BY_FIELD", entries);
      return response;
    },

    async getListFieldByOwnerCompany({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldByOwnerCompany(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response?.data?.contents;
      commit("SET_LIST_FIELD_BY_OWNER_COMPANY", entries);
      return response;
    },
    async getEndUseOfField({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getEndUseOfField(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getField: (state) => {
      return state.field;
    },
    getSpace: (state) => {
      return state.spaces;
    },
    getCompany: (state) => {
      return state.companies;
    },
    getListMachineForBooking: (state) => {
      return state.machineBooking;
    },
    getDataDetail: (state) => {
      return state.dataDetail;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getListFieldNotFinish: (state) => {
      return state.fieldNotFinish;
    },
    getListRelatedFieldByUser: (state) => {
      return state.fieldRelatedByUser;
    },
    getListMainConstructorByUser: (state) => {
      return state.mainConstructor;
    },
    getListGateByField: (state) => {
      return state.gateByField;
    },
    getListFieldByOwnerCompany: (state) => {
      return state.fieldByOwnerCompany;
    },
  },
};
