<template>
  <div class="chart-container">
    <div class="text-center">
      <span v-if="secondaryNumber != 0.01">{{ total }}</span>
      <span>({{ percentagePrimary }}%)</span>
    </div>
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      class="chart"
    />
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import { Chart as ChartJS, ArcElement } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { colorChartPrimary, colorChartAccent } from "@/assets/scss/themes.scss";

ChartJS.register(ArcElement, ChartDataLabels);

const SET_EMPTY_DATA = 0.01;

export default {
  name: "DoughnutChart",
  props: {
    primaryNumber: {
      type: Number,
      required: true,
    },
    secondaryNumber: {
      type: Number,
      required: true,
    },
    primaryTitle: {
      type: String,
      required: true,
    },
    secondaryTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    Doughnut,
  },
  computed: {
    total() {
      return this.primaryNumber + this.secondaryNumber;
    },
    percentagePrimary() {
      return this.total
        ? Math.round((this.primaryNumber / this.total) * 1000) / 10
        : 0;
    },
    chartData() {
      if (this.primaryNumber === 0 && this.secondaryNumber === 0) {
        this.chartOptions = {
          plugins: {
            datalabels: {
              display: false,
            },
          },
        };
      }
      return {
        labels: [this.primaryTitle, this.secondaryTitle],
        datasets: [
          {
            backgroundColor: [colorChartPrimary, colorChartAccent],
            data: [
              this.primaryNumber ? this.primaryNumber : "",
              this.getSecondaryNumber(this.primaryNumber, this.secondaryNumber),
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            color: "#fff",
          },
        },
      },
    };
  },
  methods: {
    getSecondaryNumber(primaryNumber, secondaryNumber) {
      if (primaryNumber === 0 && secondaryNumber === 0) {
        return SET_EMPTY_DATA;
      } else if (primaryNumber !== 0 && secondaryNumber === 0) {
        return "";
      } else {
        return secondaryNumber;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/fonts.scss";

.chart-container {
  position: relative;

  > .chart {
    width: 145px;
    height: 145px;
  }
}

.text-center {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $normal_font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: $color_text_main;
}
</style>
