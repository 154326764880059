import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/users`;
const GET_USER_ACTIVE= `${SITES_BASE_URL}/get_list_active_users`;
const GET_USER_INFO_BY_ID= `${SITES_BASE_URL}/get_user_info_by_id`;

export const users = {
  getActiveUser: (params) => {
    return Api.get(GET_USER_ACTIVE, { params });
},
  getUserInfoById: (params) => {
    return Api.get(GET_USER_INFO_BY_ID, { params });
  },
};
