import { DashboardPortal } from "./modules/api/DashboardPortal.js";
import { Drawer } from "./modules/Drawer";
import { Error } from "./modules/Error";
import { File } from "./modules/File";
import { Cruds } from "./modules/api/Crud";
import { Fields } from "./modules/api/Fields";
import { FieldUsers } from "./modules/api/FieldUsers";
import { Machines } from "./modules/api/Machines";
import { Reports } from "./modules/api/Reports.js";
import { Companies } from "./modules/api/Companies";
import { Users } from "./modules/api/Users.js";
import {Prefectures} from "./modules/api/Prefectures";
import { GlobalHeader } from "./modules/GlobalHeader.js";
import { Login } from "./modules/api/Login";
import { Notification } from "./modules/Notification";
import { Register } from "./modules/api/Register.js";
import { Toast } from "./modules/Toast";
import { Tooltip } from "./modules/Tooltip";
import { Booking } from "./modules/api/Booking";
import { Tenken } from "./modules/api/Tenken.js";
import { Machine } from "./modules/api/Machine.js";
import { MachineRequests } from "./modules/api/MachineRequests";
import { Inspection } from "./modules/api/Inspection";
import { CmnMst } from "./modules/api/CmnMst.js";
import { ReceiveOrders } from "./modules/api/ReceiveOrders.js";

import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const Store = new Vuex.Store({
  modules: {
    Error,
    Toast,
    Drawer,
    Notification,
    Tooltip,
    Login,
    GlobalHeader,
    Cruds,
    Companies,
    Fields,
    Machines,
    Reports,
    File,
    Register,
    Users,
    Prefectures,
    DashboardPortal,
    FieldUsers,
    Booking,
    Tenken,
    Machine,
    MachineRequests,
    CmnMst,
    ReceiveOrders,
    Inspection
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: "MMS",
      paths: ["Login.api_token"],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: "COMPANY_USER",
      paths: ["Login.company_user"],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: "USER",
      paths: ["Login.user"],
      storage: window.sessionStorage,
    }),
  ],
});
