<template>
  <v-menu
    ref="menu"
    v-model="isShow"
    :close-on-content-click="false"
    transition="scroll-y-transition"
    offset-y
    min-width="auto"
    dense
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col>
          <ValidationProvider
            v-slot="{ errors, valid }"
            :vid="id"
            :name="validation_label"
            :rules="validation_rules"
          >
            <v-text-field
              v-model="computedDateFormatted"
              placeholder="日付を選択"
              readonly
              :disabled="!editable"
              v-bind="attrs"
              v-on="on"
              filled
              dense
              item-text="name"
              :success="valid"
              :error-messages="errors"
              :data-testid="testid"
            >
              <template slot="prepend">
                <v-icon v-if="editable" color="black lighten-1" dark right
                  >mdi-calendar-outline</v-icon
                >
              </template>
            </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      v-model="val"
      color="primary"
      locale="ja"
      :day-format="(val) => new Date(val).getDate()"
      :first-day-of-week="0"
      :max="max"
      :min="min"
      no-title
      scrollable
      dense
      :disabled="disabledDate"
      @change="onInput(val)"
    >
      <v-spacer></v-spacer>
      <v-btn text color="black" @click="onReset()" :disabled="disabledDate">クリア</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { format, parseISO } from "date-fns";

export default {
  data: () => {
    return {
      val: "",
      isShow: false,
    };
  },
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    testid: {
      type: String,
    },
    id: {
      type: String,
    },
    max: null,
    min: null,
    flagNull: {
      type: Boolean,
      default: false,
    },
    disabledDate: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    //表示用にyyyy/MM/ddに変換
    computedDateFormatted() {
      return this.val ? format(parseISO(this.val), "yyyy/MM/dd HH:mm") : "";
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name && formValues[name]) {
          //v-datepickerがyyyy-MM-dd形式で処理する
          const _date = new Date(formValues[name]);
          this.val = format(_date, "yyyy-MM-dd HH:mm");
        } else {
          this.val = "";
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput(val) {
      this.$refs.menu.save(val);
      //保存用にyyyy/MM/ddに変換
      const _val = format(new Date(val), "yyyy/MM/dd");
      this.$emit("onInput", { name: this.name, value: _val });
      this.isShow = false;
    },
    onReset(){
      let val = this.flagNull ? null : new Date();
      this.$refs.menu.save(val);
      const _val = this.flagNull ? null : format(new Date(val), "yyyy/MM/dd");
      this.$emit("onInput", { name: this.name, value: _val });
      this.isShow = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.check {
  min-width: 96px;
  flex-grow: 0;
}
</style>
