<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row class="my-9">
                  <v-col cols="12" sm="3" md="3">
                    <v-row class="border-row">
                      <p class="fs-14 font-weight-black">
                        高車情報
                      </p>
                    </v-row>
                    <v-row class="mb-3 mt-5">
                      <v-col>
                        <v-list-item-content>
                          <p class="fs-14 title-input-item " style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            カーナンバー
                          </p>
                          <p class="fs-14" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            {{ this.item?.number_plate }}
                          </p>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col>
                        <v-list-item-content>
                          <p class="fs-14 title-input-item" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            点検実施場所
                          </p>
                          <p class="fs-14" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            {{ this.item?.inspection_place }}
                          </p>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-list-item-content>
                          <p class="fs-14 title-input-item" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            点検者
                          </p>
                          <p class="fs-14" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            {{ this.item?.inspector_row_two }}
                          </p>
                          <p class="fs-14" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            {{ this.item?.phone }}
                          </p>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="9" md="9">
                    <v-row class="border-row">
                      <span class="mrg-r-56"></span>
                      <p class="fs-14 font-weight-black">
                        点検項目
                      </p>
                    </v-row>
                    <v-row v-for="(form, key) in item?.before_delivery_details" :key="key">
                      <v-col cols="12" sm="12" md="12">
                        <div
                          class="align-center pa-4 mx-auto inspect_item"
                        >
                          <v-icon v-if="form.result === 0" class="icon-result-check">mdi-check-circle</v-icon>
                          <v-icon v-if="form.result === 1" class="icon-result-check">mdi-close-circle</v-icon>
                          <v-icon v-if="form.result === 2" class="icon-result-check">mdi-circle-outline</v-icon>
                          <p class="font-weight-medium" style="color: rgba(0, 0, 0, 0.87); font-size: 14px">
                            {{ form.inspect_item }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import Label from "@/components/forms/elements/Label";
//  import { Store } from "@/store/Store.js";
//  import Popup from "../../../common/Popup.vue";
import {
  MACHINE_ITEM
} from "@/constants/MACHINE";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      isCheckBox: String,
      MACHINE_ITEM,
    };
  },
  components: {
    ValidationObserver,
    Label,
    // Popup,
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    item: Object,
  },
  async mounted() {
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {},
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}

.fs-28 {
  font-weight: 500;
  font-size: 20px;
}

.v-list-item__content p {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.theme--light.v-icon.icon-result-check {
  color: #1B9C4F;
  margin-right: 60px;
}

.inspect_item {
  display: flex;
  align-items: center;
  box-shadow: none;
  border-bottom: 1px solid rgb(240 242 243);
}

.inspect_item p {
  margin-bottom: 0 !important;
}

.border-row {
  border-bottom: 1px solid rgb(240 242 243);
}

.mrg-r-56 {
  margin-right: 56px;
}
.theme--light.v-icon.icon-result-check {
  margin-right: 40px !important;
  color: green;
}
.fs-14 {
  font-size: 14px !important;
}
</style>
  