<template>
    <v-card class="box-shadow-dashboard">
      <v-data-table
        :headers="TABLE_LABELS"
        :items="data"
        :items-per-page="data ? data.length : 0"
        :noDataText="NO_DATA"
        :loading="isLoading"
        width="100%"
        :height="`${matchHeight}px`"
        hide-default-header
        hide-default-footer
        fixed-header
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="h in headers">
                <span class="table-header">{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, headers }">
          <tr>
            <td v-for="h in headers" class="data-text">
              <span>{{ item[h.value] }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
</template>
<script>
import { NO_DATA } from "@/constants/COMMON";
const TABLE_LABELS = [
  { text: "協力会社", value: "name" },
  { text: "予約", value: "count_booking" },
];

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: [],
    },
    matchHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      TABLE_LABELS,
      items: [],
      NO_DATA,
    };
  },
  async mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/styles/table.scss";
</style>
