<template>
  <div class="labelWrapper" :style="style">
    <div
      class="label"
      :class="warning ? 'text__warning' : ''"
      :style="isDatepicker ? 'margin-left: 40px' : ''"
    >
      {{ label }}
      <div v-if="required && editable" :class="RerquiredStyle">必須</div>
      <div v-else class="none-required">必須</div>
    </div>
    <div><slot /></div>
  </div>
</template>
<script>
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";
export default {
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    warning: {
      type: Boolean,
      default: false,
    },
    isDatepicker: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return `width:${this.width}`;
    },
    RerquiredStyle() {
      if (process.env.VUE_APP_CLIENT === ENV_CLIENT.OBAYASHI)
        return "required-obayashi";
      return "required";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.labelWrapper {
  padding: 0;
}
.label {
  font-size: 12px;
  padding: 4px;
  color: $color_primary;
  &.text__warning {
    color: $color_warning;
  }
}
.required {
  font-size: 12px;
  font-weight: normal;
  color: #ff5252 !important;
  background-color: transparent;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
}
.none-required {
  opacity: 0;
  font-size: 12px;
  font-weight: normal;
  color: #274FC2 !important;
  background-color: none;
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
}
</style>
