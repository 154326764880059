<template>
  <div>
    <SingleLayout id="admin-login-layout">
      <div class="login-header" style="display: flex;justify-content: space-around;">
          <div>
            <img src="/images/Airliza.png" alt="-image" width="220" height="45" class=""/>
          </div>
          <div style="padding-top: 6px; font-size: 30px;">
            管理事務局画面
          </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <v-form ref="form" v-model="valid" lazy-validation autocomplete="off" id="admin-login-form">
          <v-list-item>
            <v-list-item-content>
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="メールアドレス"
                rules="required|email"
              >
                <v-text-field
                  v-model="formValue.login_id"
                  :error-messages="errors"
                  :success="valid"
                  autofocus
                  dense
                  height="48px"
                  @keydown.enter="invalid ? null : Login()"
                  placeholder="ユーザーID"
                  class="input-field"
                ></v-text-field>
              </ValidationProvider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <ValidationProvider
                v-slot="{ errors }"
                name="パスワード"
                rules="required|password"
              >
                <v-text-field
                  v-model="formValue.password"
                  :error-messages="errors"
                  :append-icon="passwordShow ? 'mdi-eye ic-eye' : 'mdi-eye-off ic-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  dense
                  height="48px"
                  placeholder="パスワード"
                  @keydown.enter="invalid ? null : Login()"
                  @click:append="passwordShow = !passwordShow"
                  class="input-field"
                ></v-text-field>
              </ValidationProvider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                height="48px"
                width="375px"
                color="#274FC2"
                depressed
                :disabled="invalid"
                @click="Login()"
                ><span style="color:#FFFFFF">ログイン</span></v-btn
              >
            </v-list-item-content>
          </v-list-item>
        </v-form>
      </ValidationObserver>
    </SingleLayout>
    <!-- メールリセット -->
    <Popup width="640px" :dialog="isShowCompanyInitDialog">
      <CompanyInitForm
        :flagError="flagError"
        :item="edittedItem"
        @onSubmitCompany="onSubmitCompany"
        @close="isShowCompanyInitDialog = false"
        @closeFormInit="closeFormInit"
        @updateForm="updateForm"
        ref="CompanyInitForm"
      />
    </Popup>
    <Popup width="640px" :dialog="isShowSearch">
      <SearchFirstLogin
        title="会社選択"
        :formValues="formValueCompany"
        :isNoDataMessage="isNoDataMessage"
        @onSearch="onSearchCompany"
        @close="closePopupSearch"
        @formUpdate="formUpdate"
      />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout.vue";
import ResetMail from "./resetMail/index.vue";
import CompanyInitForm from "@/components/forms/companies/CompaniesForm.vue";
import SearchFirstLogin from "./searchFirstLogin/index.vue";
import Popup from "@/components/common/Popup.vue";
import { LOGIN_LINKS, LINK_TYPE } from "@/router/ROUTING_URL.js";
import { COMPANIES_INITAL_ITEM } from "@/constants/COMPANY_PORTAL";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: "ログイン" };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    ResetMail,
    CompanyInitForm,
    SearchFirstLogin,
    Popup,
  },
  data: () => {
    return {
      formValue: {
        login_id: "",
        password: "",
        admin_flg:"1"
      },
      passwordShow: false,
      //パスワードリセット
      //初回自社情報登録フォーム
      isShowCompanyInitDialog: false,
      isShowSearch: false,
      formValueCompany: {
        // 検索ワード
        keyword: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
      flagError: false,
      edittedItem: { ...COMPANIES_INITAL_ITEM },
      isNoDataMessage: false,
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters[`CompanyPortal/getListCompanyAll`],
      (data) => {
        const companyList = [...data];
        this.formValueCompany.items = companyList;
        this.isNoDataMessage = this.formValueCompany.items?.length == 0;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    closePopupSearch(isShowCompany) {
      this.isShowSearch = false;
      if (isShowCompany) {
        this.isShowCompanyInitDialog = true;
      } else {
        this.formValueCompany.keyword = "";
        Store.dispatch("CompanyPortal/clearListCompany");
      }
    },
    closeFormInit() {
      this.isShowCompanyInitDialog = false;
      this.isShowSearch = true;
    },

    async formUpdate(value) {
      const addUserResponse = await Store.dispatch(
        `CompanyPortal/getAddUserToCompany`,
        value
      );
      if (addUserResponse.hasError) {
        // 再ログイン失敗
        return;
      }
      const loginResponse = await Store.dispatch("Login/loginReacqire");
      if (!loginResponse.hasError) {
        this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
        this.isShowSearch = false;
      }
    },
    /**
     * ログイン
     */
    async Login() {
      const res = await Store.dispatch("Login/login", this.formValue);
      if ("result" in res && res.result) {
          this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.message || "ログインできませんでした。",
        });
      }
    },
    /**
     * 初回の自社情報登録
     */
    async onSubmitCompany(formValues) {
      // TODO api 初回自社情報の登録
      const result = await Store.dispatch(
        `CompanyPortal/initialize`,
        formValues
      );
      if (result.hasError) {
        this.flagError = !this.flagError;
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      } else {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
        // ストアに登録
        const company = result.data.contents.entries;
        Store.dispatch("Login/setCompany", company);
        const response = await Store.dispatch("Login/loginReacqire");
        if (response.hasError) {
          // 再ログイン失敗
          return;
        }
        // 自社ポータル開く
        //TOTO ストアでcompany_idを保持
        this.$router.push(LOGIN_LINKS[LINK_TYPE].LOGIN_SUCCESS);
      }
    },
    async onSearchCompany(obj) {
      const params = {
        company_name: this.formValueCompany.keyword,
        current_page: this.formValueCompany.current_page,
      };

      if (obj?.isLoadMore) {
        this.formValueCompany["current_page"] =
          this.formValueCompany["current_page"] + 1;
        await Store.dispatch("CompanyPortal/getListCompanyAll", params);
      } else {
        if (
          params.company_name &&
          params.company_name.trim() !== "" &&
          params.company_name.length <= 255
        ) {
          Store.dispatch("CompanyPortal/clearListCompany");
          this.formValueCompany["current_page"] = 1;
          await Store.dispatch("CompanyPortal/getListCompanyAll", params);
        }
      }
    },
    updateForm(data) {
      this.edittedItem = { ...data };
    },
  },
};
</script>
<style lang="scss" scoped>
.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  background: #3D3D3D;
  color: #274FC2;
}

.links {
  padding: 8px;
  text-align: center;
}

#admin-login-form {
    background: #3D3D3D !important;
}
#admin-login-layout {
    background: #3D3D3D !important;
}
.input-field {
    background: white !important;
    padding: 0px 10px 0px 10px;
}
.input-field::v-deep .ic-eye{
    margin-top: 22px !important;
}

.v-text-field {
  width: 375px!important;
}

::v-deep .card {
  max-width: 500px!important;
}

::v-deep .v-list-item {
  justify-content: center!important;
}

::v-deep  .v-list-item__content {
  flex: none;
}
</style>
