import Vue from "vue";
import Vuex from "vuex";
import {fieldUsers} from "@/api/modules/fieldUsers";

Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = fieldUsers;
export const FieldUsers = {
  namespaced: true,

  state: {
    data: [],
    leaderIds: [],
    dataAll: [],
    fieldUserNotApprove: [],
    listUserByCompany: [],
    pagination: {},
    paginationNotApprove: {},
    isLoading: {},
    roleLogin: 0,
    countFieldUserNotApprove: 0,
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_LEADER_IDS(state, payload) {
      state.leaderIds = payload;
    },
    SET_DATA_All(state, payload) {
      state.dataAll = payload;
    },
    SET_FIELD_USER_NOT_APPROVE(state, payload) {
      state.fieldUserNotApprove = payload;
    },
    SET_COUNT_FIELD_USER_NOT_APPROVE(state, payload) {
      state.countFieldUserNotApprove = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_PAGINATION_NOT_APPROVE(state, payload) {
      state.paginationNotApprove = payload;
    },

    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_ROLE_LOGIN(state, payload) {
      state.roleLogin = payload;
    },
  },
  actions: {
    async getListFieldUserInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldUserInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getListFieldUserInfoAll({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldUserInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_DATA_All", entries);
      return response;
    },
    async getListFieldUserNotApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListFieldUserNotApprove(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_FIELD_USER_NOT_APPROVE", entries);
      commit("SET_PAGINATION_NOT_APPROVE", pagination);
      return response;
    },

    async getCountFieldUserNotApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getCountFieldUserNotApprove(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {count} = response.data.contents;
      commit("SET_COUNT_FIELD_USER_NOT_APPROVE", count);
      return response;
    },

    async bulkAddFieldUserInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.bulkAddFieldUserInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async addFieldUserInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addFieldUserInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteFieldUserByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteFieldUserByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async setStatusApprove({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.setStatusApprove(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async setStatusReceiptAllocation({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.setStatusReceiptAllocation(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async deleteFieldUserRegisterByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteFieldUserRegisterByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async approveFieldUserByIds({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.approveFieldUserByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getFieldUserInfoByEmail({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getFieldUserInfoByEmail(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async updateTeamInFieldByCompany({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateTeamInFieldByCompany(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getListPartnerLeaderInField({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListPartnerLeaderInField(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_LEADER_IDS", entries);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getLeaderIds: (state) => {
      return state.leaderIds;
    },

    getAllFieldUserInfoData: (state) => {
      return state.dataAll;
    },

    getDataNotApprove: (state) => {
      return state.fieldUserNotApprove;
    },

    getCountFieldUserNotApprove: (state) => {
      return state.countFieldUserNotApprove;
    },

    getPagination: (state) => {
      return state.pagination;
    },

    getPaginationNotApprove: (state) => {
      return state.paginationNotApprove;
    },

    getCompany: (state) => {
      return state.company;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
