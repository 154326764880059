<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      :name="validation_label"
      :rules="validation_rules"
    >
      <v-layout wrap>
        <span class="pr-4" v-for="item in items" :key="item.name">
          <v-checkbox
            :label="item.name"
            :disabled="!editable"
            :value="item.value"
            v-model="selected_items"
            :error-messages="errors"
            dense
            @change="onInput()"
            :data-testid="testid"
          ></v-checkbox>
        </span>
      </v-layout>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    testid: {
      type: String,
    },
  },
  data: () => {
    return {
      selected_items: [],
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.selected_items = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput() {
      this.$emit("onInput", {
        name: this.name,
        value: this.selected_items,
      });
    },
  },
};
</script>
