<template>
  <FormDialog>
    <template #header>
      <!--
         (共通)
         ヘッダーは全て共通
        -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="primary--text">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="primary"
          @click="onStopEdit()"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="isSubmitted || !valid"
          :key="isErrorSubmit"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn
          v-if="!editable && userRole === RENTAL_USER_ROLE"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TAB_ONE_MACHINE_FORM_TWO" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!--
         (共通)
         全てのフォームのバリデーションの監視を行う
        -->
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineInventoryInformation
              :editable="editable"
              :detailData="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <!-- 
           (共通)
           ValidationObserverのスロットプロパティを受け取って
           updateValidateメソッドを実行して、
           バリデーション結果をスクリプト側に渡してます
         -->
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closePopup"
          @yes="clickClosePopup"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
 
 <script>

import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { TITLE_DETAIL_MACHINE_INVENTORY, TAB_ONE_MACHINE_FORM_TWO } from "@/constants/MACHINE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import MachineInventoryInformation from "./MachineInventoryInformation";

// RENTAL USER ROLE
const RENTAL_USER_ROLE = 1;

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      isShowConfirmDialog: false,
      TITLE_DETAIL_MACHINE_INVENTORY,
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      isBack: false,
      TAB_ONE_MACHINE_FORM_TWO,
      editable: false,
      RENTAL_USER_ROLE
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineInventoryInformation
  },
  props: {
    item: Object,
    originItem: Object,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    userRole: {
      type: Number
    }
  },
  mounted() {
    /**
     * Catch after submitted
     */
    this.$watch(
      () => this.isEmitted,
      () => {
        if (!this.isErrorSubmit) {
          this.editable = false;
        }
      }
    );
  },
  computed: {
    formtitle() {
      return TITLE_DETAIL_MACHINE_INVENTORY;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit("submit");
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    closePopup() {
      this.isShowConfirmDialog = false;
      this.isBack = false;
    },

    clickClosePopup() {
      if (this.isBack && this.editable) {
        this.editable = false;
        this.editedItem = {...this.originItem};
        this.formUpdate(this.originItem);
      } else {
        this.$emit("cancel");
      }
      this.isBack = false;
      this.isShowConfirmDialog = false;
    },

    onStopEdit() {
      this.isShowConfirmDialog = true
      this.isBack = true;
    }
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
 
 <style lang="sass" scoped>
.from-close-btn
  float: right
</style>
 