import Vue from "vue";
import Vuex from "vuex";
import { machines } from "@/api/modules/machines"

Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = machines;
export const Machines = {   

  namespaced: true,

  state: {
    data: [],
    field: [],
    pagination: {},
    spaces: [],
    companies: [],
    machineBooking: {},
    document: {},
    dataDetail: [],
    isLoading: {},
    selectedTab: 0,
    inspectDeliveryBefore: {},
    dataOnSiteMachineInfo: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FIELD(state, payload) {
      state.field = payload;
    },
    SET_SELECTED_TAB(state, payload) {
      state.selectedTab = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_DATA_MASTER_MACHINE(state, payload) {
      state.dataMasterMachine = payload;
    },
    SET_INSPECT_DELIVERY_BEFORE(state, payload) {
      state.inspectDeliveryBefore = payload;
    },
    SET_DATA_ON_SITE_MACHINE_INFO(state, payload) {
      state.dataOnSiteMachineInfo = payload;
    },
  },

  actions: {
    resetData({ commit }) {
      commit("SET_DATA", []);
      commit("SET_PAGINATION", {});
    },
    async getListMachineInStockOfRental({commit}, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListMachineInStockOfRental(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getListMachineRequestDetail({commit}, payload, isLoading = true) {
      if (isLoading) commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachineRequestDetail(payload);
      if (isLoading) commit("SET_IS_LOADING", false);

      // With error
      if (response.hasError) return response;
      
      // No error
      return response;
    },


    async getListMachineWorkingOfRental({commit}, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListMachineWorkingOfRental(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async addMasterMachineInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addMasterMachineInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async addListDeliveryInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.addListDeliveryInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    
    async deleteMasterMachineByIds({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteMasterMachineByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async deleteMachineDeliveryByIds({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteMachineDeliveryByIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async updateMasterMachineInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateMasterMachineInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getMasterMachineInfoById({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getMasterMachineInfoById(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const {entries} = response.data.contents;
      commit("SET_DATA_MASTER_MACHINE", entries);
      return response;
    },

    async getListMachineDeliveryOfRental({commit}, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getListMachineDeliveryOfRental(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const {entries, pagination} = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },

    async getInspectionBeforeDeliveryById({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getInspectionBeforeDeliveryById(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const entries = response.data?.contents?.entries;
      commit("SET_INSPECT_DELIVERY_BEFORE", entries);
      return response;
    },

    setSelected({commit} , idTab) {
      commit("SET_SELECTED_TAB", idTab)
    },

    async bulkAddMasterMachineInfo({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.bulkAddMasterMachineInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async receiptMachineFromField({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.receiptMachineFromField(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async returnMachines({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.returnMachines(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getOnSiteMachineInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getOnSiteMachineInfo(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const entries = response?.data?.contents;
      commit("SET_DATA_ON_SITE_MACHINE_INFO", entries);
      return response;
    },
    
    async updateOnSiteMachineInfo({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateOnSiteMachineInfo(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getListMachineRole({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachine(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getListInspectionAndRepairRequest({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListInspectionAndRepairRequest(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getCountAbnormalInspectionAndRepairRequest(_context, payload) {
      const response = await ENTITY.getCountAbnormalInspectionAndRepairRequest(payload);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async deleteMachineRepairRequest(_context, dataRequest) {
      const response = await ENTITY.deleteMachineRepairRequest(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getListFieldCompanyRole(_context, dataRequest) {
      const response = await ENTITY.getListFieldCompany(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async deleteMachineList(_context, dataRequest) {
      const response = await ENTITY.deleteMachine(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async commentMachineRequests(_context, dataRequest) {
      const response = await ENTITY.commentMachineRequests(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getListRequestField({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListRequestField(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getCountMachineRequestNotOrder(_context, payload) {
      const response = await ENTITY.getCountMachineRequestNotOrder(payload);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    restoreTabSelect({ commit }){
      commit("SET_SELECTED_TAB", 0)
    },

    async exportMachine({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportMachine(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getDetailMachineInventory({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getDetailMachineInventory(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async updateDetailMachineInventory({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateDetailMachineInventory(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getField: (state) => {
      return state.field;
    },
    getSelectedTab: (state) => {
      return state.selectedTab;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getMasterMachineInfoById: (state) => {
      return state.dataMasterMachine;
    },
    getInspectionBeforeDeliveryById: (state) => {
      return state.inspectDeliveryBefore;
    },
    getOnSiteMachineInfo: (state) => {
      return state.dataOnSiteMachineInfo;
    },
  },
};
