<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading" />
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab2"
          >
            <template #tableHeader="{ updateHeader }">
              <TableHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiRemoveStatus="disableRemoveBtn"
                :updateHeader="updateHeader"
                :isClassify="false"
                :isDelete="false"
                :isAddNew="false"
                @openRemoveDialog="openRemoveDialog"
                @openItemForm="openNewItemForm"
              >
                <SearchFormWrapper>
                  <Label label="搬入予定日" class="title-input-item" style="max-width: 224px">
                    <InputDatepicker
                      name="start_scheduled_delivery_date"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-start-scheduled-delivery-date:${apiParams.end_scheduled_delivery_date}`"
                      :flagNull="true"
                    />
                  </Label>
                  <span class="mt-12">
                    <v-icon>mdi-tilde</v-icon>
                  </span>
                  <Label label="搬入予定日" class="title-input-item" style="max-width: 224px">
                    <InputDatepicker
                      name="end_scheduled_delivery_date"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :validation_rules="`compare-end-scheduled-delivery-date:${apiParams.start_scheduled_delivery_date}`"
                      :flagNull="true"
                    />
                  </Label>
                  <Label label="元請" width="200px" class="title-input-item">
                    <Select
                      name="owner_company_id"
                      :items="companyBuilding"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="現場" width="200px" class="title-input-item">
                    <Select
                      name="field_id"
                      :items="fieldNotFinish"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="商品コード" width="200px" class="title-input-item" style="white-space: nowrap;">
                    <InputText
                      name="product_code"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>

                  <Label label="機番" width="200px" class="title-input-item">
                    <InputText
                      name="machine_number"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </TableHeader>

              <!-- 
                (共通)
                ソートのレイアウトを調整するラッパーコンポーネントです
              -->
              <TableSortWrapper>
                <!--  
                  (共通) ソート 
                  ソート項目、ソート順、表示件数の選択、総件数の表示
                -->
                <TableSort
                  :values="searchParams"
                  :sort_items="SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>
            </template>
            <!-- 
              (共通)テーブル
              v-data-table自体は共通ですが、
              カラムによって変更をしたい場合はそれぞれ実装してください。
            -->

            <template #tableBody="{ tableHeight }">
              <template>
                <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                  <v-tab
                    v-for="tab in TAB_MACHINE"
                    :key="tab.id"
                    @click="changeTab(tab.id)"
                    class="tab-machine"
                  >
                    {{ tab.name }}</v-tab
                  >
                </v-tabs>
              </template>
              <template>
                <v-btn class="btn-trash-box" depressed icon :disabled="disableRemoveBtn" @click="popups.isShowRemoveDialog = true">
                  <v-icon> mdi-delete-outline </v-icon>
                </v-btn>
              </template>
              <v-data-table
                item-key="delivery_infomation_id"
                v-model="selectedItems"
                :headers="TABLE_LABELS"
                :items="items"
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 100 : tableHeight - 135"
                fixed-header
                hide-default-footer
                disable-sort
                class="elevation-1 v-data-table__wrapper"
                sort-by="updatedAt"
                show-select
                :noDataText="NO_DATA_MESSAGE"
                @toggle-select-all="selectAllItems"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
              >
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.company_name, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.field_name`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.field_name, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div
                    :style="
                      item.product_code.trim() == ''
                        ? 'background: rgba(176, 0, 32, 0.1); padding: 24px; margin-bottom: -1px'
                        : 'overflow: hidden;text-overflow: ellipsis;max-width: 380px;white-space: nowrap'
                    "
                  >
                    {{ convertName(item.product_code, 65) }}
                  </div>
                </template>
                <template v-slot:[`item.machine_number`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.machine_number, 65) }}
                  </div>
                </template>
                <template v-slot:[`item.number_plate`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.number_plate) }}
                  </div>
                </template>
                <template v-slot:[`item.machine_name`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.machine_name) }}
                  </div>
                </template>
                <template v-slot:[`item.scheduled_delivery_date`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertDate(item.scheduled_delivery_date_time ) }}
                  </div>
                </template>
                <template
                  v-slot:[`item.id_inspection_before_deliveries`]="{ item }"
                >
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    <v-icon
                      v-if="item.id_inspection_before_deliveries"
                      @click="openItemForm(item.id_inspection_before_deliveries)"
                      class="icon-paperclip"
                      >mdi-paperclip</v-icon
                    >
                  </div>
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;"
              />
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>

    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowItemForm">
      <InspectionResult
        :item="editedItem"
        @cancel="closeItemForm"
      />
    </Popup>
    
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems || []"
        warning
      />
    </Popup>
    
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSortDescFirst";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import InspectionResult from "../../../../components/forms/machine/InspectionResult";
// import MachineForm from "../../../../components/forms/machine/MachineTab2";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  MACHINE_TABLE_LABELS_TAB_2,
  MACHINE_SORT_ITEMS_TAB_2,
  CRUD_INITIAL_ITEM,
  TAB_MACHINE,
} from "@/constants/MACHINE"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_RENTAL } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import _ from "lodash";
import { convertName, dateFormat } from "@/common/helper";
import Loading from "@/components/loading/Loading";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog"; //削除確認ダイアログ 
import {ValidationProvider, ValidationObserver} from "vee-validate";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "商品リスト";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Machines";

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_TABLE_LABELS_TAB_2;

//ソート要素
const SORT_ITEMS = MACHINE_SORT_ITEMS_TAB_2;

//フォーム初期値
const INITIAL_ITEM = CRUD_INITIAL_ITEM;

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TAB_MACHINE,
      MACHINE_TABLE_LABELS_TAB_2,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        word: "",
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: 0, //sortする項目
        asc: false,
        companyBranchId: null,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      selectedTab: 10,
      tab: 0,
      isLoading: false,
      companyBuilding: [],
      fieldNotFinish: [],
      defaultOtion: { id: null, name: "" },
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    InspectionResult,
    InputText,
    Select,
    Label,
    InputDatepicker,
    Loading,
    ConfirmRemoveDialog,
    ValidationProvider,
    ValidationObserver
  },

  async mounted() {
    
    this.callApiInit();
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_RENTAL.MACHINE.id,
    });

    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    }

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items.map((x) => ({
          ...x,
        }));

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems?.length === 0;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        start_scheduled_delivery_date: this.searchParams?.start_scheduled_delivery_date ? this.searchParams?.start_scheduled_delivery_date?.replaceAll("/", "-") : "",
        end_scheduled_delivery_date: this.searchParams?.end_scheduled_delivery_date ? this.searchParams?.end_scheduled_delivery_date?.replaceAll("/", "-") : "",
        field_id: this.searchParams.field_id,
        owner_company_id: this.searchParams.owner_company_id,
        product_code: this.searchParams.product_code,
        machine_number: this.searchParams.machine_number,
        company_id: this.companyId,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
          await this.getItems();
          this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      if (name === "owner_company_id") {
        if (value) {
          this.callApiListFieldByOwnerCompany(value);
        }
        if (!value) {
          this.fieldNotFinish = [this.defaultOtion];
        }
        if (this.searchParams?.owner_company_id !== value) {
          this.searchParams.field_id = null;
        }
      }
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab2.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }, invalid) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
          this.getItems();
          this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(id) {
      let params = {id: id};
      const deliveryBeforeInfo = await Store.dispatch(`${STORE}/getInspectionBeforeDeliveryById`, {params});
      this.popups.isShowItemForm = true
      if (!deliveryBeforeInfo.hasError && deliveryBeforeInfo.data?.status_code == 200) {
        this.editedItem = _.cloneDeep(deliveryBeforeInfo.data?.contents?.entries);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        //選択を初期化
        this.editedItem = { ...INITIAL_ITEM.entries };
      });
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/getListMachineDeliveryOfRental`, {
        params: this.apiParams,
      });
    },

    // 削除
    async removeItems() {
      const ids = this.selectedItems?.map((items) => items.machine_id);
      const delivery_infomation_ids  = this.selectedItems?.map((items) => items.delivery_infomation_id);
      
      const result = await Store.dispatch(`${STORE}/deleteMachineDeliveryByIds`, {
        ids,
        delivery_infomation_ids
      });

      if (!result.hasError) {
        this.selectedItemsRemoved = [];
        this.searchParams["currentPage"] = 1;
        //ダイアログ閉じる
        this.closeRemoveDialog();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message,
        });
      }
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const hasId = "id" in this.editedItem;
      const editedItem = _.cloneDeep(this.editedItem);
      const result = await Store.dispatch(
        hasId ? `${STORE}/edit` : `${STORE}/add`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        this.isResetForm = !this.isResetForm;
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = { id: this.editedItem.id };
        const resultDetail = await Store.dispatch(`${STORE}/getDetail`, {
          params,
        });
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems();
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter((element) => element.id === value.item.id)
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems?.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.id !== value.item.id
        );
        this.selectedItems = this.selectedItems?.filter(
          (item) => item?.id !== value?.item?.id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.id)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.id);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    convertDate(date) {
      return date?.replaceAll("-", "/") || '';
    },

    formatDate(date) {
      return dateFormat(date);
    },

    changeTab(id) {
      this.selectedTab = id;
      Store.dispatch(`${STORE}/setSelected`, id);
    },
  
    async callApiInit() {
      this.fieldNotFinish = [this.defaultOtion];
      let mainConstructor = await Store.dispatch("Fields/getListMainConstructorByUser");
      let dataCompany = mainConstructor?.data?.contents?.entries;
      this.companyBuilding = [this.defaultOtion, ...dataCompany];
    },
    async callApiListFieldByOwnerCompany(id) {
      let field = await Store.dispatch("Fields/getListFieldByOwnerCompany", {
        company_id: id,
      });
      let dataField = field?.data?.contents?.entries;
      this.fieldNotFinish = [this.defaultOtion, ...dataField];
    },
  },
};
</script>
<style scoped>
.tab-machine {
  color: #274fc2 !important;
}
.btn-trash-box {
  margin-left: 7px;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper table thead tr th:first-child {
  padding: 0;
  width: 5% !important;
  min-width: 5% !important;
  text-align: center !important;
}


::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding-left: 0;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th:nth-child(2) {
  padding-left: 0 !important;
}

@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  white-space: nowrap;
}
</style>
