<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title primary--text">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
        :formUserActiveValues="formUserActiveValues"
        @search="search"
        @formUpdate="formUpdate"
        :title_search="title_placehoder"
    >
      <v-list two-line>
        <template v-for="item in formUserActiveValues.items">
          <v-list-item class="wrap-list" :key="item.field_id" ripple>
            <v-list-item-content @click="onClick(item.id)">
              <v-list-item-title class="name_default">{{ convertName(item.name_sei, item.name_mei, item.email) }}</v-list-item-title>
              <v-list-item-subtitle class="name_kana">{{ convertNameKana(item.kana_sei, item.kana_mei, item.phone) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-if="showNoMessage || countData < 1">
          {{ no_data }}
        </v-list-item>
      </v-list>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import {
  NO_DATA
} from "@/constants/COMMON";
import { Store } from "@/store/Store.js";

export default {
  components: {
    SearchSelectList,
  },
  props: {
    title: String,
    formUserActiveValues: {
      type: Object,
      default: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    },
    onSearch: Function,
    close: Function,
    countData: Number,
  },

  data: () => {
    return {
      keyword: "",
      title_placehoder: '氏名',
      no_data: NO_DATA,
      showNoMessage: false,
    };
  },

  computed: {
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },

  mounted() {
    this.$watch(
      () => Store.getters[`Users/getUserActive`], (data) => {
        if (data.length < 1) {
          this.showNoMessage = true;
        } else {
          this.showNoMessage = false;
        }
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.$emit("onSearch", { keyword: this.keyword });
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.keyword = values.keyword;
      this.$emit("updateKeyWord", values);
    },

    // idの更新
    onClick(id) {
      const values = { ...this.formUserActiveValues };
      values.selected = id;
      this.$emit("formUpdateManager", values);
    },

    convertName(nameSei, nameMei, email) {
      return nameSei + '　' + nameMei + '（' + email + '）';
    },

    convertNameKana(kanaSei, kanaMei, phone) {
      return kanaSei + '　' + kanaMei + ' / ' + phone;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
  .name_default {
    font-size: 16px !important;
    font-weight: 600;
  }
  .name_kana {
    font-size: 14px !important;
    font-weight: 400;
  }
  .wrap-list {
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
