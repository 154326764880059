<template>
  <v-snackbar v-model="isActive" :color="color" :multi-line="true" top>
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" color="darken-1" icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { Store } from "@/store/Store.js";

export default {
  data() {
    return {
      isActive: false,
      status: null,
      text: "",
      color: "warning",
      timeout: 3000,
      timeoutEvent: null,
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["Error/getError"],
      (error) => {
        clearTimeout(this.timeoutEvent);
        const { message, isActive, status, tokenError } = error;
        if (tokenError) return;
        this.isActive = isActive;
        this.text = message;
        this.status = status;
        this.timeoutEvent = setTimeout(() => {
          Store.dispatch("Error/destroy");
        }, this.timeout);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    close() {
      Store.dispatch("Error/destroy");
    },
    clear() {
      clearTimeout(this.timeoutEvent);
    },
  },
};
</script>
