import Api, { Mock } from "../api";

const FORM_PRINT_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_COMPANY = `${process.env.VUE_APP_API_BASE_URL}/get_companies`;
const GET_COMPANY_INFO_CORPORATE_NUMBER = `${FORM_PRINT_BASE_URL}/get_company_info_by_corporate_number`;
const ADD_COMPANY_INFO = `${FORM_PRINT_BASE_URL}/add_company_info`;


if (Mock) {
  Mock.onGet(GET_COMPANY).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const register = {
  getCompany: (params) => {
    return Api.get(`${GET_COMPANY}?name=${params['name']}`);
  },
  getCompanyInfoByCorporateNumber: (params) => {
    return Api.get(`${GET_COMPANY_INFO_CORPORATE_NUMBER}?corporate_number=${params['corporate_number']}`);
  },
  add: (params) => {
    return Api.post(ADD_COMPANY_INFO, params);
  },
  edit: async (params) => {
    return Api.post(ADD_COMPANY_INFO, params);
  },
};
