import Api from "../api";
import apiFile from "../apiFile";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_COMPANY_QR = `${SITES_BASE_URL}/get_company_qr_code`;
const GET_COMPANY_QR_FILE = `${SITES_BASE_URL}/export_company_qr_code`;
const GET_FIELD_QR_CODE = `${SITES_BASE_URL}/fields/get_field_qr_code`;
const EXPORT_FIELD_QR_CODE = `${SITES_BASE_URL}/reports/export_field_qr_code`;

export const qr = {
    getCompanyQRCode: (params) => {
        return Api.get(GET_COMPANY_QR, {params});
    },
    getCompanyQRFile: (params) => {
        return Api.get(GET_COMPANY_QR_FILE, {params});
    },
    getFieldQrCode: (params) => {
        return Api.get(GET_FIELD_QR_CODE, {params});
    },
    exportFieldQrCode: (params) => {
        return apiFile.get(EXPORT_FIELD_QR_CODE, {params});
    }
};
