import Vue from "vue";
import Vuex from "vuex";
import { booking } from "@/api/modules/booking"

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = booking;
export const Booking = {

  namespaced: true,

  state: {
    dataInspectionResultDetails: [],
    dataInspectionResults: [],
    dataImageInspection: [],
    dataMachineInfo: {},
    dataReaipAttach: [],
    isLoading: {},
  },

  mutations: {
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async getInspectionDetail({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getInspectionDetail(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getRepairRequestDetail({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getRepairRequestDetail(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getInspectionImage({commit}, dataRequest) {
      const response = await ENTITY.getInspectionImage(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getMachineInfo(_commit, dataRequest) {
      const response = await ENTITY.getMachineInfo(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async getRepairAttach({commit}, dataRequest, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.getRepairAttach(dataRequest);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },

    async deleteUrlFile(_commit, dataRequest) {
      const response = await ENTITY.postDeleteUrlFile(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
    async uploadFileKenten(_commit, dataRequest) {
      const response = await ENTITY.uploadFilePdf(dataRequest);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
    async deletePeriodBookingInfo({commit}, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deletePeriodBookingInfo(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
  },
  getters: {
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
