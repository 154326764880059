import { LINK_TYPE, TOKEN_REDIRECT_LINKS } from "@/router/ROUTING_URL.js";

import AdminLogin from "@/views/login/indexAdmin.vue";
import AppLoading from "@/views/appLoader/index.vue";
import Booking from "@/views/booking";
import Companies from "@/views/master/companies";
import Machines from "@/views/master/machines/index.vue";
import Reports from "@/views/report/index.vue";
import CompanyPortal from "@/views/dashboard/index.vue";
import Crud from "@/views/master/crud";
import Fields from "@/views/master/fields/index.vue";
import ListUserByCompany from "@/views/master/companies/listUserByCompany";
import Login from "@/views/login/index.vue";
import Register from "@/views/register/index.vue";
import ResetMail from "@/views/login/resetMail/index.vue";
import ResetPassword from "@/views/login/resetPassword/index.vue";
import SampleForm from "@/views/UISample/sampleForm";
import { Store } from "@/store/Store";
import Vue from "vue";
import VueRouter from "vue-router";
import error404 from "@/views/error/404.vue";
import mobileError from "@/views/error/mobileError.vue";
import Machine from "@/views/machine";
import FieldUser from "@/views/fieldUser/index.vue";
import termsofservice from "@/views/termofservice/termofservice.vue";
import ReceiveOrders from "@/views/receiveOrder/index.vue";
import Inspection from "@/views/inspection/index.vue";

// import Index from "@/views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/crud",
    name: "crud",
    component: Crud,
    meta: { isPublic: true },
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking,
    props: true,
    meta: { isPublic: true },
  },
  {
    path: "/machine",
    name: "Machine",
    component: Machine,
    meta: { isPublic: true },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { isPublic: true },
  },
  {
    path: "/uisample/sampleform",
    name: "SampleForm",
    component: SampleForm,
    meta: { isPublic: true },
  },
  // views - 各画面
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/41646d696e697374726174696f6e",
    name: "AdminLogin",
    component: AdminLogin,
    meta: { isPublic: true },
  },
  {
    path: "/password_reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { isPublic: true },
  },
  {
    path: "/reset",
    name: "ResetMail",
    component: ResetMail,
    meta: { isPublic: true },
  },
  {
    path: "/portal/dashboard",
    name: "SitePortal",
    component: CompanyPortal,
    meta: { isPublic: true },
  },
  {
    path: "/inspection",
    name: "Inspection",
    component: Inspection,
    meta: { isPublic: true },
  },

  /**
   * Unityアプリからのリクエストは
   * 必ずここを経由してルーティングする
   */
  {
    path: "/app_loading",
    name: "AppLoading",
    component: AppLoading,
    meta: { isPublic: true },
  },

  {
    path: "/notfound",
    name: "404",
    component: error404,
    meta: { isPublic: true },
  },

  {
    path: "/doesnotsupportmobile",
    name: "DNSM ",
    component: mobileError,
    meta: { isPublic: true },
  },

  {
    path: "/termsofservice",
    name: "terms",
    component: termsofservice,
    meta: { isPublic: true },
  },

  {
    path: "/master/fields",
    name: "Fields",
    component: Fields,
    meta: { isPublic: true },
  },
  {
    path: "/master/companies",
    name: "Companies",
    component: Companies,
    meta: { isPublic: true },
  },
  {
    path: "/master/companies/list_user_by_company",
    name: "listUserByCompany",
    component: ListUserByCompany,
    meta: { isPublic: true },
  },
  {
    path: "/field-user",
    name: "fieldUser",
    component: FieldUser,
    meta: { isPublic: true },
  },
  {
    path: "/master/machines",
    name: "Machines",
    component: Machines,
    meta: { isPublic: true },
  },
  {
    path: "/master/receive-order",
    name: "receiveOrders",
    component: ReceiveOrders,
    meta: { isPublic: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: { isPublic: true },
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(sessionStorage.getItem("USER"))?.Login?.user;
  const site = JSON.parse(sessionStorage.getItem("MMS_HEADERS"))?.site;
  const currentSite = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));
  const activeFlg = currentSite?.active_flg;
  const role = currentSite?.role;
  const ROLE_MAIN = 1;
  const ACTIVE_FLG_IN_USE = 1
  const ACTIVE_FLG_APPLY_FOR_TERMINATION = 2;
  const ACTIVE_FLG_END = 3;
  const COMPANY_RENTAL = 2;
  // iOSとAndroid
  if (to.path === "/doesnotsupportmobile") {
    next();
    return;
  }

  if (to.path === "/termsofservice") {
    next();
    return;
  }

  if (user?.admin_flg == null) {
    if (
      to.path === "/master/companies" ||
      to.path === "/master/companies/list_user_by_company"
    ) {
      next({ name: "/" });
      return;
    }
  }

  // 画面遷移でツールチップは強制的に非表示
  Store.dispatch("Tooltip/destroy");

  if (to.path !== "/" && to.path !== "/41646d696e697374726174696f6e" && to.path !== "/register") {
    //毎回更新をチェック
    Store.dispatch("Notification/countUnreadNotification");
  }

  //アプリ
  if (to.path === "/app_loading") {
    next();
    return;
  }

  // パスワードリセット
  if (to.path === "/password_reset") {
    next();
    return;
  }

  // ローカルはトークンをチェックしない
  if (process.env.VUE_APP_NODE_ENV === "local") {
    next();
    return;
  }

  // tokenがない場合はloginに遷移
  let token = Store.getters["Login/getToken"];

  // ログイン画面の場合
  if (to.path === "/" || to.path === "/41646d696e697374726174696f6e" || to.path === "/register") {
    // loginでtokenある場合はポータルに遷移
    if (token) {
      next(TOKEN_REDIRECT_LINKS[LINK_TYPE].HAS_TOKEN);
    } else {
      next();
    }
    return;
  }
  // その他
  if (!token) {
    /**
     * login以外
     */
    next({ name: "Login" });
  } else {
    next();
  }

  if (to.path === "/master/machines" || to.path === "/master/receive-order") {
    if (user.company.company_type != COMPANY_RENTAL) {
      next({ name: "Fields" });
      return;
    } else {
      next();
    }
  }

  if (to.path === "/field-user" && !site) {
    next({ name: "Fields" });
  }

  if ((to.path === "/reports" || to.path === "/machine" || to.path === "/portal/dashboard" ||
    to.path === "/booking" || to.path === "/inspection") && !site) {
    next({ name: "Fields" });
  }

  if (to.path === "/portal/dashboard" && site) {
    if (role && role === ROLE_MAIN && activeFlg === ACTIVE_FLG_IN_USE) {
      next({ name: "SitePortal" });
      return;
    } else {
      next({ name: "Booking" });
      return;
    }
  }

  if (to.path === "/inspection" && site) {
    if (role && role === ROLE_MAIN && activeFlg === ACTIVE_FLG_IN_USE) {
      next({ name: "Inspection" });
      return;
    } else {
      next({ name: "Booking" });
      return;
    }
  }

  if (to.path === "/reports" && site) {
    if (role && role === ROLE_MAIN && activeFlg === ACTIVE_FLG_IN_USE) {
      next({ name: "Reports" });
      return;
    } else if (role &&
      role === ROLE_MAIN &&
      (
        activeFlg === ACTIVE_FLG_APPLY_FOR_TERMINATION ||
        activeFlg === ACTIVE_FLG_END
      )
    ) {
      next({ name: "Reports" });
      return;
    } else {
      next({ name: "Booking" });
      return;
    }
  }

  if (
    to.path !== "/master/fields" &&
    currentSite &&
    currentSite?.role &&
    currentSite?.role === ROLE_MAIN &&
    (currentSite?.active_flg === ACTIVE_FLG_APPLY_FOR_TERMINATION || currentSite?.active_flg === ACTIVE_FLG_END)
  ) {
    next({ name: "Reports" });
  }

  if (to.path === "/master/fields") {
    sessionStorage.removeItem("CURRENT_SITE");
    sessionStorage.removeItem("GRN_HEADERS");
  }
});

export { routes };
export default router;
