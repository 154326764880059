<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page="{ layoutParams }">
        <ValidationObserver v-slot="{ invalid }">
        <TableLayout
          :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item"
          ref="myTableLayoutTab8"
        >
          <template #tableHeader="{updateHeader}">
            <TableHeader
              ref="tableHeader"
              :pageTitle="TITLE"
              :isDelete="false"
              :isAddNew="false"
              :isClassify="false"
              :updateHeader="updateHeader"
            >
              <SearchFormWrapper>
                <!-- ロール -->
                <Label label="期間" class="title-input-item" style="max-width: 224px">
                  <InputMonthpicker
                    name="start_use_field_date"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                    :flagNull="true"
                    validation_label="期間 (左) "
                    :validation_rules="getStartMonthRules()"
                  />
                </Label>
                <span class="mt-12 ml-3">
                  <v-icon>mdi-tilde</v-icon>
                </span>
                <Label label="期間" class="title-input-item" style="max-width: 224px">
                  <InputMonthpicker
                    name="end_use_field_date"
                    :editable="true"
                    :values="searchParams"
                    @onInput="onChangeSearchParams"
                    :flagNull="true"
                    validation_label="期間 (右) "
                    :validation_rules="getEndMonthRules()"
                  />
                </Label>
                <!--  (共通) 検索ボタン -->
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-6 mt-10"
                  color="primary"
                  depressed
                  @click="onSearch(invalid)"
                >
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>

            <!--
              (共通)
              ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <TableSortWrapper>
              <!--
                (共通) ソート
                ソート項目、ソート順、表示件数の選択、総件数の表示
              -->
              <TableSort
                :values="searchParams"
                :sort_items="INVENTORY_RESULT_SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams(...arguments, invalid)"
                class="ml-3 sort_report"
              />
            </TableSortWrapper>
          </template>
          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
          -->
          <template #tableBody="{ tableHeight }">
            <template>
              <v-tabs v-model="tab" class="mt-3" style="border-bottom: 1px solid #E5E5E5 ; padding-left:16px">
                <v-tab 
                  v-for="tab in TAB" 
                  :key="tab.id" 
                  @click="changeTab(tab.id)" 
                  color="primary" 
                  class="tab-machine primary--text"
                  >
                  {{tab.name}}
                </v-tab>
              </v-tabs>
            </template>
            <template>
              <v-data-table
                v-model="selectedItems"
                :headers="TABLE_LABELS_REPORT_INVEMTORY"
                :items="items"
                item-key="stockTakingInfomationId"
                show-select
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 110 : tableHeight - 155"
                fixed-header
                hide-default-footer
                disable-sort
                class="elevation-1 v-data-table__wrapper"
                sort-by="updatedAt"
                :noDataText="NO_DATA_MESSAGE"
                @item-selected="updateSelectedItems"
                @toggle-select-all="selectAllItems"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
              >
                <template v-slot:top>
                  <v-btn
                    class="ml-4 mt-2 mb-2"
                    color="primary"
                    left
                    @click="getExport()"
                    depressed
                  :disabled="disableRemoveBtn"
                  >
                    出力
                  </v-btn>
                </template>
                <template v-slot:[`item.stockTakingInfomationTakeAt`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                    "
                  >
                    {{ monthFormat(item.stockTakingInfomationTakeAt, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.countMachine`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                    "
                  >
                    {{ item.countMachine }}台
                  </div>
                </template>
              </v-data-table>
            </template>
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
              style="background: white; position: fixed; width: 100%; bottom: 0;"
            />
          </template>
        </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用
import {
  TAB,
  TITLE,
  TABLE_LABELS_REPORT_INVEMTORY,
  INVENTORY_RESULT_SORT_ITEMS,
  TAB_INVENTORY
} from "@/constants/REPORT";
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import _ from "lodash";
import { convertName, dateFormat, monthFormat } from "@/common/helper";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {format} from "date-fns";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Reports";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: TITLE };
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      TITLE,
      TABLE_LABELS_REPORT_INVEMTORY,
      INVENTORY_RESULT_SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      TAB,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      formValues: {},

      companyItem: [],

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null, //sortする項目
        asc: true,
        companyBranchId: null,
        start_use_field_date: "",
        end_use_field_date: "",
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      isResetForm: false,
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      tab: TAB_INVENTORY,
      flagUserRentalCompany: true,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    Select,
    Label,
    InputMonthpicker,
    SitePortalHeader,
    ValidationProvider,
    ValidationObserver
  },

  async mounted() {
    let today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    this.searchParams.start_use_field_date = yyyy + "/" + mm;
    this.searchParams.end_use_field_date = yyyy + "/" + mm;

    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0].data];
        this.items = _items;
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[0].last_page;
        searchParams.currentPage = data[0].current_page;
        searchParams.total_item = data[0].total;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    /**
     * API Param
     */
    apiParams() {
      return {
        field_id: this.fieldId,
        asc: this.searchParams.asc ? 1 : 0,
        start_use_field_date: this.searchParams.start_use_field_date,
        end_use_field_date: this.searchParams.end_use_field_date,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
  },

  created() {
    this.getFieldId();
  },

  methods: {
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    async onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
        await this.getItems();
        this.selectedItems = [];
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab8.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({name, value}, invalid) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
        this.getItems();
        this.selectedItems = [];
      }
    },
    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = { ...params };
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems() {
      await Store.dispatch(`${STORE}/getInventoryResultInFields`, {
        params: this.apiParams,
      });
    },
    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter((element) => element.stockTakingInfomationId === value.item.stockTakingInfomationId)
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.stockTakingInfomationId !== value.item.stockTakingInfomationId
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.stockTakingInfomationId !== value.item.stockTakingInfomationId
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.stockTakingInfomationId)) {
            this.selectedItemsRemoved.push(item);
            idSet.add(item.stockTakingInfomationId);
          }
        });
      }
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },
    monthFormat(date) {
      return monthFormat(date);
    },
    changeTab(id) {
      Store.dispatch(`${STORE}/setSelected`, id);
    },

    getFieldId() {
      this.fieldId = JSON.parse(
        sessionStorage.getItem("CURRENT_SITE")
      ).field_id;
    },
    async getExport() {
      const ids = this.selectedItems.map((items) => items.stockTakingInfomationId);
      const takeat = this.selectedItems.map((items) => items.stockTakingInfomationTakeAt);
      const id = this.fieldId;
      const editedItem = _.cloneDeep(this.editedItem);
      editedItem.ids = ids;
      editedItem.takeat = takeat;
      editedItem.id = id;
      const response = await Store.dispatch(
        `${STORE}/exportInventoryResultInFields`,
        { params: editedItem }
      );
      var a = document.createElement("a");
      a.download = "棚卸結果_" + this.searchParams.start_use_field_date + "_" + this.searchParams.end_use_field_date + ".csv";
      a.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response?.data);
      document.body.appendChild(a);
      a.click();
    },
    getStartMonthRules() {
      let fieldEndDate = this.searchParams?.end_use_field_date;
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01');
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let compareFieldStartDate = fieldEndDate ? "required|" + `compare-field-start-date:${fieldEndDate},期間（左）,期間（右）` : "required";
      return compareFieldStartDate;
    },
    getEndMonthRules() {
      let fieldStartDate = this.searchParams?.start_use_field_date;
      fieldStartDate = fieldStartDate + '/01';
      let compareFieldEndDate = "";
      if (fieldStartDate) {
        compareFieldEndDate = "required|" + `compare-field-end-date:${fieldStartDate},期間（右）,期間（左）`;
      }
      return compareFieldEndDate;
    },
  },
};
</script>
<style scoped>
  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(1) {
    padding-right: 0;
  }

  ::v-deep .v-data-table--fixed-header>.v-data-table__wrapper>table .text-start:nth-child(2) {
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    .SearchFormWrapper .labelWrapper {
      max-height: inherit !important;
      height: auto !important;
    } 
  }
</style>
