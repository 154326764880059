import Api from "../api";

const URL = `${process.env.VUE_APP_API_BASE_URL}`;

//現場一覧
const FIELDS_LIST = `${URL}/fields/get_list_useable_fields_by_use`;

export const globalheader = {
  searchSites: (params) => {
    return Api.get(FIELDS_LIST, { params });
  },
};
