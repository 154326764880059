<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <!-- readonly -->
          <div v-if="!editable">
            <LocationTree
              v-if="locations && locations.length"
              :editable="false"
              name="location"
              :locations="locations"
              :depth="0"
            />
          </div>
          <!-- 編集用 -->
          <div v-if="editable">
            <ValidationObserver>
              <LocationTree
                v-if="locations && locations.length"
                :editable="editable"
                name="location"
                :locations="locations"
                :depth="0"
                :push="push"
                :update="update"
                :remove="remove"
              />
            </ValidationObserver>
          </div>
          <v-btn
            v-if="editable" @click="addRootLocation" color="primary"
            :disabled="!editable || CONSTRUCTION_ARRAY.length == 0"
          >追加
          </v-btn
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import LocationTree from "./components/LocationTree.vue";
import _ from "lodash";
import Select from "@/components/forms/elements/Select.vue";
import Label from "@/components/forms/elements/Label";

// init data
// 作業場所
// const LOCATIONS = [];

export default {
  data: () => {
    return {
      CONSTRUCTION_ARRAY: ['truong'],
      locations: [],
      formValues: {},
      dataConstructions: {
        construction_name: null
      },
      constructId: null,
      countItem: 0,
    };
  },

  components: {
    ValidationObserver,
    LocationTree,
    Label,
    Select,
  },

  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    locationSelect: Array,
    idSelect: Number,
  },

  async mounted() {

    this.$watch(
      () => this.item,
      (newValue) => {
        this.initData(newValue);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.formValues.field_places,
      (value) => {
        this.locations = value;
      },
      {
        immediate: true,
        deep: true,
      },
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },
  methods: {
    // propsで渡されるformデータをcomponent用にset
    initData(data) {
      const formValues = data;
      this.formValues = _.cloneDeep(formValues);
      // this.locations = this.getDataLocation();
    },

    // 最上層の場所を追加
    addRootLocation() {
      const locations = _.cloneDeep(this.locations);
      locations.push({
        name: "",
        children: [],
      });
      this.locations = _.cloneDeep(locations);
      this.formUpdate(this.locations);
    },

    // formデータ更新
    formUpdate(newValue) {
      const formValues = _.cloneDeep(this.formValues);
      formValues.field_places = newValue;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    /**
     * 更新
     */
    update({path, name}) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].name = name;
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
        if (this.constructId) {
          this.$emit('filter-select', this.locations, this.constructId);
        }
      }
    },
    /**
     * 追加
     */
    push({path}) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations[+tindex].children = locations[+tindex].children ? locations[+tindex].children : [];
          locations[+tindex].children.push({
            name: "",
            children: [],
          });
          return true;
        }
      };
      let _temp = _.cloneDeep(this.locations);
      if (req(pathArray, _temp)) {
        this.formUpdate(_temp);
      }
    },
    /**
     *
     */
    remove({path, index}) {
      const pathArray = path.split("_");
      const req = (arr, locations) => {
        const tindex = arr.shift();
        if (arr.length) {
          return req(arr, locations[+tindex].children);
        } else {
          locations.splice(index, 1);
          return true;
        }
      };
      if (req(pathArray, this.locations)) {
        this.formUpdate(this.locations);
      }
    },
    getDataLocation() {
      let rs = [];
      return rs;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
</style>
