<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title primary--text">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="closeForm()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
      :title_search="title_placehoder"
    >
      <v-list two-line>
        <template v-for="item in formValues.items">
          <v-list-item
            v-if="((item.active_flg === 3 || item.active_flg === 2) && item.role === 1)"
            :key="item.field_id" ripple style="background-color:#f5f5f5;"
          >
            <v-list-item-content @click="onClick(item.field_id)">
              <v-list-item-subtitle class="company_name">{{
                item.company_name
              }}</v-list-item-subtitle>
              <v-list-item-title class="field_name">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.full_address
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :key="item.field_id" ripple>
            <v-list-item-content @click="onClick(item.field_id)">
              <v-list-item-subtitle class="company_name">{{
                item.company_name
              }}</v-list-item-subtitle>
              <v-list-item-title class="field_name">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.full_address
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-if="showNoMessage">
          {{ no_data }}
        </v-list-item>
      </v-list>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import {
  NO_DATA
} from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
// import {HEADER_MENU_ITEMS_FIELD_UNFINISHED} from "@/constants/GLOBALHEADER";

export default {
  components: {
    SearchSelectList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    },
    onSearch: Function,
    close: Function,
  },

  data: () => {
    return {
      keyword: "",
      title_placehoder: '現場名',
      no_data: NO_DATA,
      showNoMessage: false,
    };
  },

  mounted() {
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`], (data) => {
         if (data.length < 1) {
           this.showNoMessage = true;
         } else {
           this.showNoMessage = false;
         }
        },
        {
          immidiate: true,
          deep: true,
        }
    );
  },

  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.$emit("onSearch", { keyword: this.keyword });
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.keyword = values.keyword;
      this.$emit("formUpdate", values);
    },

    // idの更新
    onClick(id) {
      const values = { ...this.formValues };
      values.selected = id;
      let arr = Object.values(values.items);
      arr = arr.filter((e) => {
        return e.field_id == id;
      });
      sessionStorage.setItem("CURRENT_SITE", JSON.stringify(arr[0]));
      this.$emit("formUpdate", values);
    },
    closeForm() {
      this.formValues.keyword = '';
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}

.field_name {
  font-size: 16px !important;
  font-weight: bold !important;
}
.company_name {
  font-size: 10px !important;
}
</style>
