<template>
  <v-card class="mx-auto my-12 mt-0 mb-0 mb-0 pb-8">
    <div class="dialog-header">
      <div class="dialog-title">パスワードリセット</div>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <v-form ref="form" lazy-validation autocomplete="off" @submit="onSubmit">
        <v-list-item>
          <v-list-item-content>
            <Label label="メールアドレス">
              <InputText
                name="login_id"
                :values="formValue"
                :editable="true"
                validation_label="メールアドレス"
                validation_rules="required|email"
                @onInput="onInput"
              />
            </Label>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              height="48px"
              color="primary"
              depressed
              :disabled="invalid == false ? isDisable : invalid"
              @click="Reset()"
              >パスワード再設定する</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "@/components/layout/SingleLayout.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Label from "@/components/forms/elements/Label.vue";
import { MESSAGES } from "@/constants/LOGIN.js";
import Popup from "@/components/common/Popup.vue";

export default {
  name: "ResetMail",
  head: {
    title() {
      return {
        inner: "AirLiza",
        separator: "|",
        complement: "パスワードリセット",
      };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    InputText,
    Label,
    Popup,
  },
  data: () => {
    return {
      formValue: {
        login_id: "",
      },
      passwordShow: false,
      isDisable: false
    };
  },
  methods: {
    async Reset() {
      this.isDisable = true;
      const res = await Store.dispatch("Login/resetMail", this.formValue);
      this.isDisable = false;
      if (res.data.result) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: MESSAGES.MAIL_SEND_SUCCESS,
        });
        setTimeout(() => {
          this.$emit("close");
        }, 500);
      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.data.message,
        });
      }
    },
    onInput({ name, value }) {
      const formValue = { ...this.formValue };
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },
    onSubmit(e) {
      if (!this.htmlSubmit) {
        e.preventDefault();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialog.scss";
</style>
