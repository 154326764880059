<template>
  <!-- Inspection Details Form -->
    <v-dialog persistent v-model="showModel" max-width="90%" min-width="1000px" v-if="showModal">
      <Loading v-if="isLoading"/>
      <v-card class="tenken-form-list-container" style="position: relative;">
        <div class="header">
          <v-toolbar-title color="primary" class="primary--text header-title">
          {{ titelModal }}
        </v-toolbar-title>
          <div class="header-button-container">
            <div class="header-action-button-container">
              <v-btn
                  v-if="isShowUnApproveButton"
                  depressed
                  color="darken-1"
                  @click="dialogUnapproved = true"
                  class="btn-unapproved"
              >
                <span class="btn-title">{{ MESSAGE_UNAPPROVE_MODAL.title }}</span>
                <Popup width="480px" :dialog="dialogUnapproved">
                  <ExtendDialog
                      v-model="dialogUnapproved"
                      :title="MESSAGE_UNAPPROVE_MODAL.title"
                      :text1="MESSAGE_UNAPPROVE_MODAL.message"
                      :acceptBtn="MESSAGE_UNAPPROVE_MODAL.title"
                      :warning="true"
                      @close="dialogUnapproved = false"
                      @yes="updateInspectionStatus(UNAPPROVED_ACTION)"
                  />
                </Popup>
              </v-btn>

              <v-btn
                  depressed
                  color="primary"
                  @click="dialogApproval = true"
                  v-if="isShowApproveButton"
                  class="btn-approval ml-2"
              >
                <span class="btn-title">{{ MESSAGE_APPROVE_MODAL.title }}</span>
                <Popup width="480px" :dialog="dialogApproval">
                  <ExtendDialog
                      v-model="dialogApproval"
                      :title="MESSAGE_APPROVE_MODAL.title"
                      :text1="MESSAGE_APPROVE_MODAL.message"
                      :acceptBtn="MESSAGE_APPROVE_MODAL.title"
                      @close="dialogApproval = false"
                      @yes="updateInspectionStatus(APPROVED_ACTION)"
                  />
                </Popup>
              </v-btn>
            </div>
            <v-btn icon @click="closeModal">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="action-container">
          <div class="action">
            <span class="action-before">レ</span>
            <span>良好</span>
          </div>
          <div class="action">
            <span class="action-before">◯</span>
            <span>修理して使用する</span>
          </div>
          <div class="action">
            <span class="action-before">× </span>
            <span>故障のため使用しない</span>
          </div>
        </div>
        <div class="tab-container">
          <v-tabs v-model="selectedTab" align-with-title class="ml-n4">
            <v-tab>点検結果</v-tab>
            <v-tab v-if="isShowImageTab">写真</v-tab>
          </v-tabs>
        </div>
        <div class="table-container-inspection" v-if="selectedTab === 0">
          <div class="header-inspection-result">
            <div style="width: 30%;" class="font-weight-black">高車情報</div>
            <div style="width: 70%; padding-left:73px;" class="font-weight-black">点検項目</div>
          </div>
          <div class="inspection-result">
            <div class="inspection-result-list">
              <div class="inspection-result-item">
                <p>カーナンバー <br /> <span v-if="machineInfo">{{ machineInfo.number_plate }}</span></p>
                <p>位置情報<br /> <span v-if="machineInfo">{{ machineInfo.place_name }}</span></p>
                <p>報告者 <br />
                  <span v-if="annunciator">
                    {{ annunciator.company_name }}{{ annunciator.user_name_sei }} {{ annunciator.user_name_mei }} <br />
                    {{ annunciator.user_phone }}
                  </span>
                </p>
              </div>
            </div>
            <div class="inspection-items">
              <div v-for="result in resultDetails">
                <span style="padding-right: 40px;">
                  <v-icon style="color: green;" v-if="result.result === 0">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon style="color: green;" v-else-if="result.result === 1">
                    mdi-close-circle
                  </v-icon>
                  <v-icon style="color: green;" v-else>
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </span>
                <span class="inspection-result-item">{{ result.inspect_item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-container-image" v-if="selectedTab === 1">
          <div class="list-image">
            <div class="container-comment">
              <span>コメント</span>
              <div class="messager-content image-content-no-galleries" v-if="listVideo === undefined || listVideo?.length === 0">
                {{ photoComment }}
              </div>
              <div class="messager-content" v-else>
                {{ photoComment }}
              </div>
            </div>
            <div class="list-image-galleries">
              <Gallery :list-video="listVideo" v-if="listVideo?.length"/>
              <div class="messager-no-data" v-if="listVideo === undefined || listVideo?.length === 0">
                {{NO_DATA}}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import { NO_DATA } from "@/constants/COMMON";
import {
  APPROVED_STATUS,
  MESSAGE_APPROVE_MODAL,
  MESSAGE_UNAPPROVE_MODAL,
  APPROVED_ACTION,
  UNAPPROVED_ACTION,
  APPROVED_STATUS_KEY_NAME,
} from "@/constants/INSPECTION";
import Gallery from "@/components/gallery/Gallery.vue";
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
import { WRN_0002 } from "@/constants/MESSAGE";
import Loading from "@/components/loading/Loading";

export default {
  data: () => ({
    NO_DATA,
    MESSAGE_APPROVE_MODAL: MESSAGE_APPROVE_MODAL,
    MESSAGE_UNAPPROVE_MODAL: MESSAGE_UNAPPROVE_MODAL,
    APPROVED_STATUS: APPROVED_STATUS,
    APPROVED_ACTION: APPROVED_ACTION,
    UNAPPROVED_ACTION: UNAPPROVED_ACTION,
    APPROVED_STATUS_KEY_NAME: APPROVED_STATUS_KEY_NAME,
    selectedTab: 0,
    listVideo: [],
    photoComment: null,
    annunciator: null,
    mottoChange: null,
    resultDetails: [],
    roleLogin: null,
    isLoading: false,
    ids: [],
    WRN_0002: WRN_0002,
    dialogUnapproved: false,
    dialogApproval: false,
    isShowUnApproveButton: false,
    isShowApproveButton: false,
    machineInfo: null,
    isShowImageTab: false,
    showModal: false
  }),

  components: {
    Gallery,
    ExtendDialog,
    Popup,
    ConfirmDialog,
    pdf,
    Loading
  },

  props: {
    showModel: {
      type: Boolean,
    },
    idSend: {
      type: Number,
    },
    idFieldMachine: {
      type: Number,
    },
    titelModal: {
      type: String,
    },
    nameCompanyRental: {
      type: String,
    },
    approvedStatus: {
      type: Number,
    }
  },

  watch: {
    showModel(newValue) {
      if (this.idSend) {
        if (this.approvedStatus === APPROVED_STATUS_KEY_NAME.APPROVED) {
          this.isShowUnApproveButton = true
        }

        if (this.approvedStatus === APPROVED_STATUS_KEY_NAME.UNAPPROVED) {
          this.isShowApproveButton = true
        }

        this.ids = [this.idSend];
        if (newValue) this.getInspectionDetail();
        this.selectedTab = 0;
      }
    },
  },
  mounted() {
    this.currentSite = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));
    this.$watch(
      () => [Store.getters["GlobalHeader/getCurrentSite"], this.currentSite],
      (data) => {
        if (data[0]) {
          this.roleLogin = data[0].role;
        } else if (data[1]) { 
          this.roleLogin = data[1].role;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Booking/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    async getInspectionDetail() {
      const params = {
        'id': this.idSend
      };
      const result = await Store.dispatch(`Booking/getInspectionDetail`, params);
      if (!result.hasError && result.contents.entries) {
        const dataDetail = result.contents.entries.inspection_results;
        this.resultDetails = result.contents.entries.inspection_result_details;
        await this.getMachineInfo(dataDetail);
        if (dataDetail) {
          this.repairStatus = dataDetail.repair_status;
          this.annunciator = dataDetail;
          this.annunciator.company_name = this.annunciator.company_name + "　";
          this.repairAttachId = dataDetail.id;
          if (dataDetail.result !== 0) {
            this.isShowImageTab = true;
          }
        }
        this.getInspectionImage();
      }
      this.showModal = true;
    },

    async getInspectionImage() {
      const params = {
        'id': this.idSend
      };
      const result = await Store.dispatch(`Booking/getInspectionImage`, params);
      if (!result.hasError) {
        const dataList = result.contents.entries;
        this.listVideo = [];
        if (dataList) {
          this.photoComment = dataList.inspection_results?.comment || '';
          this.listVideo = dataList.inspection_result_images?.map?.(item => ({
            type: 'image',
            src: item?.image_url,
          }));
        }
      }
    },

    async getMachineInfo(dataDetail) {
      const params = {
        'id': this.idFieldMachine
      };
      const result = await Store.dispatch(`Booking/getMachineInfo`, params);
      if (!result.hasError) {
        this.machineInfo = result.contents.entries;
        // this.machineInfo.place_name = (this.machineInfo) ? this.machineInfo.place_name + "" : null;
        this.machineInfo.place_name = (dataDetail) ? dataDetail.place_name + "　" : null;
      }
    },

    async updateInspectionStatus(approve_action) {
      let MSG = "";
      if (approve_action === APPROVED_ACTION) {
        MSG = "承認しました";
      } else if (approve_action === UNAPPROVED_ACTION) {
        MSG = "承認解除しました";
      }
      const result = await Store.dispatch(`Inspection/updateInspectionApprovedStatus`, { ids: this.ids, approve_action });
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: MSG,
        });
        this.$emit('reload-data');
        if (approve_action === APPROVED_ACTION) {
          this.isShowUnApproveButton = true;
          this.isShowApproveButton = false;
        } else {
          this.isShowUnApproveButton = false;
          this.isShowApproveButton = true;
        }
      }
      if (approve_action === APPROVED_ACTION) {
        this.dialogApproval = false;
      } else if (approve_action === UNAPPROVED_ACTION) {
        this.dialogUnapproved = false;
      }
    },

    closeModal() {
      this.$emit('close-formDetail-modal', false);
      this.isShowApproveButton = false;
      this.isShowUnApproveButton = false;
      this.isShowImageTab = false;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";
.messager-no-data {
  color: rgba(0,0,0,.38); 
  text-align: center; ; 
  padding-top: 60px;
  width: 75%;
}

.inspection-result-item {
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
  p {
    font-weight: 400;
    color: rgba(0,0,0,.6);
    margin-bottom: 2px;
    line-height: 1.5em!important;
    padding: 15px 0px 26px 0px;
    padding-top: 0px;
  }
}
.table-container-image {
  overflow: auto;
}
.table-container-inspection {
  padding: 27px 20px;
  width: 100%;
  overflow: auto; 
  margin: 0 auto;
  .header-inspection-result {
    width: 100%;
    display: flex; 
    border-bottom: 1px solid #f0f2f3; 
    padding: 10px;
    div {
      font-weight: 500;
      line-height: 24px;
      color: rgba(0,0,0,.6);
    }
  }
  .inspection-result {
    width: 100%; 
    display: flex;
    &-list {
      width: 30%; 
      padding: 10px;
    }
    .inspection-items {
      width: 70%;
      div {
        border-bottom: 1px solid #f0f2f3;
        padding: 15px;
      }
    }
  }
}
* {
  font-family: $normal_font;
  font-style: normal;
}
.tenken-form-list-container {
  height: max-content;
  height: 80vh;
  display: flex;
  flex-direction: column;
  >.header {
    display: flex;
    padding: 8px 4px 22px 8px;
    justify-content: space-between;

    >.header-title {
      font-size: 20px !important;
      font-weight: 600;
    }

    >.header-button-container {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: 25px;
      }

      >.header-action-button-container {
        min-width: 150px;
        text-align: right;
        > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  >.tab-container {
    border-bottom: 1px solid $color_border_accent;
    display: flex;

    >.legends-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      padding-right: 112px;

      > :not(:last-child) {
        margin-right: 15px;
      }

      >.legend {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;

        > :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .action-container {
    display: flex;
    width: auto;
    justify-content: flex-end;
    position: absolute;
    top: 88px;
    right: 100px;
    z-index: 1;

    .action {
      padding: 0px 20px;

      &-before {
        padding-right: 8px;
      }
    }
  }

  .list-image {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    position: relative;
    .list-image-galleries {
      width: 75%;
      display: flex;
      justify-content: center;
    }
    .messager-no-data {
      color: rgba(0,0,0,.38);
      text-align: center; ;
      padding-top: 60px;
    }
    .container-comment {
      width: 25%;
      padding-top: 25px;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
      .messager-content {
        border: 1px solid #e5e5e5;
        padding: 15px;
        min-height: 200px;
        height: calc(100% - 45px);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        word-wrap: break-word;
        white-space: pre-line;
      }
    }

    .image-content-no-galleries {
      height: 55vh !important;
    }

    @media screen and (max-width: 1024px) {
      .image-content-no-galleries {
        height: 50vh !important;
      }
    }
  }

  >.table-container {
    padding: 22px 75px 36px;
  }

  .btn-approval {
    width: 100px !important;
    text-align: center;
  }

  .btn-unapproved {
    width: 100px !important;
    background-color: #ff7b52 !important;
    color: #ffffff !important;
  }

  .btn-title {
    width: 100%;
  }
}
</style>
