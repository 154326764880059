import Vue from "vue";
import Vuex from "vuex";
import { inspection } from "@/api/modules/inspection";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = inspection;
export const Inspection = {
  namespaced: true,

  state: {
    inspections: [],
    isLoading: false
  },

  mutations: {
    SET_DATA_INSPECTION(state, payload) {
      state.inspections = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async getListInspectionResults({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListInspectionResults(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      if (response.result) {
        const inspections = response.data.contents.entries;
        commit("SET_DATA_INSPECTION", inspections);
      }
      return response;
    },
    async deleteInspectionResults({ commit }, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.deleteInspectionResults(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
    async updateInspectionApprovedStatus({ commit }, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.updateInspectionApprovedStatus(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
    async getInspectionResultByIds({ commit }, dataRequest) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getInspectionResultByIds(dataRequest);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        return data;
      }
      return data;
    },
  },

  getters: {
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
