<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer">
    <div class="list-item-search" style="position: relative;">
      <SearchFormWrapper>
        <Label label="搬出予定日" class="title-input-item" style="max-width: 224px">
          <InputDatepicker name="scheduled_return_date_from" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="搬出予定日" @onInput="onChangeSearchParams" :validation_rules="getRuleStartDate()" />
        </Label>
        <span class="mt-12 ml-3">
          <v-icon>mdi-tilde</v-icon>
        </span>
        <Label label="搬出予定日" class="title-input-item" style="max-width: 224px">
          <InputDatepicker name="scheduled_return_date_to" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="搬出予定日" @onInput="onChangeSearchParams" :validation_rules="getRuleEndDate()" />
        </Label>
        <Label label="ナンバー" class="title-input-item">
          <InputText name="number_plate" :editable="true" :values="searchTabAdd" @onInput="onChangeSearchParams" />
        </Label>
        <LabelSearchMachine label="レンタル会社" :editable="true" class="title-input-item">
          <Select style="max-width: 200px;" name="company_renta_id" :values="searchTabAdd" :items="fieldPlaces"
            item_text="name" :editable="true" @onInput="onChangeSearchParams" testid="id" />
        </LabelSearchMachine>
        <Label label="昇降" class="title-input-item">
          <TabSelect name="lifting_method" :items="liftingTabSelect" :editable="true" :values="searchTabAdd"
            @onInput="onChangeSearchParams" />
        </Label>
        <Label label="足回り" class="title-input-item">
          <TabSelect name="undercarriage" :items="undercarriageTabSelect" :editable="true" :values="searchTabAdd"
            @onInput="onChangeSearchParams" />
        </Label>
        <div style="position: relative; right: 10px">
          <Label label="作業床" class="title-input-item">
            <TabSelect name="working_floor" :items="workingFloorTabSelect" :editable="true" :values="searchTabAdd"
              @onInput="onChangeSearchParams" />
          </Label>
        </div>
        <v-spacer></v-spacer>
        <Label>
          <v-btn class="mr-6" color="primary" depressed @click="searchMachine" :disabled="invalid">
            検索
          </v-btn>
        </Label>
      </SearchFormWrapper>
    </div>
  </ValidationObserver>
</template>
<script>
import { format, parseISO } from "date-fns";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import { Store } from "@/store/Store.js";
import {
  LIFTING_PLAN_PAY_SELECT,
  UNDERCARRIAGE_PLAN_PAY_SELECT,
  WORKING_FLOOR_PLAN_PAY_SELECT
} from "@/constants/MACHINE";
import LabelSearchMachine from "@/components/forms/elements/LabelSearchMachine.vue";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select.vue";
import { ValidationObserver } from "vee-validate";

export default {
  data() {
    return {
      fieldId: null,
      searchTabAdd: {
        "field_id": null,
        "scheduled_return_date_from": null,
        "scheduled_return_date_to": null,
        "company_renta_id": null,
        "number_plate": null,
        "lifting_method": [],
        "undercarriage": [],
        "working_floor": [],
        "sort_value": "name_kana",
        "sort_by": "ASC",
        "page": 1,
        "page_size": 25
      },
      fieldPlaces: [],
      liftingTabSelect: LIFTING_PLAN_PAY_SELECT,
      undercarriageTabSelect: UNDERCARRIAGE_PLAN_PAY_SELECT,
      workingFloorTabSelect: WORKING_FLOOR_PLAN_PAY_SELECT,
    }
  },
  components: {
    TabSelect,
    SearchFormWrapper,
    LabelSearchMachine,
    Label,
    InputDatepicker,
    InputText,
    Select,
    ValidationObserver
  },
  computed: {
    computedDateStartFormatted() {
      return this.searchTabAdd.scheduled_return_date_from ? format(parseISO(this.searchTabAdd.scheduled_return_date_from), "yyyy/MM/dd") : "";
    },
    computedDateEndFormatted() {
      return this.searchTabAdd.scheduled_return_date_to ? format(parseISO(this.searchTabAdd.scheduled_return_date_to), "yyyy/MM/dd") : "";
    },
  },
  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.searchTabAdd.field_id = this.fieldId;
    this.getListFieldCompany();
  },

  methods: {
    async onChangeSearchParams({ name, value }) {
      let searchTabAdd = { ...this.searchTabAdd };
      searchTabAdd[name] = value;
      this.searchTabAdd = searchTabAdd;
      this.$emit("has-error");
    },

    async getListFieldCompany() {
      const params = {
        "field_id": this.fieldId,
        "role": 4
      };
      const result = await Store.dispatch(`Machines/getListFieldCompanyRole`, params);
      if (!result.hasError) {
        this.fieldPlaces = [{ id: null, name: "" }, ...result.contents.entries];
      }
    },

    async searchMachine() {
      this.$emit("search-tab-add", this.searchTabAdd);
    },

    onInput({ name, value }) {
      const searchTabAdd = { ...this.searchTabAdd };
      searchTabAdd[name] = value;
      this.searchTabAdd = searchTabAdd;
    },
    getRuleStartDate() {
      let rule = "";
      if (this.searchTabAdd?.scheduled_return_date_from && this.searchTabAdd?.scheduled_return_date_from) {
        const dateEnd = this.searchTabAdd?.scheduled_return_date_to;
        rule = `err-start-date:${dateEnd},搬出予定日（左）, 搬出予定日（右）`;
      }
      return (
        rule
      );
    },
    getRuleEndDate() {
      let rule = "";
      if (this.searchTabAdd?.scheduled_return_date_from && this.searchTabAdd?.scheduled_return_date_from) {
        const dateEnd = this.searchTabAdd?.scheduled_return_date_from;
        rule = `err-end-date:${dateEnd},搬出予定日（右）,搬出予定日（左）`;
      }
      return (
        rule
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .list-item-search {
    ::v-deep .SearchFormWrapper {
      flex-wrap: wrap;
      position: relative;

      .input-number {
        .primary--text {
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 16px;
          color: #274FC2;
        }
      }

      .header-filer {
        .v-label {
          color: rgba(0, 0, 0, 0.38) !important;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .v-text-field__slot {
        .v-label {
          color: rgba(0,0,0,.87) !important;
          font-weight: 400 !important;
          font-size: 12px !important;
        }
      }

      .tab-select-header {
        margin-top: 38px !important;

        .titel-select {
          font-size: 12px !important;
        }

        .title-tab-select {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #000000;
        }
      }
    }

    .input-date {
      border-radius: 2px;
      width: 166px;
      height: 52px;
      font-size: 12px !important;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
      flex: none;

      ::v-deep .v-input__control {
        .v-input__slot {
          .v-text-field__slot {
            input {
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 24px !important;
              color: rgba(0, 0, 0, 0.87) !important;
            }
          }

          .v-select__slot {
            .v-select__selections {
              .v-select__selection--comma {
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 24px !important;
                color: rgba(0, 0, 0, 0.87) !important;
              }
            }
          }
        }
      }
    }

    .color-label {
      color: rgba(176, 0, 32, 1);
    }

    .headline {
      width: 50px;
      line-height: 54px !important;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .tab-select-header {
      height: 56px !important;
    }

    .tableSortWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 8px;
      height: 32px;

      .cst-row {
        border-bottom: 1px solid;
        padding-top: 12px;
        border-color: #e0e0e0;
      }

      .cst-col {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

}
</style>