<template>
  <div class="FormMain mt-3">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="CCUS現場ID" required>
                              <InputText
                                name="ccus_id"
                                :values="formValues.fields"
                                :editable="editable"
                                validation_label="CCUS現場ID"
                                validation_rules="ccusid:CCUS現場ID"
                                placeholder=""
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="最大予約可能日数">
                              <Select
                                name="max_bookable_days"
                                :values="formValues.fields"
                                :items="months"
                                :editable="editable"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col v-if="showEndTimeOfDay" cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="日またぎの時間">
                              <Select
                                name="end_time_of_day"
                                :values="formValues.fields"
                                item-text="name"
                                :items="ENTER_DAY_TIME_ARRAY"
                                :editable="editable"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="管理責任者">
                              <InputText
                                name="represent_manager_name"
                                :values="formValues.fields"
                                :editable="editable"
                                placeholder=""
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-list-item>
                          <v-list-item-content>
                            <Label label="留置場所変更通知">
                              <Select
                                name="max_days_change_place_notice"
                                :values="formValues.fields"
                                :items="daysNotice"
                                :editable="editable"
                                @onInput="onInput('fields', $event)"
                              />
                            </Label>
                          <div
                            :class="[editable ? 'content-description' : 'content-description-pr']"
                            v-if="formValues.fields?.max_days_change_place_notice">
                            <span class="description-place">
                              {{formValues.fields.max_days_change_place_notice}}日前から予約している機械の留置場所の変更を通知します
                            </span>
                          </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import {Store} from "@/store/Store.js";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import {ENTER_DAY_TIME_ARRAY} from "@/constants/FIELDS";
import Select from "@/components/forms/elements/Select.vue";
import {CRUD_TITLE} from "@/constants/CRUDS";
import FileInput from "@/components/forms/elements/FileInput";


export default {
  data: () => {
    return {
      formValues: {},
      description: {},
      company_branches: [],
      resetValue: {},
      CRUD_TITLE,
      ENTER_DAY_TIME_ARRAY,
      endMonth: 30,
      months: [
        30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1
      ],
      daysNotice: [],
      showEndTimeOfDay: false,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
  },
  props: {
    item: Object,
    companyBranches: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  mounted() {
    this.$watch(
      () => [this.item],
      (data) => {
        this.initData(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    CONSTANTS() {
      return Store.getters["Constants/get"];
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login.company_user;
    }
  },
  methods: {
    initData(data) {
      const formValues = data[0];
      this.formValues = {...formValues};
      for(let i=30; i >= 1; i--) {
        this.daysNotice.push(i);
      }
      this.daysNotice.push("");
    },
    onInput(parent_name, {name, value}) {
      const formValues = {...this.formValues};
      if (parent_name) {
        formValues[parent_name][name] = value;
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.content-description-pr {
  position: relative;
  top: -18px;
  width: 92%;
  margin: 0 auto;
  padding: 0px 15px;
}
.content-description {
  position: relative;
  top: -23px;
  width: 92%;
  margin: 0 auto;
  padding: 0px 15px;
}
.description-place {
  color: #274FC2;
}
</style>
