<template>
  <div class="tabSelect">
    <div class="tabSelectwrapper" v-for="(item, index) in items" :key="item.id">
      <label :for="`checkbox_${randId}_${index}`">
        <input
          class="tabCheckbox"
          type="checkbox"
          v-model="selected[index]"
          :id="`checkbox_${randId}_${index}`"
          :value="item.id"
        />
        <div class="tabLabel">{{ item.name }}</div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      randId: Math.floor(Math.random() * 999999),
      selected: [],
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
  },
  mounted() {
    /**
     * 初期値を設定
     */
    this.$watch(
      () => [this.values, this.items, this.name],
      (newValue) => {
        const values = newValue[0][newValue[2]];
        const items = newValue[1];
        /**
         * v-checkbox用にtrue/falseに変換
         */
        const selected = items?.map((item) => {
          const result = values?.find((_item) => {
            return _item === item.id;
          });
          return result || result == 0 ? true : false;
        });
        if (JSON.stringify(this.selected) !== JSON.stringify(selected)) {
          this.selected = selected;
        }
      },
      { immediate: true, deep: true }
    );

    /**
     * 変更を検出
     * チェックしているidだけを返す
     */
    this.$watch(
      () => this.selected,
      (newValue) => {
        const ids = this.items
          .filter((item, index) => {
            return newValue[index];
          })
          .map((item) => item.id);
        this.$emit("onInput", { name: this.name, value: ids });
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.tabSelect {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 8px;
  height: 30px;
}

.tabLabel {
  height: 30px;
  font-size: 14px;
  padding: 5px 10px;
  background-color: $color_form_bg;
  color: #a1a1a1;
  &:hover {
    cursor: pointer;
  }
}
.tabCheckbox:checked + .tabLabel {
  font-weight: bold;
  background-color: $color_primary;
  color: white;
}

.tabSelectwrapper {
  height: 10px;
  &:first-of-type {
    .tabLabel {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &:last-of-type {
    .tabLabel {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.tabCheckbox {
  display: none;
}
</style>
