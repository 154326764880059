<template>
  <div>
    <!-- マスター -->
<!--    <v-list-item link @click="onClickOpen('/master/sites')">-->
<!--      <v-list-item-content>-->
<!--        <v-list-item-title>現場マスター</v-list-item-title>-->
<!--      </v-list-item-content>-->
<!--    </v-list-item>-->
<!--    <v-list-item link @click="onClickOpen('/master/offices')">-->
<!--      <v-list-item-content>-->
<!--        <v-list-item-title>支店マスター</v-list-item-title>-->
<!--      </v-list-item-content>-->
<!--    </v-list-item>-->
<!--    <v-list-item link @click="onClickOpen('/master/employees')">-->
<!--      <v-list-item-content>-->
<!--        <v-list-item-title>社員マスター</v-list-item-title>-->
<!--      </v-list-item-content>-->
<!--    </v-list-item>-->
  </div>
</template>
<script>
export default {
  methods: {
    //画面遷移
    onClickOpen(url) {
      this.$emit("onClickOpen", url);
    },
  },
};
</script>
