<template>
  <div class="page-container">
    <DefaultLayout>
      <template #mainHeader>
        <!--  現場ポータルヘッダー -->
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" />
      </template>
      <template #page>
        <v-container fluid grid-list-lg>
          <v-layout class="mx-md-8" row wrap>
            <v-flex lg4 md12 sm12> </v-flex>
          </v-layout>
          <v-layout class="portal-container" row wrap>
            <v-flex
              lg4
              md12
              sm12
              ref="matchHeight"
              class="d-flex flex-column justify-space-between"
            >
              <v-card class="qr-card box-shadow-dashboard">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="40"
                  v-if="isLoadingFieldInfo"
                ></v-progress-circular>
                <div class="qr-container" v-else>
                  <span class="qr-title">
                    {{ fieldInfo.name }}
                  </span>
                  <div class="qr-content-container">
                    <div class="qr-content">
                      <span>
                        {{ fieldInfo.prefecture }} {{ fieldInfo.city }}
                        {{ fieldInfo.address_detail }}
                      </span>
                      <div class="qr-content-second-row">
                        <span>竣工予定年月</span>
                        <span>{{ fieldInfo.field_end_date }}</span>
                      </div>
                    </div>
                    <div class="d-flex flex-column" style="align-items: center">
                      <div class="qr-border" @click="openModalQR()">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          :size="40"
                          v-if="isLoadingQR"
                        ></v-progress-circular>
                        <img
                          v-if="qrCodeUrl && !isLoadingQR"
                          :src="qrCodeUrl"
                          height="40px"
                          width="40px"
                        />
                      </div>
                      <span>QRコード</span>
                    </div>
                  </div>
                </div>
              </v-card>
              <TableLabel
                label="稼働状況"
                path="/booking"
                name="Booking"
                :params= {tabDefault:1}
                style="margin-bottom: 20px"
                @open="goPage"
              >
                <v-card class="data-card-container box-shadow-dashboard">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="40"
                    v-if="isLoadingFieldInfo"
                  ></v-progress-circular>
                  <div class="data-card" v-else>
                    <div class="data-card-item">
                      <span clas="title">今日の予約</span>
                      <OperationChart
                        :primaryNumber="
                          operationStatus?.total_machines_booking_today ?? 0
                        "
                        :secondaryNumber="
                          operationStatus?.total_machines_without_booking_today ??
                          0
                        "
                        primaryTitle="予約あり"
                        secondaryTitle="予約なし"
                      />
                      <div class="legend-container">
                        <div class="legend">
                          <span>予約あり</span>
                          <div class="legend-square primary"></div>
                        </div>
                        <div class="legend">
                          <span>予約なし</span>
                          <div class="legend-square accent"></div>
                        </div>
                      </div>
                    </div>
                    <div class="data-card-item">
                      <span clas="title">今日の稼働</span>
                      <OperationChart
                        :primaryNumber="
                          operationStatus?.total_machines_tenken_today ?? 0
                        "
                        :secondaryNumber="
                          operationStatus?.total_machines_without_tenken_today ??
                          0
                        "
                        primaryTitle="点検済み"
                        secondaryTitle="未使用"
                      />
                      <div class="legend-container">
                        <div class="legend">
                          <span>点検済み</span>
                          <div class="legend-square primary"></div>
                        </div>
                        <div class="legend">
                          <span>未使用</span>
                          <div class="legend-square accent"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </TableLabel>

              <v-card
                class="data-card-container box-shadow-dashboard"
                style="min-height: 320px"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="40"
                  v-if="isLoadingFieldInfo"
                ></v-progress-circular>
                <div class="stat-card" v-else>
                  <div class="stat-row">
                    <div class="stat-left">
                      <span class="title">搬入予定</span>
                      <span class="compare-text">今日/明日</span>
                      <div class="main-stat">
                        <span class="stat-unit">台</span>
                        <span class="stat-text">
                          {{ operationStatus.total_machines_delivery_today ?? 0 }}/{{ operationStatus.total_machines_delivery_tomorrow ?? 0 }}
                        </span>
                        <span class="stat-unit">台</span>
                      </div>
                    </div>
                    <div>
                      <span class="title">返却予定</span>
                      <span class="compare-text">今日/明日</span>
                      <div class="main-stat">
                        <span class="stat-unit">台</span>
                        <span class="stat-text">
                          {{ operationStatus.total_machines_return_today ?? 0 }}/{{ operationStatus.total_machines_return_tomorrow ?? 0 }}
                        </span>
                        <span class="stat-unit">台</span>
                      </div>
                    </div>
                  </div>
                  <div class="stat-row">
                    <div class="stat-left">
                      <span class="title">作業前点検故障報告</span>
                      <span class="compare-text">今日/累計</span>
                      <div class="main-stat">
                        <span class="stat-unit">件</span>
                        <span class="stat-text">
                          {{ operationStatus.total_report_failure_today ?? 0 }}/{{ operationStatus.total_report_failure ?? 0 }}
                        </span>
                        <span class="stat-unit">件</span>
                      </div>
                    </div>
                    <div>
                      <span class="title">故障対応</span>
                      <span class="compare-text">今日/累計</span>
                      <div class="main-stat">
                        <span class="stat-unit">件</span>
                        <span class="stat-text">
                          {{ operationStatus.total_report_repair_today ?? 0 }}/{{ operationStatus.total_report_repair ?? 0 }}
                        </span>
                        <span class="stat-unit">件</span>
                      </div>
                    </div>
                  </div>
                  <div class="stat-row">
                    <div class="stat-left">
                      <span class="title">月例点検</span>
                      <span class="compare-text">今月/在庫台数</span>
                      <div class="main-stat">
                        <span class="stat-unit">台</span>
                        <span class="stat-text">
                          {{ operationStatus.total_machine_tenken_current_month ?? 0 }}/{{ operationStatus.total_machines_stock ?? 0 }}
                        </span>
                        <span class="stat-unit">台</span>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </v-card>
            </v-flex>
            <v-flex lg5 md12 sm12>
              <TableLabel
                @open="goPage"
                label="在庫状況"
                path="/machine"
                name="Machine"
              >
                <StockTable
                  :data="stockStatus"
                  :isLoading="isLoadingFieldInfo"
                  :matchHeight="setHeight"
                />
              </TableLabel>
            </v-flex>
            <v-flex lg3 md12 sm12>
              <TableLabel
                @open="goPage"
                label="予約状況"
                path="/booking"
                name="Booking"
                :params= {tabDefault:0}
              >
                <BookingTable
                  :data="bookingStatus"
                  :isLoading="isLoadingFieldInfo"
                  :matchHeight="setHeight"
                />
              </TableLabel>
            </v-flex>
          </v-layout>
        </v-container>
        <Popup width="480px" :dialog="isShowQRDialog">
          <QrCodeDialog
            title="現場QRコード"
            fieldCode="現場コード"
            :code="fieldCode"
            :fieldId="fieldId"
            :fieldName="fieldName"
            @close="isShowQRDialog = false"
          >
          </QrCodeDialog>
        </Popup>
      </template>
    </DefaultLayout>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import TableLabel from "@/components/companyPortal/TableLabel";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import StockTable from "./components/StockTable";
import BookingTable from "./components/BookingTable";
import OperationChart from "./components/OperationChart";
import Popup from "@/components/common/Popup";
import QrCodeDialog from "@/components/dialog/QrCodeDialog.vue";
import {HEADER_MENU_ITEMS_FIELD_PORTAL} from "@/constants/GLOBALHEADER";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: "ポータル" };
    },
  },
  data() {
    return {
      item: {},
      fieldId: 0,
      fieldName: "",
      flagUserRentalCompany: false,
      qrCodeUrl: "",
      fieldCode: "",
      operationStatus: {},
      stockStatus: [],
      bookingStatus: [],
      fieldInfo: {},
      setHeight: 0,

      // Dialog
      isShowQRDialog: false,

      // Loading
      isLoadingQR: false,
      isLoadingFieldInfo: false,
      isExportingQR: false,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    CompanyHeader,
    TableLabel,
    StockTable,
    BookingTable,
    Popup,
    OperationChart,
    SitePortalHeader,
    QrCodeDialog
  },
  computed: {
    apiParams() {
      return {
        field_id: this.fieldId,
      };
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.BULLENTIN_BOARD.id,
    });
    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_SUPERVISOR) {
          this.flagSupervisor = true;
        } else if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data?.field_id !== oldData?.field_id) {
          this.fieldId = data.field_id;
          this.fieldName = data.name;
          this.fetchData();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    this.fetchData();
    this.matchHeight();
  },
  methods: {
    fetchData() {
      this.setData();
      this.fetchQRCode();
      this.fetchFieldInfoDashboard();
    },

    async fetchQRCode() {
      // Reset
      this.qrCodeUrl = "";
      this.fieldCode = "";

      // Sanitize
      if (!this.fieldId) return;

      // Fetch
      const res = await Store.dispatch(
        "DashboardPortal/getQRCode",
        this.apiParams
      );
      this.qrCodeUrl = res.qr_code;
      this.fieldCode = res.field_code;

      // Reset
      this.isLoadingQR = false;
    },

    async fetchFieldInfoDashboard() {
      // Reset
      this.operationStatus = {};
      this.fieldInfo = [];
      this.stockStatus = [];
      this.bookingStatus = [];

      // Sanitize
      if (!this.fieldId) return;

      // Assert
      this.isLoadingFieldInfo = true;

      // Fetch
      const res = await Store.dispatch(
        "DashboardPortal/getFieldInfoDashboard",
        this.apiParams
      );
      this.operationStatus = res.operation_status;
      this.fieldInfo = res.field_infor;
      this.stockStatus = Array.isArray(res.stock_status)
        ? res.stock_status
        : [];
      this.bookingStatus = Array.isArray(res.booking_status)
        ? res.booking_status
        : [];

      // Reset
      this.isLoadingFieldInfo = false;
    },

    openModalQR() {
      this.isShowQRDialog = true;
    },

    goPage(name, params = {}) {
      this.$router.push({name, params});
      Store.dispatch(
        "PortalChart/setCompanyBranchId",
        this.item.company_branch_id
      );
    },

    matchHeight() {
      const heightCol = this.$refs.matchHeight.clientHeight;
      this.setHeight = heightCol - 35;
    },

    setData() {
      if (JSON.parse(sessionStorage.getItem("CURRENT_SITE"))) {
        this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
        this.fieldName = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).name;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/dashboard.scss";
</style>
