const COMPANIES_INITIAL_ITEM = {
  address: "",
  ccus_id: "",
  company_code: "",
  company_type: null,
  corporate_number: "",
  created_at: "",
  first_user_id: "",
  id: null,
  name: "",
  name_kana: "",
  plate_number_prefix: "",
  updated_at: "",
  users: {},
};
const USER_INITIAL_ITEM = {
  company: {
    name: "",
    name_kana: "",
    corporate_status_id: null,
    corporate_rating_position: "",
    corporate_classification: "",
    corporate_number: null
  }
};

/**
 * (共通)
 * タイトル
 */
const FORM_TITLE = "現場情報";
const COMPANIES_TITLE = "現場";

const COMPANIES_TABLE_LABELS = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "name",
    width:"27%",
  },
  {
    text: "種別",
    value: "company_type",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "所在地",
    value: "address",
    align: "left",
    sortable: false,
    width:"28%",
  },
  {
    text: "電話番号",
    value: "phone",
    align: "left",
    sortable: false,
    width:"10%",
  },
  {
    text: "所属ユーザー",
    value: "number_member",
    align: "left",
    sortable: true,
    width:"10%",
  },
  {
    text: "",
    value: "action",
    align: "center",
    sortable: true,
    width:"10%",
  }
];

const USER_TABLE_LABELS = [
  {
    text: "会社名",
    align: "left",
    sortable: true,
    value: "name",
    width:"25%",
  },
  {
    text: "氏名",
    value: "user_full_name",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    sortable: false,
    width:"25%",
  },
  {
    text: "携帯番号",
    value: "phone",
    align: "left",
    sortable: false,
    width:"20%",
  },
  {
    text: "",
    value: "status",
    align: "center",
    sortable: false,
    width:"5%",
  }
];
const COMPANIES_SORT_ITEMS = [
  {
    id: "name_kana",
    name: "会社名",
  },
  {
    id: "number_member",
    name: "所属ユーザー数",
  }
];
const USER_SORT_ITEMS = [
  {
    id: "kana",
    name: "氏名",
  },
  {
    id: "users.email",
    name: "メールアドレス",
  }
];
const TAB_COMPANIES = [
  {
    id : 1,
    name : '建設会社',
  },
  {
    id : 2,
    name : 'レンタル会社',
  },
];
const COMPANY_TYPE = [
  { id: 1, value: 1, name: "現場関係会社" },
  { id: 2, value: 2, name: "レンタル会社" },
];
const RENTAL_USER_TYPE = [
  { id: 1, value: 1, name: "技術" },
  { id: 0, value: 0, name: "その他" },
];
const USER_INIT = {
  email: '',
  phone: '',
  name_sei: '',
  name_mei: '',
  kana_sei: '',
  kana_mei: '',
}
const ENGINEERS = 1;
export {
  FORM_TITLE,
  COMPANIES_INITIAL_ITEM,
  COMPANIES_SORT_ITEMS,
  USER_INITIAL_ITEM,
  USER_SORT_ITEMS,
  COMPANIES_TABLE_LABELS,
  TAB_COMPANIES,
  COMPANIES_TITLE,
  USER_TABLE_LABELS,
  COMPANY_TYPE,
  RENTAL_USER_TYPE,
  USER_INIT,
  ENGINEERS,
};
