<template>
  <v-dialog v-model="isOpen" persistent max-width="90%" min-width="1000px">
    <Loading v-if="isLoading"/>
    <ValidationObserver v-slot="{ invalid }">
      <v-card class="dialog-container">
      <div
        class="
          main-container
          d-flex
          flex-row
          justify-space-between
          align-center
          flex-wrap
        "
        style="padding-left: 10px!important;padding-top: 10px!important;"
      >
        <span class="dialog-title">受注情報</span>
        <div class="button-container">
          <v-btn
            outlined
            color="primary"
            class="dialog-button"
            @click="isShowCancel = true"
          >
            やめる
          </v-btn>
          <v-btn
            depressed color="primary"
            class="dialog-button mr-3"
            @click="openConfirmResponseForm"
            :disabled="checkDisableResponse()"
          >
            回答
          </v-btn>
          <v-icon @click="isShowCancel = true" class="mr-n2">mdi-close</v-icon>
          <Popup width="480px" :dialog="isShowCancel">
            <ExtendDialog
              v-model="isShowCancel"
              :title="WRN_0002.TITLE"
              :text1="WRN_0002.TEXT1"
              :text2="WRN_0002.TEXT2"
              acceptBtn="閉じる"
              :warning="true"
              @close="isShowCancel = false"
              @yes="close()"
            />
          </Popup>
          <Popup width="480px" :dialog="isOpenConfirmResponseForm">
            <ExtendDialog
              v-model="isOpenConfirmResponseForm"
              :title="TEXT_CONFIRM_RESPONSE.TITLE"
              :text1="TEXT_CONFIRM_RESPONSE.TEXT1"
              :text2="TEXT_CONFIRM_RESPONSE.TEXT2"
              acceptBtn="確定"
              :warning="false"
              @close="isOpenConfirmResponseForm = false"
              @yes="responseMachineRequests()"
            />
          </Popup>
        </div>
      </div>
      <div class="wrap-search receive-order-modal-request-info-custom-wrap-search">
        <div class="main-container d-flex flex-row second-row flex-wrap">

          <Label label="元請会社" width="150px" class="title-input-item custom-input-select-label">
            <Select
              name="request_company_id"
              :items="listRequestCompanies"
              :editable="true"
              :values="searchParams"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <Label label="現場" width="150px" class="title-input-item custom-input-select-label">
            <Select
              name="request_field_id"
              :editable="true"
              :values="searchParams"
              :items="listRequestFields"
              @onInput="onChangeSearchParams"
            />
          </Label>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="getRuleFrom()"
            v-show="false"
          >
            <Label label="床高（m）" class="title-input-item custom-input-number-message" width="120px">
              <InputText
                v-model="searchParams.floor_height_min"
                name="floor_height_min"
                :values="searchParams"
                :editable="true"
                @onInput="onChangeSearchParams"
                validation_label="床高（m）"
                :validation_rules="getRuleFrom()"
              />
            </Label>
          </ValidationProvider>
          <span class="headline" style="padding-top: 45px"  v-show="false">~</span>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="getRuleTo()"
            v-show="false"
          >
            <Label label="床高（m）" class="title-input-item custom-input-number-message" width="120px">
              <InputText
                v-model="searchParams.floor_height_max"
                name="floor_height_max"
                :values="searchParams"
                :editable="true"
                @onInput="onChangeSearchParams"
                validation_label="床高（m）"
                :validation_rules="getRuleTo()"
              />
            </Label>
          </ValidationProvider>
          <div class="d-flex flex-column"  v-show="false">
            <Label label="昇降" class="title-input-item modal-request-info-select"  v-show="false">
              <TabSelect
                class="status-item"
                name="lifting_method"
                :items="LIST_LIFTING_METHOD"
                :editable="true"
                :values="searchParams"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
          <div class="d-flex flex-column"  v-show="false">
            <Label label="足回り" class="title-input-item modal-request-info-select"  v-show="false">
              <TabSelect
                class="status-item"
                name="undercarriage"
                :items="LIST_UNDERCARRIAGE"
                :editable="true"
                :values="searchParams"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
          <div class="d-flex flex-column"  v-show="false">
            <Label label="作業床" class="title-input-item modal-request-info-select"  v-show="false">
              <TabSelect
                class="status-item"
                name="working_floor"
                :items="LIST_WORKING_FLOOR"
                :editable="true"
                :values="searchParams"
                @onInput="onChangeSearchParams"
              />
            </Label>
          </div>
        </div>
        <div class="d-flex flex-column justify-end ml-auto mr-7 mt-16 pt-1" style="width: 100px">
          <v-btn
            depressed
            color="primary"
            class="dialog-button mb-1"
            @click="fetchData(invalid)"
          >
            検索
          </v-btn>
        </div>
      </div>
      <div class="main-content-container">
        <div v-for="(item, index) in dragList" :key="'drag' + index">
          <div
            class="d-flex flex-column content-col"
          >
            <div class="content-header" v-if="index !== 0">
              <span class="title">{{ item.title }}</span>
              <div class="number" v-if="item.item.length == 0">0</div>
              <div style="background-color: #1F5697 !important; color: #FFFFFF" class="number" v-else>
                {{ item.item.length }}
              </div>
            </div>
            <div class="badge" style="padding-left: 17px; font-size: 13px" v-else>
              {{ item.title }}（{{getTotalNotResponseRequests}}）
            </div>
            <div class="content">
              <v-checkbox
                v-model="item.isChecked = onCheckAll(index)"
                label="全て選択"
                v-if="index > 0 && item.item.length"
                @change="onChangeSingleCheckbox(index)"
              ></v-checkbox>
              <draggable
                class="draggable"
                v-bind="dragOptions"
                :list="item.item"
                @change="getMovedItem"
              >
                <div
                  v-for="(request, cIndex) in item.item"
                  :key="`drag${index}-${cIndex}`"
                >
                  <v-hover v-slot="{ hover }">
                    <v-card
                      class="content-item d-flex flex-row"
                      :elevation="hover ? 16 : 2"
                    >
                      <v-checkbox
                        v-if="index > 0"
                        v-model="request.isChecked"
                      ></v-checkbox>
                      <div class="d-flex flex-column">
                        <span class="content-name">
                          {{ getFloor(request.floor_height_min, request.floor_height_max) }}
                          {{ getListMethod(request.lifting_method, request.undercarriage, request.working_floor) }}
                        </span>
                        <span class="content-number">
                          {{ request.field_name }}
                        </span>
                        <span class="content-number">
                          {{ request.company_name }}
                        </span>
                        <span class="content-number">
                          搬入口： {{ request.gate_name }}
                        </span>
                        <span class="content-number">
                          搬入希望日： {{ formatDesiredLiveryDate(request.desired_delivery_date) }}
                        </span>
                      </div>
                    </v-card>
                  </v-hover>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <v-btn
          outlined
          color="primary"
          class="dialog-button"
          :disabled="checkDisableRollback()"
          @click="onReset()"
        >
          元に戻す
        </v-btn>
      </div>
    </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import {WRN_0002} from "@/constants/MESSAGE";
import {Store} from "@/store/Store";
import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
import Popup from "@/components/common/Popup.vue";
import TabSelect from "@/components/forms/elements/TabSelect";
import InputText from "@/components/forms/elements/InputText.vue";
import draggable from "vuedraggable";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用

import {cloneDeep} from "lodash";
import {
  LIST_LIFTING_METHOD,
  LIST_UNDERCARRIAGE,
  LIST_WORKING_FLOOR,
  TEXT_CONFIRM_RESPONSE,
  LIFTING,
  UNDERCARRIAGE,
  WORKING_FLOOR,
} from "@/constants/RECEIVE_ORDERS"
import {ValidationProvider, ValidationObserver} from "vee-validate";
import Loading from "@/components/loading/Loading";
import {monthFormat} from "../../../common/helper";

const default_dragList = [
  {
    status: 2,
    title: "未回答",
    item: [],
  },
  {
    status: 3,
    title: "承諾",
    item: [],
  },
  {
    status: 5,
    title: "辞退",
    item: [],
  },
];
const STORE = "ReceiveOrders";
const STATUS_PENDING = 2;
const STATUS_ACCEPT = 3;
const STATUS_CANCEL = 5;

export default {
  components: {
    ExtendDialog,
    Popup,
    draggable,
    Label,
    Select,
    ValidationProvider,
    ValidationObserver,
    Loading,
    InputText,
    TabSelect
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    listMachineRequestId: []
  },
  computed: {
    dragOptions() {
      return {
        disabled: false,
        scrollSensitivity: "250",
        forceFallback: true,
        group: "drag",
      };
    },
    getTotalNotResponseRequests() {
      return this.totalNotResponseRequests - this.dragList[1].item.length - this.dragList[2].item.length;
    }
  },
  data() {
    return {
      // Constant
      WRN_0002,
      TEXT_CONFIRM_RESPONSE,

      //Dialog
      isOpen: false,
      isShowCancel: false,

      //Variable
      searchParams: {
        floor_height_min: "",
        floor_height_max: "",
        request_field_id: null,
        request_company_id: null,
        lifting_method: [],
        undercarriage: [],
        working_floor: [],
        exceptId: []
      },
      LIST_LIFTING_METHOD,
      LIST_UNDERCARRIAGE,
      LIST_WORKING_FLOOR,
      LIFTING,
      UNDERCARRIAGE,
      WORKING_FLOOR,
      STATUS_PENDING,
      STATUS_ACCEPT,
      STATUS_CANCEL,
      totalNotResponseRequests: 0,
      //List
      listRequestFields: [],
      listRequestCompanies: [],
      defaultOption: {id: null, name: ""},
      dragList: cloneDeep(default_dragList),
      tempDragList: [],
      isOpenConfirmResponseForm: false,
      isDisableRollback: true,
      isLoading: false,
    };
  },
  async mounted() {
    this.isOpen = this.isShow;

    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    await this.fetchData();

    this.watchField();

    await this.getListFieldAndCompany();
  },
  methods: {
    close() {
      this.isShowCancel = false;
      this.isOpen = false;
      this.$emit("close");
    },
    watchField() {
      this.$watch(
        () => Store.getters["GlobalHeader/getCurrentSite"],
        (data, oldData) => {
          if (data?.field_id !== oldData?.field_id) this.fetchData();
        },
        {
          deep: true,
          immediate: true,
        }
      );
    },
    async fetchData(invalid) {
      if (!invalid) {
        const listMachineRequestId = this.listMachineRequestId.map((item) => item.id);
        // Create params
        const body = {
          request_company_id: this.searchParams.request_company_id,
          request_field_id: this.searchParams.request_field_id,
          floor_height_min: this.searchParams.floor_height_min,
          floor_height_max: this.searchParams.floor_height_max,
          working_floor: this.searchParams.working_floor,
          undercarriage: this.searchParams.undercarriage,
          lifting_method: this.searchParams.lifting_method,
          listMachineRequestId: listMachineRequestId,
          exceptId: this.getListChangeId()
        };

        const res = await Store.dispatch(
          `${STORE}/getListDeliveryRequestDetail`, {params: body}
        );
        const list = res?.data?.contents?.entries ?? [];

        // Assign and sync
        this.dragList[0].item = list.map((x) => ({...x, isChecked: false}));
        if (this.totalNotResponseRequests == 0) {
          this.totalNotResponseRequests = this.dragList[0].item.length;
        }
      }
    },
    onReset() {
      for (let i=1; i < this.dragList.length; i++) {
        for (let j = 0; j < this.dragList[i].item.length; j++) {
          if (this.dragList[i]?.item[j].isChecked === true) {
            this.dragList[0].item.push(this.dragList[i].item[j]);
            this.dragList[i].item.splice(j, 1);
            j--;
          }
        }
      }
    },
    onChangeSingleCheckbox(index) {
      this.dragList[index].isChecked = !this.onCheckAll(index);
      for (let i = 0; i < this.dragList[index].item.length; i++) {
        this.dragList[index].item[i].isChecked = this.dragList[index].isChecked;
      }
    },
    onCheckAll(index) {
      if (this.dragList[index].item.length > 0) {
        for (let i = 0; i < this.dragList[index].item.length; i++) {
          if (this.dragList[index].item[i].isChecked == false) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    checkDisableRollback() {
      for (let index = 1; index < this.dragList.length; index++) {
        for (let i = 0; i < this.dragList[index].item.length; i++) {
          if (this.dragList[index]?.item[i]?.isChecked == true) {
            return false;
          }
        }
      }
      return true;
    },
    checkDisableResponse() {
      var isDisableRollback = true;
      if (this.dragList[1].item.length > 0 || this.dragList[2].item.length > 0) {
        isDisableRollback = false;
      }
      return isDisableRollback;
    },
    onChangeSearchParams({name, value}) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      this.searchParams = searchParams;
    },
    getFloor(floorHeightMin, floorHeightMax) {
      if (floorHeightMin === null && floorHeightMax !== null) {
        return `～${ floorHeightMax }m`;
      } else if (floorHeightMax === null & floorHeightMin !== null) {
        return `${ floorHeightMin }m～`;
      } else if (floorHeightMin !== null && floorHeightMax !== null) {
        return `${ floorHeightMin }m～${ floorHeightMax }m`;
      } else {
        return ``;
      }
    },
    getListMethod(lifting_method, undercarriage, working_floor) {
      let result = `${LIFTING[lifting_method].name}`;
      if (undercarriage != 0) {
        result += this.getSpaceFullSize() + `${UNDERCARRIAGE[undercarriage].name}`;
      }
      if (working_floor != 0) {
        result += this.getSpaceFullSize() + `${WORKING_FLOOR[working_floor].name}`;
      }
      return result;
    },
    openConfirmResponseForm() {
      this.isOpenConfirmResponseForm = true;
    },
    getSpaceFullSize(){
      return "　";
    },
    getListChangeId() {
      var listIds = [];
      this.dragList.filter((item) => {
        if (item.status != STATUS_PENDING) {
          return item;
        }
      }).forEach((item) => {
        item.item.map((a) => {
          listIds.push(a.id);
        })
      });
      return listIds;
    },
    async responseMachineRequests() {
      let params = { 0: {"status": STATUS_ACCEPT, "listIds": []}, 1: {"status":STATUS_CANCEL, "listIds": []} };
      this.dragList.forEach((listItems) => {
        if (listItems.status == STATUS_PENDING) {
          return;
        }
        else {
          listItems.item.forEach((item) => {
            if (listItems.status == STATUS_ACCEPT) {
              params[0].listIds.push(item.id)
            } else {
              params[1].listIds.push(item.id)
            }
          })
        }
      });
      const res = await Store.dispatch(
        `${STORE}/responseMachineRequests`,
        {"params": params}
      );
      this.isOpenConfirmResponseForm = false;
      if (res?.data?.status_code === 200) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: res?.data?.message,
        });
        this.$emit("callGetListTotalRequest");
        this.isOpen = false;
        this.$emit("callGetItems");
        this.$emit("close");
      }
    },
    getMovedItem(item) {
      if (item.removed?.element)
      {
        item.removed.element.isChecked = false;
      }
    },
    getRuleFrom() {
      let rule = "";
      if (this.searchParams.floor_height_min && this.searchParams.floor_height_max) {
        rule = `compare-float-min:${this.searchParams.floor_height_max},床高（m）（左）,床高（m）（右）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    getRuleTo() {
      let rule = "";
      if (this.searchParams.floor_height_min && this.searchParams.floor_height_max) {
        rule = `compare-float-max:${this.searchParams.floor_height_min},床高（m）（右）,床高（m）（左）`;
      }
      return "decimal|decimalLimitOther|" + rule;
    },
    async getListFieldAndCompany(){
      let listField = await Store.dispatch("Fields/getListRelatedFieldByUser");
      let data = listField?.data?.contents?.entries;
      this.listRequestFields = [this.defaultOption, ...data];
      let listMainConstructor = await Store.dispatch("Fields/getListMainConstructorByUser");
      let dataCompany = listMainConstructor?.data?.contents?.entries;
      this.listRequestCompanies = [this.defaultOption, ...dataCompany];
    },
    formatDesiredLiveryDate(date) {
      return monthFormat(date);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/fonts.scss";
@import "@/assets/scss/themes.scss";

$border_bottom: 1px solid $color_border_accent;

* {
  font-family: $normal_font;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.dialog-button {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 1.25px;
  min-width: 90px !important;
}

.dialog-container {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .main-container {
    padding: 22px 30px 0 !important;
  }

  .dialog-title {
    letter-spacing: 0.18px;
    font-weight: 600;
    font-size: 20px;
    color: $color_primary;
  }

  .button-container {
    > :not(:last-child) {
      margin-right: 20px;
    }

    > :last-child {
      margin-left: 15px;
    }
  }

  > .wrap-search {
    border-bottom: $border_bottom;
    > .second-row {
      width: calc(100% - 125px);
      float: left;

      > * {
        margin-bottom: 0px;
      }

      > :not(:last-child) {
        margin-right: 10px;
      }

      > .select {
        max-width: 130px;
      }

      .number-input-container {
        > :not(:last-child) {
          margin-right: 6px;
        }

        > .number-input {
          max-width: 98px;
        }

        > .headline {
          font-size: 30px !important;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }

  > .main-content-container {
    padding: 17px 30px 0;
    overflow-x: auto;
    display: flex;
    height: 100%;

    > * {
      display: flex;
    }

    > :not(:last-child) {
      > :first-child {
        margin-right: 30px;
      }
    }

    .content-col {
      > .content-header {
        display: flex;
        align-items: center;

        :first-child {
          margin-right: 15px;
        }

        > .title {
          font-size: 16px !important;
          color: $color_text_main;
          text-overflow: ellipsis;
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
        }

        $circle: 35px;

        > .number {
          min-width: $circle;
          height: $circle;
          border-radius: $circle;
          background: rgba(0, 0, 0, 0.38);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.38);
        }
      }

      > :first-child {
        margin-bottom: 10px;
      }

      > .badge {
        padding: 8px;
        text-align: center;
        background: #e5951d;
        border-radius: 16px;
        width: fit-content;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.87);
      }

      .content {
        width: 250px;
        height: 100%;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 5px 5px 0px 0px;
        padding: 5px 10px;
        overflow-y: auto;
        transition: 0.5s linear;

        &:hover {
          background: rgba(39, 79, 194, 0.08);
        }

        > :first-child {
          padding: 0;
          margin-top: 5px;
          margin-bottom: 6px;
        }

        > .draggable {
          width: 100%;
          height: calc(100% - 35px);

          > :not(:last-child) {
            margin-bottom: 10px;
          }

          > :last-child {
            padding-bottom: 10px;
          }
        }

        .content-item {
          padding: 10px;
          padding-bottom: 5px !important;
          width: 100%;
          font-size: 14px;
          transition: 0.5s linear;

          .content-name {
            font-weight: 500;
            letter-spacing: 0.1px;
            color: rgba(0, 0, 0, 0.87);
          }

          .content-number {
            line-height: 20px;
            letter-spacing: 0.25px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  > .footer {
    margin: 22px 0px;

    > * {
      float: right;
      margin-right: 20px;
    }
  }
}

:deep(.button-toggle) {
  max-height: 30px;

  > .v-btn__content {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.38);
  }

  &.v-btn--active {
    background-color: $color_primary;

    > .v-btn__content {
      color: #ffffff !important;
    }
  }
}

:deep(.content) {
  .v-input--selection-controls__ripple {
    display: none;
  }

  .v-input--selection-controls__input {
    margin-right: 6px;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-messages {
    display: none;
  }
}

:deep(.content-item) {
  .v-input {
    margin: 0;
    padding: 0;
  }
}

.button-toggle-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $color_text_main;
  margin-bottom: 10px;
}

:deep(.second-row) {
  .v-input__slot {
    margin-bottom: 0;
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.receive-order-modal-request-info-custom-wrap-search {
  padding-bottom: 16px;
}
@media (max-width: 1152px) {
  .dialog-container > .main-content-container {
    padding: 17px 45px 0;
  }
  .receive-order-modal-request-info-custom-wrap-search .custom-input-select-label {
    max-width: 100px;
  }
  .receive-order-modal-request-info-custom-wrap-search .second-row{
    width: calc(100% - 120px);
    padding-right: 10px !important;
  }
}

.status-item {
  height: 54px !important;
}

</style>
<style>

.modal-request-info-select .status-item .tabLabel {
  height: 30px;
  line-height: 25px;
  font-size: 16px;
}
.receive-order-modal-request-info-custom-wrap-search .custom-input-number-message .v-messages .v-messages__message{
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 16px;
}
</style>
