<template>
    <ValidationProvider
        v-slot="{ errors, valid }"
        :name="validation_label"
        :rules="validation_rules"
        :vid="vid"
        ref="provider"
    >
        <v-text-field
            v-model="val"
            dense
            color="primary"
            :disabled="!editable"
            :filled="editable"
            :error-messages="errors"
            :success="valid"
            :suffix="suffix"
            :prefix="prefix"
            :placeholder="editable ? placeholder : ''"
            @input="$emit('onInput', { name, value: val })"
            @click="$emit('onClick')"
            @blur="$emit('onBlur')"
            @focusout="$emit('focusout', { name, value: val})"
            :data-testid="testid"
        ></v-text-field>
    </ValidationProvider>
</template>
<script>
import {ValidationProvider} from "vee-validate";

export default {
    components: {
        ValidationProvider,
    },
    props: {
        editable: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
        },
        values: {
            type: Object,
        },
        validation_rules: {
            type: String,
        },
        validation_label: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "",
        },
        testid: {
            type: String,
        },
        suffix: {
            type: String,
            default: "",
        },
        vid: {
            type: String,
        },
        prefix: {
            type: String,
            default: "",
        }
    },
    data: () => {
        return {
            val: "",
        };
    },
    computed: {
        isValid() {
            return this.$refs['provider'].errors.length === 0;
        }
    },  
    mounted() {
        this.$watch(
            () => [this.values, this.name],
            (newValue) => {
                const formValues = newValue[0];
                const name = newValue[1];
                if (formValues && name) this.val = formValues[name];
            },
            {immediate: true, deep: true}
        );
    },
};
</script>
