<template>
    <div class="d-flex flex-column px-2 custom-head" :class="[ { 'satuday': isSatuday, 'sunday': isSunday}]">
        <div class="d-flex justify-center">{{ listDate[index - 1]? listDate[index - 1].getDate() : '' }}日 </div>
        <div class="d-flex justify-center">({{parseDayText(listDate[index - 1] ? listDate[index - 1].getDay() : '')}})</div>
        <div class="d-flex justify-space-around" style="color:rgba(60, 48, 67, 0.5); font-size:12px">
            <div class="mr-1">AM</div>
            <div>PM</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ToHeaderDate',
    components: {},
    props: {
        index: {
            type: Number,
        },
        totalDate: {
            type: Number,
        },
        listDate: {
            type: Array,
        }
    },
    data() {
        return {
            spanClass: 'col-1 p-' + this.totalDate + ' bg-light border',
        }
    },
    computed: { 
        isSatuday() {
            return this.listDate[this.index - 1]?.getDay() === 6;
        },
        isSunday() {
            return this.listDate[this.index - 1]?.getDay() === 0;
        }
    },
    created() { },
    methods: { 
        parseDayText(day) {
            switch(day) {
                case 0:
                    return '日'
                case 1:
                    return '月'
                case 2:
                    return '火'
                case 3:
                    return '水'
                case 4:
                    return '木'
                case 5:
                    return '金'
                case 6:
                    return '土'
                default:
                    return null;
            }
        }
    },
    watch: { }
};
</script>
<style lang="scss" scoped>
    .bg-light {
        background-color: none;
    }
    .satuday {
        background: #EEF1FA;
    }
    .sunday{
        background: #FFE5E5;
    }
    .custom-head {
        width: 50px;
        font-size:14px;
        color: #3C3043;
        border-bottom: thin solid rgba(0,0,0,.12);
        font-weight: 400;
    }
</style>