<template>
  <div>
    <v-card class="dialog">
      <v-card-title>
        <div class="primary--text">パスワード変更</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="body">
        <ValidationObserver ref="observer" v-slot="observer">
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-list-item>
              <v-list-item-content>
                <Label label="現在のパスワード" class="title-input-item" :editable="true" required>
                  <Password
                    name="password_old"
                    :editable="true"
                    :values="formValue"
                    validation_label="現在のパスワード"
                    validation_rules="required|max:50"
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <Label label="新しいパスワード" class="title-input-item" :editable="true" required>
                  <Password
                    name="password_src"
                    :editable="true"
                    :values="formValue"
                    validation_label="新しいパスワード"
                    validation_rules="required|halfSize-resetpass|repassword:新しいパスワード|max:50"
                    :hint="passwordHint"
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <Label label="新しいパスワード（確認）" class="title-input-item" :editable="true" required>
                  <Password
                    name="password_re"
                    :editable="true"
                    :values="formValue"
                    validation_label="新しいパスワード（確認）"
                    :validation_rules="`required|halfSize-resetpass|repassword:新しいパスワード（確認)|passwordRetype|max:50|passwordDiff:${formValue.password_src}`"
                    :hint="passwordHintConfirm"
                    @onInput="onInput"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-form>
          <ValidationCallback :observer="observer" @callback="updateValidate"/>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          outlined
          color="primary"
          class="btn"
          @click="$emit('close')"
        >
          やめる
        </v-btn>
        <v-btn
          depressed
          class="btn"
          color="primary"
          :disabled="!valid"
          @click="actionReset"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import {Store} from "@/store/Store.js";
import Popup from "@/components/common/Popup";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog";
import Password from "@/components/forms/elements/Password.vue";
import {ValidationObserver} from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Label from "@/components/forms/elements/Label";
import Loading from "@/components/loading/Loading";

export default {
  data() {
    return {
      formValue: {
        password_old: "",
        password_src: "",
        password_re: "",
      },
      errors: {},
      valid: false,
      isLoading: false,
      passwordHint: "半角英数字4文字以上で入力してください",
      passwordHintConfirm: "半角英数字4文字以上で入力してください",
    };
  },
  components: {
    Popup,
    ConfirmDialog,
    ConfirmCloseDialog,
    Password,
    ValidationObserver,
    ValidationCallback,
    Label,
    Loading,
  },
  props: {
    userId: Number
  },
  mounted() {
    this.$watch(
      () => Store.getters[`Login/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({name, value}) {
      const formValue = {...this.formValue};
      formValue[name] = value;
      this.formValue = formValue;
      this.$emit("formUpdate", formValue);
    },

    updateValidate(params) {
      const {valid, errors} = params;
      this.valid = valid;
      this.errors = errors;
    },

    async actionReset() {
      const formValue = {
        id: this.userId,
        last_password: this.formValue.password_old,
        password: this.formValue.password_src,
      };

      const res = await Store.dispatch("Login/changePassword", formValue);

      if (!res.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: res?.data?.message,
        });

        this.$emit('closeAfterReset');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";
.dialog {
  margin: 0 auto;
}

.v-card__actions {
  padding-top: 0;
  padding-bottom: 19px;
}
.title-input-item .label{
  color: #000000;
}
</style>
