<template>
  <div>
    <template>
      <ReceiveOrderTabDeliveryRequests :key="selectedTab" v-show="selectedTab == TAB_DELIVERY_REQUESTS"/>
      <ReceiveOrderTabRepairRequests :key="selectedTab" v-show="selectedTab == TAB_REPAIR_REQUESTS"/>
      <ReceiveOrderTabReturnInfomations :key="selectedTab" v-show="selectedTab == TAB_RETURN_INFOMATIONS"/>
    </template>
  </div>
</template>

<script>
import {Store} from "@/store/Store";
import ReceiveOrderTabDeliveryRequests from "@/views/receiveOrder/components/ReceiveOrderTabDeliveryRequests";
import ReceiveOrderTabRepairRequests from "@/views/receiveOrder/components/ReceiveOrderTabRepairRequests";
import ReceiveOrderTabReturnInfomations from "@/views/receiveOrder/components/ReceiveOrderTabReturnInfomations";

const STORE = "ReceiveOrders";
const TAB_DELIVERY_REQUESTS = 0;
const TAB_REPAIR_REQUESTS = 1;
const TAB_RETURN_INFOMATIONS = 2;

export default {
  data() {
    return {
      selectedTab: 0,
      totalNotResponseRequests: 0,
      totalNotReportRequest: 0,
      totalListReturnRequestToRental: 0,
      TAB_DELIVERY_REQUESTS,
      TAB_REPAIR_REQUESTS,
      TAB_RETURN_INFOMATIONS
    }
  },

  components: {
    ReceiveOrderTabDeliveryRequests,
    ReceiveOrderTabRepairRequests,
    ReceiveOrderTabReturnInfomations
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    }
    await Store.dispatch(`${STORE}/countListDeliveryRequestNotResponse`);
    await Store.dispatch(`${STORE}/countListRepairRequestNotReport`);
    await Store.dispatch(`${STORE}/countListReturnRequestToRental`);
    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.selectedTab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    restoreTabSelect() {
      Store.dispatch(`${STORE}/restoreTabSelect`);
    }
  },
  destroyed() {
    this.restoreTabSelect()
  }
}

</script>

<style scoped>
  @media (max-width: 1024px) {
    ::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
      white-space: nowrap;
    }
  } 
</style>
