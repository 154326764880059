<template>
  <v-item-group>
    <v-row class="container" style="margin-bottom: 5px">
      <v-col cols="10">
        <h3 class="table-header">{{ label }}</h3>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-if="path"
          icon
          color="accent"
          x-small
          @click="$emit('open', name, params)"
        >
          <v-icon>mdi-greater-than</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <slot />
  </v-item-group>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    path: {
      type: String,
    },
    name: {
      type: String,
    },
    params: {
      type: Object,
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/fonts.scss";

.table-header {
  font-family: $normal_font;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color_text_main;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none  !important;
}
</style>
