// src/plugins/vuetify.js
import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import "vuetify/dist/vuetify.min.css";

// アイコン設定
import RemoveCheck from "@/components/icon/RemoveCheck.vue";
import {
  colorPrimary,
  colorAccent,
  colorWarning,
  colorFormBg,
} from "@/assets/scss/themes.scss";

Vue.use(Vuetify);
Vue.directive("blur", {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

const COLORS = {
  primary: colorPrimary,
  warning: colorWarning,
  form_bg: colorFormBg,
  sp_btn_cancel: "#aaa",
  accent: colorAccent,
};

export default new Vuetify({
  icons: {
    values: {
      removeCheck: {
        component: RemoveCheck,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: COLORS.primary,
        secondary: colors.indigo.lighten4,
        accent: COLORS.accent,
        warning: COLORS.warning,
        form_bg: COLORS.form_bg,
        sp_btn: COLORS.sp_btn_cancel,
        success: COLORS.success,
      },
      options: {
        // 色変数を使えるように
        // ex. var(--v-primary-base);
        customProperties: true,
      },
    },
  },
});
