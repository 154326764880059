<template>
  <FormDialog>
    <template #header>
      <Loading v-if="isLoading"/>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          {{ !isRental ? '現場関係会社として利用登録する' : 'レンタル会社として利用登録する' }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>

        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onClickBtnClose"
        >
          やめる
        </v-btn>

        <v-btn 
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <FormFieldCompany
          :editable="editable"
          :isRental="isRental"
          :dataSelectCompany="dataSelectCompany"
          @openListCompany="openListCompany"
          @searchListCompany="searchListCompany"
          :mainHeight="params.mainHeight"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirm">
        <Confirm
          title="利用登録"
          text1="入力した内容でアカウントを作成します。"
          text2="よろしければ「登録」ボタンを、修正する場合は「戻る」ボタンを選択してください。"
          @close="onClickBtnCancelShowConfirm"
          @yes="saveData"
          warning
        />
      </Popup>
      <Popup width="900px" :dialog="isShowItemForm">
        <div class="popup-company">
          <ListCompany
            :items="dataListCompany"
            @dataCheckCompany="handCheckCompany"
            @cancel="closeItemForm"
          />
        </div>
      </Popup>
      <Popup width="480px" :dialog="isShowQR">
        <ShowQR
          @close="closeShowQrCode()"
          description="法人番号が重複しています。<br>
            スマホアプリからアカウント作成をお願いします。"
          title="所属会社QRコード"
          :data="dataRegister"
          text="所属会社コード："
          type="field"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Confirm from "../popup/Confirm.vue";
import ListCompany from "../popup/ListCompany.vue";
import { Store } from "@/store/Store.js";
import FormFieldCompany from "./FormFieldCompany.vue";
import ShowQR from "@/components/qr/ShowQR";
import _ from "lodash";

const FORMS = {
  BasicInfo: { id: 1, title: "基本情報" },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: true,
      isShowConfirmDialog: false,
      isShowConfirm: false,
      isClickCloseBtn: false,
      isShowQR: false,
      errors: {},
      isSubmitted: false,
      idSelect : null,
      isShowItemForm: false,
      dataCompanyTest: null,
      dataSelectCompany: null,
      dataRegister: null,
      keySearch: null,
      isLoading: true,
      dataListCompany: {}
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormFieldCompany,
    FormDialog,
    Confirm,
    ListCompany,
    ShowQR,
  },
  props: {
    isRental: Boolean
  },
  methods: {
    formUpdate(params) {
      this.dataRegister = _.cloneDeep(params);
      this.$emit("formUpdate", this.dataRegister);
    },

    onQR(){
      this.isShowQR = true;
    },

    onEditable() {
      this.editable = true;
    },

    onStopEdit() {
      this.isShowConfirmDialog = true;
    },

    onSubmit() {
      if(this.dataRegister.company_code) {
        this.isShowQR = true;
      } else {
        this.isShowConfirm = true
      }
    },

    async saveData() {
      this.isShowConfirm = true;
      delete this.dataRegister.company_code;
      const res = await Store.dispatch('Register/add', this.dataRegister);
      if (!res.hasError && res.data.status_code === 200) {
        this.$emit("cancel");
        this.$emit("cancelSuccess");
      } else {
        this.onClickBtnCancelShowConfirm();
        Store.dispatch(
            "Error/show",
            { status: "500", message: res.data.contents.msg },
            { root: true }
        );
      }
    },

    updateValidate(params) {
      const { valid, errors } = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },

    closeShowQrCode(){
      this.isShowQR = false;
    },

    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    handCheckCompany(data) {
      if (data) {
        this.dataSelectCompany = data;
        this.closeItemForm();
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelShowConfirm() {
      this.isShowConfirm = false;
    },

    openListCompany(name) {
      this.keySearch = name.keyword
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },

    async searchListCompany() {
      const res = await Store.dispatch('Register/getCompany', {
        name: this.keySearch
      });
      this.isLoading = false;
      if (res.data.count > 0) {
        this.dataListCompany = res.data.corporation
        this.isShowItemForm = true;
      } else {
        Store.dispatch("Error/show", {
            status: "422",
            message: "データがありません。"
          },
          {
            root: true
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title-popup{
  color: #274FC2;
}
.popup-company{
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
