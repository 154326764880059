<template>
  <div>
    <DefaultLayout :key="keyTab" class="receive-order-tab-2">
      <template #mainHeader>
        <CompanyHeader />
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayoutTab2"
          >
            <template #tableHeader="{ updateHeader }">
              <TableHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :updateHeader="updateHeader"
                :isClassify="false"
                :isDelete="false"
                :isAddNew="false"
              >
                <SearchFormWrapper class="receive-order-custom-search-form-wrapper">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`compare-start-order-date:${apiParams.order_date_to}`"
                  >
                    <Label label="受注日" class="title-input-item" style="max-width: 250px; min-width: 150px">
                      <InputDatepicker
                        name="order_date_from"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                        :validation_rules="`compare-start-order-date:${apiParams.order_date_to}`"
                        :editable="true"
                        :flagNull="true"
                      />
                    </Label>
                  </ValidationProvider>
                  <span class="mt-12">
                  <v-icon>mdi-tilde</v-icon>
                </span>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`compare-end-order-date:${apiParams.order_date_from}`"
                  >
                    <Label label="受注日" class="title-input-item" style="max-width: 250px; min-width: 150px">
                      <InputDatepicker
                        name="order_date_to"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                        :validation_rules="`compare-end-order-date:${apiParams.order_date_from}`"
                        :editable="true"
                        :flagNull="true"
                      />
                    </Label>
                  </ValidationProvider>
                  <Label label="商品コード" class="title-input-item" style="min-width: 112px">
                    <InputText
                      name="productCode"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="元請" class="title-input-item" style="max-width: 200px; min-width: 150px">
                    <Select
                      name="request_company_id"
                      :items="listRequestCompanies"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="現場" class="title-input-item" style="max-width: 200px; min-width: 150px">
                    <Select
                      name="request_field_id"
                      :items="listRequestFields"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="ステータス" class="title-input-item receive-order-status-select">
                    <TabSelect
                      class="status-item"
                      name="tmp_requests_process_status"
                      :items="STATUS_TAB"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeStatus"
                    />
                  </Label>
                  <v-spacer></v-spacer>
                  <v-btn class="mr-6 mt-10" color="primary" depressed @click="onSearch(invalid)">
                    検索
                  </v-btn>
                </SearchFormWrapper>
              </TableHeader>
              <!--
                  (共通)
                  ソートのレイアウトを調整するラッパーコンポーネントです
                -->
              <TableSortWrapper>
                <!--
                  (共通) ソート
                  ソート項目、ソート順、表示件数の選択、総件数の表示
                -->
                <TableSort
                  class="ml-2"
                  :values="searchParams"
                  :sort_items="SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                />
              </TableSortWrapper>
            </template>

            <!--
              (共通)テーブル
              v-data-table自体は共通ですが、
              カラムによって変更をしたい場合はそれぞれ実装してください。
            -->
            <template #tableBody="{ tableHeight }">
              <template>
                <v-tabs v-model="tab" class="cst-row" :key="keyTab">
                  <v-tab
                    v-for="tab in TAB_RECEIVE_ORDER"
                    :key="tab.id"
                    @click="changeTab(tab.id)"
                    class="tab-machine"
                  >
                  <span>
                    {{ tab.name }}
                  </span>
                    <span>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[0].id && totalNotResponseRequests > 0">
                    mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[1].id && totalNotReportRequest > 0">
                      mdi-alert-circle
                    </v-icon>
                    <v-icon class="icon-active" v-if="tab.id == TAB_RECEIVE_ORDER[2].id && totalListReturnRequestToRental > 0">
                      mdi-alert-circle
                    </v-icon>
                  </span>
                  </v-tab>
                </v-tabs>
              </template>

              <v-data-table
                v-model="selectedItems"
                :items="items"
                :headers="TABLE_LABEL"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight - 50: tableHeight - 90"
                :items-per-page="searchParams.pageCount"
                fixed-header
                hide-default-footer
                disable-sort
                class="elevation-1 v-data-table__wrapper receive-order-data-table-css"
                :noDataText="NO_DATA_MESSAGE"
                @click:row="handlerDataDetailTenken"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
                style="box-shadow: none !important;"
              >
                <template v-slot:[`item.status_flag`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    <span
                      v-if="item.repair_status == REPAIR_STATUS['STATUS_PENDING']"
                      style="background-color: #E5951D; color: rgba(0, 0, 0, 0.87); width: 110px"
                      class="active_flg status-btn"
                    >
                      {{ convertName(REPAIR_REQUEST_PROCESS_STATUS[0].name, 40) }}
                    </span>
                    <span
                      v-if="item.repair_status == REPAIR_STATUS['STATUS_ESTIMATE']"
                      style="background-color: #274FC2; color: #FFFFFF; width: 110px"
                      class="active_flg status-btn"
                    >
                      {{ convertName(REPAIR_REQUEST_PROCESS_STATUS[1].name, 40) }}
                    </span>
                    <span
                      v-if="item.repair_status == REPAIR_STATUS['STATUS_DONE']"
                      style="background-color: rgba(0, 0, 0, 0.38); color: #FFFFFF; width: 110px"
                      class="active_flg status-btn"
                    >
                      {{ convertName(REPAIR_REQUEST_PROCESS_STATUS[2].name, 40) }}
                    </span>
                  </div>
                </template>
                <template v-slot:[`item.report_type`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    <div v-if="item.report_kind == INSPECTION_RESULTS_KIND.type">
                      {{ convertName(INSPECTION_RESULTS_KIND.text, 40) }}
                    </div>
                    <div v-else-if="item.report_kind == REPAIR_REQUESTS_KIND.type">
                      {{ convertName(REPAIR_REQUESTS_KIND.text, 40) }}
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.order_at`]="{ item }">
                  {{ formatDate(item.order_at) }}
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      vertical-align: middle;
                    "
                  >
                  <span>
                    {{ convertName(item.company_name, 50) }}{{getSpaceFullSize()}}{{ getFullName(item) }}
                  </span>
                    <div>
                      {{ item.phone }}
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.field_places_name`]="{ item }">
                  <div
                    style="
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      vertical-align: middle;
                    "
                  >
                  <span>
                    {{ getFieldPlace(item) }}
                  </span>
                  </div>
                </template>
              </v-data-table>
            </template>

            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;"
              />
            </template>

          </TableLayout>
        </ValidationObserver>
        <FormDetailTenken
          :idSend="idSend"
          :titelModal="TITLE"
          :inspectorId="inspectorId"
          :idFieldMachine="idFieldMachine"
          :showModel="showModel"
          @close-formDetail-modal="closeFormDetailModel"
          :callFromTable="dataFromTable"
          :repairStatusItem="repairStatusItem"
          @callReloadData="getListRepairRequestsData"
        />
      </template>
    </DefaultLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/layout/TableLayout";
import {Store} from "@/store/Store";
import {HEADER_MENU_ITEMS_RENTAL} from "@/constants/GLOBALHEADER";
import DefaultLayout from "@/components/layout/DefaultLayout";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import Loading from "@/components/loading/Loading";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import TabSelect from "@/components/forms/elements/TabSelect";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Pagination from "@/components/masterTable/elements/Pagination";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import FormDetailTenken from "../../booking/components/FormDetailTenken.vue";

import {
  TAB_RECEIVE_ORDER,
  RECEIVE_ORDERS_STATUS_TAB_2,
  RECEIVE_ORDERS_SORT_TAB_2,
  RECEIVE_ORDERS_LABEL_TAB_2,
  REPAIR_REQUEST_PROCESS_STATUS,
  REPAIR_STATUS,
  LIFTING,
  UNDERCARRIAGE,
  WORKING_FLOOR
} from "@/constants/RECEIVE_ORDERS";

import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON";
import {convertName} from "@/common/helper";
import moment from "moment/moment";
import {ValidationObserver, ValidationProvider} from "vee-validate"; //絞り込みフォームで使用

const STORE = "ReceiveOrders";
const PAGE_TITLE = "受注";
const STATUS_TAB = RECEIVE_ORDERS_STATUS_TAB_2;
const SORT_ORDERS = TABLE_SORT_ORDERS;
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;
const SORT_ITEMS = RECEIVE_ORDERS_SORT_TAB_2;
const TABLE_LABEL = RECEIVE_ORDERS_LABEL_TAB_2;
const PAGE_COUNT = 25;
const TAB_SELECT = 1;

const INSPECTION_RESULTS_KIND = {
  type: 1,
  text: "点検時異常報告"
};
const REPAIR_REQUESTS_KIND = {
  type: 2,
  text: "修理要請"
};

export default {
  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },

  data() {
    return {
      searchParams: {
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        order_date_from: "",
        order_date_to: "",
        productCode: "",
        request_field_id: null,
        request_company_id: null,
        tmp_requests_process_status: [0],
        requests_process_status: [],
        sort: RECEIVE_ORDERS_SORT_TAB_2[0].id , //sortする項目
        asc: true,
      },
      PAGE_TITLE,
      isLoading: false,
      listRequestFields: [],
      listRequestCompanies: [],
      tab: 0,
      TAB_RECEIVE_ORDER,
      STATUS_TAB,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      SORT_ITEMS,
      TABLE_LABEL,
      NO_DATA_MESSAGE,
      items: [],
      selectedItems: [],
      REPAIR_STATUS,
      REPAIR_REQUEST_PROCESS_STATUS,
      INSPECTION_RESULTS_KIND,
      REPAIR_REQUESTS_KIND,
      defaultOption: { id: null, name: "" },
      idSend: null,
      inspectorId: null,
      showModel :false,
      idFieldMachine: null,
      TITLE: null,
      dataFromTable: null,
      repairStatusItem: null,
      LIFTING,
      UNDERCARRIAGE,
      WORKING_FLOOR,
      totalNotResponseRequests: 0,
      totalNotReportRequest: 0,
      totalListReturnRequestToRental: 0,
      keyTab: 0,
    }
  },

  components: {
    SearchFormWrapper,
    TableHeader,
    TableLayout,
    InputDatepicker,
    Label,
    Select,
    TabSelect,
    TableSortWrapper,
    TableSort,
    InputText,
    Pagination,
    //共通のコンポーネント
    DefaultLayout,
    CompanyHeader,
    Loading,
    FormDetailTenken,
    ValidationProvider,
    ValidationObserver,
  },

  async mounted() {
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * グローバルヘッダーメニューを更新
     */
    await Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_RENTAL.ORDER.id,
    });

    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotResponseRequests`],
      (data) => {
        this.totalNotResponseRequests = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalNotReportRequest`],
      (data) => {
        this.totalNotReportRequest = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )
    this.$watch(
      () => Store.getters[`${STORE}/getTotalListReturnRequestToRental`],
      (data) => {
        this.totalListReturnRequestToRental = data;
      },
      {
        immediate: true,
        deep: true,
      }
    )

    this.$watch(
      () => Store.getters[`${STORE}/getSelectedTab`],
      (data) => {
        this.tab = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    await this.getListRepairRequestsData();

    this.$watch(
      () => [
        Store.getters[`${STORE}/getListRepairRequestToRental`],
        Store.getters[`${STORE}/getPaginationRepairRequestToRental`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.items = _items.map((x) => {
          return {...x}
        });

        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    await this.getListFieldAndCompany();

  },

  computed: {
    apiParams() {
      return {
        list_repair_status: this.searchParams?.requests_process_status.length > 0 ? this.searchParams.requests_process_status : [REPAIR_STATUS['STATUS_PENDING']],
        order_date_from: this.searchParams?.order_date_from ? this.searchParams?.order_date_from?.replaceAll("/", "-") : "",
        order_date_to: this.searchParams?.order_date_to ? this.searchParams?.order_date_to?.replaceAll("/", "-") : "",
        product_code: this.searchParams?.productCode,
        request_field_id: this.searchParams?.request_field_id,
        request_company_id: this.searchParams?.request_company_id,
        sort_value: this.searchParams?.sort,
        sort_by: this.searchParams?.asc ? 1 : 0,
        page: this.searchParams?.currentPage,
        page_count: this.searchParams?.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
  },

  methods: {
    // データ取得
    async getListRepairRequestsData() {
      await Store.dispatch(`${STORE}/getListRepairRequestToRental`, {
        params: this.apiParams,
      });
    },

    changeTab(id) {
      this.getListTotalRequest();
      this.selectedTab = id;
      Store.dispatch(`${STORE}/setSelectedTab`, id);
      if (id === TAB_SELECT) {
        this.getListRepairRequestsData()
      }
    },
    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      if (name === "request_company_id") {
        if (value) {
          this.callApiListFieldByOwnerCompany(value);
        }
        if (!value) {
          this.listRequestFields = [this.defaultOption];
        }
        if (this.searchParams?.request_company_id !== value) {
          this.searchParams.request_field_id = null;
        }
      }
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayoutTab2.onChangeWindowSize()
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({name, value}, invalid) {
      if (!invalid) {
        let searchParams = {...this.searchParams};
        searchParams[name] = value;
        name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
        this.searchParams = searchParams;
        this.getListRepairRequestsData();
        this.selectedItems = [];
      }
    },

    onChangeStatus({name, value}) {
      let requests_process_status = [];
      let tmp_requests_process_status = [];
      if (value.length > 0) {
        if (value.includes(0)) {
          tmp_requests_process_status.push(0);
          requests_process_status.push(REPAIR_STATUS['STATUS_PENDING']);
        }
        if (value.includes(1)) {
          tmp_requests_process_status.push(1);
          requests_process_status.push(REPAIR_STATUS['STATUS_ESTIMATE']);
        }
        if (value.includes(2)) {
          tmp_requests_process_status.push(2);
          requests_process_status.push(REPAIR_STATUS['STATUS_DONE']);
        }
      } else {
        requests_process_status.push(REPAIR_STATUS['STATUS_PENDING'], REPAIR_STATUS['STATUS_ESTIMATE'], REPAIR_STATUS['STATUS_DONE'])
      }
      let searchParams = { ...this.searchParams };
      searchParams[name] = tmp_requests_process_status;
      searchParams["requests_process_status"] = requests_process_status;
      this.searchParams = searchParams;
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    /**
     * (共通)
     * 検索
     */
    onSearch(invalid) {
      if (!invalid) {
        this.searchParams["currentPage"] = 1;
        this.getListRepairRequestsData();
      }
    },

    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      this.selectedItems = []
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getListRepairRequestsData();
    },

    getFullName: function (item) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`
    },

    getFieldPlace: function (item) {
      let fieldPlace = "";
      if (item.lv1_field_places_name) {
        fieldPlace += item.lv1_field_places_name + "/";
      }
      if (item.parent_field_places_name) {
        fieldPlace += item.parent_field_places_name + "/";
      }
      fieldPlace += item.child_field_places_name;
      return fieldPlace;
    },

    formatDate(date) {
      if (date) {
        const format = "YYYY/MM/DD";
        const now = new Date(date);
        return moment(now).format(format);
      }
    },

    closeFormDetailModel(value) {
      this.idSend = null;
      this.showModel = value;
    },

    convertTitleModal(item){
      let title =  item.number_plate +
        " / " +
        item.floor_height +
        "m " +
        LIFTING[item.lifting_method].name;
      if (item.undercarriage != 0) {
        title += " " + UNDERCARRIAGE[item.undercarriage].name;
      }
      if (item.working_floor != 0) {
        title += " " + WORKING_FLOOR[item.working_floor].name;
      }
      return title;
    },
    handlerDataDetailTenken(item) {
      this.idSend = item.report_id;
      this.inspectorId = item.inspector_id;
      this.showModel = true;
      this.idFieldMachine = item.id_field_machine;
      this.TITLE = this.convertTitleModal(item);
      this.dataFromTable = item.report_kind;
      if (item.report_kind == REPAIR_REQUESTS_KIND.type) {
        this.repairStatusItem = item.repair_status;
      }
    },
    getSpaceFullSize(){
      return "　";
    },
    async getListFieldAndCompany(){
      this.listRequestFields = [this.defaultOption];
      let listMainConstructor = await Store.dispatch("Fields/getListMainConstructorByUser");
      let dataCompany = listMainConstructor?.data?.contents?.entries;
      this.listRequestCompanies = [this.defaultOption, ...dataCompany];
    },
    async callApiListFieldByOwnerCompany(id) {
      let field = await Store.dispatch("Fields/getListFieldByOwnerCompany", {
        company_id: id,
      });
      let dataField = field?.data?.contents?.entries;
      this.listRequestFields = [this.defaultOption, ...dataField];
    },
    async getListTotalRequest(){
      let totalNotResponseRequestsResponse = await Store.dispatch(`${STORE}/countListDeliveryRequestNotResponse`);
      let totalNotResponseRequests = totalNotResponseRequestsResponse?.data?.contents?.totalNotResponseRequests;
      this.totalNotResponseRequests = totalNotResponseRequests;
      let totalNotReportRequestResponse = await Store.dispatch(`${STORE}/countListRepairRequestNotReport`);
      let totalNotReportRequest = totalNotReportRequestResponse?.data?.contents?.totalNotReportRequest;
      this.totalNotReportRequest = totalNotReportRequest;
      let totalListReturnRequestToRentalResponse = await Store.dispatch(`${STORE}/countListReturnRequestToRental`);
      let totalListReturnRequestToRental = totalListReturnRequestToRentalResponse?.data?.contents?.totalListReturnRequestToRental;
      this.totalListReturnRequestToRental = totalListReturnRequestToRental;
      this.keyTab = this.keyTab + 1;
    }
  },
}

</script>

<style scoped>
  .cst-row {
    border-bottom: 1px solid #cccccc;
    padding-left: 16px;
  }
  .receive-order-status-select .status-item {
    height: 50px !important;
  }
  .cst-row .tab-machine, .cst-row .tab-machine .icon-active, .cst-row .tab-machine span {
    color: #274FC2;
  }
  .icon-active{
    color: #FF0000 !important;
  }
  .receive-order-data-table-css .status-btn {
    height: 36px;
    min-width: 80px;
    padding: 0px 20px;
    border-radius: 20px;
    display: table;
    line-height: 36px;
    text-align: center;
  }

</style>
<style>
  .receive-order-status-select .status-item .tabLabel {
    height: 30px;
    line-height: 25px;
  }

  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  }
  .receive-order-custom-search-form-wrapper .SearchFormWrapper .labelWrapper .v-messages .v-messages__message {
    line-height: 16px;
  }
  .receive-order-tab-2 thead.v-data-table-header th {
    min-width: 75px;
  }
</style>
