<template>
  <FormDialog>
    <template #header>
      <!-- 
         (共通)
         ヘッダーは全て共通
        -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)" class="machine-tenken">
        <template>
          <v-toolbar-title color="primary" class="primary--text">
            {{ formtitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="onClickBtnClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in TAB_PRE_DELIVERY" :key="key" @click="changeTab(form.id)">
              {{ form.name }}
            </v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <div class="action-container">
            <div class="action">
              <span class="action-before">レ</span>
              <span>良好</span>
            </div>
            <div class="action">
              <span class="action-before">◯</span>
              <span>修理して使用する</span>
            </div>
            <div class="action">
              <span class="action-before">× </span>
              <span>故障のため使用しない</span>
            </div>
          </div>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
      -->
      <InspectionResult v-if="selectedTab == SELECT_TAB_SECOND"
        :mainHeight="params.mainHeight - 20"
        :item="item"
      />
      <Photo v-if="selectedTab == SELECT_TAB_FIRST"
        :mainHeight="params.mainHeight - 20"
        :item="item"
        :sourceImages="sourceImages"
        :imageActive="image_active"
      />
    </template>
  </FormDialog>
</template>

<script>
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import {PRE_DELIVERY,
        FORMS_CREATE_EDIT,
        TAB_PRE_DELIVERY,
        SELECT_TAB_FIRST,
        SELECT_TAB_SECOND} from "@/constants/MACHINE";
import InspectionResult from "./components/InspectionResult";
import Photo from "./components/Photo";

import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";

const VERRICAL_LIFT_TEXT = '垂直昇降';
const STRAIGHT_BOOM_TEXT = '直伸ブーム';
const ARTICYLATED_BOOM_TEXT = '屈折ブーム';
const VERRICAL_LIFT_VALUE = 0;
const STRAIGHT_BOOM_VALUE = 1;
const ARTICYLATED_BOOM_VALUE = 2;

const WHEEL_TEXT = 'ホイール';
const CRAWLER_TEXT = 'クローラ';
const WHEEL_VALUE = 1;
const CRAWLER_VALUE = 2;

const NORMAL_DECK_TEXT = '普通デッキ';
const SLIDE_DECK_TEXT = 'スライドデッキ';
const BUCKET_TEXT = 'バケット';
const NORMAL_DECK_VALUE = 1;
const SLIDE_DECK_VALUE = 2;
const BUCKET_VALUE = 3;


export default {
  data: () => {
    return {
      titleCheckList: "レ　良好　〇　修理して使用する　×　故障のため使用しない",
      tab: null,
      PRE_DELIVERY,
      FORMS_CREATE_EDIT,
      isClickCloseBtn: false,
      TAB_PRE_DELIVERY,
      SELECT_TAB_SECOND,
      selectedTab: SELECT_TAB_SECOND,
      SELECT_TAB_FIRST,
      firstImages : [],
      sourceImages : [],
      image_active: 0,
    };
  },
  components: {
    Popup,
    FormDialog,
    InspectionResult,
    Photo,
    ConfirmCloseDialog,
  },
  props: {
    item: Object,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.dataItems = data?.before_delivery_images;
        let rs = [];
        this.dataItems.forEach(e => {
          rs.push(e);
        });
        this.initiateImageSource(rs, true);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      let numberPlate = this.item?.number_plate;
      let floorHeight = this.item?.floor_height;
      let liftingMethod = this.item?.lifting_method;
      if (liftingMethod === VERRICAL_LIFT_VALUE) {
        liftingMethod = VERRICAL_LIFT_TEXT;
      } else if (liftingMethod === STRAIGHT_BOOM_VALUE) {
        liftingMethod = STRAIGHT_BOOM_TEXT;
      } else if (liftingMethod === ARTICYLATED_BOOM_VALUE) {
        liftingMethod = ARTICYLATED_BOOM_TEXT;
      }
      let undercarriage = this.item?.undercarriage;
      if (undercarriage === WHEEL_VALUE) {
        undercarriage = WHEEL_TEXT;
      } else if (undercarriage === CRAWLER_VALUE) {
        undercarriage = CRAWLER_TEXT;
      }
      let workingFloor = this.item?.working_floor;
      if (workingFloor === NORMAL_DECK_VALUE) {
        workingFloor = NORMAL_DECK_TEXT;
      } else if (workingFloor === SLIDE_DECK_VALUE) {
        workingFloor = SLIDE_DECK_TEXT;
      } else if (workingFloor === BUCKET_VALUE) {
        workingFloor = BUCKET_TEXT;
      }
      return numberPlate + ' / ' + floorHeight + 'm ' + liftingMethod + ' ' + undercarriage + ' ' + workingFloor;
    },
  },
  methods: {
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      this.isClickCloseBtn = true;
      this.$emit("cancel");
      this.isBack = false;
    },
    changeTab(id) {
      this.selectedTab = id;
    },
    async initiateImageSource(arr, flag) {
      if (flag) {
        await arr.forEach((item) => {
          if (item.image_url && !this.firstImages.find(e => e.id == item.id)) {
            this.createObjectUrlForImage(item);
          }
        });
      } else {
        await arr.forEach((item) => {
          if (item.image_url && !this.sourceImages.find(e => e.id == item.id)) {
            this.createObjectUrlForImage(item);
          }
        });
      }
    },
    async createObjectUrlForImage(item) {
        this.firstImages.push({ id: item.id, url:  item.image_url });
        this.sourceImages.push({ id: item.id, url: item.image_url });
    },
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
<style lang="scss" scoped>
.action-container {
  display: flex;
  width: auto;
  justify-content: flex-end;
  white-space: nowrap;
  margin-right: 120px;
  max-height: 21px;
  
  .action {
    padding: 0px 20px;
    
    &-before {
      padding-right: 8px;
    }
  }
}

::v-deep .machine-tenken {
  height: 116px !important;
}

::v-deep .machine-tenken > .v-toolbar__extension {
  margin-top: 20px;
}
</style>
 