<template>
  <div>
    <div class="detail-modal-check-box">
      <div v-for="(item, ind) in memberOfLead" :key="ind" class="row-of-item" :class="`${selected.includes(item.user_id)? 'highlight': ''}`">
        <label :for="`checkbox_${item.user_id}_${ind}`" class="detail-modal-check-box-label " style="font-size: 14px !important;">
          <input
            class="tabCheckbox"
            type="checkbox"
            v-model="selected"
            :id="`checkbox_${item.user_id}_${ind}`"
            :value="item.user_id"
            @change="isSelected"
          />
          <span style="padding-left: 23px; font-size: 14px !important;">{{ convertFullName(item) }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      checked: {},
      selected: []
    };
  },

  props: {
    memberOfLead: {
      type: Array,
    },
    checkBox: {
      type: Array,
    },
    currentLeader: {
      type: Number
    }
  },

  created() {
    this.selected = this.checkBox;
  },

  watch: {
    checkBox() {
      this.selected = this.checkBox;
    },
  },

  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#274FC2;" : "";
    },
  },

  methods: {
    convertFullName(item) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`;
    },
    isSelected() {
      const data = {
        selected: this.selected,
        currentLeader: this.currentLeader
      }
      this.$emit("checkSelected", data)
    }
  },
};
</script>
<style lang="scss" scoped>
.detail-modal-check-box-label {
  color: #000000;
  position: relative;
}
.detail-modal-check-box-label input[type=checkbox] {
  accent-color: #2450c1;
  margin-top: 4px;
  position: absolute;
}
.detail-modal-check-box-label input[type=checkbox]
{
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
}

.detail-modal-check-box {
  box-shadow: 0 2px 5px grey;
  height: 100%;
  width: calc(100% - 10px);
  overflow-y: auto;
  margin-left: 5px;
  padding: 10px 0px;
  border-radius: 5px;
}
.detail-modal-check-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.detail-modal-check-box::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.row-of-item {
  padding: 2px 20px;
}
.highlight {
  background: #EDF1FC;
}
</style>
