<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                      <Label label="商号又は名称" class="search-company title-input-item" :editable="editable">
                        <v-row>
                          <v-col cols="9" style="padding: 0">
                            <v-list-item-content>
                              <SearchSelectListHight
                                name="name"
                                :values="formValues"
                                :editable="editable"
                                @formUpdate="openListCompany"
                                @search="searchListCompany"
                                validation_label="商号又は名称"
                              />
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                      </Label>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="法人番号" class="title-input-item" :editable="editable">
                              <InputText
                                name="corporate_number"
                                :values="formValues"
                                placeholder=""
                                :disabled="true"
                                validation_label="法人番号"
                                validation_rules="required|max:255"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="会社名" class="title-input-item" :editable="editable">
                              <InputText
                                name="name"
                                :values="formValues"
                                :disabled="true"
                                placeholder=""
                                validation_label="会社名"
                                validation_rules="required|max:512"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="12">
                            <Label label="所在地" class="title-input-item" :editable="editable">
                              <InputText
                                name="address"
                                :values="formValues"
                                :disabled="true"
                                validation_label="所在地"
                                validation_rules="max:120"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="6">
                            <Label label="CCUS事業者ID" class="title-input-item ct" :editable="editable">
                              <InputText
                                name="ccus_id"
                                :values="formValues"
                                :editable="editable == !ccusIdCheck ? true : false"
                                :disabled="ccusIdCheck"
                                validation_label="CCUS事業者ID"
                                validation_rules="ccusid:CCUS事業者ID"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                          <v-col cols="24" sm="12" md="6" v-if="isRental">
                            <Label label="ナンバープレート会社名" class="title-input-item ct" :editable="editable">
                              <InputText
                                name="plate_number_prefix"
                                :values="formValues"
                                :editable="editable == !plateNumberPrefixCheck ? true : false"
                                :disabled="plateNumberPrefixCheck"
                                validation_label="ナンバープレート会社名"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                    <Label label="初期登録者情報" class="title-input-item" :editable="editable">
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メールアドレス" :editable="editable" required>
                              <InputText
                                name="email"
                                :values="formValues.users"
                                :editable="editable == !usersEmailCheck ? true : false"
                                :disabled="usersEmailCheck"
                                placeholder=""
                                validation_label="メールアドレス"
                                validation_rules="required|max:255|email"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="電話番号" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="phone"
                                :values="formValues.users"
                                :editable="editable == !usersPhoneCheck ? true : false"
                                :disabled="usersPhoneCheck"
                                placeholder=""
                                validation_label="電話番号"
                                validation_rules="required|max:16|phone"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="姓" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_sei"
                                :values="formValues.users"
                                :editable="editable == !usersNameSeiCheck ? true : false"
                                :disabled="usersNameSeiCheck"
                                placeholder=""
                                validation_label="姓"
                                validation_rules="required|max:64"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="名" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_mei"
                                :values="formValues.users"
                                :editable="editable == !usersNameMeiCheck ? true : false"
                                :disabled="usersNameMeiCheck"
                                placeholder=""
                                validation_label="名"
                                validation_rules="required|max:64"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="セイ" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="kana_sei"
                                :values="formValues.users"
                                :editable="editable == !usersKanaSeiCheck ? true : false"
                                :disabled="usersKanaSeiCheck"
                                placeholder=""
                                validation_label="セイ"
                                validation_rules="required|max:128|kana"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メイ" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="kana_mei"
                                :values="formValues.users"
                                :editable="editable == !usersKanaMeiCheck ? true : false"
                                :disabled="usersKanaMeiCheck"
                                placeholder=""
                                validation_label="メイ"
                                validation_rules="required|max:128|kana"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </Label>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import { Store } from "@/store/Store.js";
import { COMPANY_RENTAL, COMPANY_SCENE } from "@/constants/COMMON";
import {kuroshiro} from "@/utils/convertKana";
import _ from "lodash";

export default {
  data: () => {
    return {
      formValues: {
        users: {}
      },
      resetValue: {},
      ccusIdCheck: false,
      plateNumberPrefixCheck: false,
      usersEmailCheck: false,
      usersPhoneCheck: false,
      usersNameSeiCheck: false,
      usersNameMeiCheck: false,
      usersKanaSeiCheck: false,
      usersKanaMeiCheck: false,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    dataSelectCompany: Object,
  },
  mounted() {
    this.$watch(
      () => this.isRental,
      (newValue) => {
        const formValues = { ...this.formValues };
        formValues.company_type = newValue ? COMPANY_RENTAL : COMPANY_SCENE;
        this.$emit("formUpdate", formValues);
      },
      { immediate: true, deep: true }
    );
    this.$watch(
      () => [this.dataSelectCompany],
      (data) => {
        if (data[0]) {
          const formValues = { ...this.formValues };
          formValues.corporate_number = data[0].corporateNumber ?? '';
          formValues.id = data[0].corporateNumber ? data[0].id: '';
          formValues.name = data[0].name ?? '';
          formValues.name_kana =  typeof data[0].furigana === 'object' ? '' : data[0].furigana ?? ''
          formValues.address = data[0].prefectureName + data[0].cityName + data[0].streetNumber
          this.formValues = formValues;
          if (data[0].corporateNumber) {
            this.getCompanyInfoByCorporateNumber(data[0].corporateNumber);
          } else {
            let users = {};
            this.ccusIdCheck = false;
            this.plateNumberPrefixCheck = false;
            this.usersEmailCheck = false;
            this.usersPhoneCheck = false;
            this.usersNameSeiCheck = false;
            this.usersNameMeiCheck = false;
            this.usersKanaSeiCheck = false;
            this.usersKanaMeiCheck = false;
            users.email = '';
            users.phone = '';
            users.name_sei = '';
            users.name_mei = '';
            users.kana_sei = '';
            users.kana_mei = '';
            formValues.users = users;
            formValues.company_type = this.isRental ? COMPANY_RENTAL : COMPANY_SCENE;
            formValues.ccus_id = '';
            formValues.plate_number_prefix = '';
          }
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (parent_name) {
        if (!formValues[parent_name]) {
          formValues[parent_name] = {};
        }
        formValues[parent_name][name] = value;
        if (name == 'name_sei' || name == 'name_mei') {
          this.getNameKana(parent_name, name, formValues[parent_name][name]);
        }
      } else {
        formValues[name] = value;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getNameKana(parent_name, name, value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues =  _.cloneDeep(this.formValues);
      if (!formValues[parent_name]) {
        formValues[parent_name] = {};
      }
      if (name == 'name_sei') {
        formValues[parent_name].kana_sei = result;
      }
      if (name == 'name_mei') {
        formValues[parent_name].kana_mei = result;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    async getCompanyInfoByCorporateNumber(id) {
      const formValues = { ...this.formValues };
      let users = {};
      const res = await Store.dispatch('Register/getCompanyInfoByCorporateNumber', {
        corporate_number: id,
      });
      let itemRes = res.data.contents;
      if (!itemRes) {
        this.ccusIdCheck = false;
        this.plateNumberPrefixCheck = false;
        users.email = '';
        users.phone = '';
        users.name_sei = '';
        users.name_mei = '';
        users.kana_sei = '';
        users.kana_mei = '';
        formValues.users = users;
        formValues.ccus_id = '';
        formValues.plate_number_prefix = '';
        formValues.company_code = '';
      } else {
        if (this.isRental) {
          formValues.plate_number_prefix = itemRes.plate_number_prefix ?? '';
        }
        if (itemRes.id) {
          formValues.id = itemRes.id;
        }
        if (itemRes.company_code) {
          formValues.company_code = itemRes.company_code ?? '';
          this.ccusIdCheck = true;
          this.plateNumberPrefixCheck = true;
        } else {
          this.plateNumberPrefixCheck = false;
          this.ccusIdCheck = false;
        }
        if (itemRes.corporate_number) {
          formValues.corporate_number = itemRes.corporate_number;
        }
        if (itemRes.name) {
          formValues.name = itemRes.name;
        }
        if (itemRes.name_kana) {
          formValues.name_kana = itemRes.name_kana;
        }
        formValues.ccus_id = itemRes.ccus_id ?? '';
      }
      if (!itemRes || !itemRes?.users) {
        this.usersEmailCheck = false;
        this.usersPhoneCheck = false;
        this.usersNameSeiCheck = false;
        this.usersNameMeiCheck = false;
        this.usersKanaSeiCheck = false;
        this.usersKanaMeiCheck = false;
      } else {
        if (itemRes.users.email) {
          this.usersEmailCheck = true;
        }
        if (itemRes.users.phone) {
          this.usersPhoneCheck = true;
        }
        if (itemRes.users.name_sei) {
          this.usersNameSeiCheck = true;
        }
        if (itemRes.users.name_mei) {
          this.usersNameMeiCheck = true;
        }
        if (itemRes.users.kana_sei) {
          this.usersKanaSeiCheck = true;
        }
        if (itemRes.users.kana_mei) {
          this.usersKanaMeiCheck = true;
        }
        users.email = itemRes.users.email ?? '';
        users.phone = itemRes.users.phone ?? '';
        users.name_sei = itemRes.users.name_sei ?? '';
        users.name_mei = itemRes.users.name_mei ?? '';
        users.kana_sei = itemRes.users.kana_sei ?? '';
        users.kana_mei = itemRes.users.kana_mei ?? '';
      }
      formValues.company_type = this.isRental ? COMPANY_RENTAL : COMPANY_SCENE;
      formValues.users = users;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    openListCompany(name) {
      this.$emit('openListCompany', name)
    },

    searchListCompany() {
      this.$emit('searchListCompany')
    }
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";
.title-input-item .label{
  color: #000000;
}
.title-input-item.ct .label{
  padding: 2px;
}
</style>
