<template>
  <div>
    <GlobalDrawer width="260px">
      <!-- 共通 -->
      <v-list-item>
        <v-icon class="mr-2" size="33" color="primary">mdi-account-circle-outline</v-icon>
        <span class="cts-user-name color-primary">
          {{ userName }}
        </span>
      </v-list-item>

      <!-- 共通 -->
      <v-list-item>
        <v-btn @click="logout" color="primary">ログアウト</v-btn>
        <v-spacer></v-spacer>
      </v-list-item>
      <v-divider class="my-3"></v-divider>
      <v-container v-if="dataUser?.admin_flg !== ADMIN_USER">
        <div></div>
        <v-list-item @click="openUserForm">
          <v-icon class="mr-2" color="primary">mdi-cog</v-icon>
          <span class="cts-user-name color-primary">
            アカウント編集
          </span>
        </v-list-item>

        <v-list-item @click="openQRShow">
          <v-icon class="mr-2" color="primary">mdi-qrcode</v-icon>
          <span class="cts-user-name color-primary">
            所属会社QRコード表示
          </span>
        </v-list-item>

        <v-list-item @click="openResetPasswordForm">
          <v-icon class="mr-2" color="primary">mdi-lock-outline</v-icon>
          <span class="cts-user-name color-primary">
            パスワード変更
          </span>
        </v-list-item>
      </v-container>
    </GlobalDrawer>
    <Popup :dialog="isShowFieldDocumentForm">
      <DocumentForm
        @cancel="closeSettingForm"
      />
    </Popup>
    <!--  (ユーザー用) 追加/編集ダイアログ -->
    <Popup :dialog="popups.isShowUserForm">
      <User
        :item="userInfo"
        :isNewItem="isNewItem"
        @formUpdate="formUpdate"
        @cancel="closeUserForm"
        :key="isResetForm"
        @reloadUser="reloadUser"
      />
    </Popup>
    <Popup :dialog="showResetPasswordForm" max-width="480">
      <ResetPasswordForm
        @close="closeResetPasswordForm"
        @closeAfterReset="closeResetPasswordSuccess"
        :userId="userId"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowQR">
      <ShowQR
        @close="isShowQR = false"
        title="所属会社QRコード"
        :data="dataCompany"
        text="所属会社コード："
        type="field"
      />
    </Popup>
    <Popup width="480px" :dialog="isShowConfirmDialog">
      <ConfirmCloseDialog
        title="フォームを閉じる確認"
        text1="フォームを閉じますがよろしいですか？
              入力内容は保存されません。"
        text2="このページから移動してもよろしいですか？"
        @close="onClickBtnCancelForm"
        @yes="closeForm"
        warning
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import User from "../../../components/forms/user/components/User.vue";
// storeを使ったDrawer
import GlobalDrawer from "@/components/common/GlobalDrawer";
import { ROLE_USER_RENTAL_COMPANY } from "@/constants/COMMON";
import InhouseDrawerMenu from "./InhouseDrawerMenu.vue";
import SitePortalDrawerMenu from "./SitePortalDrawerMenu.vue";
import SpDrawerMenu from "./SpDrawerMenu.vue";
import DocumentForm from "@/components/forms/document/DocumentSettingForm";
import Popup from "@/components/common/Popup";
import ResetPasswordForm from "./ResetPasswordForm";
import ShowQR from "@/components/qr/ShowQR";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog";
import _ from "lodash";
import {
  USER_INITIAL_ITEM,
  ADMIN_USER,
} from "@/constants/USER"; //絞り込みフォームで使用
/**
 * ポータル共通 初期値
 */
const INITIAL_ITEM = USER_INITIAL_ITEM;
export default {
  data() {
    return {
      isShowConfirmDialog: false,
      isShowFieldDocumentForm : false,
      showResetPasswordForm: false,
      ROLE_USER_RENTAL_COMPANY,
      dataUser: {},
      dataCompany: {},
      userName : '',
      userId: {},
      isShowQR: false,
      userInfo: {},
      popups: {
        isShowUserForm: false,
      },
      isNewItem: false,
      isResetForm: false,
      ADMIN_USER,
    };
  },

  components: {
    GlobalDrawer,
    InhouseDrawerMenu,
    SitePortalDrawerMenu,
    SpDrawerMenu,
    DocumentForm,
    ResetPasswordForm,
    ShowQR,
    Popup,
    User,
    ConfirmCloseDialog,
  },

  async mounted() {
    this.$watch(
      () => Store.getters["Login/getUser"],
      (data) => {
        this.dataUser = {...data};
        // eslint-disable-next-line no-irregular-whitespace
        let userData = JSON.parse(sessionStorage.getItem("USER"));
        this.userName = userData.Login.user.name_sei +'　'+userData.Login.user.name_mei;
        this.userId = data.id;

        this.dataCompany = {...data?.company, id: data.company_id}
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },

  methods: {
    // ログアウト
    async logout() {
      const userTmp = {...this.dataUser};
      await Store.dispatch("Login/logout");
      Store.dispatch("Drawer/close");
      // login画面に遷移
      if (userTmp?.admin_flg === ADMIN_USER) {
        await this.$router.push("/41646d696e697374726174696f6e")
      } else {
        await this.$router.push("/");
      }
    },
    // open user infomation
    async openUserForm() {
      Store.dispatch("Drawer/close");
      const result = await Store.dispatch("Users/getUserInfoById", {id: this.dataUser.id});
      if (!result.hasError) {
        this.userInfo = _.cloneDeep(result?.data?.contents?.entries);
        this.isNewItem = false;
        this.popups.isShowUserForm = true;
      }
    },
    async reloadUser() {
      const result = await Store.dispatch("Users/getUserInfoById", {id: this.dataUser.id});
      if (!result.hasError) {
        this.userInfo = _.cloneDeep(result?.data?.contents?.entries);
      }
    },
    // open qr show
    async openQRShow() {
      Store.dispatch("Drawer/close");
      this.isShowQR = true
    },
    closeSettingForm() {
      this.isShowFieldDocumentForm = false;
    },
    openResetPasswordForm() {
      this.showResetPasswordForm = true;
      Store.dispatch("Drawer/close");
    },
    closeResetPasswordForm() {
      this.isShowConfirmDialog = true;
    },
    closeResetPasswordSuccess() {
      this.showResetPasswordForm = false;
    },
    closeUserForm() {
      this.popups.isShowUserForm = false;
      this.$nextTick(() => {
        this.userInfo = { ...INITIAL_ITEM };
      });
    },
    /**
     * (共通)
     * フォームの変更を受け取る
     */
     formUpdate(params) {
      this.userInfo = { ...params };
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
    },
    closeForm() {
      this.isShowConfirmDialog = false;
      this.showResetPasswordForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-company-url {
  background-color: #f8f8f8;
  border-radius: 4px;
}

a.user-company-url-text {
  color: black;
  text-decoration: none;
}

.cts-user-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
}
.color-primary {
  color: #385DC7;
}
</style>
