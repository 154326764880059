import { render, staticRenderFns } from "./StockTable.vue?vue&type=template&id=0d469342&scoped=true&"
import script from "./StockTable.vue?vue&type=script&lang=js&"
export * from "./StockTable.vue?vue&type=script&lang=js&"
import style0 from "./StockTable.vue?vue&type=style&index=0&id=0d469342&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d469342",
  null
  
)

export default component.exports