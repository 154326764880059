import Api from "../api";

const SITES_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const SITES_BASE_URL = `${SITES_URL}/receive_order`;
const GET_LIST_DELIVERY_REQUEST = `${SITES_BASE_URL}/get_list_delivery_request`;
const GET_LIST_FIELD_MAIN_CONTRACTOR_FOR_RENTAL = `${SITES_BASE_URL}/get_list_field_main_contractor_for_rental`;
const GET_LIST_REPAIR_REQUEST_TO_RENTAL = `${SITES_BASE_URL}/get_list_repair_request_to_rental`;
const GET_LIST_RETURN_REQUEST_TO_RENTAL = `${SITES_BASE_URL}/get_list_return_request_to_rental`;
const DELETE_RETURN_INFORMATIONS_BY_IDS = `${SITES_BASE_URL}/delete_return_infomations_by_ids`;
const GET_LIST_DELIVERY_REQUEST_DETAIL = `${SITES_BASE_URL}/get_list_delivery_request_detail`;
const RESPONSE_MACHINE_REQUETS = `${SITES_BASE_URL}/response_machine_requests`;
const RECEIPT_MACHINE_RETURN = `${SITES_BASE_URL}/receipt_machine_return`;
const COUNT_LIST_DELIVERY_REQUEST_NOT_RESPONSE = `${SITES_BASE_URL}/count_list_delivery_request_not_response`;
const COUNT_LIST_REPAIR_REQUEST_NOT_REPORT = `${SITES_BASE_URL}/count_list_repair_request_not_report`;
const COUNT_LIST_RETURN_REQUEST_TO_RENTAL = `${SITES_BASE_URL}/count_list_return_request_to_rental`;

export const receiveOrders = {
  getListDeliveryRequest: (params) => {
    return Api.get(`${GET_LIST_DELIVERY_REQUEST}`, params);
  },
  getListFieldMainContractorForRental: () => {
    return Api.get(`${GET_LIST_FIELD_MAIN_CONTRACTOR_FOR_RENTAL}`)
  },
  getListRepairRequestToRental: (params) => {
    return Api.get(`${GET_LIST_REPAIR_REQUEST_TO_RENTAL}`, params);
  },
  getListReturnRequestToRental: (params) => {
    return Api.get(`${GET_LIST_RETURN_REQUEST_TO_RENTAL}`, params);
  },
  deleteReturnInformationsByIds: (params) => {
    return Api.post(`${DELETE_RETURN_INFORMATIONS_BY_IDS}`, params);
  },
  getListDeliveryRequestDetail: (params) => {
    return Api.get(`${GET_LIST_DELIVERY_REQUEST_DETAIL}`, params);
  },
  responseMachineRequests: (params) => {
    return Api.post(`${RESPONSE_MACHINE_REQUETS}`, params);
  },
  receiptMachineReturn: (params) => {
    return Api.post(`${RECEIPT_MACHINE_RETURN}`, params);
  },
  countListDeliveryRequestNotResponse: (params) => {
    return Api.get(`${COUNT_LIST_DELIVERY_REQUEST_NOT_RESPONSE}`, params);
  },
  countListRepairRequestNotReport: (params) => {
    return Api.get(`${COUNT_LIST_REPAIR_REQUEST_NOT_REPORT}`, params);
  },
  countListReturnRequestToRental: (params) => {
    return Api.get(`${COUNT_LIST_RETURN_REQUEST_TO_RENTAL}`, params);
  },

}
