import moment from "moment";

export const convertName = (name, length) => {
    let result = "";
    if (name) {
        result = name;
        if (name.length > length) {
            result = name.substring(0, length) + "•••";
        }
    }
    return result;
}

export const dateFormat = (date, format = 'DD-MM-YYYY') => {
    const now = new Date(date);
    return moment(now).format(format);
}
export const monthFormat = (date, format = 'YYYY/MM/DD') => {
    const now = new Date(date);
    return moment(now).format(format);
}
export const convertDateBooking = (start_date, end_date, start_time, end_time) => {
    let resultStartTime = "" ;
    let resultEndTime = "" ;
    if (start_time == 1) {
        resultStartTime = "PM";
    }
    if (start_time == 0) {
        resultStartTime = "AM"
    }
    if (end_time == 1) {
        resultEndTime = "PM";
    }
    if (end_time == 0) {
        resultEndTime = "AM"
    }
    return start_date + " " + resultStartTime + " " + "~" + " " + end_date + " " + resultEndTime;
}

export const replaceDate = (date) => {
    let newDate = date.replaceAll('-', '/');
    return newDate;
}

export const floatFormat = (number) => {
    const rs = number.replaceAll('.0', '');
    return rs;
}
