<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-list>
                    <Label label="工事" :editable="editable" />
                    <Select
                      name="roll"
                      :items="selectRollItems"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                    <v-treeview
                      :items="locations"
                      activatable
                      item-key="path"
                      return-object
                      @update:active="onChangeLoaction"
                      :key="flg"
                    >
                      <template v-slot:label="{ item, active }">
                        <!-- すでにselectされたitemをクリックしても@updateさせない -->
                        <div @click="active ? $event.stopPropagation() : null">
                          {{ item.item_name }}
                        </div>
                      </template>
                    </v-treeview>
                  </v-list>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" sm="9" md="9">
                  <v-row class="justify-center ma-10">
                    <v-col
                      v-for="(item, i) in items"
                      :key="i"
                      class="d-flex"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                    >
                      <v-card elevation="0">
                        <v-img
                          width="200"
                          :src="
                            item.image_data || getUrlImage(item.original_index)
                          "
                          aspect-ratio="1"
                          class="grey lighten-2"
                          @click="onSelectDrawing(i)"
                        ></v-img>
                        <v-list two-line class="text-center">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{
                              item.updated_at
                            }}</v-list-item-subtitle>
                            <v-list-item-title>{{
                              item.image
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { Store } from "@/store/Store.js";
import Select from "@/components/forms/elements/Select.vue";
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import { ENV_CLIENT } from "@/constants/ENV_CLIENT";

// 図面
const DRAWINGS = [];

export default {
  data: () => {
    return {
      //作業場所
      locations: [],
      path: "",
      itemSelected: [],
      // 権限リスト？
      selectRollItems: [],
      //
      searchParams: {},
      //画像一覧
      items: DRAWINGS,
      // 取得した画像の一覧
      sourceImages: [],
      // 選択中の location id
      location_id: null,
      // 工事一覧
      workTypes: [],
      //工事id
      construction_types_id: null,
      flg: false,
    };
  },

  components: {
    Select,
    Label,
    InputText,
    InputDatepicker,
  },

  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },

  async mounted() {
    this.$watch(
      () => Store.getters["Sites/getData"],
      (data, oldData) => {
        if (
          data &&
          data.length &&
          JSON.stringify(data) !== JSON.stringify(oldData)
        ) {
          //現場一覧から該当現場を取得
          const { field_id } = this.CURRENT_SITE;
          this.loadFiledDetail(field_id);
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getSite"],
      (data) => {
        if (data?.field_constructions) {
          const field_constructions = _.cloneDeep(data?.field_constructions);
          this.constructions = _.cloneDeep(field_constructions);
          let _selectedRollItems = [];
          this.constructions?.forEach((item) => {
            if (item.construction_name)
              _selectedRollItems.push({
                id: item.id ? item.id : item.construction_name,
                name: item.construction_name,
                key: item.key,
              });
          });
          this.selectRollItems = _selectedRollItems;
          this.locations = this.getDataLocation(field_constructions);
          if (this.selectRollItems.length > 0 && !this.searchParams.roll) {
            let searchParams = { ...this.searchParams };
            searchParams.roll = this.selectRollItems[0].id;
            searchParams.rollName = this.selectRollItems[0].name;
            searchParams.key = this.selectRollItems[0].key;
            this.searchParams = searchParams;
            if (!this.idSelect) {
              this.onChangeSearchParams({
                name: "roll",
                value: this.selectRollItems[0].id,
              });
              this.$emit(
                "filter-select",
                this.locations,
                this.selectRollItems[0].id
              );
            }
          }
          this.insertPath();
          // this.updateConstructions();
        }
      },
      {
        deep: true,
      }
    );

    // 図面データをwatch
    this.$watch(
      () => Store.getters["SiteDrawings/getData"],
      (newValue, oldValue) => {
        // Storeデータ変更時に itemsを更新
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const items = { ...newValue[0] };
          if (items.images) {
            this.items = items.images.files;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.loadSite();
  },

  computed: {
    /**
     * (共通)
     * 定数をapi/ストアからまとめて取得します
     *
     * this.CONSTANTS.roll
     * こうやって個々の定数を取得します
     */
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    CURRENT_COMPANY() {
      return Store.getters["Login/getCompany"];
    },
    CURRENT_SITE_LOCATION() {
      return Store.getters["Sites/getLocations"];
    },
    ENV_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },
  methods: {
    //現場情報取得
    async loadSite() {
      const apiParams = {
        location_flag: 1, // get list of site that is using for portal dashboard
        company_id: this.CURRENT_SITE.company_id,
        company_branch_id: this.CURRENT_SITE.company_branch_id,
        page_size: 25,
      };
      await Store.dispatch("Sites/get", { params: apiParams });
    },

    //現場情報詳細
    async loadFiledDetail(field_id) {
      let params = { id: field_id}
      this.ENV_OBAYASHI ? params.is_oba = 1 : '';
      await Store.dispatch("Sites/getDetail", {params});
    },

    insertPath() {
      const pathArray = "";
      const req = (arr, locations) => {
        locations.forEach((element, index) => {
          element["path"] = arr != "" ? `${arr}_${index}` : `${index}`;
          element.field_images.forEach((image, i) => {
            image["original_index"] = i;
          });
          if (element.children && element.children.length > 0) {
            req(element["path"], locations[index].children);
          }
        });
      };
      req(pathArray, this.locations);
    },

    // つりー構造を取得
    getDataLocation(construction) {
      let rs = [];
      construction.forEach((e) => {
        if (e.id) {
          if (e.id == this.searchParams["roll"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        } else {
          if (e.construction_name == this.searchParams["roll"]) {
            rs = e.field_item_trees || [];
            return rs;
          }
        }
      });
      return rs;
    },
    updateConstructions() {
      const req = (locations) => {
        locations.forEach((element, index) => {
          for (let i = element.field_images.length - 1; i >= 0; i--) {
            const selectedRollItem = this.selectRollItems.find(
              (item) => item.key == element.field_images[i].key
            );
            if (!selectedRollItem) {
              element.field_images.splice(i, 1);
            } else {
              element.field_images[i].field_construction_name =
                selectedRollItem.name;
            }
          }
          if (element.children && element.children.length > 0) {
            req(locations[index].children);
          }
        });
      };
      if (this.locations.length > 0) {
        req(this.locations);
        this.formUpdate(this.locations);
        // this.onUpdate(this.itemSelected);
      }
    },

    /**
     * クリックで選択した項目の図面表示
     */
    onChangeLoaction(val) {
      if (!val[0]) {
        return;
      }
      this.itemSelected = val;
      this.path = val[0].path;
      let itemsImage = [];
      if (val[0].field_images) {
        val[0].field_images.forEach((item) => {
          if (item.field_construction_id) {
            itemsImage.push(item);
          }
          if (!item.field_construction_id) {
            itemsImage.push(item);
          }
        });
      }
      this.items = itemsImage;
      this.sourceImages = [];
      this.initiateImageSource();
    },
    // 配置計画画面を開く
    onSelectDrawing(index) {
      const item = this.items[index];
      this.createChatRoomFromFieldId({
        id: item.id,
        image: item.image,
        callback: () => {
          const url = `${window.location.origin}/oekaki/?image_id=${item.id}`;
          window.open(url, "_blank");
        },
      });
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      let result = this.selectRollItems.find((item) => item.id == value);
      searchParams[name] = value;
      searchParams["rollName"] = result.name;
      searchParams["key"] = result.key;
      this.searchParams = searchParams;
      this.flg = !this.flg; // reload treeview
      this.locations = this.getDataLocation(this.constructions);
      this.$emit("filter-select", this.locations, value);
      this.insertPath();
      this.onChangeLoaction(this.itemSelected);
      // this.isShowBtnAddImage = false; // disable button add image
      this.items = []; // set items is empty
    },

    /**
     * お絵描き＆チャットルーム作成
     * @param {int}} fieldId
     */
    async createChatRoomFromFieldId({ id, image, callback }) {
      // 現場id
      const { field_id } = Store.state.GlobalHeader.selectedSite;

      // お絵描き作成
      const _result = await Store.dispatch("Oekaki/loadData", {
        field_image_id: id,
      });

      // お絵描きデータがなければ初期化
      if (_result.hasError) {
        await Store.dispatch("Oekaki/saveData", {
          field_image_id: id,
          layout: { contents: null, background: null },
        });
      }

      // チャットルーム作成
      await Store.dispatch("TextChatManage/roomCreateFromFieldImageId", {
        name: image,
        field_image_id: id,
        field_id,
        approval_flag: 1,
      });
      callback();
    },

    /**
     * ここから画像取得
     */
    async initiateImageSource() {
      await this.items.forEach((item) => {
        if (item.image_url) this.createObjectUrlForImage(item);
      });
    },

    getUrlImage(key, isPdf) {
      return isPdf
        ? this.sourceImages.find((item) => item.id == key)?.pdf_url
        : this.sourceImages.find((item) => item.id == key)?.url;
    },

    // 画像はセキュアに取得できるようにauthorizeしてリクエストする
    async createObjectUrlForImage(item) {
      if (item.pdf) {
        this.sourceImages.push({
          id: item.original_index,
          url: await this.fetchImageSource(item.image_url),
          pdf_url: await this.fetchImageSource(item.pdf_url),
        });
      } else {
        this.sourceImages.push({
          id: item.original_index,
          url: await this.fetchImageSource(item.image_url),
        });
      }
    },

    // propsで渡されるformデータをcomponent用にset
    async fetchImageSource(sourceUrl) {
      let image = "";
      if (sourceUrl) {
        const params = getParamsOfUrlReadFile(sourceUrl);
        const response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          image = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
        }
      }
      return image;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/components/forms/style/forms.scss";

.v-input {
  z-index: 1000;
}
</style>
