<template>
  <div class="appLoading">
    <half-circle-spinner
      :animation-duration="1000"
      :size="60"
      :color="'#61d690'"
    />
    <div class="title">D2T</div>
  </div>
</template>
<script>
import { HalfCircleSpinner } from "epic-spinners";
import { AppChatLogin, APP_LOGIN_TYPE } from "@/utils/appChatLogin";

/**
 * Unityアプリからのリクエストは
 * 必ずここを経由してルーティングする
 */
export default {
  components: {
    HalfCircleSpinner,
  },
  mounted() {
    this.$watch(
      () => this.$route.query,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const query = newValue;
          if ("app" in query) {
            // お絵描きアプリ
            if (
              query.app === APP_LOGIN_TYPE.DRAWING &&
              "token" in query &&
              "fieldId" in query
            ) {
              this.isShowAppLoading = true;
              AppChatLogin({
                query,
                callback: (result) => {
                  if (result) {
                    this.$router.push(result.path);
                  }
                },
              });
              return;
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
.appLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
}
.title {
  text-align: center;
  font-size: 18px;
  color: $color_primary_sub;
}
</style>
