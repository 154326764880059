import Vue from "vue";
import Vuex from "vuex";
import {reports} from "@/api/modules/reports";

Vue.use(Vuex);
/**
 * apiを指定
 */
const ENTITY = reports;
export const Reports = {
  namespaced: true,

  state: {
    data: [],
    field: [],
    pagination: {},
    spaces: [],
    companies: [],
    machineBooking: {},
    document: {},
    dataDetail: [],
    isLoading: {},
    selectedTab: 0,
    inspectDeliveryBefore: {},
    companySub: [],
    rentalResultsByMachine: {},
    listBookingInfoBySubLeader: [],
    listMachineHasInspectionDaily: [],
    listMachineHasInspectionYear: [],
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_FIELD(state, payload) {
      state.field = payload;
    },
    SET_SELECTED_TAB(state, payload) {
      state.selectedTab = payload;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_IS_COMPANY_SUB(state, payload) {
      state.companySub = payload;
    },
  },

  actions: {
    resetData({ commit }) {
      commit("SET_DATA", []);
      commit("SET_PAGINATION", {});
    },
    async get({ commit }, payload, isLoading = true) {
      if (isLoading) {
        commit("SET_IS_LOADING", true);
      }
      const response = await ENTITY.get(payload);
      if (isLoading) {
        commit("SET_IS_LOADING", false);
      }
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);

      return response;
    },

    async getBookingInfoBySubContractor({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getBookingInfoBySubContractor(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;

      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);

      return [];
    },

    async companyGetBookingInfoBySubContractor({ commit }, payload) {
      const response = await ENTITY.companyGetBookingInfoBySubContractor(payload);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_IS_COMPANY_SUB", entries);
      return response;
    },
    async getListMachineHasInspectionDaily({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachineHasInspectionDaily(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getListMachineHasInspectionMonthly({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachineHasInspectionMonthly(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getInventoryResultInFields({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getInventoryResultInFields(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    setSelected({commit} , idTab) {
      commit("SET_SELECTED_TAB", idTab)
    },
    async exportInventoryResultInFields({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportInventoryResultInFields(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async exportBookingInfoByMachine({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportBookingInfoByMachine(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async exportBookingInfoBySubLeader({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportBookingInfoBySubLeader(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async exportBookingInfoBySubcontractor({commit}, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportBookingInfoBySubcontractor(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },
    async getListMachineHasInspectionYear({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachineHasInspectionYear(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getBookingInfoBySubLeader({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getBookingInfoBySubLeader(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async getRentalResultsByMachine({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getRentalResultsByMachine(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },
    async downloadInspectionYearsByMachineIds({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.downloadInspectionYearsByMachineIds(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    async getListMachineHasInspectionBeforeDelivery({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.getListMachineHasInspectionBeforeDelivery(payload);
      commit("SET_IS_LOADING", false);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_DATA", entries);
      return response;
    },

    async exportReportInspectionBeforeDelivery({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await ENTITY.exportReportInspectionBeforeDelivery(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    restoreTabSelect({ commit }){
      commit("SET_SELECTED_TAB", 0)
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getSelectedTab: (state) => {
      return state.selectedTab;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getListMachineHasInspectionDaily: (state) => {
      return state.listMachineHasInspectionDaily;
    },
    getCompanySub: (state) => {
      return state.companySub;
    },
    getListMachineHasInspectionYear: (state) => {
      return state.listMachineHasInspectionYear;
    },
    getBookingInfoBySubLeader: (state) => {
      return state.listBookingInfoBySubLeader;
    },
    getRentalResultsByMachine: (state) => {
      return state.rentalResultsByMachine;
    },
    downloadInspectionYearsByMachineIds: (state) => {
      return state.downloadInspectionYearsByMachineIds;
    },

    getListMachineHasInspectionBeforeDelivery: (state) => {
      return state.getListMachineHasInspectionBeforeDelivery;
    },

  },
};
