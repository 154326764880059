<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          ユーザー情報
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="
            editable
          "
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onClickBtnClose"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnCloseIcon">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <UserForm
          :editable="editable"
          :detailData="item"
          :mainHeight="params.mainHeight"
          :actions="actions"
          :active_flg="item.active_flg"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowConfirm">
        <Confirm
          title="利用登録"
          text1="入力した内容でアカウントを作成します。"
          text2="よろしければ「登録」ボタンを、修正する場合は「戻る」ボタンを選択してください。"
          @close="onClickBtnCancelShowConfirm"
          @yes="saveData"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>
 
 <script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Confirm from "../popup/Confirm.vue";
import { Store } from "@/store/Store.js";
import UserForm from "./UserForm.vue";
import {USER_ACTIVE} from "@/constants/USER";
import _ from "lodash";

const FORMS = {
  BasicInfo: {
    id: 1,
    title: "基本情報",
  },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isShowConfirm: false,
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      isShowItemForm: false,
      dataRegister: null,
      detailData: {},
      USER_ACTIVE,
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    Confirm,
    UserForm,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    company: Object,
    actions: String,
  },
  computed: {
    getDataDetail() {
      return Store.getters[`Users/getUser`];
    },
  },
  async mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.detailData = { ...data };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    formUpdate(params) {
      this.dataRegister = params;
      this.$emit("formUpdate", params);
    },

    onEditable() {
      this.editable = true;
    },

    onStopEdit() {
      this.isShowConfirmDialog = true;
    },

    onSubmit() {
      this.saveData();
    },

    async saveData() {
      let res = {};
      res = await Store.dispatch("Companies/updateUserInfo", this.detailData);
      if (!res.hasError && res.data.status_code === 200 && !this.isNewItem) {
        let userData = JSON.parse(sessionStorage.getItem("USER"));
        userData.Login.user.name_mei = this.detailData.name_mei;
        userData.Login.user.name_sei = this.detailData.name_sei;
        sessionStorage.setItem("USER", JSON.stringify(userData));
        Store.dispatch("Login/updateUser", userData.Login.user);
        this.$emit("getListUser");
        this.$emit("reloadUser");
        this.editable = false;
        Store.dispatch("Toast/show", {
          status: 200,
          message: res?.data?.message,
        });
      } else {
        this.onClickBtnCancelShowConfirm();
      }
    },

    updateValidate(params) {
      const { valid, errors } = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && this.isClickCloseBtn) {
        this.item = _.cloneDeep(this.getDataDetail);
        this.isShowConfirmDialog = false;
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    onClickBtnClose() {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
    },
    onClickBtnCloseIcon() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = false;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelShowConfirm() {
      this.isShowConfirm = false;
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },
  },
};
</script>
 <style lang="scss" scoped>
.title-popup {
  color: #274fc2;
}

.popup-company {
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
