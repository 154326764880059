import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_LIST_FIELD_INVENTORY = `${SITES_BASE_URL}/fields/get_list_field_inventory`;
const GET_LIST_FIELD_COMPANY_BY_ROLE = `${SITES_BASE_URL}/fields/get_list_field_company_by_role`;
const GET_LIST_SPACE_BY_FIELD_ID = `${SITES_BASE_URL}/fields/get_list_space_by_field_id`;
const GET_LIST_GATE_BY_FIELD_ID= `${SITES_BASE_URL}/fields/get_list_gate_by_field_id`;
const GET_LIST_DELIVERY_MACHINE_ON_FIELD= `${SITES_BASE_URL}/fields/get_list_delivery_machine_on_field`;

export const machine = {
    getListFieldInventory: async (params) => {
        return Api.get(GET_LIST_FIELD_INVENTORY, {params});
    },
    getListFieldCompanyByRole: async (params) => {
        return Api.get(GET_LIST_FIELD_COMPANY_BY_ROLE, {params});
    },
    getListSpaceByFieldId: (params) => {
        return Api.get(GET_LIST_SPACE_BY_FIELD_ID, { params: params });
    },
    getListGateByFieldId: (params) => {
        return Api.get(GET_LIST_GATE_BY_FIELD_ID, { params: params });
    },
    getListDeliveryMachineOnField: (params) => {
        return Api.get(GET_LIST_DELIVERY_MACHINE_ON_FIELD, { params: params });
    }
};