<template>
  <v-dialog v-model="isOpen" persistent max-width="90%" min-width="1000px">
    <Loading v-if="isLoading" />
    <v-card class="inspection-tenken-list-container">
      <div class="header">
        <v-toolbar-title color="primary" class="primary--text header-title">
          {{ TITLE }}
        </v-toolbar-title>
        <div class="header-button-container">
          <v-btn
            class="btn-delete"
            color="darken-1"
            icon
            @click="dialogDel = true"
            :disabled="isDisable"
          >
            <span class="btn-title"><v-icon>mdi-delete-outline</v-icon></span>
            <Popup width="480px" :dialog="dialogDel">
              <ConfirmCloseDialog
                v-model="dialogDel"
                :title="WRN_0001.TITLE"
                :text1="WRN_0001.TEXT1"
                :text2="WRN_0001.TEXT2"
                textSubmit="解除"
                :warning="true"
                @close="dialogDel = false"
                @yes="deleteInspection"
              />
            </Popup>
          </v-btn>
          <div class="header-action-button-container">
            <v-btn
              depressed
              color="darken-1"
              @click="dialogUnapproved = true"
              class="btn-unapproved"
              style="margin-right: 30px"
              :disabled="isDisable"
            >
              <span class="btn-title">{{ MESSAGE_UNAPPROVE_MODAL.title }}</span>
              <Popup width="480px" :dialog="dialogUnapproved">
                <ExtendDialog
                  v-model="dialogUnapproved"
                  :title="MESSAGE_UNAPPROVE_MODAL.title"
                  :text1="MESSAGE_UNAPPROVE_MODAL.message"
                  :acceptBtn="MESSAGE_UNAPPROVE_MODAL.title"
                  :warning="true"
                  @close="dialogUnapproved = false"
                  @yes="updateInspectionStatus(UNAPPROVED_ACTION)"
                />
              </Popup>
            </v-btn>

            <v-btn
              depressed
              color="primary"
              @click="dialogApproval = true"
              class="btn-approval"
              style="margin-right: 30px"
              :disabled="isDisable"
            >
              <span class="btn-title">{{ MESSAGE_APPROVE_MODAL.title }}</span>
              <Popup width="480px" :dialog="dialogApproval">
                <ExtendDialog
                  v-model="dialogApproval"
                  :title="MESSAGE_APPROVE_MODAL.title"
                  :text1="MESSAGE_APPROVE_MODAL.message"
                  :acceptBtn="MESSAGE_APPROVE_MODAL.title"
                  @close="dialogApproval = false"
                  @yes="updateInspectionStatus(APPROVED_ACTION)"
                />
              </Popup>
            </v-btn>

            <v-btn icon @click="close">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="tab-container">
        <div class="legends-container">
          <div class="legend">
            <span>レ</span>
            <span>良好</span>
          </div>
          <div class="legend">
            <span>◯</span>
            <span>修理して使用する</span>
          </div>
          <div class="legend">
            <span>×</span>
            <span>故障のため使用しない</span>
          </div>
        </div>
      </div>
      <div class="table-container" style="overflow: hidden!important;">
        <v-data-table :noDataText="NO_DATA" :headers="FORM_LIST_TENKEN_LABEL" :items="itemsTenken" height="480px" disable-pagination fixed-header hide-default-footer class="data-table"
          disable-sort
          v-model="selected"
          item-key="inspection_result_id"
          show-select
          @click:row="openFormDetailModel"
        >
          <template v-slot:[`item.approved_status`]="{ item }">
            <v-chip :color="getColor(item.approved_status)" :text-color="item.approved_status == APPROVED_STATUS.APPROVED.id ? '#ffffff' : '#000000'">
              {{ getApprovedStatus(item.approved_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.act`]="{ item }">
            <span v-if="item.inspector_id != item.created_field_user_id">
              <div class="act-circle">
                <img width="25" height="25" class="block-frame" v-bind:src="ICON_REPORTER" alt=""/>
              </div>
            </span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.user_name_sei }} {{ item.user_name_mei }}</span>
          </template>
          <template v-slot:[`item.inspection_at`]="{ item }">
            {{ formatDateTime(item.inspection_at) }}
          </template>
          <template v-slot:[`item.inspection_status`]="{ item }">
            <span style="margin-right: 3px;">{{ LIST_REPAIR[item.result] }}</span>
            <span v-if="item.repair_status" style="color: #E5951D;">{{ LIST_REPAIR_STATUS[item.repair_status] }}</span>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <InspectionDetailTenken :idSend="idSend" :titelModal="TITLE" :inspectorId="inspectorId" :typeModal="'inspection'"
      :idFieldMachine="idFieldMachine" :showModel="showModel" :nameCompanyRental="NameCompanyRental" :approvedStatus="approvedStatus"
      @close-formDetail-modal="closeFormDetailModel" @reload-data="reloadData" />
  </v-dialog>
</template>
<script>
import { NO_DATA } from "@/constants/COMMON";
import { FORM_LIST_TENKEN_LABEL, APPROVED_STATUS, MESSAGE_APPROVE_MODAL, MESSAGE_UNAPPROVE_MODAL, APPROVED_ACTION, UNAPPROVED_ACTION, LIST_REPAIR, LIST_REPAIR_STATUS } from "@/constants/INSPECTION";
import Popup from "@/components/common/Popup.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import { Store } from "@/store/Store.js";
import { WRN_0001, WRN_0002 } from "@/constants/MESSAGE";
import moment from "moment";
import ExtendDialog from "../../../components/dialog/ExtendDialog.vue";
import InspectionDetailTenken from "../components/InspectionDetailTenken.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Loading from "@/components/loading/Loading";

export default {
  components: {
    Popup,
    ConfirmDialog,
    ExtendDialog,
    InspectionDetailTenken,
    ConfirmCloseDialog,
    Loading
  },
  data: () => ({
    NO_DATA,
    FORM_LIST_TENKEN_LABEL,
    MESSAGE_APPROVE_MODAL: MESSAGE_APPROVE_MODAL,
    MESSAGE_UNAPPROVE_MODAL: MESSAGE_UNAPPROVE_MODAL,
    APPROVED_STATUS,
    LIST_REPAIR,
    LIST_REPAIR_STATUS,
    APPROVED_ACTION,
    UNAPPROVED_ACTION,
    ICON_REPORTER: "/images/icon_reporter.png",
    TITLE: null,
    isOpen: false,
    dialogDel: false,
    dialogUnapproved: false,
    dialogApproval: false,
    showModel: false,
    ids: [],
    selected: [],
    itemsTenken: [],
    isDisable: true,
    idSend: null,
    NameCompanyRental: null,
    params: {},
    idFieldMachine: null,
    showDialog: false,
    inspectorId: null,
    approvedStatus: null,
    WRN_0001: WRN_0001,
    WRN_0002: WRN_0002,
    isLoading: false,
  }),
  computed: {
    APPROVED_STATUS_SELECT() {
      return Object.keys(APPROVED_STATUS).map((key) => {
        return APPROVED_STATUS[key];
      });
    },
  },
  mounted() {
    this.$watch(
      () => Store.getters[`Inspection/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  watch: {
    selected(listSelect) {
      this.ids = [];
      listSelect.forEach((item) => {
        this.ids.push(item.inspection_result_id);
      });
      this.isDisable = listSelect.length == 0;
    },
    isOpen() {
      if (!this.isOpen) {
        this.resetCheckbox();
      }
    },
  },
  methods: {
    getColor(status) {
      if (status === APPROVED_STATUS.APPROVED.id) return "#274FC2";
      else if (status === APPROVED_STATUS.UNAPPROVED.id) return "#E5951D";
    },
    getApprovedStatus(status) {
      return this.APPROVED_STATUS_SELECT.find(i => i.id === status).name;
    },
    resetCheckbox() {
      this.ids = [];
      this.selected = [];
      this.isDisable = true;
    },
    async getItems() {
      const result = await Store.dispatch(`Inspection/getInspectionResultByIds`, this.params);
      if (!result.hasError) {
        this.isOpen = true;
        this.itemsTenken = result.contents.entries;
      } else {
        this.close();
        Store.dispatch(
          "Error/show",
          { status: "500", message: "点検が存在しません。" },
          { root: true }
        );

      }
    },
    async updateInspectionStatus(approve_action) {
      let MSG = "";
      if (approve_action === APPROVED_ACTION) {
        MSG = "承認しました";
      } else if (approve_action === UNAPPROVED_ACTION) {
        MSG = "承認解除しました";
      }
      const result = await Store.dispatch(`Inspection/updateInspectionApprovedStatus`, { ids: this.ids, approve_action });
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: MSG,
        });
        this.getItems();
      }
      this.resetCheckbox();
      if (approve_action === APPROVED_ACTION) {
        this.dialogApproval = false;
      } else if (approve_action === UNAPPROVED_ACTION) {
        this.dialogUnapproved = false;
      }
    },
    async deleteInspection() {
      const result = await Store.dispatch(`Inspection/deleteInspectionResults`, { ids: this.ids });
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.getItems();
      }
      this.resetCheckbox();
      this.dialogDel = false;
    },
    formatDateTime: function (date) {
      let dataValid = moment(date);
      return dataValid.isValid() ? moment(new Date(date)).format("YYYY/MM/DD HH:mm:ss") : '';
    },
    setName: function () {
      this.dialogSubmit = true;
    },
    open() {
      this.isOpen = true;
    },
    async openModalFormListTenken(data) {
      this.itemsTenken = [];
      this.NameCompanyRental = data?.company_rental_name;
      this.idFieldMachine = data?.field_machine_id;
      this.params = { ids: data.inspection_result_ids };
      await this.getItems();
      this.TITLE =
        data?.number_plate +
        " / " +
        data?.floor_height +
        "m " +
        data?.lifting_method +
        " " +
        data?.undercarriage +
        " " +
        data?.working_floor;
    },
    close() {
      this.isOpen = false;
      this.$emit("reload-data");
    },
    openFormDetailModel(item) {
      this.idSend = item.inspection_result_id;
      this.inspectorId = item.inspector_id;
      this.approvedStatus = item.approved_status;
      this.showModel = true;
    },
    closeFormDetailModel(value) {
      this.idSend = null;
      this.approvedStatus = null;
      this.showModel = value;
    },
    reloadData() {
      this.getItems();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";

.inspection-tenken-list-container {
  min-height: 80vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  >.header {
    display: flex;
    padding: 8px 4px 22px 8px;
    justify-content: space-between;

    >.header-title {
      font-size: 20px !important;
      font-weight: 600;
    }

    >.header-button-container {
      display: flex;
      align-items: center;
      min-width: 357px;

      > :not(:last-child) {
        margin-right: 25px;
      }

      >.header-action-button-container {
        > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  >.tab-container {
    display: flex;

    >.legends-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      padding-left: 30px;

      > :not(:last-child) {
        margin-right: 15px;
      }

      >.legend {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;

        > :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  >.table-container {
    padding: 22px 20px 36px;
    max-height: 500px;
    overflow-y: auto;
    .data-table {
      ::v-deep table {
        th {
          span {
            font-weight: 500;
            line-height: 24px;
            color: rgba(0,0,0,.6);
            font-weight: 900 !important;
          }
        }
      }
    }
  }

  .data-table {
    ::v-deep {
      tbody tr {
        cursor: pointer;
      }
    }
  }
}

.act-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  $circle: 30px;
  height: $circle;
  width: $circle;
  background-color: $color_table_highlight;
  border-radius: $circle;

  >.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $color_text_white;
  }
}

.color-status {
  margin-left: 5px;
  font-size: 16px;

  color: $color_register !important;
}

.btn-title {
  width: 100%;
}

.btn-approval {
  width: 100px !important;
}

.btn-unapproved {
  width: 100px !important;
  background-color: #ff7b52 !important;
  color: #ffffff !important;
}

.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;

      .text {
        padding-left: 30px;
        font-size: 16px !important;
        color: #000000;
      }
    }
  }

  .btn {
    margin-right: 12px;
  }

  .title {
    font-size: 16px !important;
    padding-top: 37px;
    padding-left: 28px;
    color: rgba(39, 79, 194, 1);
  }

  .textAr {
    .lb-texAr {
      font-size: 12px !important;
    }
  }
}
</style>
