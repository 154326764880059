<template>
  <div>
    <Loading v-if="isLoading" />
    <v-data-table
      class="table-data"
      :headers="TABLE_LABELS"
      :items="LIST_DATA_INSPECTION"
      :items-per-page="LIST_DATA_INSPECTION.length"
      :height="tableHeight - 46"
      fixed-header
      hide-default-footer
      disable-sort
      v-model="selected"
      item-key="field_machine_id"
      show-select
      @click:row="showListTenken"
      :noDataText="NO_DATA"
      @update:page="$vuetify.goTo(0)"
      :key=page
    >
      <template v-slot:[`item.model`]="{ item }">
        <span>{{ item.floor_height }}m </span>
        <span>{{ item.lifting_method }} </span>
        <span>{{ item.undercarriage }} </span>
        <span>{{ item.working_floor }}</span>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <span>{{ item.total_tenken_not_approved }}</span>
        <span>({{ item.total_tenken }})</span>
      </template>
      <template v-slot:[`item.inspection_at`]="{ item }">
        {{ FormatDateTime(item.inspection_at) }}
      </template>

      <template v-slot:top class="top-report">
        <v-btn
          depressed
          color="primary"
          @click="dialogApproval = true"
          class="btn-approval ml-5"
          style="margin-right: 30px;"
          :disabled="isDisable"
        >
          <span class="btn-title">{{ MESSAGE_APPROVE_MODAL.title }}</span>
          <Popup width="480px" :dialog="dialogApproval">
            <ExtendDialog
                v-model="dialogApproval"
              :title="MESSAGE_APPROVE_MODAL.title"
              :text1="MESSAGE_APPROVE_MODAL.message"
              :acceptBtn="MESSAGE_APPROVE_MODAL.title"
              @close="dialogApproval = false"
              @yes="updateInspectionStatus(APPROVED_ACTION)"
            />
          </Popup>
        </v-btn>
        <v-btn
          depressed
          color="darken-1"
          @click="dialogUnapproved = true"
          class="btn-unapproved"
          style="margin-right: 30px"
          :disabled="isDisable"
        >
          <span class="btn-title">{{ MESSAGE_UNAPPROVE_MODAL.title }}</span>
          <Popup width="480px" :dialog="dialogUnapproved">
            <ExtendDialog
              v-model="dialogUnapproved"
              :title="MESSAGE_UNAPPROVE_MODAL.title"
              :text1="MESSAGE_UNAPPROVE_MODAL.message"
              :acceptBtn="MESSAGE_UNAPPROVE_MODAL.title"
              :warning="true"
              @close="dialogUnapproved = false"
              @yes="updateInspectionStatus(UNAPPROVED_ACTION)"
            />
          </Popup>
        </v-btn>

        <v-btn
          class="btn-delete"
          color="darken-1"
          icon
          @click="dialogDel = true"
          :disabled="isDisable"
        >
          <span class="btn-title"><v-icon>mdi-delete-outline</v-icon></span>
          <Popup width="480px" :dialog="dialogDel">
            <ConfirmCloseDialog
              v-model="dialogDel"
              :title="WRN_0001.TITLE"
              :text1="WRN_0001.TEXT1"
              :text2="WRN_0001.TEXT2"
              textSubmit="解除"
              :warning="true"
              @close="dialogDel = false"
              @yes="deleteInspection"
            />
          </Popup>
        </v-btn>

        <InspectionListTenken @reload-data="reloadData" ref="formListTenken"/>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { TABLE_LABELS, INSPECTION_TAB_SELECT, MESSAGE_APPROVE_MODAL, MESSAGE_UNAPPROVE_MODAL, APPROVED_ACTION, UNAPPROVED_ACTION } from "@/constants/INSPECTION";
import moment from "moment";
import { NO_DATA } from "@/constants/COMMON";
import { WRN_0001 } from "@/constants/MESSAGE";
import { Store } from "@/store/Store.js";
import Popup from "@/components/common/Popup.vue";
import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
import InspectionListTenken from "../components/InspectionListTenken.vue";
import Loading from "@/components/loading/Loading";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog";

export default {
  components: {
    ConfirmCloseDialog,
    ExtendDialog,
    Popup,
    InspectionListTenken,
    Loading
  },
  data() {
    return {
      NO_DATA,
      WRN_0001,
      MESSAGE_APPROVE_MODAL: MESSAGE_APPROVE_MODAL,
      MESSAGE_UNAPPROVE_MODAL: MESSAGE_UNAPPROVE_MODAL,
      TABLE_LABELS,
      INSPECTION_TAB_SELECT,
      APPROVED_ACTION,
      UNAPPROVED_ACTION,
      isLoading: false,
      fieldId: null,
      selected: [],
      ids: [],
      isDisable: true,
      dialogDel: false,
      dialogUnapproved: false,
      dialogApproval: false,
      editedInspection: {}
    };
  },
  props: {
    LIST_DATA_INSPECTION: {
      type: Array,
    },
    tableHeight: {
      type: Number,
    },
    selectedTab: {
      type: Number,
    },
    getItems: {
      type: Function,
    },
    page: {
      type: Number
    }
  },
  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
  },
  watch: {
    selected(listSelect) {
      this.ids = [];
      listSelect.forEach((item) => {
        this.ids.push(item.field_machine_id);
      });
      this.isDisable = listSelect.length == 0;
    },
    LIST_DATA_INSPECTION() {
      this.selected = []
    }
  },
  computed: {
    TAB_SELECT() {
      return Object.keys(INSPECTION_TAB_SELECT).map((key) => {
          return INSPECTION_TAB_SELECT[key];
      });
    },
  },
  mounted() {
    this.$watch(
      () => this.selectedTab,
      () => {
        this.resetCheckbox();
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Inspection/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    FormatDateTime: function (date) {
      let dataValid = moment(date);
      return dataValid.isValid() ? moment(new Date(date)).format("YYYY/MM/DD HH:mm") : '';
    },
    resetCheckbox() {
      this.ids = [];
      this.selected = [];
      this.isDisable = true;
    },
    getIds() {
      const itemsSelect = this.LIST_DATA_INSPECTION.filter(i => this.ids.includes(i.field_machine_id));
      return [...new Set(itemsSelect.reduce((res, {inspection_result_ids}) => res.concat(inspection_result_ids), []))];
    },
    async updateInspectionStatus(approve_action) {
      let MSG = "";
      if (approve_action === APPROVED_ACTION) {
        MSG = "承認しました";
      } else if (approve_action === UNAPPROVED_ACTION) {
        MSG = "承認解除しました";
      }
      const result = await Store.dispatch(`Inspection/updateInspectionApprovedStatus`, { ids: this.getIds(), approve_action });
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: MSG,
        });
        this.getItems();
      }
      this.resetCheckbox();
      if (approve_action === APPROVED_ACTION) {
        this.dialogApproval = false;
      } else if (approve_action === UNAPPROVED_ACTION) {
        this.dialogUnapproved = false;
      }
    },
    async deleteInspection() {
      const result = await Store.dispatch(`Inspection/deleteInspectionResults`, { ids: this.getIds() });
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
        this.getItems();
      }
      this.resetCheckbox();
      this.dialogDel = false;
    },
    showListTenken(item) {
      this.editedInspection = item;
      this.$refs.formListTenken.openModalFormListTenken(this.editedInspection);
    },
    reloadData() {
      this.selected = [];
      this.getItems();
    }
  },
};
</script>
<style lang="scss" scoped>
.table-data {
  box-sizing: border-box !important;
  position: relative;
  padding-top: 10px;

  ::v-deep {
    tbody tr {
      cursor: pointer;
    }
  }

  .btn-title {
    width: 100%;
  }

  .btn-approval {
    width: 100px !important;
  }

  .btn-unapproved {
    width: 100px !important;
    background-color: #ff7b52 !important;
    color: #ffffff !important;
  }

  .btn-unapproved::v-deep.v-btn--disabled {
    color: rgba(0,0,0,.26)!important;
  }
}
</style>