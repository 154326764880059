<template>
  <div style="background: white">
    <Loading v-if="isLoading"/>
    <v-dialog
      v-model="dialog"
      persistent
      width="85%"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex">
          <v-btn
            color="primary"
            depressed
            class="mr-6 mb-3 pl-8 pr-8"
            v-bind="attrs"
            v-on="on"
            @click="getItems()"
          >
            班編集
          </v-btn>
        </div>
      </template>
      <v-card class="dialog" height="80vh">
        <v-card-title class="ml-0 pt-1 mr-0 style-btn-modal-edit-team pr-2" style="padding-left: 5px !important;">
          <span class="title">{{ companyName }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="btnEdit"
            depressed
            class="pt-0 pb-0 mt-1 btn-edit-team"
            color="primary"
            dark
            @click="handlerClickEdit()"
          >
            編集
          </v-btn>
          <v-btn
            v-if="btnCancel"
            depressed
            color="primary"
            dark
            @click="handlerClickClose()"
            outlined
            class="mr-5 mt-1 btn-edit-team"
            data-testid="btn-back"
          >
            やめる
          </v-btn>
          <v-btn
            v-if="btnSave"
            depressed
            color="primary"
            @click="handlerSaveEditTeam()"
            class="mt-1 btn-edit-team"
          >
            保存
          </v-btn>
          <v-icon v-if="btnEdit" @click="close" class="ml-6 mt-1">mdi-close</v-icon>
          <v-icon v-else @click="handlerClickCloseX" class="ml-6 mt-1">mdi-close</v-icon>
        </v-card-title>
        <v-tabs class="mb-5 border-bottom pl-6">
          <v-tab grow
          > 作業班
          </v-tab>
        </v-tabs>
        <v-card-text class="form-leader-member" ref="formLeaderMember">
          <v-form autocomplete="off">
            <div class="wrap-leader-member-team">
              <v-row v-for="(item, i) in leaderTeam" :key="item.id">
                <v-col cols="4">
                  <Label label="職長" class="title-input-item pl-7">
                    <InputText
                      name="name"
                      :values="item"
                      disabled
                    />
                  </Label>
                </v-col>
                <template>
                </template>
                <v-col cols="8" :class="borderListUser+' border-list-user-'+item.userId" class="pr-10">
                  <Label label="作業員" class="title-input-item">
                    <div class="pl-3 hidden-when-show-edit">
                      <span class="primary mr-2 mb-4 list-user-team" v-for="(member, index) in memberOfLead[item.userId]"
                        :key="index">
                        {{ convertFullName(member) }}
                      </span>
                    </div>
                  </Label>
                  <v-icon class="field-user-mdi-menu-down-css included" :class="'field-user-'+item.userId" @click="openCheckBox(item.userId)"> mdi-menu-down
                  </v-icon>
                  <EditTeam
                    v-click-outside="{
                      handler: onClickOutside,
                      include
                    }"
                    :class="'edit-team-fixed'+item.userId"
                    class="edit-team-fixed"
                    :memberOfLead=arraySelect
                    :currentLeader=currentLeader
                    :checkBox=checked[currentLeader]
                    @checkSelected="checkSelected"
                  />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
      <Popup width="480px" :dialog="isShowCloseConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelFormX"
          @yes="closeFormX"
          warning
        />
      </Popup>
    </template>
  </div>
</template>
<script>
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import EditTeam from "./EditTeam"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label";
import {Store} from "@/store/Store"; //絞り込みフォームで使用
import {MESSAGES} from "@/constants/FIELD_USERS";
import {ROLE_SUPERVISOR} from "@/constants/COMMON"; //絞り込みフォームで使用
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";
import Loading from "@/components/loading/Loading";
const GET_ALL = -1;

const STORE = "FieldUsers";
export default {
  components: {
    InputText,
    EditTeam,
    Label,
    ConfirmCloseDialog,
    Popup,
    Loading,
  },
  props: {
    fieldId: {
      type: Number
    }
  },
  data: () => ({
    items: [],
    dialog: false,
    userInTeam: [],
    checked: {},
    userNotChecked: [],
    memberOfLead: {},
    memberIdsOfLead: {},
    isShowCheckBox: false,
    currentLeader: 0,
    arraySelect: [],
    btnEdit: true,
    btnCancel: false,
    btnSave: false,
    allowSave: false,
    editable: false,
    borderListUser: "",
    allUser: [],
    leaderTeamIds: [],
    leaderTeam: [],
    MESSAGES,
    ROLE_SUPERVISOR,
    companyName: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.company?.name,
    isShowConfirmDialog: false,
    isShowCloseConfirmDialog: false,
    topPosition: 0,
    isLoading: false,
    userIdCheckbox: null
  }),
  async mounted() {
    this.$watch(
      () => [
        Store.getters[`${STORE}/getAllFieldUserInfoData`],
      ],
      (data) => {
        let _items = [...data[0]];
        this.allUser = _items.map((item) => ({
          ...item
        }));
        this.items = _items.map((item) => ({
          ...item
        }));

        const listAllUsers = this.allUser.map((item) => {
          return {
            id: item.id,
            userId: item.user_id,
            name: this.convertFullName(item),
            role: item.role,
          }
        });
        this.leaderTeam = listAllUsers.filter((item) => item.role === 2);
        this.leaderTeamIds = this.leaderTeam.map((item) => {
          return item.userId;
        });
      },
      {
        immediate: true,
        deep: true,
      },
    );
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  created() {
    this.getItems()
    this.init();
  },
  watch: {
    allUser() {
      this.init();
    },
  },
  updated() {
  },
  computed: {
    apiParams() {
      return {
        field_id: this.fieldId,
        page_count: GET_ALL,
      };
    },
  },
  methods: {
    onClickOutside() {
      if(this.userIdCheckbox) {
        this.arraySelect = [...this.memberOfLead[this.userIdCheckbox], ...this.userNotChecked];
        this.isShowCheckBox = true;
        if (this.currentLeader === this.userIdCheckbox) {
          this.isShowCheckBox = !this.isShowCheckBox;
          this.currentLeader = 0;
        } else {
          this.currentLeader = this.userIdCheckbox;
        }
        let elems = document.querySelector(".border-list-user-"+this.userIdCheckbox+".show-dialog-edit");
        if(elems) {
          elems.classList.remove("show-dialog-edit");
        }
        this.userIdCheckbox = null
      }
    },
    include() {
      return [document.querySelector('.field-user-'+this.userIdCheckbox), document.querySelector(".edit-team-fixed"+this.userIdCheckbox)]
    },
    convertFullName(item) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${item.name_sei}　${item.name_mei}`;
    },
    async getItems(isShowLoading = true) {
      await Store.dispatch(`${STORE}/getListFieldUserInfoAll`, {params: this.apiParams}, isShowLoading);
    },
    handlerClickEdit() {
      this.btnEdit = false;
      this.btnSave = true;
      this.btnCancel = true;
      this.editable = true;
      this.borderListUser = "border-filed-user-list-user"
    },
    handlerClickClose() {
      this.isShowConfirmDialog = true;
    },
    handlerClickCloseX() {
      this.isShowCloseConfirmDialog = true;
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
    },
    closeForm() {
      this.isShowCheckBox = false;
      this.btnEdit = true;
      this.btnSave = false;
      this.btnCancel = false;
      this.editable = false;
      this.borderListUser = ""
      this.currentLeader = 0
      this.allUser = this.items.map((item) => ({
        ...item
      }));
      this.isShowConfirmDialog = false;
    },
    onClickBtnCancelFormX() {
      this.isShowCloseConfirmDialog = false;
    },
    closeFormX() {
      this.isShowCheckBox = false;
      this.btnEdit = true;
      this.btnSave = false;
      this.btnCancel = false;
      this.editable = false;
      this.borderListUser = ""
      this.currentLeader = 0
      this.allUser = this.items.map((item) => ({
        ...item
      }));
      this.isShowCloseConfirmDialog = false;
      this.close();
    },
    async handlerSaveEditTeam(isShowLoading = true) {
      this.isShowCheckBox = false;
      if (this.userNotChecked.length) {
        await Store.dispatch("Error/show", {
          status: 422,
          message: "エラー: " + MESSAGES.ERR_EXISTS_EMPLOYEE_WITHOUT_LEADER,
        });
      } else {
        let userLeaderIds = {};

        Object.entries(this.memberOfLead).forEach(([key, value]) => {
          const ids = value.map((item) => item.id);
          if (!userLeaderIds[key]) {
            userLeaderIds[key] = ids;
          }
        });
        const result = await Store.dispatch(`${STORE}/updateTeamInFieldByCompany`, {user_leader_ids: userLeaderIds}, isShowLoading);
        if (result?.data?.contents?.ids) {
          await Store.dispatch("Toast/show", {
            status: 200,
            message: result?.data?.message,
          });
          this.close()
          this.$emit("updateTeamSuccess")
        } else {
          await Store.dispatch("Error/show", {
            status: 200,
            message: result?.data?.message,
          });
        }
        await this.getItems()
      }
    },
    init() {
      this.memberOfLead = {};
      this.memberIdsOfLead = {};
      this.checked = {};
      this.userNotChecked = [];
      this.leaderTeamIds.map((leaderId) => {
        this.memberOfLead[leaderId] = [];
        this.memberIdsOfLead[leaderId] = [];
        this.checked[leaderId] = [];
      })

      this.userInTeam = this.allUser.filter((item) => {
        if (!item.field_user_id_leader && !this.leaderTeamIds.includes(item.user_id) && item.role === this.ROLE_SUPERVISOR) {
          this.userNotChecked.push(item);
        }
        if (item.field_user_id_leader && item.role === this.ROLE_SUPERVISOR) {
          if (this.leaderTeamIds.includes(item.field_user_id_leader)) {
            this.memberOfLead[item.field_user_id_leader].push(item)
            this.memberIdsOfLead[item.field_user_id_leader].push(item.user_id)
            this.checked[item.field_user_id_leader].push(item.user_id);
          } else {
            this.userNotChecked.push(item);
          }
        }

        return !this.leaderTeamIds.includes(item.user_id)
      })
      let elems = document.querySelectorAll(".show-dialog-edit");
      [].forEach.call(elems, function (el) {
        el.classList.remove("show-dialog-edit");
      });
    },
    close() {
      this.init();
      this.dialog = false;
      this.btnEdit = true;
      this.btnSave = false;
      this.btnCancel = false;
      this.editable = false;
      this.borderListUser = ""
    },
    openCheckBox(id) {
      this.userIdCheckbox = id
      this.arraySelect = [...this.memberOfLead[id], ...this.userNotChecked];
      this.isShowCheckBox = true;
      if (this.currentLeader === id) {
        this.isShowCheckBox = !this.isShowCheckBox;
        this.currentLeader = 0;
      } else {
        this.currentLeader = id;
      }
      const parent = event.target.parentElement
      if (parent.classList.contains('show-dialog-edit')) {
        parent.classList.remove('show-dialog-edit');
        this.userIdCheckbox = null
      } else {
        let elems = document.querySelectorAll(".show-dialog-edit");
        [].forEach.call(elems, function (el) {
          el.classList.remove("show-dialog-edit");
          this.userIdCheckbox = null
        });
        parent.classList.add('show-dialog-edit');
        this.userIdCheckbox = id
      }

    },
    checkSelected(data) {
      const changeSelect = data.selected.filter((item) => {
        return !this.checked[data.currentLeader].includes(item)
      })
      const changeUnSelect = this.checked[data.currentLeader].filter((item) => {
        return !data.selected.includes(item)
      })
      this.checked[data.currentLeader] = data.selected;
      let listUserSelected = [];
      this.leaderTeamIds.map((item) => {
        listUserSelected = [...listUserSelected, ...this.checked[item]]
      })
      this.memberOfLead[data.currentLeader] = this.userInTeam.filter((item) => {
        if (changeUnSelect.includes(item.user_id)) {
          this.userNotChecked.push(item)
        }
        if (changeSelect.includes(item.user_id)) {
          this.userNotChecked = this.userNotChecked.filter((notCheck) => {
            return notCheck.user_id !== item.user_id;
          })
        }
        if (data.selected.includes(item.user_id)) {
          return item;
        }
      })
    }

  },
}
</script>
<style scoped>
.title {
  color: #274FC2;
  font-weight: 600!important;
  font-size: 20px!important;
}

.edit-team-fixed {
  position: absolute;
  left: -5px;
  top: 7px;
  width: 300px;
  height: 200px;
  border-radius: 5px;
  padding: 5px 0px;
  background: white;
  overflow-wrap: break-word;
  display: none;
  z-index: 1;
}

.show-dialog-edit .edit-team-fixed {
  display: inherit;
}

.show-dialog-edit .list-user-team {
  position: relative;
}
.show-dialog-edit .list-user-team:before {
  content: "";
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}

.list-user-team {
  border-radius: 20px;
  padding: 7px 20px;
  color: white;
  min-width: 95px;
  display: inline-block;
  text-align: center;
}

.border-bottom {
  border-bottom: 1px #eeeeee solid;
}

.border-filed-user-list-user {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-bottom: 0;
  position: relative;
  margin-top: 22px;
}

.form-leader-member {
  height: calc(100% - 146px);
  overflow-y: scroll;
}

.wrap-leader-member-team {
  position: relative;
}
</style>
<style>
.border-filed-user-list-user {
  position: relative !important;
}

.field-user-mdi-menu-down-css {
  display: none !important;
}

.border-filed-user-list-user .title-input-item .label {
  position: absolute;
  top: -17px;
}

.border-filed-user-list-user .field-user-mdi-menu-down-css {
  display: inherit !important;
  position: absolute !important;
  right: 5px;
  top: calc(50% - 15px)
}

.style-btn-modal-edit-team .btn-edit-team {
  height: 28px !important;
  min-width: auto !important;
  font-size: 0.75rem !important;
  padding: 0 12px !important;
}
</style>
