<template>
  <v-container class="panel-image">
    <div v-for="(item, index) in items" :key="index" :id="`image_patrol_${index}`">
      <v-img
        :style="getBorderColor(item)" cover min-height="100%" max-height="9vw" max-width="9vw"
        :src="item.image_data? item.image_data: (item.image_url? getUrl(item.id): '')"
        :class="editable ? 'ml-2 mr-4 image-patrol':'ml-2 mr-4'"
        @click="changeImageActive(index)"
        width="120px"
        height="120px"
        contain
      />
    </div>
  </v-container>
</template>
<script>

export default {
  data: () => {
    return {
      isShowSliderImages: false
    };
  },
  props: {
    items: {
      type: Array
    },
    editable: {
      type: Boolean
    },
    sourceImages : Array
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    }
  },
  methods: {
    getUrl(id) {
      return this.sourceImages.find(e => e.id == id)?.url;
    },
    getBorderColor(item) {
      if (item.check) {
        return "border-style: solid;border-color: #32C36C;display: block;";
      }
    },
    changeImageActive(index) {
      this.$emit("changeImageActive", index);
    }
  }
};
</script>
<style lang="scss" scoped>
.check-box-image {
  position: sticky;
  z-index: 1;
  margin-left: 19px;
}

.image-patrol {
  margin-top: -50px;
}

.panel-image {
  overflow: auto;
  display: flex;
  justify-content: center;
}

.panel-image::-webkit-scrollbar-thumb {
  background: #767676;
  border-radius: 10px;
}

.panel-image::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  background-color: #E5E5E5;
  border-radius: 10px;
}
</style>
