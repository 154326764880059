<template>
  <div class="wrapper">
    <div class="title__primary">
      <img src="/images/Airliza.png" alt="-image" width="330" height="60" class=""/>
    </div>
    <div class="description">
      利用規約への同意<br>
      <p>本サービス利用規約（以下、「本規約」といいます。）は、サコス株式（以下、「当社」といいます）が提供する建設現場に関する ASP サービス「高車管理システム（AirLiza）」の利用に関する条件を定めるものです。本サービスをご利用いただくにあたり、利用法人（元請会社、協力会社、レンタル会社等）の皆様（以下、「お客様」といいます）は以下の通り、サービス利用規約への同意が必要です。最後までお読みください。当社は、お客様の承諾無く本規約を変更することがあります。本規約が変更された場合、サービスの提供条件は、変更後の内容によるものとし、お客様は、本規約の変更内容について、異議なく同意するものとします</p>
      <div class="description__head">利用規約</div>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１条（定義）<br/></p></div>
      <p>本規約において次の用語の意味は当該各号に定めるとおりです。</p>
      <p>1. 本サービス
        <br>当社がお客様に提供する会員制建設現場に関する ASP サービス「高車管理システム（AirLiza）」とそれに付随するすべてのオプションを含んだサービスをいいます。</p>
      <p>2. 本アプリ
        <br>当社が提供する本サービスをスマートフォンで使用するためのアプリをいいます。</p>
      <p>3. お客様
        <br>本規約に同意した上で、本サービスをご利用いただく法人（元請会社、協力会社、レンタル会社等）をいいます。</p>
      <p>4. 協力会社
        <br>本サービスを利用して工事を行う下請会社、孫請会社等の工事の実施主体を言います。</p>
      <p>5. 本契約
        <br>利用登録に基づき当社とお客様との間に成立する契約をいい、本規約が適用されます。</p>
      <p>6. ご利用案内
        <br>本サービスの利用情報の表示や、利用申込み、お客様情報の変更、契約内容の変更等を行うためのWebページ若しくは書面をいいます。</p>
      <p>7. フォーム
        <br>本契約に同意してお客様情報を入力いただくフォーマットをいいます。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第２条（規約の適用）</p></div>
      <p>本規約は、本サービスにおける当社とお客様との間に生ずる一切の関係に適用されるものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第３条（規約の変更）</p></div>
      <p>当社は、お客様に承諾を得ることなく、適宜本サービス及び本規約を変更する場合があります。変更した規約については、当社指定の場所に掲示するものとし、お客様が変更後に、本サービスにログインした時点で規約の変更を承諾したものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第４条（通知方法）</p></div>
      <p>1. 当社からお客様に対する通知は、本規約に特に定めのない限り、ご利用案内若しくは電子メールの送信等、当社が適切と判断する方法により通知するものとします。</p>
      <p>2. 当社からお客様に対して、前項の方法により通知した場合において、当社からの通知がお客様に到達しなかったとしても、当該不到達に起因して発生した損害については、当社は一切の責任を負わないものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第５条（サービスの内容）</p></div>
      <p>1. 本サービスは、ASP サービス「高車管理システム（AirLiza）」と付随するすべてのオプションを含んだサービスにより構成されており、お客様はインターネットを経由して、パーソナルコンピュータ又は電子端末を用いて利用することができます。</p>
      <p>2. 当社は、お客様が本サービスを利用して業務を行うためのサーバ環境を構築し、電子端末で使用するための本アプリやその他付随するサービスを提供・運営します。</p>
      <p>3. 本サービスの内容及び機能は、提供を開始し始めたもののため、サービス改善の過程で内容、機能又は仕様等を、お客様への事前通知なしに追加、変更又は削除等を行なう場合があります。</p>
      <p>4. 本サービスの提供に関して、業務の全部又は⼀部を、提供会社の責任において第三者に委託することがあります。ただし、その場合、当社は責任をもって委託先を管理するものとします。</p>
      <p>5. 本サービスの円滑な利用を行うためにお客様が下記の各号にあらかじめ同意いただくものとします。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">本サービス上の種々の条件が一致したお客様同士は、社名及び氏名がお互いのログイン後のエリアに表示される可能性があります。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本サービス上の種々の条件が合致した会社名が他のお客様のログイン後のエリアに表示される可能性があります。</div>
      </div>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第６条（利用申込）</p></div>
      <p>1. 利用申込画面にて、入力頂いた会社情報、個人情報に記載のメールアドレスに、本サービスのログインURLを送りますので、記載されているログインID、パスワードを使用してログインする事で利用可能となります。</p>
      <p>2. AppStore/Googleストアよりスマホアプリをインストールし、入力頂いた所属会社コードまたは所属会社QRコードと個人情報、メールアドレス、パスワードを設定してログインする事で利用可能となります。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第７条（利用期間）</p></div>
      <p>1. 本サービスの利用開始日は、前条の通り、お客様が本サービスにログインした日となります。</p>
      <p>2. 本サービスの利用期間は、第1項に定める利用開始日から本サービスの解除又は第16条による終了時迄とします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第８条（変更の届出）</p></div>
      <p>お客様は、利用申込時の内容に変更がある場合は、直ちに登録画面より変更するものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第９条（当社による契約解除）</p></div>
      <P>1. 当社は、お客様が以下のいずれかの事由に該当した場合、催告その他の手続きを要することなく本契約を解除し、お客様に対する本サービスの提供を直ちに終了することができるものとします。また、契約を解除した時点でデータは削除するものとします。</P>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">当社に対し虚偽の申告をした場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本規約に違反する行為を行った場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">本規約第12条に定める内容に抵触する行為をした場合又はその行為をするおそれがある場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">当社の営業上の重要な変更があったとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">お客様が、本サービスの最終利用日から起算して1年以内に一度もログインしていないとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(6)</div>
        <div class="indent__number-text">解散決議をし、又は他の会社と合併したとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(7)</div>
        <div class="indent__number-text">違法行為及び公序良俗に反する行為をなしたとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(8)</div>
        <div class="indent__number-text">上記各号に準ずる事由が生じたとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(9)</div>
        <div class="indent__number-text">その他当社が本サービスの利用について不適切であると判断したとき</div>
      </div>
      <p>2. 当社はお客様に対し、お客様が前項各号の事由に該当したことにより当社に損害が生じた場合は、損害賠償請求をすることができるものとします。</p>
      <p>3.	当社はお客様に対し、第1項各号に該当することで本サービスの提供を終了したことによりお客様に損害が生じた場合であっても、一切の責任を負わないものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１０条（ID及びパスワードの管理）</p></div>
      <p>1. お客様は、自身のID及びパスワードを適切に管理するものとします。</p>
      <P>2. お客様は、自身のID又はパスワードをお客様以外の第三者に漏洩し、流出させてはなりません。ID又はパスワードについて、漏洩、流出、紛失、盗難等お客様以外の第三者に知られる可能性のある事象が発生した場合には、お客様は、当社に対し、速やかにその事実を報告し、パスワードの変更その他の適切な対応をとるものとします。</P>
      <p>3. お客様によるID及びパスワードの管理が原因、又は前項の対応が原因によりお客様に発生した損害については、お客様がその責任を負うものとし、当社は一切の責任を負いません。また、それらが原因により当社が損害を被った場合には、当社は、お客様に対し発生した損害の賠償を請求することができます。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１１条（登録情報）</p></div>
      <P>1. お客様は、本サービスの利用のためにシステムに登録する情報について、正確な情報を登録し、登録内容に変更があった場合、速やかに登録情報の変更を自ら行い、常に最新の情報を登録するものとします。</P>
      <p>2. 当社は、以下に定める場合、当社の判断で、お客様に事前に通知の上、合理的な範囲内でお客様が登録した情報を削除することがあります。当社は、情報を削除したことによりお客様に生じる損害について、一切の責任を負わないものとします。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">お客様が 第12条に定める禁止行為を行ったときその他本規約に違反したとき</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">その他、当社において削除の必要があると判断したとき</div>
      </div>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１２条（禁止行為）</p></div>
      <p>1. お客様は、本サービスの利用にあたり、以下の行為を行ってはならないものとします。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">当社の承諾を得ずに、本サービスを利用する以外の商用目的その他不正の目的をもって利用する行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本サービスを犯罪の用に供する目的をもって利用する行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">本サービスを犯罪に結びつく又は結びつくおそれの高い形態で利用する行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">システムの登録情報に虚偽又は不正確な情報を登録する行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">本サービスに関連して使用される当社又は第三者の著作権、商標権その他一切の権利を侵害する行為又はそのおそれのある行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(6)</div>
        <div class="indent__number-text">他のお客様の本サービスの利用を妨げる行為、当社の本サービスの運用を妨げる行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(7)</div>
        <div class="indent__number-text">リバースエンジニアリング、逆コンパイル又は逆アセンブル、修正、翻訳、改造する行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(8)</div>
        <div class="indent__number-text">本サービス又は本サービスが接続しているサーバ若しくはネットワークを妨害又は混乱させる等の行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(9)</div>
        <div class="indent__number-text">コンピュータウィルス、スパムメールその他の不正な送信により、本サービスの提供を妨害し、又はその支障となる行為</div>
      </div>
      <div class="indent">
        <div class="indent__number">(10)</div>
        <div class="indent__number-text">本規約に反する行為。その他、当社が合理的理由に基づいて、本サービスの利用が不適切又は不相当と判断する行為</div>
      </div>
      <p>2. 当社は、お客様が前項各号に違反している場合に、以下の各号の措置を行えるものとします。なお、本項の措置によりお客様に生じた損害について、当社は一切の責任を負いません。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">本サービス及びサポートの提供停止</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本サービスに登録された情報の削除</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">お客様情報の開示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">お客様に対する当社に生じた損害の賠償請求</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">その他、当社が適切と判断する措置</div>
      </div>
      <div class="description">
        <div class="description__title-lead"><p>第１３条（守秘義務）</p></div>
        <p>1. 当社は、通信の秘密に係るお客様の情報について、電気通信事業法第4条（秘密の保護）を遵守した取扱いを行うものとします。</p>
        <p>2. 前項の規定にかかわらず、当社は、本サービスの提供のために必要かつ正当な業務行為である場合、並びに法令の定めに基づき許容される場合に限り、前項に定める通信の秘密を知得、利用、第三者に開示する場合があり、お客様はあらかじめこれらについて同意するものとし、当社に対し異議を申し立てることはできません。</p>
      </div>
    </div>  
    <div class="description">
      <div class="description__title-lead"><p>第１４条（個人情報）</p></div>
      <p>1. 当社は、システムに登録された個人情報を、以下の利用目的の範囲内で、目的の達成に必要な限りにおいて利用します。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">本サービスの提供を行うため。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本サービスの運営に関する各種通知や連絡のため。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">当社が提供または提携する商品・サービス等に関する各種お知らせ等の送信、送付のため。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">商品・サービス等の企画、開発等のための情報収集を目的とした調査を実施するため。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">第2項に規定される「共同利用の目的」に記載の目的</div>
      </div>
      <div class="indent">
        <div class="indent__number">(6)</div>
        <div class="indent__number-text">第3項に規定される第三者に対する提供</div>
      </div>
      <p>2. 当社は、お客様がシステムに登録する個人情報を、以下のとおり共同して利用することがあります。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">共同利用されることがある個人情報の項目</div>
      </div>  
      <div class="indent__number-text-list">
        <ul>
          <li>氏名、電話番号、電子メールアドレス、生年月日、会社名、職種、CCUSIDなどの属性情報</li>
        </ul>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">共同利用されることがある者の範囲</div>
      </div>
      <div class="indent__number-text-list">
        <ul>
          <li>建設キャリアアップシステムの運営主体</li>
        </ul>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
          <div class="indent__number-text">共同利用の目的</div>
        </div>
      <div class="indent__number-text-list">
        <ul class="list">
          <li>システム上でお客様の個人認証をするため。</li>
          <li>建設工事にかかわる業務の効率化を目的としたサービス（例えば、施工体制台帳・再下請け通知書・作業員名簿等の作成・管理、建設現場への入退場の記録・管理、作業日報・安全日誌・安全巡視記録等の作成・管理・建設キャリアアップシステムへの就労履歴の連携など）の利用のため。</li>
          <li>システムに関する各種通知や連絡のため。</li>
          <li>サービスや運営や改善のため。</li>
          <li>サービス新機能開発およびそれに付随する調査等のため。</li>
          <li>サービスに関するお問い合わせ・依頼等に対応するため。</li>
          <li>個人を識別できない形式に加工した統計データを作成するため。</li>
        </ul>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">共同利用の管理責任者</div>
      </div>
      <div class="indent__number-text-list">
        <ul class="CMPinfo">
          <li>（住所）東京都品川区東五反田４－５－３</li>
          <li>（会社名）サコス株式会社</li>
        </ul>
      </div>
      <p>3. 当社は、個人情報保護法で認められる場合及び以下に掲げる場合を除き、あらかじめ本人の同意を得ないで、個人データを第三者に提供することはありません。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">本サービス（本アプリを含む。）利用者に対する問い合わせ先として担当者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">レンタル会社管理画面におけるレンタル手配者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">本サービスにおける車両の予約状況確認画面における予約者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">本サービスにおける車両の点検状況確認画面における報告者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">本サービスにおける車両の修理要請状況確認画面における手配者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(6)</div>
        <div class="indent__number-text">本サービスにおける予約済み車両の一覧表における予約者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(7)</div>
        <div class="indent__number-text">前各号の他、本サービス（本アプリを含む。）を用いた利用者間の連絡に必要な利用者情報の表示</div>
      </div>
      <div class="indent">
        <div class="indent__number">(8)</div>
        <div class="indent__number-text">本サービス（本アプリを含む。）利用者に対する問い合わせ先として担当者情報の表示</div>
      </div>
      <p>4. 当社は、その取り扱う個人情報の漏えい、滅失または既存の防止その他個人情報の安全管理のために、必要かつ適切な措置を講じます。当社が講じる安全管理措置については、第6項のお問い合わせ窓口までお問い合わせください。</p>
      <p>5. 当社は、その保有する個人データについて、法令に従って、利用目的の通知、個人情報または第三者提供記録の開示、訂正、追加、削除、利用の停止、第三者提供の停止の請求に対応します。その具体的な方法については、第6項のお問い合わせ窓口までお問い合わせください。</p>
      <p>6. 本項に関するご意見、ご質問、苦情のお申出、ご請求は、以下のお問い合わせ窓口までご連絡ください。</p>
      <ul class="CMPinfo">
        <li>【お問い合わせ窓口】</li>
        <li>東京都品川区東五反田４－５－３</li>
        <li>サコス株式会社</li>
        <li>個人情報取扱責任者：山本 重信</li>
        <li>070-2458-6524</li>
        <li>受付時間：平日 9時～18時</li>
      </ul>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１５条（サービスの停止等）</p></div>
      <p>1. 当社は、以下の各号いずれかの事由が発生した場合は、お客様に事前に通知することなく、本サービスの運用の全部又は一部を中断・停止することができるものとします。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">天災事変、戦争、その他の当社の過失に基づかない事由が発生し又は発生するおそれがあり、電気通信事業法第8条に定める処置をとる場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">上記の法律上の要請如何に拘らず、天災事変、戦争、その他の当社の過失に基づかない事由が発生し、又は発生するおそれがある場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">当社の過失に基づかない電気通信設備の保守上又は工事上やむを得ない事由が生じた場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">当社の過失に基づかない電気通信設備の障害その他やむを得ない事由が発生した場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">法令による規制、司法・行政命令が適用された場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(6)</div>
        <div class="indent__number-text">定期システム保守作業の場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(7)</div>
        <div class="indent__number-text">その他、当社の故意又は重過失を問わず、当社が本サービスの停止をやむを得ないと判断した場合</div>
      </div>
      <p>2. 当社は、前項各号に基づき本サービスの運用の全部又は一部が中断・停止されたことによってお客様に損害が発生した場合については、一切の責任を負わないものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><P>第１６条（本サービスの終了）</P></div>
      <p>1. 当社は、やむを得ない事由がある場合、本サービスの提供を終了することができるものとします。この場合、お客様に対して第4条（通知方法）に定めた手順により、サービス終了の30日前に通知するものとします。</p>
      <p>2. 本サービスを終了した以降、お客様は、本サービスの利用が出来なくなり、お客様のデータに関しては、削除するものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１７条（著作権）</p></div>
      <p>本サービスに付随する全てのソフトウェア及びコンテンツに関する権限及び著作権は、すべて当社に帰属するものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１８条（損害賠償）</p></div>
      <p>お客様の本サービスの利用に伴い、サーバーダウン等その他本サービス利用に関し支障が発生した場合において、お客様が当社に損害を与えた場合、当社は、お客様に対し損害賠償請求（逸失利益及び弁護士費用を含みます。）することができるものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第１９条（非補償・免責）</p></div>
      <p>1. 当社は、お客様に対して、以下の各号の事項について、一切の保証をしません。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">本サービスの内容について、その完全性、正確性及び有効性等</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">本サービスに中断、中止その他の障害が生じないこと</div>
      </div>
      <p>2. 当社は、他条項で定める内容を含め、以下の各号の損害について、一切の責任を負いません。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">お客様が登録情報の変更を行わなかったことによりお客様に生じた損害</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">予期しない不正アクセス等の行為によりお客様に生じた損害</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">本サービスの利用に関連してお客様が日本又は外国の法令に触れたことによりお客様に生じた損害</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">天災、地変、火災、ストライキ、通商停止、戦争、内乱、疫病・感染症の流行その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、お客様に生じた損害</div>
      </div>
      <div class="indent">
        <div class="indent__number">(5)</div>
        <div class="indent__number-text">本サービスの利用に関し、お客様が第三者との間でトラブル（本サービス内外を問いません。）になった場合、お客様に生じた損害</div>
      </div>
      <p>3. 本サービスの提供を受けるために必要な機器、通信手段及び交通手段等の環境は全てお客様の費用と責任で備えます。また、本サービスの利用にあたり必要となる通信費用は、全てお客様の負担とします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第２０条（反社会勢力の排除）</p></div>
      <p>1. 当社及びお客様は、それぞれ相手方に対し、次の各号の事項を確約します。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下、「反社会的勢力」といいます。）ではないこと。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいう）が反社会的勢力ではないこと。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">反社会的勢力に自己の名義を利用させ、本契約を締結するものでないこと。</div>
      </div>
      <div class="indent">
        <div class="indent__number">(4)</div>
        <div class="indent__number-text">本契約の有効期間内に、自ら又は第三者を利用して、次の行為をしないこと。 相手方に対する脅迫的な言動又は暴力を用いる行為、偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</div>
      </div>
      <p>2. 当社又はお客様の一方について、本契約の利用申込中及び有効期間内に、次のいずれかに該当した場合には、その相手方は、何らの催告を要せずして、本契約への申込み手続きを中止及び解除することができます。</p>
      <div class="indent">
        <div class="indent__number">(1)</div>
        <div class="indent__number-text">前項の(1)又は(2)の確約に反する申告をしたことが判明した場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(2)</div>
        <div class="indent__number-text">前項の(3)の確約に反し契約をしたことが判明した場合</div>
      </div>
      <div class="indent">
        <div class="indent__number">(3)</div>
        <div class="indent__number-text">前項の(4)の確約に反する行為をした場合</div>
      </div>
      <p>3. 客様が前項の規定に抵触したことにより本契約を解除したとき、当社は、すみやかに本サービスの提供を中止し且つ登録済データのすべてを削除いたします。また本サービスの提供を中止したことによりお客様に損害が生じた場合であっても、一切責任を負わないものとします。なお、当社に損害が有った場合は、当社はお客様に対し損害賠償請求ができるものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第２１条（情報利用）</p></div>
      <p>お客様は、本サービスの利用に際し、お客様が当社の管理するサーバに対して送信した情報のうち「匿名加工情報」に関しては、個人情報保護法に基づき当社が本サービスの管理・保守並びに会社の営業活動へ使用することや第三者へ提供することを許諾するものとします。この場合、お客様は、その補償を求める権利を有しません。尚、「匿名加工情報」の定義は以下の通りです。</p>
      <p>1. 特定の個人を識別することができないよう加工され（特定個人識別可能性の排除）、かつ当該個人情報を復元することができないようにされた（復元可能性の排除）情報（個人情報保護法2条9項）</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第２２条（分離性）</p></div>
      <p>1. 本規約にいずれかの条項が無効とされた場合でも、本規約の他の条項は引き続き完全な効力を有するものとします。</p>
      <p>2. 本規約のいずれかの条項又はその一部が、あるお客様との関係で無効又は執行不能と判断された場合であっても、他のお客様との関係における有効性等には影響を及ぼさないものとします。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><P>第２３条（合意管轄）</P></div>
      <P>当社とお客様との間で訴訟の必要が生じた場合、当社の本社所在地を管轄する裁判所を専属管轄裁判所とします。</P>
    </div>
    <div class="description">
      <div class="description__title-lead"><p>第２４条（準拠法）</p></div>
      <p>本規約の成立、効力、履行及び解釈に関しては、日本国法を適用します。</p>
    </div>
    <div class="description">
      <div class="description__title-lead"><P>第２５条（その他）</P></div>
      <P>1. 本サービスを使用したお客様の役員、従業員、顧客及び関係者が行った行為についても、本規則の適用においては利用企業等の行為とみなします。</P>
      <P>2. 本規約に定めのない事項については、法令に従いお客様と当社が協議の上、誠意を持って解決にあたる事とします。</P>
    </div>
    <div class="description">
      <div class="description__title-lead"><P>付則（本規約の発行）</P></div>
      <P>本利用規約は、令和6年1月１日から施行します。</P>
    </div>
  </div>
</template>
<script>
//タイトル
const PAGE_TITLE = "利用規約";

export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
}
</script>
<style lang="scss" scoped>
      @import "@/assets/scss/themes.scss";
      body {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: sans-serif;
        background-color: white;
        color: black;
      }
    
      .wrapper {
        max-width: 1200px;
        margin: 48px auto;
      }
    
      .title__primary {
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
        letter-spacing: 0em;
        text-align: left;
        color: blue;
        margin-bottom: 64px;
      }
    
      .description__head{
        background-color: blue;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 20px;
        padding: 20px;
        margin-top: 20px;
      }
    
      .description {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 8px 0;
      }
    
      .indent {
        display: flex; 
        margin-bottom: 30px;
      }
    
    
      .indent__number {
        padding-left: 16px; 
      }
    
    
      .indent__number-text {
        display: flex; 
        padding-left: 15px;
      }



      .indent__number-text-list {
        padding-left: 18px;
        margin: -20px 0px 30px 0px;
      }


      ul.list li {
        margin: 10px 0px;
      }

      .CMPinfo {
        list-style: none;
      }

    
      .description__title{
        font-size: 2.0rem;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
    
    
      .description__title-lead{
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
    
      @media (max-width: 414px) {
        .title__primary {
          font-size: 48px;
        }
        .description__head{
          background-color: blue;
          text-align: center;
          color: white;
          font-weight: bold;
          padding: 5px;
        }
        .wrapper {
          max-width: 414px;
        }
        .description{
          max-width: 300px;
        }
      }
    </style>
