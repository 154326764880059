<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
      <v-spacer></v-spacer>
      <v-btn v-if="showDialog" small icon text @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <v-card-text v-html="text"></v-card-text>
        <v-card-text v-html="text2"></v-card-text>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined class="btn" color="primary" @click="$emit('close')"> {{ textClose }} </v-btn>
      <v-btn depressed class="btn" color="primary" @click="$emit('yes')">
        {{ textSubmit }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  components: {},
  props: {
    text: {
      type: String,
    },
    text2: {
      type: String,
    },
    title: {
      type: String,
    },
    textClose: {
      default: 'いいえ',
      type: String,
    },
    textSubmit: {
      default: 'はい',
      type: String,
    },
    showDialog: {
      default: true,
      type: Boolean,
    }
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;

  .title {
    color: #274fc2;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto';
    font-style: normal;
  }
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      position: relative;
      padding: 20px 0 20px 0 !important;
      .text {
        text-align: start;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 90%;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 0 !important;
      }
      .v-card__text {
        width: 85%;
        margin: 0px auto;
        padding: 0px 20px;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
