/**
 *  PC/SP で出し分けるURL
 */

import { isSP } from "@/utils/responsive.js";

/**
 * 現在のデバイスタイプ
 */
const LINK_TYPE = isSP() ? "SP" : "PC";

/**
 * ログイン成功後の遷移先
 * -> パスを指定
 */
const LOGIN_LINKS = {
  PC: {
    LOGIN_SUCCESS: `/master/fields`,
  },
  //SP: { LOGIN_SUCCESS: `/sp/siteSelect` },
  SP: { LOGIN_SUCCESS: `/master/fields` },
};

/**
 * ルーター内でトークンで判定する遷移先
 */
const TOKEN_REDIRECT_LINKS = {
  PC: {
    HAS_TOKEN: "/master/fields",
  },
  //SP: {
  //  HAS_TOKEN: "/sp/siteSelect",
  //},
  SP: {
    HAS_TOKEN: "/master/fields",
  },
};

export { LOGIN_LINKS, TOKEN_REDIRECT_LINKS, LINK_TYPE };
