const TAB = [
  {id: 0, name: '作業前点検'},
  {id: 1, name: '月例点検'},
  {id: 2, name: '年次点検'},
  {id: 3, name: '出庫前点検'},
  {id: 4, name: '協力会社別貸与実績'},
  {id: 5, name: '班別貸与実績'},
  {id: 6, name: '機械別貸与実績'},
  {id: 7, name: '棚卸結果'},
];
const TAB_SHARE = [
  {id: 0, name: '占有機'},
  {id: 1, name: '共用機'},
];
const TITLE = '帳票出力';
const TABLE_LABELS = [
  {
    text: "ナンバー",
    value: "number_plate",
    width: '10%'
  },
  {
    text: "",
    value: 'data-table-select',
  },
  {
    text: "全て選択",
  },
];
const TABLE_LABELS_REPORT = [
  
  {
    text: "協力会社",
    value: "companyName",
  },
  {
    text: "職長",
    value: "userName",
  },
  {
    text: "携帯番号",
    value: "userPhone",
  },
  {
    text: "ナンバー",
    value: "numberPlate",
  },
  {
    text: "機種",
    value: "machineSpecs",
  },
  {
    text: "予約期間",
    value: "bookingDate",
  },
  {
    text: "日数",
    value: "dateBooking",
  },
];
const TABLE_LABELS_RENTAL = [
  {
    text: "ナンバー",
    value: "numberPlate",
  },
  {
    text: "機種",
    value: "machineSpecs",
  },
  {
    text: "予約期間",
    value: "bookingDate",
  },
  {
    text: "協力会社",
    value: "companyName",
  },
  {
    text: "職長",
    value: "userName",
  },
  {
    text: "携帯番号",
    value: "userPhone",
  },
  {
    text: "日数",
    value: "dateBooking",
  },
];
const TABLE_LABELS_RESULT_BY_SUB_CONTACTOR = [
  {
    text: "協力会社",
    value: "companyName",
  },
  {
    text: "ナンバー",
    value: "numberPlate",
  },
  {
    text: "機種",
    value: "machineSpecs",
  },
  {
    text: "予約期間",
    value: "bookingDate",
  },
  {
    text: "日数",
    value: "dateBooking",
  },
];
const TABLE_LABELS_RESULT_BY_SUB_CONTACTOR_SHARE = [
  {
    text: "協力会社",
    value: "companyName",
  },
  {
    text: "ナンバー",
    value: "numberPlate",
  },
  {
    text: "機種",
    value: "machineSpecs",
  },
  {
    text: "回数",
    value: "total_tenken",
  },
];
const TABLE_LABELS_REPORT_INVEMTORY = [
  {
    text: "棚卸日",
    value: "stockTakingInfomationTakeAt",
    width: '10%'
  },
  {
    text: "読み取り台数",
    value: "countMachine",
  },
];
const SORT_ITEMS = [
  {
    id: 1,
    name: "項目名",
  },
];
const SORT_ITEMS_BY_GROUP = [
  {
    id: 1,
    name: "職長名",
  },
];
const SORT_ITEMS_BY_SUB = [
  {
    id: 1,
    name: "協力会社",
  },
];
const SORT_ITEMS_BY_MACHINE = [
  {
    id: 1,
    name: "ナンバー",
  },
];
const INVENTORY_RESULT_SORT_ITEMS = [
  {
    id: 1,
    name: "棚卸日",
  },
];
const INITIAL_ITEM = {
  entries: {
    d2t_crud: {
      id: null,
      name: null,
      description: null,
      sex: 1,
      age: null,
      file: null,
    }
  },
};
const TAB_PREWORK = 0;
const TAB_MONTHY = 1;
const TAB_ANNUA = 2;
const TAB_PRE = 3;
const TAB_RESULTS = 4;
const TAB_RESULT = 5;
const TAB_RENTAL_RESULTS = 6;
const TAB_INVENTORY = 7;
export {
  TAB,
  TAB_SHARE,
  TITLE,
  TABLE_LABELS,
  INITIAL_ITEM,
  SORT_ITEMS,
  TABLE_LABELS_REPORT,
  TABLE_LABELS_REPORT_INVEMTORY,
  TABLE_LABELS_RESULT_BY_SUB_CONTACTOR,
  TABLE_LABELS_RESULT_BY_SUB_CONTACTOR_SHARE,
  INVENTORY_RESULT_SORT_ITEMS,
  TABLE_LABELS_RENTAL,
  TAB_PREWORK,
  TAB_MONTHY,
  TAB_ANNUA,
  TAB_PRE,
  TAB_RESULTS,
  TAB_RESULT,
  TAB_RENTAL_RESULTS,
  TAB_INVENTORY,
  SORT_ITEMS_BY_SUB,
  SORT_ITEMS_BY_GROUP,
  SORT_ITEMS_BY_MACHINE
};
