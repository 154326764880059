<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                    <Label class="title-input-item" :editable="editable">
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メールアドレス" :editable="editable" required>
                              <InputText
                                name="email"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="メールアドレス"
                                validation_rules="required|max:255|email"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="電話番号" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="phone"
                                :values="formValues"
                                :editable="editable"
                                validation_label="電話番号"
                                validation_rules="max:16|required|phone"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="姓" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_sei"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="姓"
                                validation_rules="required|max:64"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="名" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_mei"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="名"
                                validation_rules="required|max:64"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="セイ" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="kana_sei"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="セイ"
                                validation_rules="required|max:128|kana"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メイ" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="kana_mei"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="メイ"
                                validation_rules="required|max:128|kana"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="24" sm="12" md="6">
                          <v-list-item-content>
                            <Label label="CCUS技能者ID" class="title-input-item" :editable="editable">
                              <InputText
                                name="ccus_id"
                                :editable="editable"
                                @onInput="onInput"
                                :values="formValues"
                                validation_rules="ccusid:CCUS技能者ID"
                                validation_label="CCUS技能者ID"
                                :disabled="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </Label>
                    </v-col>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                    <Label class="title-input-item" :editable="editable">
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="郵便番号" :editable="editable">
                              <InputText
                                name="postal_code"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="郵便番号"
                                validation_rules="max:8|postcode"
                                @onInput="onChangePostalcode"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="都道府県" class="title-input-item" :editable="editable">
                              <Select
                                name="prefecture_id"
                                :values="formValues"
                                :items="prefectures"
                                :editable="editable"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="市区町村" class="title-input-item" :editable="editable">
                              <InputText
                                name="city"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="市区町村"
                                validation_rules="max:255"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="番地・建物名" class="title-input-item" :editable="editable">
                              <InputText
                                name="address_detail"
                                :values="formValues"
                                :editable="editable"
                                placeholder=""
                                validation_label="番地・建物名"
                                validation_rules="max:255"
                                @onInput="onInput($event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </Label>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import {Store} from "@/store/Store";
import {DEFAULT_SELECT_BLANK} from "@/constants/USER";
import {kuroshiro} from "@/utils/convertKana";
import _ from "lodash";
import { postalCodeSearch } from "@/utils/postalCodeSearch";

export default {
  data: () => {
    return {
      formValues: {},
      prefectures: [],
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    Select,
    FileInput,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
  },
  async mounted() {
    Store.dispatch("Prefectures/get");
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Prefectures/getData`],
      (data) => {
        this.prefectures = data.map((item) => {
          return { id: item.id , name: item.prefecture }
        });
        this.prefectures = [DEFAULT_SELECT_BLANK, ...this.prefectures]
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      if (name == 'name_sei' || name == 'name_mei') {
          this.getNameKana( name, formValues[name]);
        }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async getNameKana( name, value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues =  _.cloneDeep(this.formValues);
      if (name == 'name_sei') {
        formValues.kana_sei = result;
      }
      if (name == 'name_mei') {
        formValues.kana_mei = result;
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async onChangePostalcode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      if (value == "") {
        formValues.prefecture_id = null;
        formValues.city = '';
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if(result){
          formValues.prefecture_id = result.prefecture.id;
          formValues.city = result.city;
        }
      }

      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
