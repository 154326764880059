const TAB_RECEIVE_ORDER = [
  {id: 0, name: '納品依頼'},
  {id: 1, name: '修理依頼'},
  {id: 2, name: '入庫依頼'}
];

// ---------------------Tab 1 ( tab delivery requests)------------------------
const RECEIVE_ORDERS_STATUS_TAB_1 = [
  {
    id: 0,
    name: "未回答"
  },
  {
    id: 1,
    name: "回答済"
  }
];

const RECEIVE_ORDERS_SORT_TAB_1 = [
  {
    id: 1,
    name: "受注日"
  },
  {
    id: 2,
    name: "元請"
  },
  {
    id: 3,
    name: "搬入希望日"
  }
];

const MACHINE_REQUEST_DETAILS_STATUS = [
  {
    status: 3,
    name: "未回答",
    color: "#E5951D"
  },
  {
    status: 4,
    name: "承諾",
    color: "#e5951d"
  },
  {
    status: 5,
    name: "辞退",
    color: "#e5951d"
  }
];

const DELIVERY_REQUEST_STATUS = {
  "STATUS_PENDING" : 2,
  "STATUS_ACCEPT" : 3,
  "STATUS_CANCEL" : 5
};

const RECEIVE_ORDERS_LABEL_TAB_1 = [
  {
    text: "",
    align: "center",
    sortable: false,
    value: "status_flag",
  },
  {
    text: "搬入希望日",
    align: "left",
    sortable: true,
    value: "m_desired_delivery_date"
  },
  {
    text: "受注日",
    align: "left",
    sortable: true,
    value: "m_order_at"
  },
  {
    text: "元請",
    align: "left",
    sortable: true,
    value: "main_company_name"
  },
  {
    text: "現場",
    align: "left",
    sortable: false,
    value: "field_name"
  },
  {
    text: "仕様",
    align: "left",
    sortable: false,
    value: "spec"
  },
  {
    text: "台数",
    align: "left",
    sortable: false,
    value: "total"
  },
  {
    text: "搬入ゲート",
    align: "left",
    sortable: false,
    value: "gate_name"
  },
  {
    text: "特殊機能",
    align: "left",
    sortable: false,
    value: "special_feature"
  }
];

const SORT_PARAMS_TAB_DELIVERY_REQUEST = [
  'm_order_at',
  'company_kana_name',
  'm_desired_delivery_date'
];

// ---------------------Tab 2 ( tab repair requests)------------------------

const RECEIVE_ORDERS_STATUS_TAB_2 = [
  {
    id: 0,
    name: "未対応"
  },
  {
    id: 1,
    name: "見積済"
  },
  {
    id: 2,
    name: "修理報告済"
  }
]

const RECEIVE_ORDERS_SORT_TAB_2 = [
  {
    id: "order_at",
    name: "受注日",
  },
  {
    id: "company_kana_name",
    name: "元請"
  },
  {
    id: "product_code",
    name: "商品コード"
  },
  {
    id: "machine_number",
    name: "機番"
  }
];

const REPAIR_REQUEST_PROCESS_STATUS = [
  {
    status: 2,
    name: "未対応",
    color: "#E5951D"
  },
  {
    status: 3,
    name: "見積済",
    color: "#e5951d"
  },
  {
    status: 4,
    name: "修理報告済",
    color: "#e5951d"
  }
]

const RECEIVE_ORDERS_LABEL_TAB_2 = [
  {
    text: "",
    align: "center",
    sortable: false,
    value: "status_flag",
  },
  {
    text: "",
    align: "left",
    sortable: false,
    value: "report_type"
  },
  {
    text: "受注日",
    align: "left",
    sortable: false,
    value: "order_at"
  },
  {
    text: "元請",
    align: "left",
    sortable: false,
    value: "company_name"
  },
  {
    text: "現場",
    align: "left",
    sortable: false,
    value: "field_name"
  },
  {
    text: "商品コード",
    align: "left",
    sortable: false,
    value: "product_code"
  },
  {
    text: "機番",
    align: "left",
    sortable: false,
    value: "machine_number"
  },
  {
    text: "ナンバー",
    align: "left",
    sortable: true,
    value: "number_plate"
  },
  {
    text: "留置場所",
    align: "left",
    sortable: false,
    value: "field_places_name"
  }
];

const REPAIR_STATUS = {
  "STATUS_PENDING" : 2,
  "STATUS_ESTIMATE" : 3,
  "STATUS_DONE" : 4
};

// ---------------------Tab 3 ( tab return requests)------------------------

const RECEIVE_ORDERS_SORT_TAB_3 = [
  {
    id: "request_at",
    name: "受注日"
  },
  {
    id: "company_name_kana",
    name: "元請"
  },
  {
    id: "scheduled_return_date", // r_scheduled_return_type, r_scheduled_return_time
    name: "引取希望日"
  },
  {
    id: "product_code",
    name: "商品コード"
  },
  {
    id: "machine_number",
    name: "機番"
  }
];

const RECEIVE_ORDERS_LABEL_TAB_3 = [
  {
    text: "引取希望日",
    align: "left",
    sortable: false,
    value: "scheduled_return_date"
  },
  {
    text: "受注日",
    align: "left",
    sortable: true,
    value: "request_at"
  },
  {
    text: "元請",
    align: "left",
    sortable: true,
    value: "company_name"
  },
  {
    text: "現場",
    align: "left",
    sortable: false,
    value: "field_name"
  },
  {
    text: "搬出ゲート",
    align: "left",
    sortable: false,
    value: "gate_name"
  },
  {
    text: "商品コード",
    align: "left",
    sortable: true,
    value: "product_code"
  },
  {
    text: "機番",
    align: "left",
    sortable: false,
    value: "machine_number"
  },
  {
    text: "ナンバー",
    align: "left",
    sortable: false,
    value: "number_plate"
  },
  {
    text: "注意事項",
    align: "left",
    sortable: false,
    value: "comment"
  }
];

// ---------------------Modal Request Details------------------------
const LIST_LIFTING_METHOD = [
  {
    id: 0,
    name: "垂直昇降"
  },
  {
    id: 1,
    name: "直伸"
  },
  {
    id: 2,
    name: "屈折"
  },
];

const LIST_UNDERCARRIAGE = [
  {
    id: 1,
    name: "ホイール"
  },
  {
    id: 2,
    name: "クローラ"
  },
];

const LIST_WORKING_FLOOR = [
  {
    id: 1,
    name: "普通"
  },
  {
    id: 2,
    name: "スライド"
  },
  { id: 3,
    name: "バケット"
  },
];

const TEXT_CONFIRM_RESPONSE = {
  TITLE: "回答",
  TEXT1: "受注した機械について回答します。 ",
  TEXT2: "この処理を元に戻すことはできません。",
}

const LIFTING = [
  { id: 0, value: 0, name: "垂直昇降" },
  { id: 1, value: 1, name: "直伸ブーム" },
  { id: 2, value: 2, name: "屈折ブーム" },
];

const UNDERCARRIAGE = [
  { id: 0, value: 0, name: "" },
  { id: 1, value: 1, name: "ホイール" },
  { id: 2, value: 2, name: "クローラ" },
];

const WORKING_FLOOR = [
  { id: 0, value: 0, name: "" },
  { id: 1, value: 1, name: "普通デッキ" },
  { id: 2, value: 2, name: "スライドデッキ" },
  { id: 3, value: 3, name: "バケット" },
];

const TIME = [
  { id: 1, value: 1, name: "当日中" },
  { id: 2, value: 2, name: "午前" },
  { id: 3, value: 3, name: "午後" },
  { id: 4, value: 4, name: "時間指定" },
];

export {
  TAB_RECEIVE_ORDER,
  RECEIVE_ORDERS_STATUS_TAB_1,
  RECEIVE_ORDERS_SORT_TAB_1,
  RECEIVE_ORDERS_LABEL_TAB_1,
  MACHINE_REQUEST_DETAILS_STATUS,
  DELIVERY_REQUEST_STATUS,
  SORT_PARAMS_TAB_DELIVERY_REQUEST,
  RECEIVE_ORDERS_STATUS_TAB_2,
  RECEIVE_ORDERS_SORT_TAB_2,
  RECEIVE_ORDERS_LABEL_TAB_2,
  REPAIR_REQUEST_PROCESS_STATUS,
  REPAIR_STATUS,
  RECEIVE_ORDERS_LABEL_TAB_3,
  RECEIVE_ORDERS_SORT_TAB_3,
  LIST_LIFTING_METHOD,
  LIST_UNDERCARRIAGE,
  LIST_WORKING_FLOOR,
  TEXT_CONFIRM_RESPONSE,
  LIFTING,
  UNDERCARRIAGE,
  WORKING_FLOOR,
  TIME,
}
