import Vue from "vue";
import Vuex from "vuex";
import { login } from "../../../api/modules/login";
import { resetMail } from "../../../api/modules/resetMail";
import { resetPassword, changePassword } from "../../../api/modules/resetPassword";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";
import { STORAGE_KEY } from "@/constants/GLOBALHEADER";

Vue.use(Vuex);

export const Login = {
  namespaced: true,

  state: {
    api_token: null,
    refresh_token: null,
    user: {},
    company: {},
    companyBranch: [],
    company_user: {},
    fields: [],
    isDrawingApp: false, // お絵描きアプリフラグ
    isLoading: false,
  },

  mutations: {
    SET_USER(state, payload) {
      const { data, user, fields} = payload.contents.entries;
      const { company, company_branch, company_user } = user;
      state.user = user;
      state.user.login_id = data.login_id;
      state.company = company ? company : null;
      state.companyBranch = company_branch ? company_branch : null;
      state.company_user = company_user ? company_user : null;
      state.api_token = data.api_token;
      state.refresh_token = data.refresh_token;
      if (fields && fields.length) state.fields = fields;
      // console.log("Login", { ...state });
    },
    LOGOUT(state) {
      state.user = {};
      state.company = {};
      state.company_user = {};
      state.companyBranch = [];
      state.api_token = null;
      state.refresh_token = null;
    },
    SET_RESET_TOKEN(state, payload) {
      state.api_token = payload.reset_token;
    },
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    SET_DRAWING_APP(state, payload) {
      state.isDrawingApp = payload;
    },
    DELETE_TOKEN(state) {
      state.api_token = null;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    }
  },

  actions: {
    /**
     * ログイン
     */
    async login({ commit }, payload) {
      const response = await login.post(payload);
      if (response.hasError) {
        return { ...response };
      }
      const { data } = response;
      if (data.result) {
        // local storageにフラグを追加
        localStorage.setItem(LOGIN_FLAG_KEY, 1);
        commit("SET_USER", data);
      }
      return data;
    },

    /**
     * ログアウト
     */
    async logout({ commit }) {
      const response = await login.logout();
      // 403 のセッション切れは除外
      if (response.hasError && response.response.status !== 403) {
        return response;
      }
      commit("LOGOUT");
      // local storageにフラグを削除
      localStorage.removeItem(LOGIN_FLAG_KEY);
      sessionStorage.removeItem("COMPANY_USER");
      sessionStorage.removeItem("USER");
      sessionStorage.removeItem("MMS");
      sessionStorage.removeItem(STORAGE_KEY);
      sessionStorage.removeItem('CURRENT_SITE');
      return response;
    },

    /**
     *会社情報を登録
     */
    setCompany({ commit }, payload) {
      commit("SET_COMPANY", payload);
    },

    /**
     * リセットパスワード
     * リセットトークンを追加
     */
    setResetToken({ commit }, payload) {
      commit("SET_RESET_TOKEN", payload);
    },
    //パスワードリセット
    async resetPassword(context, payload) {
      return await resetPassword.post(payload);
    },
    
    async changePassword({ commit }, payload) {
      commit("SET_IS_LOADING", true);
      const response = await changePassword.post(payload);
      commit("SET_IS_LOADING", false);
      return response;
    },

    /**
     * リセットメールの送信
     */
    async resetMail(context, payload) {
      return await resetMail.post(payload);
    },

    /**
     * ログインを保持
     */
    async loginReacqire({ commit, dispatch }) {
      const result = await login.postReacuire();
      const { data } = result;
      if (!result.hasError) {
        commit("SET_USER", data);
        dispatch("GlobalHeader/init", null, { root: true });
      } else {
        //再ログイン失敗
        commit("LOGOUT");
        // local storageにフラグを削除
        localStorage.removeItem(LOGIN_FLAG_KEY);
        sessionStorage.removeItem("COMPANY_USER");
        sessionStorage.removeItem("MMS");
        sessionStorage.removeItem(STORAGE_KEY);
        return result;
      }
      return data;
    },

    /**
     * お絵描きアプリフラグ
     * @param {bool} payload
     */
    isDrawingApp({ commit }, payload) {
      commit("SET_DRAWING_APP", payload);
    },

    deleteToken({ commit }) {
      commit("DELETE_TOKEN");
    },

    updateUser({ commit }, payload) {
      commit("UPDATE_USER", payload);
    },
  },

  getters: {
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.api_token;
    },
    getCompany: (state) => {
      return state.company;
    },
    getFields: (state) => {
      return state.fields;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
  },
};
