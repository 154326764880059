const MACHINE_STATUS = {
    AVAILABLE: { id: 1, name: "予約あり" },
    UNAVAILABLE: { id: 0, name: "予約なし" },
};
const LIFTING_METHOD = {
    VERTICAL_LIFT: {id: 0, name: "垂"},
    NAOSHINOBU : {id: 1, name: "直"},
    REFRACTION: {id: 2, name: "屈"},
};
const UNDERCARRIAGE = {
    WHEEL: {id: 1, name: "ホ"},
    CRAWLER : {id: 2, name: "ク"},
};
const WORKING_FLOOR = {
    ORDINARILY: {id: 1, name: "普"},
    SLIDE : {id: 2, name: "ス"},
    BUCKET: {id: 3, name: "バ"},
}
const MACHINE_TAB_SELECT = {
    INVENTORY_LIST_TAB: {id: 1, name: "現場在庫一覧"},
    ADDITIONAL_REQUIREMENTS_TAB: {id: 2, name: "追加要求"},
    DELIVERY_SCHEDULE_TAB: {id: 3, name: "搬入予定"},
    LIST_REPORT_TAB: {id: 4, name: "報告一覧"},
    RETURNS_TAB: {id: 5, name: "返却予定"},
}

const MACHINE_INVENTORY_TAB_TABLE_LABELS = [
    {
        text: "",
        align: "left",
        sortable: false,
        value: "status",
        width: '90px'
    },
    {
        text: "ナンバー",
        align: "left",
        sortable: false,
        value: "number_plate",
        width: '125px'
    },
    {
        text: "機種",
        align: "left",
        sortable: false,
        value: "model",
        width: '310px'
    },
    {
        text: "予約者",
        value: "reservation_person",
        align: "left",
        sortable: false,
        width: '215px'
    },
    {
        text: "予約期間",
        value: "date_booking",
        align: "left",
        sortable: false,
        width: '170px'
    },
    {
        text: "留置場所",
        value: "place_of_detention",
        align: "left",
        sortable: false,
        width: '115px'
    },
    {
        text: "棚卸日",
        value: "inventory_date",
        align: "left",
        sortable: false,
        width: '120px'
    },
    {
        text: "使用期間",
        value: "date_field_machines",
        align: "left",
        sortable: false,
        width: '155px'
    },
];
const MACHINE_DELIVERY_TAB_TABLE_LABELS = [
    {
        text: "レンタル会社",
        align: "left",
        sortable: false,
        value: "company",
        width: '230px'
    },
    {
        text: "搬入予定日",
        align: "left",
        sortable: false,
        value: "delivery_date",
        width: '270px'
    },
    {
        text: "搬入ゲート",
        align: "left",
        sortable: false,
        value: "loading_gate",
        width: '140px'
    },
    {
        text: "ナンバー",
        value: "number",
        align: "left",
        sortable: false,
        width: '130px'
    },
    {
        text: "機種",
        value: "model",
        align: "left",
        sortable: false,
        width: '370px'
    },
    {
        text: "出庫前点検",
        value: "shipment",
        align: "left",
        sortable: false,
        width: '160px'
    },
];
const TITLE_OPTIONS_TAB_ONE = [
  {id: "status", name: "ステータス"},
  {id: "start_date", name: "使用開始日"},
  {id: "end_date", name: "使用終了日"},
  {id: "move_at", name: "棚卸日"},
  {id: "number_plate", name: "ナンバー"},
];
const TITLE_OPTIONS = [
  {id: "status", name: "ステータス"},
  {id: "request_date", name: "要求日"},
  {id: "use_start_date", name: "使用開始日"},
];
const TITLE_REPORT_OPTIONS = [
  {id: "repair_status", name: "ステータス"},
  {id: "report_date", name: "報告日"},
  {id: "company_renta_name_kana", name: "手配先"},
  {id: "number_plate", name: "ナンバー"},
];
const TITLE_PLAN_PAY_OPTIONS = [
  {id: "name_kana", name: "返却先"},
  {id: "scheduled_return_date", name: "搬出予定日"},
];
const TITLE_PLAN_OPTIONS = [
    {id: "name_kana", name: "レンタル会社"},
    {id: "scheduled_delivery_date", name: "搬入予定日"},
    {id: "gate_name", name: "搬入ゲート"},
];
export {
    MACHINE_TAB_SELECT,
    MACHINE_STATUS,
    LIFTING_METHOD,
    UNDERCARRIAGE,
    WORKING_FLOOR,
    MACHINE_INVENTORY_TAB_TABLE_LABELS,
    MACHINE_DELIVERY_TAB_TABLE_LABELS,
    TITLE_OPTIONS_TAB_ONE,
    TITLE_OPTIONS,
    TITLE_REPORT_OPTIONS,
    TITLE_PLAN_PAY_OPTIONS,
    TITLE_PLAN_OPTIONS
};