<template>
  <FormDialog :style="getBodyStyle">
    <template #header>
      <!-- 
         (共通)
         ヘッダーは全て共通
        -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="primary--text">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="defaultWidth == 1902">
          <v-btn
            v-if="editable"
            class="mr-5"
            depressed
            small
            outlined
            color="primary"
            @click="isShowConfirmDialog = true"
          >
            やめる
          </v-btn>
          <v-btn
            v-if="editable"
            depressed
            small
            :disabled="isSubmitted || !valid"
            :key="isErrorSubmit"
            color="primary"
            @click.once="onSubmit"
          >
            保存
          </v-btn>
          <v-btn
            v-if="!editable"
            depressed
            small
            color="primary"
            @click="onEditable"
          >
            編集
          </v-btn>
        </template>
        <template>
          <v-btn
            v-if="editable"
            class="mr-5"
            depressed
            small
            outlined
            color="primary"
            @click="isShowConfirmDialog = true"
          >
            やめる
          </v-btn>
          <v-btn
            depressed
            small
            :disabled="isSubmitted || !valid"
            color="primary"
            class="mx-1 px-5"
            @click="onSubmit"
          >
            確定
          </v-btn>
        </template>
        <v-btn icon @click="onClickBtnClose" class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
         (共通)
         全てのフォームのバリデーションの監視を行う
        -->
       <ValidationObserver ref="observer" v-slot="observer">
         <GoodsIssueProcedure
          :editable="editable"
          :detailData="item"
          :mainHeight="params.mainHeight"
          :actions="actions"
          :active_flg="item.active_flg"
          @formUpdate="formUpdate"
          :itemMachineSelected="itemMachineSelected"
        />
         <!-- 
           (共通)
           ValidationObserverのスロットプロパティを受け取って
           updateValidateメソッドを実行して、
           バリデーション結果をスクリプト側に渡してます
         -->
         <ValidationCallback :observer="observer" @callback="updateValidate" />
       </ValidationObserver>
  
      <div class="dialog-header sticky pt-8 pb-6 d-flex justify-end">
      </div>
 
       <!-- TODO他のフォームもここに追加して v-ifで出し分け -->
       <Popup width="480px" :dialog="isShowConfirmDialog">
         <ConfirmCloseDialog
           title="フォームを閉じる確認"
           text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
           text2="このページから移動してもよろしいですか？"
           @close="onClickBtnCancelForm"
           @yes="closeForm"
           warning
         />
       </Popup>
     </template>
   </FormDialog>
 </template>
 
 <script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import {TITLE_FORM_2, FORMS_CREATE_EDIT, WIDTH_DEFAULT} from "@/constants/MACHINE";
import _ from "lodash";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import GoodsIssueProcedure from "./components/GoodsIssueProcedure";
const STORE = "Machines";

export default {
  data: () => {
    return {
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      TITLE_FORM_2,
      FORMS_CREATE_EDIT,
      beforeUpdateItem: {},
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      actions: "",
      itemMachineSelected: [],
      defaultWidth: WIDTH_DEFAULT,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    GoodsIssueProcedure,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    selectedItems: Array,
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        //新規の場合は最初から編集モード
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.selectedItems,
      (data) => {
        if (data) {
          this.itemMachineSelected = data;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`${STORE}/getMachines`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * Catch after submitted
     */
    this.$watch(
      () => this.isEmitted,
      () => {
        if (!this.isErrorSubmit) {
          this.editable = false;
        }
      }
    );
  },
  computed: {
    // 新規or編集でタイトル変更
    formtitle() {
      return TITLE_FORM_2;
    },
    getBodyStyle() {
      let addStyle = "";
      if (window.innerHeight > 650) {
        addStyle = `min-height: 640px;`;
      }
      let style = `max-width:${this.defaultWidth}px;` + addStyle;
      return style;
    },
  },
  methods: {
    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      this.$emit("submit");
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * (共通)
     * フォーム閉じる時に確認ダイアログを表示
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
  /**
   * Important: clear timeout
   */
  beforeDestroy() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  },
};
</script>
 
 <style lang="css" scoped>
.from-close-btn {
  float: right;
}
.FormDialog {
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

div::v-deep .v-card {
  height: auto !important;
}
@media screen and (max-width:1024px) {
  div::v-deep .v-card.v-sheet {
    height: 596px !important;
    overflow: auto;
  }
  .FormDialog {
    top: 60% !important;
  }
}
@media screen and (max-height:768px) {
  div::v-deep .label {
    padding-top: 0 !important;
  }
}
</style>
 