import Api from "../api";
const FIELDS_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${FIELDS_BASE_URL}/get_list_field_info`;
const END_USE = `${FIELDS_BASE_URL}/end_use_field`;
const CANCEL_END_USE = `${FIELDS_BASE_URL}/cancel_end_use_field`;
const PERMIT_ON_SITE_FORM = `${FIELDS_BASE_URL}/permit_on_site_form_output`;
const END_FORM_OUTPUT_ON_SITE = `${FIELDS_BASE_URL}/end_form_output_on_site`;
const APPROVE = `${FIELDS_BASE_URL}/approve_field_by_ids`;
const ADD_MASTER_FIELD_INFO = `${FIELDS_BASE_URL}/add_master_field_info`;
const UPDATE_MASTER_FIELD_INFO_BY_ID = `${FIELDS_BASE_URL}/update_master_field_info_by_id`;
const GET_MASTER_FIELD_INFO = `${FIELDS_BASE_URL}/get_master_field_info`;
const DELETE_MASTER_FIELD_INFO_BY_ID = `${FIELDS_BASE_URL}/delete_master_field_info_by_id`;
const GET_LIST_PARTNER_COMPANY_BY_FIELD_ID = `${FIELDS_BASE_URL}/get_list_partner_company_by_field_id`;
const GET_LIST_SPACE_BY_FIELD_ID = `${FIELDS_BASE_URL}/get_list_space_by_field_id`;
const GET_LIST_MACHINE_FOR_BOOKING = `${FIELDS_BASE_URL}/get_list_machine_for_booking`;
const GET_LIST_FIELD_NOT_FINISH = `${FIELDS_BASE_URL}/get_list_field_not_finish`;
const GET_LIST_GATE_BY_FIELD = `${FIELDS_BASE_URL}/get_list_gate_by_field_id`;
const GET_LIST_RELATED_FIELD_BY_USER = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_related_field_by_user`;
const GET_LIST_MAIN_CONSTRUCTOR_BY_USER = `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_main_constructor_by_user`;
const GET_LIST_FIELD_BY_OWNER_COMPANY= `${process.env.VUE_APP_API_BASE_URL}/fields/get_list_field_by_owner_company`;
const GET_END_USE_OF_FIELD = `${FIELDS_BASE_URL}/get_end_use_of_field`;
 
export const fields = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  endUse: (params) => {
    return Api.post(END_USE, params);
  },
  cancelEndUse: (params) => {
    return Api.post(CANCEL_END_USE, params);
  },
  permitOnSite: (params) => {
    return Api.post(PERMIT_ON_SITE_FORM, params);
  },
  endFormOutput: (params) => {
    return Api.post(END_FORM_OUTPUT_ON_SITE, params);
  },
  approve: (params) => {
    return Api.post(APPROVE, params);
  },
  addMasterFieldInfo: (params) => {
    return Api.post(ADD_MASTER_FIELD_INFO, params);
  },  
  updateMasterFieldInfoById: (params) => {
    return Api.post(UPDATE_MASTER_FIELD_INFO_BY_ID, params);
  }, 
  getMasterFieldInfo: (params) => {
    return Api.get(GET_MASTER_FIELD_INFO, params);
  },
  deleteMasterFieldInfoById: (params) => {
    return Api.post(DELETE_MASTER_FIELD_INFO_BY_ID, params);
  },
  getListPartnerCompanyByFieldId: (params) => {
    return Api.get(GET_LIST_PARTNER_COMPANY_BY_FIELD_ID, { params: params });
  },
  getListSpaceByFieldId: (params) => {
    return Api.get(GET_LIST_SPACE_BY_FIELD_ID, { params: params });
  },
  getListMachineForBooking: (params) => {
    return Api.get(GET_LIST_MACHINE_FOR_BOOKING, { params: params });
  },
  getListFieldNotFinish: (params) => {
    return Api.get(GET_LIST_FIELD_NOT_FINISH, { params: params });
  },
  getListGateByField: (params) => {
    return Api.get(GET_LIST_GATE_BY_FIELD, { params: params });
  },
  getListRelatedFieldByUser: (params) => {
    return Api.get(GET_LIST_RELATED_FIELD_BY_USER, params);
  },
  getListMainConstructorByUser: (params) => {
    return Api.get(GET_LIST_MAIN_CONSTRUCTOR_BY_USER, params);
  },
  getListFieldByOwnerCompany: (params) => {
    return Api.get(GET_LIST_FIELD_BY_OWNER_COMPANY, { params: params });
  },
  getEndUseOfField: (params) => {
    return Api.get(GET_END_USE_OF_FIELD, { params: params });
  }
};
