import Vue from "vue";
import Vuex from "vuex";
import { globalheader } from "../../api/modules/globalheader";
import { STORAGE_KEY } from "@/constants/GLOBALHEADER";

Vue.use(Vuex);

// ローカルストレージで保持
const SessionValues = () => {
  const values = {};
  const set = (key, value) => {
    let _values = { ...values };
    _values[key] = value;
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(_values));
  };
  const get = (key) => {
    const _values = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return _values && key in _values ? _values[key] : null;
  };
  return {
    set,
    get,
  };
};

/**
 * global header内で使うデータを扱うstore
 */

// api名を指定
const ENTITY = globalheader;

export const GlobalHeader = {
  namespaced: true,

  state: {
    // 自社ポータル 選択中のメニュー項目id
    inhouseMenuId: 0,

    siteMenuId: 0,

    // 現場ポータル 選択中のメニュー項目id
    menuSite: 0,

    //選択している現場
    selectedSite: {},

    // 現場ポータル時の選択リスト: 現場リスト
    siteList: [],
  },

  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, payload);
    },
    SET_INHOUSE_MENU(state, payload) {
      state.inhouseMenuId = payload;
    },

    SET_SITE_MENU(state, payload) {
      state.siteMenuId = payload;
    },

    SET_SITE_LIST(state, payload) {
      state.siteList = payload;
    },
    SET_SITE(state, payload) {
      state.selectedSite = payload;
    },
  },

  actions: {
    /**
     * リロード時に実行
     */
    async init({ dispatch }) {
      // TODO 現場一覧を取得 & 現場を指定
      // 選択現場を取得
      const values = SessionValues().get("site");

      if (values) {
        // 現場選択
        await dispatch("setSite", { field_id: values });
      }
    },
    
    /**
     * 現場一覧を取得
     */
     async searchSites({ commit }, payload) {
      let params = {};
      // 現場名検索
      if (payload?.keyword) params.field_name = payload.keyword;
      const response = await ENTITY.searchSites(params);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_SITE_LIST", entries);
      return response;
    },

    /**
     * 自社ポータルの選択したヘッダーメニュー
     */
    setInHouseMenu({ commit }, { menuId }) {
      commit("SET_INHOUSE_MENU", menuId);
    },

    setSiteMenu({ commit }, { menuId }) {
      commit("SET_SITE_MENU", menuId);
    },

    /**
     * 選択した現場を保持
     */
    setSite({ commit, state }, { field_id }) {
      const selectedSite = state.siteList.find((item) => {
        return item.field_id === field_id;
      });

      // id を保持
      SessionValues().set("site", field_id);
      commit("SET_SITE", selectedSite);
    },

    /**
     * アプリ用に選択した現場を保持してレスポンス返す
     */
    setSiteForApp({ commit, state }, { field_id }) {
      const selectedSite = state.siteList.find((item) => {
        return item.field_id === field_id;
      });
      // id を保持
      if (selectedSite) {
        SessionValues().set("site", field_id);
        commit("SET_SITE", selectedSite);
        return true;
      }
      return false;
    },
  },

  getters: {
    /**
     * 現場の一覧を返す
     */
    getSiteList: (state) => {
      return state.siteList;
    },
    /**
     * 選択している自社ポータルのメニュー
     */
    getInHouseMenuId: (state) => {
      return state.inhouseMenuId;
    },

    getSiteMenuId: (state) => {
      return state.siteMenuId;
    },

    /**
     * 選択している現場
     * @param {*} state
     */
    getCurrentSite: (state) => {
      return state.selectedSite;
    },
  },
};
