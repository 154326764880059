import Api, { Mock } from "../api";
import notificationList from "./mock/notification_list.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}/notices/get_notification`;
const COUNT_UNREAD_NOTIFICATION = `${process.env.VUE_APP_API_BASE_URL}/notices/count_unread_notification`;
const READ_NOTIFICATION_BY_USER = `${process.env.VUE_APP_API_BASE_URL}/notices/read_notification_by_user`;

// TODO apiができたら
// import Api, { Mock } from "../apiForceMock";
// ↓
// import Api, { Mock } from "../api";
// に変更する

if (Mock) {
  Mock.onGet(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: notificationList,
  });

  Mock.onGet(COUNT_UNREAD_NOTIFICATION).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      new_notice_status: 1,
    },
  });
  Mock.onPost(READ_NOTIFICATION_BY_USER).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {
      ids: [1],
    },
  });
}

export const notification = {
  get: () => {
    return Api.get(URL);
  },
  countUnreadNotification: () => {
    return Api.get(COUNT_UNREAD_NOTIFICATION);
  },
  readNotificationByUser: async (params) => {
    return Api.post(READ_NOTIFICATION_BY_USER, params);
  },
};
