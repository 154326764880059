export const NOTIFICATION_COLOR = {
  ORANGE_LIGHT: { id: 1, key: "orange_light", color: "#E5951D" },
  GREEN: { id: 2, key: "green", color: "#6CA955" },
  RED: { id: 3, key: "red", color: "#B00020" },
};

export const NOTIFICATION_MESSAGE_TYPE = [
  { id: 1, name: "利用申請" },
  { id: 2, name: "終了申請" },
  { id: 3, name: "竣工予定" },
  { id: 4, name: "竣工" },
  { id: 5, name: "連携依頼" },
  { id: 6, name: "連携解除" },
  { id: 7, name: "追加要求" },
  { id: 8, name: "納品依頼" },
  { id: 9, name: "点検時異常報告" },
  { id: 10, name: "修理要請" },
  { id: 11, name: "修理依頼" },
  { id: 12, name: "修理報告" },
  { id: 13, name: "割付" },
  { id: 14, name: "入庫依頼" },
  { id: 15, name: "返却通知" },
  { id: 16, name: "移動通知" }
];
export const NOTIFICATION_SYS = [
  { id: 1, name: "予定管理" },
  { id: 2, name: "巡回記録" },
  { id: 3, name: "労務管理" },
  { id: 4, name: "自社管理" },
  { id: 5, name: "資機材管理" },
];

export const NOTIFICATION_URGENCY = [
  { id: 0, name: "低", tag_color: 4 },
  { id: 1, name: "中", tag_color: 1 },
  { id: 2, name: "高", tag_color: 2 },
  { id: 3, name: "急", tag_color: 5 },
];
