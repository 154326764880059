<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ text }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined color="primary" class="btn" @click="$emit('close')"> やめる </v-btn>
      <v-btn depressed class="btn" color="primary" @click="$emit('yes')">
        承認
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data: () => ({}),

  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 90%;
      }
    }
  }
  .btn {
    margin-right: 12px;
    border: 1px solid #274FC2;
  }

  .title {
    color: #274FC2;
  }
}
</style>
