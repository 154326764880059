<template>
  <FormDialog>
    <template #header>
      <!-- 
        (共通)
        ヘッダーは全て共通
      -->
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ FORM_TITLE }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>

        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="success"
          @click="onStopEdit"
        >
          やめる
        </v-btn>

        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="onSubmit"
        >
          保存
        </v-btn>

        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <!-- 
        (共通)
        全てのフォームのバリデーションの監視を行う
       -->
      <ValidationObserver ref="observer" v-slot="observer">
        <CrudFormInput
          :editable="editable"
          :item="item"
          :mainHeight="params.mainHeight"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import {  FORM_TITLE,MASTER_FORM_TABS,DASHBOARD_TABS } from "@/constants/CRUDS";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON.js";
import  { ENV_CLIENT } from "@/constants/ENV_CLIENT.js";
import CrudFormInput from "./CrudFormInput.vue";

//ストア
const STORE = "Cruds";

export default {
  data: () => {
    return {
      isBack: false,
      MASTER_FORM_TABS,
      DASHBOARD_TABS,
      FORM_TITLE,
      valid: false, //バリデーション結果
      // tab初期化
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: {},
      errors: {},
      isSubmitted: false,
      timeout: null,
      SUBMIT_DELAY_TIMEOUT,
      flagTab6 : false,
      flagTab5 : false,
      locationSelect : [],
      idSelect : null,
      ENV_CLIENT
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    Popup,
    CrudFormInput,
    FormDialog,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    companyBranches: Object,
    isErrorSubmit: Boolean,
    isEmitted: Boolean,
    flag:Boolean,
    flagSupervisor: {
      type: Boolean,
      default: false
    },
    flagUserRentalCompany: {
      type: Boolean,
      default: false
    },
    flagErrorUser: Boolean,
    flagEditSuccess : Boolean
  },
  mounted() {
    /**
     * (共通)
     * 新規作成を監視
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.flagEditSuccess,
      (newFlag,oldFlag) => {
        if(newFlag != oldFlag) {
          this.flagTab5 = !this.flagTab5;
          this.flagTab6 = !this.flagTab6;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`${STORE}/getCrud`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * Catch after submitted
     */
    this.$watch(
        () => this.isEmitted,
        () => {
          if (!this.isErrorSubmit) {
            this.editable = false;
          }
        }
    );
  },
  methods: {
    updateFlag() {
      this.$emit("updateFlag");
    },

    /**
     * (共通)
     * フォームの変更を送信
     */
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    /**
     * (共通)
     * 状態を編集に変更
     */
    onEditable() {
      this.editable = true;
    },
    /**
     * (共通)
     * 状態を詳細に変更
     */
    onDetail() {
      this.editable = false;
    },

    onStopEdit() {
      this.isShowConfirmDialog = true;
    },

    /**
     * (共通)
     * 登録
     */
    onSubmit() {
      this.isSubmitted = true;
      this.$emit("submit");
      // Re-enable after submit
      this.timeout = setTimeout(() => {
        this.isSubmitted = false
      }, SUBMIT_DELAY_TIMEOUT);
    },

    /**
     * (共通)
     * フォーム全体のバリデーション
     */
    updateValidate(params) {
      const { valid, errors } = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.addKeyConstructions();
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        // cancel form
        this.$emit("cancel");
      }
    },

    /**
     * (共通)
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    addKeyConstructions() {
      this.beforeUpdateItem.field_constructions.forEach((element, index) => {
        element['key'] = `${Date.now()}_${index}`;
      })
      let first_id = this.beforeUpdateItem.field_constructions[0]?.id;
      const req = (locations) => {
        locations?.forEach((element, i) => {
          element.field_images?.forEach((image) => {
            if(image.id && !image.deleted) {
              let itemConstruction = this.beforeUpdateItem.field_constructions.find(item => item.id == first_id);
              image['key'] = itemConstruction['key'];
            }
          });
          if (element.children && element.children.length > 0) {
            req(locations[i].children);
          }
        });
      };
      req(this.beforeUpdateItem.field_constructions[0]?.field_item_trees)
    },
    updateListRight(data){
      this.$emit("updateListRight", data);
    },

    filterSelect(e, i) {
      this.locationSelect = e;
      this.idSelect = i;
    },
  },

    /**
   * Important: clear timeout
   */
  beforeDestroy () {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout)
  },
  computed : {
    IS_OBAYASHI(){
      return ENV_CLIENT.OBAYASHI === process.env.VUE_APP_CLIENT;
    }
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
