import {format} from "date-fns";

export const PARTNER_ROLE = 2;
export const RENTAL_ROLE = 4;

const MACHINE_INITIAL_ITEM = {
    entries: {
      'lifting_method': 0,
      'undercarriage': 1,
      'working_floor': 1,
      'drive': 0
    },
  };

  const MACHINE_DELIVERY_IMFO_ITEM = {
    entries: {
      'scheduled_delivery_type': 0,
      'scheduled_delivery_time': "08:00",
      'start_bookable_date': format(new Date(), "yyyy/MM/dd"),
      'end_bookable_date': format(new Date(), "yyyy/MM/dd"),
      'scheduled_delivery_date': format(new Date(), "yyyy/MM/dd"),
    },
  };
  const MINS_ON_HOUR = ["00"];
  const createEnterDayTime = () => {
    let result = [];
    let i = 0;
    while (i <= 23) {
      for (let m of MINS_ON_HOUR) {
        let hour = i < 10 ? "0" + i : i;
        let element = {
          id: hour + ":" + m,
          name: hour + ":" + m,
        };
        result.push(element);
      }
      i++;
    }
    result.push({id: "12:00", name: "12:00"});
    return result;
  };

  const ENTER_DAY_TIME_ARRAY = createEnterDayTime();
  const TAB_MACHINE = [
    {id: 0 , name: '在庫'},
    {id: 1 , name: '出庫予定'},
    {id: 2 , name: '稼働機械'}
  ]
  const TAB_PRODUCTION_INFO = [
    {id: 2 , name: '基本情報'},
    {id: 1 , name: '年次点検'},
  ]
  const TAB_PRE_DELIVERY = [
    {id: 2 , name: '点検結果'},
    {id: 1 , name: '写真'},
  ]
  /**
   * (共通)
   * タイトル
   */
  const FORM_TITLE = "CRUD Form";
  const CRUD_TITLE = "cruds";
  
  const MACHINE_TABLE_LABELS_TAB_1 = [
    {
      text: "商品コード",
      align: "left",
      sortable: false,
      value: "product_code",
      width:"11%",
    },
    {
      text: "機番",
      value: "machine_number",
      align: "left",
      sortable: false,
      width:"12%",
    },
    {
      text: "ナンバー",
      value: "number_plate",
      align: "left",
      sortable: false,
      width:"12%",
    },
    {
      text: "商品名",
      value: "machine_name",
      align: "left",
      sortable: false,
      // width:"25%",
    },
  ];
  const TITLE_FORM_2 = '出庫手続き';
  const TITLE_FORM_1 = '商品情報';
  const PRE_DELIVERY = 'SACOS 1002 / 4m 垂直昇降 クローラ スライドデッキ';
  const TITLE_DETAIL_MACHINE_INVENTORY = '現場機械情報';

  const MACHINE_TABLE_LABELS_TAB_2 = [
    {
      text: "元請",
      align: "left",
      sortable: true,
      value: "company_name",
      width:"10%",
    },
    {
      text: "現場",
      value: "field_name",
      align: "left",
      sortable: false,
      width:"13%",
    },
    {
      text: "搬入予定日",
      value: "scheduled_delivery_date",
      align: "left",
      sortable: false,
      width:"10%",
    },
    {
      text: "搬入ゲート",
      value: "gate_name",
      align: "left",
      sortable: false,
      width:"10%",
    },
        {
      text: "商品コード",
      value: "product_code_machine",
      align: "left",
      sortable: false,
      width:"8%",
    },
    {
      text: "機番",
      value: "machine_number_machine",
      align: "left",
      sortable: false,
      width:"7%",
    },
    {
      text: "ナンバー",
      value: "number_plate_machine",
      align: "left",
      sortable: false,
      width:"10%",
    },
    {
      text: "商品名",
      value: "name_machine",
      align: "left",
      sortable: false,
      width:"18%",
    },
    {
      text: "出庫前点検表",
      value: "id_inspection_before_deliveries",
      align: "left",
      sortable: false,
      width:"10%",
    },
  ];

  const MACHINE_TABLE_LABELS_TAB_3 = [
    {
      text: "元請",
      align: "left",
      sortable: true,
      value: "company_name",
      width:"20%",
    },
    {
      text: "現場",
      value: "field_name",
      align: "left",
      sortable: false,
      width:"15%",
    },
    {
      text: "商品コード",
      value: "product_code",
      align: "left",
      sortable: false,
      width:"12%",
    },
    {
      text: "機番",
      value: "machine_number",
      align: "left",
      sortable: false,
      width:"10%",
    },
    {
      text: "ナンバー",
      value: "number_plate",
      align: "left",
      sortable: false,
      width:"10%",
    },
    {
      text: "商品名",
      value: "machine_name",
      align: "left",
      sortable: false,
      width:"15%",
    },
    {
      text: "使用期間",
      value: "bookable_date",
      align: "left",
      sortable: false,
      width:"10%",
    },
  ];


  const MACHINE_SORT_ITEMS_TAB_1 = [
    {
      id: "1",
      name: "商品コード",
    },
    {
      id: "2",
      name: "機番",
    },
  ];

  const MACHINE_SORT_ITEMS_TAB_2 = [
    {
      id: "0",
      name: "出庫手続日"
    },
    {
      id: "1",
      name: "搬入予定日"
    },
    {
      id: "2",
      name: "元請"
    },
    {
      id: "5",
      name: "現場"
    },
    {
      id: "3",
      name: "商品コード"
    },
    {
      id: "4",
      name: "機番"
    }
  ]

  const MACHINE_SORT_ITEMS_TAB_3 = [
    {
      id: "0",
      name: "使用期間"
    },
    {
      id: "1",
      name: "使用終了予定日"
    },
    {
      id: "2",
      name: "元請"
    },
    {
      id: "5",
      name: "現場"
    },
    {
      id: "3",
      name: "商品コード"
    },
    {
      id: "4",
      name: "機番"
    }
  ]

  const LIFTING = [
    { id: 0, value: 0, name: "垂直昇降" },
    { id: 1, value: 1, name: "直伸ブーム" },
    { id: 2, value: 2, name: "屈折ブーム" },
  ];
  const UNDERCARRIAGE = [
    { id: 1, value: 1, name: "ホイール" },
    { id: 2, value: 2, name: "クローラ" },
  ];
  const WORKING_FLOOR = [
    { id: 1, value: 1, name: "普通デッキ" },
    { id: 2, value: 2, name: "スライドデッキ" },
    { id: 3, value: 3, name: "バケット" },
  ];
  const DRIVE = [
    { id: 0, value: 0, name: "モーター" },
    { id: 1, value: 1, name: "エンジン" },
  ];
  const TIME = [
    { id: 0, value: 0, name: "当日中" },
    { id: 1, value: 1, name: "午前" },
    { id: 2, value: 2, name: "午後" },
    { id: 3, value: 3, name: "時間指定" },
  ];
  const TABLE_LABELS = [
    {
      text: "商品コード",
      align: "left",
      sortable: true,
      value: "product_code",
      width:"150px !important"
    },
    {
      text: "機番",
      value: "machine_number",
      align: "left",
      sortable: false,
      width:"150px !important"
    },
    {
      text: "商品名",
      value: "machine_name",
      align: "left",
      sortable: false,
    },
  ];
  const TAB_ONE_MACHINE_FORM_TWO = [
    { id: 1, title: "基本情報" },
  ];
  const MACHINE_ITEM = [
    { 
      id: 1 , 
      text: "破損、欠落、カケ、割れ、陥没等がないか。",
    },
    { 
      id: 2 , 
      text: "破損、欠落、変形等がないか。",
    },
    { 
      id: 3 , 
      text: "機体アース・機能接地用アースが確実にとれているか。",
    },
    {
      id: 4 , 
      text: "作動はよいか。",
    },
    { 
      id: 5 , 
      text: "損傷はないか",
    },
    {
      id: 6 , 
      text: "ガス漏れは無いか。圧力はよいか。",
    },
  ]
  const SELECT_TAB_FIRST = 1;
  const SELECT_TAB_SECOND = 2;
  const CREATE = 'create';
  const EDIT = 'edit';
  const SCHEDULE_DELIVERY_TYPE = 3;
  const WIDTH_DEFAULT = 1300;
  const STATUS_TAB_SELECT = [
    { "id": 0, "name": "未発注" },
    { "id": 1, "name": "対応済" },
    { "id": 2, "name": "手配済" },
    { "id": 3, "name": "発注済" },
    { "id": 4, "name": "割付済" },
  ];
  const LIFTING_TAB_SELECT = [
    { "id": 0, "name": "垂" },
    { "id": 1, "name": "直" },
    { "id": 2, "name": "屈" },
  ];
  const UNDERCARRIAGE_TAB_SELECT = [
    { "id": 1, "name": "ホ" },
    { "id": 2, "name": "ク" },
  ];
  const WORKING_FLOOR_TAB_SELECT = [
    { "id": 1, "name": "普" },
    { "id": 2, "name": "ス" },
    { "id": 3, "name": "バ" },
  ];
  const LIFTING_PLAN_PAY_SELECT = [
    { "id": 0, "name": "垂" },
    { "id": 1, "name": "直" },
    { "id": 2, "name": "屈" },
  ];
  const UNDERCARRIAGE_PLAN_PAY_SELECT = [
    { "id": 1, "name": "ホ" },
    { "id": 2, "name": "ク" },
  ];
  const WORKING_FLOOR_PLAN_PAY_SELECT = [
    { "id": 1, "name": "普" },
    { "id": 2, "name": "ス" },
    { "id": 3, "name": "バ" },
  ];
  const TABS = {
    'TAB_ONE': 0,
    'TAB_TWO': 1,
    'TAB_THREE': 2,
    'TAB_FOUR': 3,
    'TAB_FIVE': 4,
  }

  const MACHINE_REPORT_TAB_TABLE_LABELS = [
    {
      text: "",
      value: "repair_status",
      align: "left",
      sortable: false,
      width: '90px'
    },
    {
      text: "",
      value: "report_kind",
      align: "left",
      sortable: false,
      width: '145px'
    },
    {
      text: "報告日",
      value: "report_date",
      align: "left",
      sortable: false,
      width: '162px'
    },
    {
      text: "",
      value: "act",
      align: "center",
      sortable: false,
      width: '30px',
    },
    {
      text: "報告者",
      value: "reporter",
      align: "left",
      sortable: false,
      width: '220px'
    },
    {
      text: "手配先",
      value: "company_renta_name",
      align: "left",
      sortable: false,
      width: '150px'
    },
    {
      text: "ナンバー",
      value: "number_plate",
      align: "left",
      sortable: false,
      width: '130px'
    },
    {
      text: "機種",
      value: "model",
      align: "left",
      sortable: false,
      width: '238px'
    },
    {
      text: "留置場所",
      value: "place_name",
      align: "left",
      sortable: false,
      width: '120px'
    },
  ];

  const STATUS_MACHINE_REQUEST_DETAIL = ['未発注', '対応済', '手配済', '発注済', '割付済'];
  const HEADER_ADDITIONAL_REQUIREMENT = [
    { 
      text: '', 
      value: 'machine_request_detail_status',
      sortable: false,
      width: '30px'
    },
    { 
      text: '使用開始日', 
      value: 'use_start_date',
      sortable: false,
      width: '100px'
    },
    { 
      text: '要求日', 
      value: 'request_date',
      sortable: false,
      width: '50px'
    },
    { 
      text: '要求者', 
      value: 'requester',
      sortable: false,
      width: '250px'
    },
    { 
      text: '機種', 
      value: 'name',
      sortable: false,
      width: '250px'
    },
    { 
      text: '台数', 
      value: 'id',
      sortable: false,
      width: '80px' 
    },
    { 
      text: '特殊機能', 
      value: 'special_feature',
      sortable: false,
      width: '100px'
    }
  ];
  const HEADER_PLAN_TO_PAY = [
    { 
      text: 'レンタル会社',
      value: 'name',
      sortable: false,
      width: '200px'
    },
    { 
      text: '搬出予定日', 
      value: 'scheduled_return_date',
      sortable: false,
      width: '170px'
    },
    { 
      text: '搬出ゲート', 
      value: 'gate_name',
      sortable: false,
      width: '150px'
    },
    { 
      text: '使用終了日', 
      value: 'use_end_date',
      sortable: false,
      width: '150px'
    },
    { 
      text: 'ナンバー', 
      value: 'number_plate',
      sortable: false,
      width: '150px'
    },
    { 
      text: '機種', 
      value: 'floor_height',
      sortable: false,
      width: '250px'
    },
    { 
      text: '登録者', 
      value: 'name_sei',
      sortable: false,
      width: '250px'
    },
    { 
      text: '注意事項', 
      value: 'comment',
      sortable: false,
      width: '120px'
    },
  ];

  const LIST_KEY_NAME_STATUS_REPORT_TAB = {
    notCompatible: null,
    onSite: 1,
    rentalArranged: 2,
    estimated: 3,
    reportedForRepair: 4,
    //MMSV2-1573-start
    approved: 5
    //MMSV2-1573-start
  };

  const LIST_STATUS_REPORT_TAB = {
    null: "未対応",
    1: "現場で対応",
    2: "レンタル会社を手配",
    3: "見積済",
    4: "修理報告済",
    //MMSV2-1573-start
    5: "承認済"
    //MMSV2-1573-end
  };

  const LIST_KIND_REPORT_TAB = {
    1: "点検時異常報告",
    2: "修理要請",
  };

  const TYPE_REPORT_KIND = {
    inspectionResult: '1',
    requestResult: '2',
  }

  const TYPE_REPORT_KIND_API = {
    inspectionResult: 0,
    requestResult: 1,
    //MMSV2-1573-start
    approveInspec: 2,
    approveRepair: 3,
    //MMSV2-1573-end
  }

  const LEADER_ROLE = 2;

  const ATTACH_FILE_TYPE = {
    repairQuote: 0,
    repairReport: 1
  }

  export {
    TAB_MACHINE,
    FORM_TITLE,
    MACHINE_TABLE_LABELS_TAB_1,
    MACHINE_TABLE_LABELS_TAB_2,
    MACHINE_TABLE_LABELS_TAB_3,
    MACHINE_SORT_ITEMS_TAB_1,
    MACHINE_SORT_ITEMS_TAB_2,
    MACHINE_SORT_ITEMS_TAB_3,
    CRUD_TITLE,
    MACHINE_INITIAL_ITEM,
    TITLE_FORM_1,
    TITLE_FORM_2,
    LIFTING,
    UNDERCARRIAGE,
    WORKING_FLOOR,
    DRIVE,
    TIME,
    TABLE_LABELS,
    TAB_PRODUCTION_INFO,
    TAB_ONE_MACHINE_FORM_TWO,
    MACHINE_DELIVERY_IMFO_ITEM,
    ENTER_DAY_TIME_ARRAY,
    PRE_DELIVERY,
    TAB_PRE_DELIVERY,
    MACHINE_ITEM,
    SELECT_TAB_FIRST,
    SELECT_TAB_SECOND,
    CREATE,
    EDIT,
    SCHEDULE_DELIVERY_TYPE,
    WIDTH_DEFAULT,
    STATUS_TAB_SELECT,
    LIFTING_TAB_SELECT,
    UNDERCARRIAGE_TAB_SELECT,
    WORKING_FLOOR_TAB_SELECT,
    LIFTING_PLAN_PAY_SELECT,
    UNDERCARRIAGE_PLAN_PAY_SELECT,
    WORKING_FLOOR_PLAN_PAY_SELECT,
    MACHINE_REPORT_TAB_TABLE_LABELS,
    TABS,
    STATUS_MACHINE_REQUEST_DETAIL,
    HEADER_ADDITIONAL_REQUIREMENT,
    HEADER_PLAN_TO_PAY,
    LIST_STATUS_REPORT_TAB,
    LIST_KIND_REPORT_TAB,
    LIST_KEY_NAME_STATUS_REPORT_TAB,
    TYPE_REPORT_KIND,
    TYPE_REPORT_KIND_API,
    LEADER_ROLE,
    ATTACH_FILE_TYPE,
    TITLE_DETAIL_MACHINE_INVENTORY
  };
  
